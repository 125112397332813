
export class Timer {
    public numOfBits?: number;
    public width?: number;
    // public height? : number;
    public bitsArr;
    public bitWidth = 3;
    public bitHeight;
    public radius;

    constructor(_numOfBits, _width) {
        this.numOfBits = _numOfBits;
        this.width = _width;
        this.bitsArr = new Array<number>(this.numOfBits).fill(0);

        this.radius = this.width / 2.2;
        this.bitHeight = this.width * 0.025;
    }

    setDegree(i) {
        return ((360 * i) - this.bitWidth) / this.numOfBits;
    }

    setActiveBits(num) {
        console.log(num)

        for (let i = 0; i < num; i++) {
            setTimeout(() => {
                this.bitsArr[i] = 1;
            }, i * 50);
        }
        console.log(this.bitsArr)
    }

    private setActiveBit(spot, isActive) {
        console.log('in', this.bitsArr[spot])
        this.bitsArr[spot] = isActive;
    }



}