import { CacheService, beneficiary } from './../../services/cache.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { CordovaService } from 'src/app/core/services/cordova.service';

@Component({
  selector: 'distribution-of-percentages',
  templateUrl: './distribution-of-percentages.component.html',
  styleUrls: ['./distribution-of-percentages.component.scss']
})
export class DistributionOfPercentagesComponent implements OnInit {
  beneficiaries: any;
  hundredPrecents = 100
  error = false

  constructor(private cacheService: CacheService, private cdf: ChangeDetectorRef,
    private analyticService: AnalyticsService,
    private cordovaService: CordovaService) {
    // if(this.cacheService.cache[3] && Object.keys(this.cacheService.cache[3]).length > 1){
    //   this.beneficiaries = this.cacheService.cache[3]
    //   this.beneficiaries = this.cacheService.cache[2].filter(el => !!(el.checked))
    //   this.beneficiaries.map(el => this.hundredPrecents -= el.precents)
    // }
    // else if(this.cacheService.cache[2]){
    //   this.beneficiaries = this.cacheService.cache[2].filter(el => !!(el.checked))
    //   this.devideIntoPrecentage()
    // }

    //render the page
    if (this.cacheService.cache[3] && this.cacheService.cache[3].length > 1) {
      this.beneficiaries = this.cacheService.cache[3]
      this.devideIntoPrecentage()
    }

    // else if(this.cacheService.cache[3] && this.cacheService.cache[3].length <= 1){
    //   this.cacheService.currentTab.next(4)
    // }
  }

  ngOnInit() {
    this.analyticService.trackByMixpanel("DP - Risk - Ben Percentage");
    this.analyticService.trackByAppFlyer("DP - Risk - Ben Percentage", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });


  }

  devideIntoPrecentage() {
    let numOfBeneficiaries = this.beneficiaries.length;
    if (numOfBeneficiaries > 0) {
      let equalPrecent = Math.floor(100 / numOfBeneficiaries)
      this.beneficiaries = this.beneficiaries.map(el => {
        // el.precents = equalPrecent
        el.Percentage = equalPrecent

        return el
      })
      this.hundredPrecents = 100 - (equalPrecent * numOfBeneficiaries)
    }
    else {
      this.hundredPrecents = 100
    }
    // this.cdf.detectChanges()
  }
  recalculatePrecentage(e) {
    let elements = document.querySelectorAll('input')
    let changedElement = Array.prototype.findIndex.call(elements, (el, ind) => el === e.target)
    let val = Number(e.target.value.slice(0, 3))
    this.beneficiaries.map((el, ind) => {
      if (val >= 100) {
        if (ind === changedElement) {
          el.Percentage = 0
          let ell: any = elements[changedElement]
          ell.value = 0
        }
        else {
          let precents = Math.floor(100 / (this.beneficiaries.length - 1))
          el.Percentage = precents
        }
      }
      else {
        if (ind === changedElement)
          el.Percentage = val
        else {
          let precents = Math.floor(((100 - val) / (this.beneficiaries.length - 1)))
          el.Percentage = precents
        }
      }
    })
    let counter = 100
    this.beneficiaries.forEach(el => counter -= el.Percentage)
    this.hundredPrecents = counter
    this.cdf.detectChanges()
  }

  onlyOnce = 0;
  nullify(index) {
    if (!!!this.onlyOnce) {
      this.onlyOnce++;
      let numOfBeneficiaries = this.beneficiaries.length;
      if (numOfBeneficiaries > 0) {
        this.beneficiaries.forEach(el => el.Percentage = 0)
        this.hundredPrecents = 100
        this.cdf.detectChanges()
      }
    }
  }

  calculateTotalPrecentage(index, e) {
    if (this.onlyOnce) {
      this.error = false
      let tempVal = this.hundredPrecents;
      let hundredPrecents = 100
      this.beneficiaries[index].Percentage = Number(e.target.value)
      // let inputElements:any = document.querySelectorAll('input')
      // Array.prototype.map.call(inputElements, (el) => hundredPrecents -= Number(el.value))
      this.beneficiaries.forEach(el => hundredPrecents -= Number(el.Percentage))
      if (hundredPrecents < 0) {
        this.beneficiaries[index].precents = 0
        let val = 0
        val = this.beneficiaries.map(el => val += Number(el.Percentage))
        this.hundredPrecents = val
      }
      else {

        this.hundredPrecents = hundredPrecents
      }
    }
    this.cdf.detectChanges()
  }

  next() {
    if (this.hundredPrecents > 1) {
      this.error = true
    }
    else {
      this.cacheService.cache[3] = this.beneficiaries
      this.cacheService.cache[4] = {}
      this.cacheService.currentTab.next(4)
    }
  }

  closeKeyboard(e) {
    this.cordovaService.closeKeyBoard();
  }

}
