import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RadioButtonService {

  public ev = new Subject;
  public ev$ = this.ev.asObservable();


  constructor() { 
  }
}
