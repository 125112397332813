import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Question } from '../health-declaration.component';


@Component({
  selector: 'questioncard',
  templateUrl: './questioncard.component.html',
  styleUrls: ['./questioncard.component.scss']
})
export class QuestioncardComponent implements OnInit {
  _type = ''
  _question = 'האם אתה מעשן?'
  _answers = []
  _userAnswer: any = -1
  _bullets = []
  _hasExtension = false
  _extensionQuestion: Question
  checkboxAnswersArr = []
  @Output() answered = new EventEmitter()
  @Input() set type(t) { if (!!(t)) { this._type = t; if (t === 'checkbox') this.checkboxAnswersArr = new Array(this._answers.length).fill(false) } }
  @Input() set question(t) { this._question = t }
  @Input() set answers(t) { this._answers = t }
  @Input() set bullets(t) { if (!!(t)) this._bullets = t; }
  @Input() set hasExtension(t) {  if (!!(t)) this._hasExtension = t }
  @Input() set extensionQuestion(t) { if (this._hasExtension) this._extensionQuestion = t;  }
  constructor() { }

  ngOnInit() {
  }

  answerClicked(ans) {
    if(ans){
      this._userAnswer = this._answers.findIndex(el => el === ans)
      this.answered.emit(ans)
    }
  }
  handleCheckboxClick(index) {
    this.checkboxAnswersArr[index] = !this.checkboxAnswersArr[index]
  }
  checkboxSaveButton() {
    this._answers;
    let ansArr = this.checkboxAnswersArr.map((el, ind) => {
      return !!(el) ? ind : -1
    }).filter(el => el > -1)
    // let ansArr = [];
    // for (let i=0; i<this.checkboxAnswersArr.length; i++) {
    //   if (this.checkboxAnswersArr[i]===true)
    //     ansArr.push(this._answers[i])
    // }
    // this._userAnswer = ansArr;
    if(ansArr.length > 0)
      this.answered.emit(ansArr)
  }
  textareaSave() {
    if(this._userAnswer.length > 0)
      this.answered.emit(this._userAnswer)
  }
  textareaValue(e) {
    this._userAnswer = e && e.target && e.target.value
  }
}
