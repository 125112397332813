import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[Autofocus]'
})
export class AutofocusDirective {
  constructor(private el?: ElementRef) { }
  ngAfterViewInit() {
    this.el.nativeElement.children[0].focus();
    }
}
