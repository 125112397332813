import { UpgradeProductComponent } from './../../risk/components/shifted-life-insurance/shifted-life-insurance.component';
import { JoinFingerIdComponent } from './../../authentication/components/join-finger-id/join-finger-id.component';
import { PrivacyComponent } from './../../shared/components/modals/robin-side-nav/pages/privacy/privacy.component';
import { SettingsComponent } from './../../shared/components/modals/robin-side-nav/pages/settings/settings.component';
import { InsuranceModule } from '../../account/insurance/insurance.module';
import { PensionModule } from '../../account/pension/pension.module';
import { PersonalAccountComponent } from '../../account/components/personal-account/personal-account.component';
import { ForecastComponent } from '../../account/pension/components/forecast/forecast.component';
import { IntroComponent } from '../../onboarding/components/intro/intro.component';
import { AuthenticationModule } from '../../authentication/authentication.module';
import { AccountModule } from '../../account/account.module';
import { QuestionnaireModule } from '../../questionnaire/questionnaire.module';
import { OnBoardingModule } from '../../onboarding/onboarding.module';
import { NgModule, isDevMode } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HomepageModule } from '../../homepage/homepage.module';
import { PensionProductExtensionViewComponent } from '../../account/pension/components/pension-product-extension-view/pension-product-extension-view.component';
import { PensionProfileComponent } from '../../account/pension/components/pension-profile/pension-profile.component';
import { RecommendationBarComponent } from '../../shared/components/indicators/recommendation-bar/recommendation-bar.component';
import { AboutComponent } from '../../shared/components/modals/robin-side-nav/pages/about/about.component';
import { SecurityComponent } from '../../shared/components/modals/robin-side-nav/pages/security/security.component';

import { TechSupportComponent } from '../../shared/components/support/tech-support/tech-support.component';
import { CreditCardComponent } from '../../onboarding/components/credit-card/credit-card.component';
import { TestComponent } from '../../test/test.component';
import { DigitalProccessModule } from 'src/app/digitalproccess/digital-proccess.module';
import { InviteFriendsComponent } from 'src/app/account/components/invite-friends/invite-friends.component';
import { UpdateDataMislakaComponent } from 'src/app/account/components/update-data-mislaka/update-data-mislaka.component';
import { ChooseFriendsComponent } from 'src/app/account/components/choose-friends/choose-friends.component';
import { CreditUpdateMislakaComponent } from 'src/app/account/components/credit-update-mislaka/credit-update-mislaka.component';
import { LoaderComponent } from 'src/app/account/loader/loader.component';
import {LoginComponent} from '../components/login/login.component'
import { PersonalProfileComponent } from 'src/app/shared/components/modals/robin-side-nav/pages/personal-profile/personal-profile.component';
import { UpdateAddressComponent } from 'src/app/shared/components/modals/robin-side-nav/pages/personal-profile/update-address/update-address.component';
import { UpdateChildrenComponent } from 'src/app/shared/components/modals/robin-side-nav/pages/personal-profile/update-children/update-children.component';
import { UpdateOccupationComponent } from 'src/app/shared/components/modals/robin-side-nav/pages/personal-profile/update-occupation/update-occupation.component';
import { UpdateOccupationStatusComponent } from 'src/app/shared/components/modals/robin-side-nav/pages/personal-profile/update-occupation-status/update-occupation-status.component';
import { UpdateEmployerNameComponent } from 'src/app/shared/components/modals/robin-side-nav/pages/personal-profile/update-employer-name/update-employer-name.component';
import { UpdateSalaryComponent } from 'src/app/shared/components/modals/robin-side-nav/pages/personal-profile/update-salary/update-salary.component';
import { UpdateRiskLevelComponent } from 'src/app/shared/components/modals/robin-side-nav/pages/personal-profile/update-risk-level/update-risk-level.component';

import { UpgradeProcessComponent } from 'src/app/risk/components/upgrade-process/upgrade-process.component';
import { PurchaseProductComponent } from 'src/app/risk/components/recommended-package/recommended-package.component';
import {EndOfProcessComponent} from 'src/app/risk/components/end-of-process/end-of-process.component'
import {EndOfProcessPensionComponent} from 'src/app/digitalproccess/components/pension/end-of-process/end-of-process.component'
import { QuestionnaireComponent } from 'src/app/criticalillness/components/bid/questionnaire/questionnaire.component';

var appRoutes: Routes = [
  {
    path: 'test',
    component: TestComponent
  },
  {
    path: 'digitalproccess',    
    loadChildren: '../../digitalproccess/digital-proccess.module#DigitalProccessModule'
  },
  {
    path: 'onboarding',
    // loadChildren: () => OnBoardingModule
    loadChildren: '../../onboarding/onboarding.module#OnBoardingModule'
  },
  {
    path: 'upgrade',
    component: UpgradeProductComponent,
  },
  {
    path: 'purchase',
    component: PurchaseProductComponent,
  },
  {
    path: 'risk',
    component: UpgradeProcessComponent,
    children: [
      {
        path: '',
        loadChildren: '../../risk/risk.module#RiskModule'
      }
    ]
  },
  {
    path: 'criticalillness',
    // component: QuestionnaireComponent,
    children: [
      {
        path: '',
        loadChildren: '../../criticalillness/criticalillness.module#CriticalillnessModule'
      }
    ]
  },
  
  {
    path: 'risksuccess',
    component: EndOfProcessComponent
  },

  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'account',
    component: PersonalAccountComponent,
    children: [
      {
        path: 'pension',
        // loadChildren: () => PensionModule,
        loadChildren: '../../account/pension/pension.module#PensionModule'
      },
      {
        path: 'insurance',
        loadChildren: '../../account/insurance/insurance.module#InsuranceModule'
        // loadChildren: () => InsuranceModule,
      },
      {
        path: 'invitefriends',
        component: InviteFriendsComponent,
        // loadChildren: () => InsuranceModule,
      },

      {
        path: 'updatedata',
        component: UpdateDataMislakaComponent,
        // loadChildren: () => InsuranceModule,
      },
      {
        path: 'choosefriends',
        component: ChooseFriendsComponent,
        // loadChildren: () => InsuranceModule,
      },
      {
        path: 'creditupdatemislaka',
        component: CreditUpdateMislakaComponent,
      },
      {
        path: 'loader',
        component: LoaderComponent,
        // loadChildren: () => InsuranceModule,
      },

    ]
  },
  
  {
    path: 'authentication',
    // loadChildren: () => AuthenticationModule,
    loadChildren: '../../authentication/authentication.module#AuthenticationModule'
  },
  {
    path: 'termsofcondition',
    // loadChildren: () => AuthenticationModule,
    component: SecurityComponent
  },
  {
    path: 'privacy',
    // loadChildren: () => AuthenticationModule,
    component: PrivacyComponent
  },
  {
    path: 'questionnaire',
    // loadChildren: () => QuestionnaireModule
    loadChildren: '../../questionnaire/questionnaire.module#QuestionnaireModule'
  },
  {
    path: 'homepage',
    loadChildren: '../../homepage/homepage.module#HomepageModule'

    //loadChildren: () => HomepageModule
    // loadChildren: '../../questionnaire/questionnaire.module#QuestionnaireModule'
  },
 
  {
    path: 'support',
    // loadChildren: () => AuthenticationModule,
    component: TechSupportComponent
  },
  {
    path: 'recomendation',
    loadChildren: '../../entertainment/entertainment.module#EntertainmentModule'
  },
  {
    path: 'personalprofile',
    component: PersonalProfileComponent
  },
  {
    path: 'updateaddress',
    component: UpdateAddressComponent
  },
  {
    path: 'updatechildren',
    component: UpdateChildrenComponent
  },
  {
    path: 'updateoccupation',
    component: UpdateOccupationComponent
  },
  {
    path: 'updateoccupationstatus',
    component: UpdateOccupationStatusComponent
  },
  {
    path: 'updateemployername',
    component: UpdateEmployerNameComponent
  },
  {
    path: 'updaterisklevel',
    component: UpdateRiskLevelComponent
  },
  {
    path: 'updatesalary',
    component: UpdateSalaryComponent
  },
  {
    path: '**',
    redirectTo: '/onboarding/intro'
    // loadChildren: 'app/admin/admin.module#AdminModule',
    // canLoad: [AuthGuard]
  }
];


@NgModule({
  imports: [
    // // RouterModule.forRoot(appRoutes)
    // RouterModule.forRoot(appRoutes, {
    //   // scrollPositionRestoration: 'enabled'
    // })
    RouterModule.forRoot(appRoutes, {useHash: true})


  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class AppRoutingModule { }

