import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'robin-toggle',
  templateUrl: './robin-toggle.component.html',
  styleUrls: ['./robin-toggle.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RobinToggleComponent), multi: true },
  ]
})
export class RobinToggleComponent implements OnInit {

  public containerClass = 'container';
  public toggleClass = 'toggle-circle';
  onchange;
  @Output() valueChanged = new EventEmitter();
  @Input() toggleValue;
  constructor() { }

  ngOnInit() {
    this.toggleClasses(this.toggleValue)
  }

  toggle(e) {
    this.toggleValue = !this.toggleValue;
    this.valueChanged.emit(this.toggleValue);
    this.toggleClasses(this.toggleValue);
    this.onchange(this.toggleValue);
  }

  toggleClasses(toggleVal) {
    this.toggleClass = toggleVal ? 'toggle-circle active' : 'toggle-circle';
    this.containerClass = toggleVal ? 'container active' : 'container';
  }

  writeValue(value: any) {
    this.toggleValue = value;
    this.toggleClasses(this.toggleValue)
  }


  registerOnChange(fn) {
    this.onchange = fn;
  }
  registerOnTouched() { }

}
