import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { JoinFingerIdComponent } from './components/join-finger-id/join-finger-id.component';
import { AuthSuccessfullyCompletedComponent } from './components/auth-successfully-completed/auth-successfully-completed.component';
import { PasswordOtpComponent } from './components/forgot-password/password-otp/password-otp.component';
import { PasswordEmailComponent } from './components/forgot-password/password-email/password-email.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FeatureSupportComponent } from './components/feature-support/feature-support.component';
import { JoinFaceIdComponent } from './components/join-face-id/join-face-id.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    SharedModule,
    FormsModule,
    AuthenticationRoutingModule
  ],
  declarations: [LoginComponent, JoinFingerIdComponent, AuthSuccessfullyCompletedComponent, PasswordOtpComponent, PasswordEmailComponent, FeatureSupportComponent, JoinFaceIdComponent],
  exports: [FeatureSupportComponent]
})
export class AuthenticationModule { }
