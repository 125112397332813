import { Store } from '@ngrx/store';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../../robin-modal/modal.service';
import { AppState, selectRegisterState } from '../../../../../store/app.states';
import { Register, LoginFailed } from '../../../../../store/user.actions';
import { CordovaService } from '../../../../../core/services/cordova.service';
import { OtpService } from '../../../../../onboarding/services/otp/otp.service';
import { AccountService } from 'src/app/account/account.service';
import { ErrorModalService } from '../../error-modal/modal.service';

@Component({
  selector: 'robin-side-nav-item',
  templateUrl: './robin-side-nav-item.component.html',
  styleUrls: ['./robin-side-nav-item.component.scss']
})
export class RobinSideNavItemComponent implements OnInit {

  @Input() text;
  @Input() icon;
  @Input() url;

  constructor(private router: Router, 
              private modalSupport: ModalService, 
              private store: Store<AppState>, 
              private cordovaservice: CordovaService,
              private otpService: OtpService,
              private errorModalService: ErrorModalService,
              private accountService: AccountService) {
   }

  ngOnInit() {
  }

  navTo(url) {
    if(url === '/settings'){
      this.store.select(selectRegisterState).subscribe((data: any) =>{
        this.otpService.anonymousStatus(data.user).subscribe((data: any) => {
          if (data.Code === 0) {
            this.otpService.allowads = data.Data.AllowAds;

          } else {
            this.errorModalService.setNewError({
              title: 'אופס...',
              description: 'נראה שמשהו השתבש בדרך',
              type: 'error',
              buttonText: 'נסה שנית',
            }).open();
          }

        })
       });
    }
    if (url === 'signout') {
      this.signout();
    }

    else if (url === 'invitefriends') {
      this.accountService.friendsOpen = true;
    }
    else {
      var n = url.indexOf('http');
      if (n === -1) {
        if(url === '/settings'){
          this.store.select(selectRegisterState).subscribe((data: any) => {
            this.otpService.anonymousStatus(data.user).subscribe((data: any) => {

              if (data.Code === 0) {
                this.otpService.allowads = data.Data.AllowAds;
                this.router.navigate([url]);
              } else {
                this.errorModalService.setNewError({
                  title: 'אופס...',
                  description: 'נראה שמשהו השתבש בדרך',
                  type: 'error',
                  buttonText: 'נסה שנית',
                }).open();
              }

            })
           });
        } else {
          console.log('url => ', url);
          this.router.navigate([url]);
        }

      } else {
        console.log('I am here!');
        if (this.cordovaservice.platform === "Android") window.open(url, '_system', 'location=no');
        else window.open(url, '_system', 'location=yes');
      }
    }
  }

  signout() {
    this.store.dispatch(new LoginFailed({}));
    this.router.navigate(['/homepage']);
  }

}
