import { Action } from '@ngrx/store';

export const CALLEDANONYMOUS = '[Anonymous] Data';
export const AUTHENTICATED = '[Authenticated] Data';


export enum RegisterActionTypes {
  REGISTER = '[Register] Register',
  REGISTER_SUCCESS = '[Register] Register Success',
  REGISTER_FAILURE = '[Register] Register Failed',
}

export enum CheckInfoActionTypes {
  CHECKINFO = '[CheckInfo] CheckInfo',
  CHECKINFO_SUCCESS = '[CheckInfo] CheckInfo Success',
  CHECKINFO_FAILURE = '[CheckInfo] CheckInfo Failed',
}

export enum LoginActionTypes {
  LOGIN = '[Login] Login',
  LOGIN_SUCCESS = '[Login] Login Success',
  LOGIN_FAILURE = '[Login] Login Failed',
}

export enum PensionActionTypes {
  PENSION = '[Pension] Pension',
  PENSION_SUCCESS = '[Pension] Pension Data Success',
  PENSION_FAILURE = '[Pension] Pension Data Failed'
}

export enum InsuranceActionTypes {
  INSURANCE = '[Insurance] Insurance',
  INSURANCE_SUCCESS = '[Insurance] Insurance Data Success',
  INSURANCE_FAILURE = '[Insurance] Insurance Data Failed'
}
/*
Anonymous
*/
export class CalledAnonymous implements Action{
  readonly type = CALLEDANONYMOUS;
  constructor(public payload: any) { }
}


export class Authenticated implements Action{
  readonly type = AUTHENTICATED;
  constructor(public payload: any) { }
}



/*
        Register
*/
export class Register implements Action {
  readonly type = RegisterActionTypes.REGISTER;
  constructor(public payload: any) { }
}
export class RegisterSuccess implements Action {
  readonly type = RegisterActionTypes.REGISTER_SUCCESS;
  constructor(public payload: any) { }
}

export class RegisterFailure implements Action {
  readonly type = RegisterActionTypes.REGISTER_FAILURE;
  constructor(public payload: any) { }
}


/*
        Check Info
*/
// export class CheckInfo implements Action {
//   readonly type = CheckInfoActionTypes.CHECKINFO;
//   constructor(public payload: any) { }
// }
// export class CheckInfoSuccess implements Action {
//   readonly type = CheckInfoActionTypes.CHECKINFO_SUCCESS;
//   constructor(public payload: any) { }
// }

// export class CheckInfoFailure implements Action {
//   readonly type = CheckInfoActionTypes.CHECKINFO_FAILURE;
//   constructor(public payload: any) { }
// }

/*
    Login
*/
export class Login implements Action {
  readonly type = LoginActionTypes.LOGIN;
  constructor(public payload: any) { }
}

export class LoginSuccess implements Action {
  readonly type = LoginActionTypes.LOGIN_SUCCESS;
  constructor(public payload: any) { }
}
export class LoginFailed implements Action {
  readonly type = LoginActionTypes.LOGIN_FAILURE;
  constructor(public payload: any) { }
}

/*
    Pension
*/
export class Pension implements Action {
  readonly type = PensionActionTypes.PENSION;
  constructor(public payload: any) { }
}
export class PensionSuccess implements Action {
  readonly type = PensionActionTypes.PENSION_SUCCESS;
  constructor(public payload: any) { }
}
export class PensionFailed implements Action {
  readonly type = PensionActionTypes.PENSION_FAILURE;
  constructor(public payload: any) { }
}

/*
    Insurance
*/
export class Insurance implements Action {
  readonly type = InsuranceActionTypes.INSURANCE;
  constructor(public payload: any) { }
}
export class InsuranceSuccess implements Action {
  readonly type = InsuranceActionTypes.INSURANCE_SUCCESS;
  constructor(public payload: any) { }
}
export class InsuranceFailed implements Action {
  readonly type = InsuranceActionTypes.INSURANCE_FAILURE;
  constructor(public payload: any) { }
}
export type All =
  Register |
  RegisterSuccess |
  RegisterFailure |
  Login |
  LoginSuccess |
  LoginFailed |
  Pension |
  PensionSuccess |
  PensionFailed |
  Insurance |
  InsuranceSuccess |
  InsuranceFailed | 
  // CheckInfoActionTypes |
  CalledAnonymous |
  Authenticated;