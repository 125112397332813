import { FetchService } from './services/fetch.service';
import { EntertainmentRoutingModule } from './entertainment-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisturbingPopupComponent } from './components/disturbing-popup/disturbing-popup.component';
import { PublishAnIdeaComponent } from './components/publish-an-idea/publish-an-idea.component';
import { RateComponent } from './tasks/rate/rate.component';
import { SharedModule } from '../shared/shared.module';
import { LikeComponent } from './components/like/like.component';
import { CommentComponent } from './components/comment/comment.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EntertainmentRoutingModule
  ],
  providers:[
    FetchService
  ],
  declarations: [
    DisturbingPopupComponent,
     RateComponent,
      PublishAnIdeaComponent,
      LikeComponent,
      CommentComponent
    ],
  exports: [RateComponent]
})
export class EntertainmentModule { }
