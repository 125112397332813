import { ValidatorFn, AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { CordovaService } from './cordova.service';
import { Injectable, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Observable, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';

declare const window;
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  isLoader = new ReplaySubject(2, 100);

  constructor(private location: Location, private cordovaService: CordovaService) { }

  public setLoaderStatus(value){
    this.isLoader.next(value);
    return this;
  }
  // TODO ::   loader subject.
  public getLoaderStatus() {
    return this.isLoader;
  }

  getComponentName() {
    var splitArr = this.location.path().split('/');
    return splitArr[splitArr.length - 1];
  }

  getModuleName() {
    var splitArr = this.location.path().split('/');
    splitArr.shift();
    splitArr.pop();
    return splitArr.length > 1 ? splitArr : splitArr.join('/');
  }

  getPath() {
    return this.location.path();
  }

  convertObjToArray(obj) {
    let arr = [];
    Object.keys(obj).forEach(key => { arr.push(obj[key]) });
    return arr;
  }

  currencyPhrase(num) {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }

  getAllInputs(el: ElementRef): any {
    let html = el.nativeElement.innerHTML;
    let parser = new DOMParser();
    let content = parser.parseFromString(html, 'text/html');
    return content.querySelectorAll('input');
  }

  closeKeyboard() {
    if (this.cordovaService.isMobile) {
      window.Keyboard.hide();
      // inputs[i].blur();
    }
  }
  passCodeUtil(e, options, cb) {
    e.preventDefault();
    let currentInput = e.target;
    let inputs: any = document.querySelectorAll('form input');
    //i is the index of the input within the form
    let i = -1;
    for (let j = 0; j < inputs.length; j++) {
      if (inputs[j] == currentInput) { i = j; }
    }
    //input index
    if (i !== -1) {
      let key;
      if (options.type === 'digits') {
        if (e.keyCode === 229) {
          console.log('229');
          key = e.target.value[e.target.value.length - 1];
        }
        else {
          key = e.code || e.key;
        }
        //add
        // if (e.code !== 'Delete' && e.code !== 'Backspace' && e.key !== 'Backspace' && e.key !== 'Delete') {
        if (key !== 'Delete' && key !== 'Backspace' && key !== 'Enter' && key !== 'Tab') {
          //digit
          if (!isNaN(e.key) || !isNaN(key)) {
            //last input
            if (i === inputs.length - 1) {
              if (currentInput.value)
                // setTimeout(() => currentInput.value = e.key, 0);
                currentInput.value = !isNaN(key) ? key : e.key;
              //close the keyboard
              // setTimeout(() => currentInput.blur(), 10);
              currentInput.value = !isNaN(key) ? key : e.key;
              currentInput.blur();
              this.closeKeyboard();
              !isNaN(key) ? cb('add', i, Number(key)) : cb('add', i, Number(e.key));
            }
            else {
              if (currentInput.value)
                !isNaN(key) ? setTimeout(() => currentInput.value = key, 0) : setTimeout(() => currentInput.value = e.key, 0);
              //skip to the next input
              // setTimeout(() => inputs[i + 1].focus(), 0);
              // e.preventDefault();
              inputs[i].value = !isNaN(key) ? key : e.key;
              inputs[i + 1].focus();
              !isNaN(key) ? cb('add', i, Number(key)) : cb('add', i, Number(e.key));
            }
          }
          else {
            setTimeout(() => currentInput.value = '', 0);
          }
        }
        //remove
        else {
          //first input 
          if (i === 0) {
            if (currentInput.value)
              setTimeout(() => currentInput.value = '', 1);
            //close the keyboard
            this.closeKeyboard();
            cb('remove', i, Number(e.key));
          }
          else {
            let hasInput = false;
            //current input contains value
            if (currentInput.value) {
              hasInput = true;
              // setTimeout(() => currentInput.value = '', 0);
              currentInput.value = '';
            }
            //go to prev input
            inputs[i - 1].focus();
            if (!hasInput) {
              //delete prev inputs value
              inputs[i - 1].value = '';
            }
            cb('remove', i, Number(e.key));
          }
        }
      }
    }
    else {
    }
  }

  dateInput(e) {
    //on delete
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (e.target.value.length === 7 || e.target.value.length === 4) {
        setTimeout(() => { e.target.value = e.target.value.slice(0, e.target.value.length - 1) }, 1);
      } else {
        setTimeout(() => null, 1);
      }
    }
    // else if (e.keyCode > 57 || e.keyCode < 48) {
    // // else if (!(e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
    //   setTimeout(() => { e.target.value = e.target.value.slice(0, e.target.value.length - 1) }, 1);
    // }

    //in case the input is longer than usual
    else if (e.target.value.length >= 10) {
      setTimeout(() => { e.target.value = e.target.value.slice(0, e.target.value.length - 1) }, 1);
    }
    //normal scenerio
    else {
      if (e.target.value.length === 2 || e.target.value.length === 5) {
        e.target.value = e.target.value + '/';
      }
    }
  }

  checkIfAllInputsAreValid() {
    let inputs: any = document.querySelectorAll('form input');
    let bool = Array.prototype.filter.call(inputs, function (el) {
      return el.value
    });
    return bool.length === inputs.length;
  }


  detectKey(e) {
    let currentInput = e.target;
    let inputs: any = document.querySelectorAll('form input');
    //i is the index of the input within the form
    let i = -1;
    for (let j = 0; j < inputs.length; j++) {
      if (inputs[j] == currentInput) { i = j; }
    }
    //valid input
    if (i !== -1) {
      let code = (e.keyCode ? e.keyCode : e.which);
      //delete
      if (code === 8 || code === 46) {

      }
      else if (code === 13) {
        console.log('code => ', code);
        if (inputs.length - 1 === i) {
          if (this.cordovaService.isMobile) {
            window.Keyboard.hide();
          }
        }
        else
          inputs[i + 1].click();
      }
    }
  }
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  inputLength(e, length) {
    let key = e.key || e.code;
    let cond = e.type === 'keyup' ? e.target.value.length <= length : e.target.value.length < length;
    if (cond) {
    }
    else {
      if (key === 'Delete' || key === 'Backspace' || key === 'Enter' || key === 'Tab') {
      }
      else {
        if (this.cordovaService.platform === 'Android') {
          let data = e.target.value.split('');
          data = data.splice(0, data.length - 1);
          e.target.value = data.join('');
        }
        else {
          if (e.type === 'keyup') {
            let data = e.target.value.split('');
            data = data.splice(0, data.length - 1);
            e.target.value = data.join('');
          } else e.preventDefault();
        }
      }
    }
  }
  digitOnly(e) {
    let key;
    if (this.cordovaService.platform === 'Android') {
      key = e.target.value[e.target.value.length - 1];
    }
    else key = e.key || e.code;
    if (isNaN(key)) {
      if (key === 'Delete' || key === 'Backspace' || key === 'Enter' || key === 'Tab') {
      }
      else {
        if (this.cordovaService.platform === 'Android') {
          let data = e.target.value.split('');
          data = data.splice(0, data.length - 1);
          e.target.value = data.join('');
        }
        else {
          if (e.type === 'keyup') {
            let data = e.target.value.split('');
            data = data.splice(0, data.length - 1);
            e.target.value = data.join('');
          } else e.preventDefault();
        }
      }
    }
  }
  disableScrolling() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function(){
      if(window.scrollY > y){
      window.scrollTo(x, y);
      }
      }
    // window.onscroll = function () { window.scrollTo(x, y); };
  }

  enableScrolling() {
    window.onscroll = function () { };
  }

  expirationDate(e) {
    setTimeout(() => {
      switch (e.target.value.length) {
        case 2:
          e.target.value += '/';
          break;
      }
    }, 10);

  }
  resizeDOM(elementTag: any){
    let el = document.querySelector(elementTag);
    if(el.getBoundingClientRect().height < window.innerHeight){
      this.setManualHtmlBodyHeight();
      console.log('100%');
    }
    else{
      this.setAutoHtmlBodyHeight();
      console.log('auto');
    }
  }
  setAutoHtmlBodyHeight(){
    let html = document.querySelector('html');
    let body = document.querySelector('body');
    html.style.height = 'auto';
    body.style.height = 'auto';
  }
  setManualHtmlBodyHeight(){
    let html = document.querySelector('html');
    let body = document.querySelector('body');
    html.style.height = '100%';
    body.style.height = '100%';
  }

}


export function expirationDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let val = control.value;
  val = val.split('/');
  if (!(Number(val[0]) > 0 && Number(val[0]) < 13 && Number(val[1]) > 0)) {
    return { expiration: true };
  }
  return null;
}
