import { Component, OnInit } from '@angular/core';
import { uiStrings } from '../../../assets/heb.ui_strings';

@Component({
  selector: 'new-robin',
  templateUrl: './new-robin.component.html',
  styleUrls: ['./new-robin.component.scss']
})
export class NewRobinComponent implements OnInit {

  public uiStrings;
  constructor() { 
    this.uiStrings = uiStrings.newrobin;
  }

  ngOnInit() {
  }

}
