import { Component, OnInit, Input } from '@angular/core';
import {slideInDown} from 'ng-animate'
import { trigger, transition, useAnimation, style, state, animate } from '@angular/animations';

@Component({
  selector: 'bars-comparison',
  templateUrl: './bars-comparison.component.html',
  styleUrls: ['./bars-comparison.component.scss'],
})
export class BarsComparisonComponent implements OnInit {
  _currentColor = 'rgb(227, 45, 84)';
  _recommendedColor = '#18C394';
  _currentValue = 0;
  _recommendedValue = 0;
  //aka NIS / PRECENTAGE etc...
  _postFix = '₪';
  _recommendedIcon = 'assets/images/shared/indicators/robin-state/heart_pin_green.png'
  _currentIcon = 'assets/images/shared/indicators/robin-state/heart_pin_red.png'
  @Input() set postFix(p) { if (p) this._postFix = p }
  @Input() set currentColor(p) { if (p) this._currentColor = p }
  @Input() set recommendedColor(p) { if (p) this._recommendedColor = p }
  @Input() set currentValue(p) { if (p) this._currentValue = p }
  @Input() set recommendedValue(p) { if (p) this._recommendedValue = p }
  constructor() { }

  ngOnInit() {
  }

}
