import { Directive, ElementRef, Input, OnInit, AfterViewInit, HostListener, Renderer2, HostBinding } from '@angular/core';
const TIME = 1000 * 1;
@Directive({
  // selector: '[robinButton]'
  selector: 'aarobin-button'
})
export class PreventDoubleSubmit {

  constructor(private renderer: Renderer2) {
  }
  @HostListener('click', ['$event'])
  function(e) {
    this.renderer.listen(e.target, 'click', (e) => {

    }

    );


    // e.stopPropagation();
    // let target = e.target;
    // if(!this.clicked){
    //   this.clicked = !this.clicked;
    // }
    // //clicked
    // else{
    //   e.cancelBubble = true;
    //   console.log('here');
    // }
  }
}
