import { Store } from '@ngrx/store';
import { AppState, selectRegisterState } from './../store/app.states';
import { environment } from '../../environments/environment';
import { LoggerService } from '../core/services/logger.service';
import { Window } from '../models/global.models';
import { Injectable } from '@angular/core';
import { CordovaService } from '../core/services/cordova.service';
import { ConfigService } from '../core/services/config.service';

declare var window;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private prefix = '${this.prefix}';
  public phoneNumber: any;
  public uuid;

  constructor(private store: Store<AppState>,
    private phoneReady: CordovaService,
    private loggerService: LoggerService,
    private configService: ConfigService) {
    // this.configService.getConfing().subscribe((response) => {
    //   console.log('hi',response);
    //   this.phoneReady.deviceReady$.subscribe((data) => {
    //     this.uuid = window.device.uuid;
    //     console.log('hi2',response);
    //     this.configService.configModel = response;
    //     if (this.configService.configModel && this.configService.configModel.thirdParties.appsFlyerShow) {
    //       console.log('hi3',this.configService.configModel.thirdParties.appsFlyer);
    //       window.plugins.appsFlyer.initSdk(this.configService.configModel.thirdParties.appsFlyer, () => this.loggerService.debug(`${this.prefix} appsFlyer successfully loaded`), (failed) => this.loggerService.error(`${this.prefix} appsFlyer could not be initialized: ${failed}`));
    //     }
    //   });
    // });
    this.phoneReady.deviceReady$.subscribe((data) => {
      this.loggerService.debug(`${this.prefix} deviceready event triggered`);
      this.uuid = window.device.uuid;
      // window.Appsee.start(environment.thirdParties.appsee, () => {
      //   this.loggerService.debug(`${this.prefix} Appsee successfully loaded`);
      //   window.Appsee.setUserId(localStorage.getItem('phoneNumber') || 'noId');
      // }, (failed) => this.loggerService.error(`${this.prefix} Appsee could not be initialized: ${failed}`));


      // if (this.configService.configModel && this.configService.configModel.thirdParties.appsFlyer.show) {
      //   window.plugins.appsFlyer.initSdk(environment.thirdParties.appsFlyer, () => this.loggerService.debug(`${this.prefix} appsFlyer successfully loaded`), (failed) => this.loggerService.error(`${this.prefix} appsFlyer could not be initialized: ${failed}`));
      // }
      // window.mixpanel.init(environment.thirdParties.mixpanel, () => this.loggerService.debug(`${this.prefix} mixpanel successfully loaded`), (failed) => this.loggerService.error(`${this.prefix} mixpanel could not be initialized: ${failed}`))
    },
      (err) => {
        this.loggerService.debug(err);
        let regEx = new RegExp(/^\/\//);
        // let mixPanelAnalytics = `
        // (function(e,a){if(!a.__SV){var b=window;try{var c,l,i,j=b.location,g=j.hash;c=function(a,b){return(l=a.match(RegExp(b+"=([^&]*)")))?l[1]:null};g&&c(g,"state")&&(i=JSON.parse(decodeURIComponent(c(g,"state"))),"mpeditor"===i.action&&(b.sessionStorage.setItem("_mpcehash",g),history.replaceState(i.desiredHash||"",e.title,j.pathname+j.search)))}catch(m){}var k,h;window.mixpanel=a;a._i=[];a.init=function(b,c,f){function e(b,a){var c=a.split(".");2==c.length&&(b=b[c[0]],a=c[1]);b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments,
        //   0)))}}var d=a;"undefined"!==typeof f?d=a[f]=[]:f="mixpanel";d.people=d.people||[];d.toString=function(b){var a="mixpanel";"mixpanel"!==f&&(a+="."+f);b||(a+=" (stub)");return a};d.people.toString=function(){return d.toString(1)+".people (stub)"};k="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
        //   for(h=0;h<k.length;h++)e(d,k[h]);a._i.push([b,c,f])};a.__SV=1.2;b=e.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(${regEx})?"https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";c=e.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}})(document,window.mixpanel||[]);
        //   mixpanel.init('96d93b4833de41ada3c3e82a478bd986');
        // `;
        // //       let mixPanelAnalytics = `
        // //       <!-- start Mixpanel -->
        // // <script type="text/javascript">(function(f,b){if(!b.__SV){var a,e,i,g;window.mixpanel=b;b._i=[];b.init=function(a,e,d){function f(b,h){var a=h.split(".");2==a.length&&(b=b[a[0]],h=a[1]);b[h]=function(){b.push([h].concat(Array.prototype.slice.call(arguments,0)))}}var c=b;"undefined"!==typeof d?c=b[d]=[]:d="mixpanel";c.people=c.people||[];c.toString=function(b){var a="mixpanel";"mixpanel"!==d&&(a+="."+d);b||(a+=" (stub)");return a};c.people.toString=function(){return c.toString(1)+".people (stub)"};i="disable track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.track_charge people.clear_charges people.delete_user".split(" ");
        // // for(g=0;g<i.length;g++)f(c,i[g]);b._i.push([a,e,d])};b.__SV=1.2;a=f.createElement("script");a.type="text/javascript";a.async=!0;a.src="js/mixpanel.min.js";e=f.getElementsByTagName("script")[0];e.parentNode.insertBefore(a,e)}})(document,window.mixpanel||[]);
        // // mixpanel.init("96d93b4833de41ada3c3e82a478bd986");</script>
        // // <!-- end Mixpanel -->
        // //       `;
        // let scriptEl = document.createElement('SCRIPT');
        // var script = document.createTextNode(mixPanelAnalytics);
        // scriptEl.appendChild(script);
        // document.body.appendChild(scriptEl);
        // this.loggerService.debug(`${this.prefix} mixpanel analytics loaded successfuly`);
        //apply the event on => window.mixpanel
        // window.mixpanel.track("Video play");
        // let FullStory = `
        // window['_fs_debug'] = false;
        // window['_fs_host'] = 'fullstory.com';
        // window['_fs_org'] = '9KSRQ';
        // window['_fs_namespace'] = 'FS';
        // (function(m,n,e,t,l,o,g,y){
        //     if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
        //     g=m[e]=function(a,b){g.q?g.q.push([a,b]):g._api(a,b);};g.q=[];
        //     o=n.createElement(t);o.async=1;o.src='https://'+_fs_host+'/s/fs.js';
        //     y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
        //     g.identify=function(i,v){g(l,{uid:i});if(v)g(l,v)};g.setUserVars=function(v){g(l,v)};g.event=function(i,v){g('event',{n:i,p:v})};
        //     g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
        //     g.consent=function(a){g("consent",!arguments.length||a)};
        //     g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
        //     g.clearUserCookie=function(){};
        // })(window,document,window['_fs_namespace'],'script','user');
        // `;
        // let scriptFSl = document.createElement('SCRIPT');
        // var script2 = document.createTextNode(FullStory);
        // scriptFSl.appendChild(script2);
        // document.body.appendChild(scriptFSl);
        // this.loggerService.debug(`${this.prefix} FullStory loaded successfuly`);
      });
  }

  ngOnInit(): void {

  }

  track(event, options = null) {
    // if (options) {
    //   window.Appsee.addEventWithProperties(event, options);
    //   window.mixpanel.track(event, options, success => { this.loggerService.debug(`[${event}] mixpanel registered successfuly`) }, fail => { this.loggerService.error(`[${event}] mixpanel registered successfuly`) });
    //   window.plugins.appsFlyer.trackEvent(event, options);
    // }
    // else {
    //   window.Appsee.addEvent(event);
    //   window.mixpanel.track(event, options, success => { this.loggerService.debug(`[${event}] mixpanel registered successfuly`) }, fail => { this.loggerService.error(`[${event}] mixpanel registered successfuly`) });
    //   window.plugins.appsFlyer.trackEvent(event, options);
    // }
  }

  //HACK: in order to implement both mixpanel and appsee events at the same time
  trackByMixpanel(event, options = null) {
    // if (window.mixpanel) {
    //   window.mixpanel.track(event, options, success => { this.loggerService.debug(`[${event}] mixpanel registered successfuly`) }, fail => { this.loggerService.error(`[${event}] mixpanel registered failed`) });
    // }
    // // if(window.Appsee){
    // //   this.trackByAppsee(event);
    // // }
  }
  trackByAppFlyer(event, options = null) {
    if (window.plugins && window.plugins.appsFlyer) {
      window.plugins.appsFlyer.trackEvent(event, options);
    }
  }

  trackByAppsee(event) {
    // window.Appsee.addEvent(event);
  }


}
