import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'robin-recommended-solution',
  templateUrl: './robin-recommended-solution.component.html',
  styleUrls: ['./robin-recommended-solution.component.scss']
})
export class RobinRecommendedSolutionComponent implements OnInit {

  @Input() amount = '';
  @Input() amount2 = '';
  constructor() { }

  ngOnInit() {
  }

}
