import { NavManagerService } from './../../../../../../shared/components/robin-navbar/robin-navbar.service';
import { Router } from '@angular/router';
import { CordovaService } from '../../../../../../core/services/cordova.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { LoggerService } from '../../../../../../core/services/logger.service';
import { ModalService } from '../../../../../../shared/components/robin-modal/modal.service';
import { ImageCaptureService } from '../../../../../services/image-capture.service';
import { uiStrings } from '../../../../../assets/heb.ui_strings';
import { ErrorModalService } from '../../../../../../shared/components/modals/error-modal/modal.service';

@Component({
  selector: 'id-card-tips',
  templateUrl: './id-card-tips.component.html',
  styleUrls: ['./id-card-tips.component.scss']
})
export class IdCardTipsComponent implements OnInit {
  public uiStrings;
  constructor(
    private cordovaService: CordovaService,
    private router: Router,
    private loggerService: LoggerService,
    private modalService: ModalService,
    private captureService: ImageCaptureService,
    private navService: NavManagerService,
    private errorModalService: ErrorModalService,
    private zone: NgZone
    ) {

    this.uiStrings = uiStrings.idcardtips;
      
  }

  ngOnInit() {
  }

  openCamera() {
    this.modalService.close('tipsForPerfectCapture');
    if (this.cordovaService.isMobile) {
      this.cordovaService.captureImage({}, (err, data) => err ? this.onErr(err) : this.onData(data));
    }
  }
  //need to redirect to => idCardPreview
  onData(data) {
    // this.captureService.imageCapture.next(data);
    this.captureService.idImage.next(data);
    this.captureService.onetime = true;
    this.zone.run(() => {
      this.navService.next('idcardpreview');
    });
  }

  onErr(err) {
    this.loggerService.error(err);
    // this.errorModalService.setNewError({
    //   title: 'שגיאה',
    //   description: 'שגיאה בבחירת התמונה',
    //   type: 'error',
    //   buttonText: 'אישור',
    // }).open();
  }
}
