import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { AppState, selectInsurance } from 'src/app/store/app.states';

import { AccountService } from '../../../account/account.service';
import { ApiService } from './../../services/api.service';
import { CacheService } from './../../../risk/services/cache.service';
import { InsuranceService } from 'src/app/account/insurance/services/insurance.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'end-of-process',
  templateUrl: './end-of-process.component.html',
  styleUrls: ['./end-of-process.component.scss']
})
export class EndOfProcessComponent implements OnInit {
  numOfCoins = 0
  type;
  constructor(private accountService: AccountService,
              private cache: CacheService, 
              private router: Router, 
              private apiService: ApiService, 
              private store: Store<AppState>, 
              private insuranceService: InsuranceService,
              private analyticService: AnalyticsService) {
    this.store.select(selectInsurance).pipe(map((data: any) => data.insuranceDetails && data.insuranceDetails.LifeInsurance && data.insuranceDetails.LifeInsurance.SummaryInsuranceRecommendation && data.insuranceDetails.LifeInsurance.SummaryInsuranceRecommendation.SummaryStatus)).subscribe(
      data => {
        // switch (data) {
          //   //purchase
          //   case (SummaryStatus.Under):
          //     break;
          //   //upgrade
          //   case SummaryStatus.Upgrade:
          //     break;
          // }
          this.type = data;
          this.apiService.finishProcess(data).then(data => {
            console.log('finishProcess => ', data); 
            if (this.type !== 2)
              this.insuranceService.firtsTimeRisk = true;
            this.cache.clearCache()
          }).catch(err => console.log('finishProcess error => ', err))
        });
        this.accountService.coin$.subscribe(data => {
          this.numOfCoins = data + 1 || 1});
        this.accountService.coin.next(this.numOfCoins);


    const data = this.cache.cache
    const digitalProccessId = this.cache.generatedProcessId
    console.log(data, digitalProccessId);
    // this.apiService.finishProcess(data)
  }

  ngOnInit() {
    this.analyticService.trackByMixpanel("DP - Risk - Signature");
    this.analyticService.trackByAppFlyer("DP - Risk - Signature", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });


  }
  next() {
    (this.router.routeReuseStrategy as any).storedRoutes = {}
    // this.accountService.coin.next(this.numOfCoins + 1)
    this.router.navigate(['/account/insurance'])
  }
}
