import { AfterViewInit } from '@angular/core';
import { UtilityService } from './../../../core/services/utility.service';
import { NavManagerService } from './../../../shared/components/robin-navbar/robin-navbar.service';
import { Router } from '@angular/router';
import { modalStrings } from '../../../shared/components/support/assets/heb.strings';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Response } from '../../../models/global.models';
import { RegisterService } from '../../services/register/register.service';
import { CacheService } from '../../services/cache.service';
import { Location } from '@angular/common';
import { Component, OnInit, HostListener, ElementRef, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { uiStrings } from '../../assets/heb.ui_strings';
import { AppState, selectRegisterState } from '../../../store/app.states';
import { Register } from '../../../store/user.actions';
import { ModalService } from '../../../shared/components/robin-modal/modal.service';
import { ErrorModalService } from '../../../shared/components/modals/error-modal/modal.service';
import { CordovaService } from '../../../core/services/cordova.service';
import { AnalyticsService } from '../../../analytics/analytics.service';

import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig } from '@angular/material';
import { BranchService } from 'src/app/core/services/branch.service';
import { OtpService } from '../../services/otp/otp.service';

declare const window;
@Component({
  selector: 'lets-know',
  templateUrl: './lets-know.component.html',
  styleUrls: ['./lets-know.component.scss'],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
  ]
})
export class LetsKnowComponent implements OnInit, OnDestroy, AfterViewInit {
  error = false;
  registerForm: FormGroup;
  ui_strings = uiStrings.letsKnow;
  getState: Observable<any>;
  mytoggle = false;
  submitted = false;
  messageError: string = "";
  errorOnSubmit: boolean = false;
  isFocused0: boolean = false;
  isFocused1: boolean = false;
  isFocused2: boolean = false;
  isFocused3: boolean = false;
  @ViewChild('formContainer') formContainer: ElementRef;
  public getAds = true;

  constructor(private fb: FormBuilder,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private cordovaService: CordovaService,
    private _nav: NavManagerService,
    private router: Router,
    private _location: Location,
    private _cacheService: CacheService,
    public registerService: RegisterService,
    private store: Store<AppState>,
    private errorModalService: ErrorModalService,
    private utilityService: UtilityService,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private branchService: BranchService,
    private cordovaservice: CordovaService,
    private otpService: OtpService) {
    this.createForm();
    if (this._cacheService.getCache('letsKnow') instanceof FormGroup) this.registerForm = this._cacheService.getCache('letsKnow');
    this.getState = this.store.select(selectRegisterState);
    window.addEventListener('keyboardWillHide', () => {console.log('keyboardWillHide');this.keyboardOpen = true; this.cdr.detectChanges();console.log(this.keyboardOpen);});
    window.addEventListener('keyboardWillShow', () => {console.log('keyboardWillShow');this.keyboardOpen = false;this.cdr.detectChanges();console.log(this.keyboardOpen);});
  }
  ngAfterViewInit(): void {
    this.formContainer.nativeElement.addEventListener('focusout', (e) => {
      try {
        if (e.relatedTarget.tagName === 'INPUT') {
          console.log('dont do anything..');
        }
        else {
          console.log('close!');
          this.cordovaService.closeKeyBoard()
        }
      }
      catch (e) {
        this.cordovaService.closeKeyBoard()
      }
    })
  }
  // @HostListener('document:touchstart', ['$event']) onTouch(event: any) {
  //   console.log(`HostListener('document:touchstart`);
  //   //clicked
  //   if (event.target.id === 'robinButtonCompSpan' || event.target.id === 'robinButtonCompContainer') {
  //     console.log(`HostListener('document:touchstart 2`);
  //     if (this.registerForm.valid) {
  //       console.log(`HostListener('document:touchstart 3`);
  //       this.onSubmit();
  //     }
  //   }
  // }
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.cordovaService.skipToNextInput()
    }
  }

  ngOnDestroy(): void {
    this.formContainer.nativeElement.removeEventListener('focusout', () => console.log('focusout event removed...'))
    window.removeEventListener('keyboardWillHide', () => {console.log('removed keyboardWillHide'); })
    window.removeEventListener('keyboardWillShow', () => {console.log('removed keyboardWillShow'); })
  }
  ngOnInit() {
    this.analyticsService.trackByMixpanel("User Registration - Form - Start");
    this.analyticsService.trackByAppFlyer("User Registration - Form - Start", { phoneNumber: this.analyticsService.phoneNumber, uuid: this.analyticsService.uuid });
    this.getState.subscribe((state) => {
    });
  }

  keyboardOpen = false;
  // dropdownValue(e) {
  // }


  createForm() {
    this.registerForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[\sa-z\u0590-\u05fe-]+$/i), Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[\sa-z\u0590-\u05fe-]+$/i), Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phoneNumber: [this.registerService.phoneNumber, [Validators.required, Validators.pattern(/^05[.\d]+$/), Validators.minLength(10), Validators.maxLength(10)]],
      // allowAds: [true, []],
      // policy: [false, [Validators.requiredTrue]],
      
    });
  }

  navTo(url) {
    if (url == "login") {
      //User pressed the "I'm already registered" button
      this.analyticsService.trackByMixpanel("User Registration - Registered User");
      this.analyticsService.trackByAppFlyer("User Registration - Registered User", { phoneNumber: this.analyticsService.phoneNumber, uuid: this.analyticsService.uuid });

    }
    this._nav.next(url);
  }

  toggleME() {
    this.mytoggle = !this.mytoggle;
  }

  navigateTakanon() {
    //User read the Terms&Conditions Takanon
    this.analyticsService.trackByMixpanel("User Registration - Terms");
    this.analyticsService.trackByAppFlyer("User Registration - Terms", { phoneNumber: this.analyticsService.phoneNumber, uuid: this.analyticsService.uuid });

    // this.router.navigate(['/onboarding', 'takanon']);
    // this.router.navigate(['/termsofcondition']);
    if (this.cordovaservice.platform === 'Android') {
      window.open('https://www.robinhoodpro.com/term-of-use/termsofuse-heb', '_system', 'location=no');
    } else {
      window.open('https://www.robinhoodpro.com/term-of-use/termsofuse-heb', '_system', 'location=yes');
    }
  }

  navigatePrivacy() {
    // User read the Terms&Conditions Takanon
    this.analyticsService.trackByMixpanel("User Registration - Terms");
    this.analyticsService.trackByAppFlyer("User Registration - Terms", { phoneNumber: this.analyticsService.phoneNumber, uuid: this.analyticsService.uuid });

    // this.router.navigate(['/onboarding', 'takanon']);
    // this.router.navigate(['/termsofcondition']);
    if (this.cordovaservice.platform === 'Android') {
      window.open('https://www.robinhoodpro.com/term-of-use/privacy-heb', '_system', 'location=no');
    } else {
      window.open('https://www.robinhoodpro.com/term-of-use/privacy-heb', '_system', 'location=yes');
    }
  }

  onSubmit() {
    //if this.errorOnSubmit displayed switch it off
    if (this.errorOnSubmit)
      this.errorOnSubmit = false;
    if ((this.registerForm.controls['firstName'].value == "" ||
      this.registerForm.controls['lastName'].value == "" ||
      this.registerForm.controls['email'].value == "") &&
      (!this.isFocused0 && !this.isFocused1 && !this.isFocused2 && !this.isFocused3)) {
      this.messageError = "יש למלא את כל השדות כדי להמשיך";
      this.errorOnSubmit = true;
    }

    if (this.registerForm.valid && !this.submitted) {
      this.submitted = true;
      let register = {
        email: this.registerForm.value.email,
        firstName: this.registerForm.value.firstName,
        lastName: this.registerForm.value.lastName,
        phoneNumber: this.registerService.phoneNumber,
      };

      this.registerService.register(register).subscribe((data: Response) => {
        if (data.Code === 0) {
          this.submitted = false;
          this.store.dispatch(new Register({ user: this.registerForm.value }));
          this._nav.next('otp');
          //this.router.navigate(['/onboarding', 'otp']);
          //User has finished to fill all fields with no errors and response is succes
          this.analyticsService.trackByMixpanel("User Registration - Form - End");
          this.analyticsService.trackByAppFlyer("User Registration - Form - End", { phoneNumber: this.analyticsService.phoneNumber, uuid: this.analyticsService.uuid });
        }
        else if (data.Code === 1002) {
          this.submitted = false;
          this.modalService.open('register');
        }
        else {
          this.submitted = false;
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open();
        }
      },
        err => {
          this.submitted = false;
          this.error = true;
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open();
        })
    }
    else {
      return;
    }
  }

  closeKeyboard() {
      if (window.Keyboard.isVisible)
        this.cordovaService.closeKeyBoard()
  }

  changeVal(event) {
    this.otpService.allowads = event;
  }
}
