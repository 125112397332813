import { Component, OnInit } from '@angular/core';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { AccountService } from 'src/app/account/account.service';
import { NavManagerService } from 'src/app/shared/components/robin-navbar/robin-navbar.service';
import { ErrorModalService } from '../../../../error-modal/modal.service';

@Component({
  selector: 'app-update-occupation-status',
  templateUrl: './update-occupation-status.component.html',
  styleUrls: ['./update-occupation-status.component.scss']
})
export class UpdateOccupationStatusComponent implements OnInit {

  position = this.accountService.authenticatedModel.Position;
  answered = this.accountService.authenticatedModel.Position;
  errorLabel = false;
  placeHolder;

  constructor(private cordovaService: CordovaService,
    private accountService: AccountService,
    private navbarService: NavManagerService,
    private errorModalService: ErrorModalService) {
    if (this.accountService.authenticatedModel.Position)
      this.placeHolder = this.convertOccupationStatusToWords(accountService.authenticatedModel.Position);
    else {
      this.placeHolder = 'סטטוס תעסוקתי';
    }
  }


  ngOnInit() {
  }

  closeKeyboard(e) {
    console.log('closeKeyboard ', e);
    if (!e) {
      this.cordovaService.closeKeyBoard()
    }
  }

  save() {
    if (this.answered) {
      this.errorLabel = false;
      var sendNewDetails = {
        RiskLevel: this.accountService.authenticatedModel.RiskLevel,
        City: this.accountService.authenticatedModel.City,
        StreetName: this.accountService.authenticatedModel.StreetName,
        HouseNumber: this.accountService.authenticatedModel.HouseNumber,
        PostalCode: this.accountService.authenticatedModel.PostalCode,
        Country: this.accountService.authenticatedModel.Country,
        AccountMaritalStatus: this.accountService.authenticatedModel.AccountMaritalStatus,
        LastSalary: this.accountService.authenticatedModel.Salary,
        JobTitle: this.accountService.authenticatedModel.JobTitle,
        Position: this.convertOccupationStatusEnum(this.answered),
        EmployerName: this.accountService.authenticatedModel.EmployerName,
        // NumberOfChildrens: this.accountService.authenticatedModel.NumberOfChildrens
        ChildrensBirthdates: this.accountService.authenticatedModel.Childrens

      };
      this.accountService.updateProfile(sendNewDetails).subscribe((data: any) => {
        if (data.Code === 0) {
          this.accountService.authenticatedModel.Position = this.convertOccupationStatusEnum(this.answered);
          this.accountService.showSuccessUpdate = true;
          setTimeout(() => {
            this.accountService.showSuccessUpdate = false;
          }, 3000);
          this.navbarService.prev();
        } else {
          this.errorModalService
            .setNewError({
              title: "אופס...",
              description: "נראה שמשהו השתבש בדרך",
              type: "error",
              buttonText: "נסה שנית"
            })
            .open();
        }
      });

    } else {
      this.errorLabel = true;
    }
  }

  convertOccupationStatusToWords(occupationStatus) {
    switch (occupationStatus) {
      case 1:
        return 'שכיר'
      case 2:
        return 'עצמאי'
      // case 3:
      //   return 'בעל שליטה'
      // case 4:
      //   return 'פנסיונר'
      default:
        return ''
    }
  }

  convertOccupationStatusEnum(occupationStatus) {
    switch (occupationStatus) {
      case 'שכיר':
        return 1
      case 'עצמאי':
        return 2
      // case 'בעל שליטה':
      //   return 3
      // case 'פנסיונר':
      //   return 4
      default:
        return ''
    }
  }

  positionDD(event) {
    this.answered = event;
    this.errorLabel = false;
  }
}