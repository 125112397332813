import {Component, OnInit, ViewChild, ElementRef, Input, forwardRef, Output, EventEmitter} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {logging} from "selenium-webdriver";

interface Coordinates {
  x: number;
  y: number;
}

@Component({
  selector: 'robin-slider',
  templateUrl: './robin-slider.component.html',
  styleUrls: ['./robin-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RobinSliderComponent),
      multi: true
    }
  ]
})
export class RobinSliderComponent implements OnInit {


  @ViewChild('slider') _slider: ElementRef;
  @Input() steps: number;
  @Input() answers: string[];
  @Output() public slided = new EventEmitter<any>();
  @Output() public ArrowPositionChanged = new EventEmitter<any>();
  @Input()
  public set currentStep(value: number) {
    if (value < 1) {
      this._currentStep = 1;
      return;
    }

    if (value > this.steps) {
      this._currentStep = this.steps;
      return;
    }
    this._currentStep = value;
    const eventData = {
      currentStep: this.currentStep,
    };
    this.slided.emit(eventData);
  }

  public get currentStep(): number {
    return this._currentStep;
  }
  public set arrowPosition (value: number) {
    this._arrowPosition = value;
    this.ArrowPositionChanged.emit(value);
  }
  public get arrowPosition (): number {
    return this._arrowPosition;
  }

  public get innerSlider(): HTMLElement {
    return this.slider.querySelector('.innerSlider');
  }

  public get measure(): HTMLElement {
    return this.slider.querySelector('.measure');
  }

  public get slider(): HTMLElement {
    return this._slider.nativeElement;
  }

  public _currentStep = 1;
  public canMove = false;
  public clickDelta: number;
  public _arrowPosition = 0;
  public sliderRect: any;

  constructor() {
  }

  ngOnInit() {
    this.sliderRect = this.slider.getBoundingClientRect();
    this.redrawSlider();
  }

  public touchStart(event: TouchEvent): void {
    this.canMove = true;
    this.clickDelta = event.touches[0].clientX - (event.target as HTMLElement).getBoundingClientRect().right + 22.5;
  }

  public touchMove(event: MouseEvent): void {
    const left = event['srcEvent'].clientX - this.sliderRect.left + this.clickDelta - 22.5;
    const width = this.sliderRect.width;
    const widthOfStep = width / (this.steps);

    if (this.canMove && left >= -1 && left <= width - 45) {
      this.measure['style'].left = left + 'px';
      this.innerSlider['style'].width = left + 23 + 'px';
      let old = this.currentStep;
      this.currentStep = 1 + Math.trunc( left / widthOfStep);
      if (this.currentStep !== old) this.arrowPosition = left;
    }
  }

  public touchEnd(event: MouseEvent): void {
    this.canMove = false;
    this.redrawSlider();
  }


  public redrawSlider(): void {
    const width = this.slider.offsetWidth;
    const widthOfStep = width / (this.steps - 1);

    if (this.currentStep === 1) {
      this.arrowPosition = 0;
      this.measure.style.left = '0px';
      this.innerSlider.style.width = '0px';
    } else if (this.currentStep === this.steps) {
      this.arrowPosition = this.slider.offsetWidth - this.measure.offsetWidth;
      this.measure.style.left = this.arrowPosition + 'px';
      this.innerSlider.style.width = this.slider.offsetWidth + 'px';
    } else {
      this.arrowPosition = (widthOfStep * (this.currentStep - 1)) - (this.measure.offsetWidth / 2);
      this.measure.style.left = this.arrowPosition + 'px';
      this.innerSlider.style.width = widthOfStep * (this.currentStep - 1) + 'px';
    }
  }
}
