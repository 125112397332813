import { User } from './../../../../../../../core/models/user.model';
import { selectRegisterState, AppState, selectAnonymous } from './../../../../../../../store/app.states';
import { HomepageService } from './../../../../../../../homepage/homepage.service';
import { Router } from '@angular/router';
import { Component, OnInit, NgZone } from '@angular/core';
import { uiStrings } from '../../../../../../assets/heb.ui_strings';
import { AnalyticsService } from '../../../../../../../analytics/analytics.service';
import { OcrService } from '../../../../../../services/ocr/ocr.service';
import { Store } from '@ngrx/store';
import { pipe } from '@angular/core/src/render3/pipe';
import { map } from 'rxjs/operators';
import { CalledAnonymous } from 'src/app/store/user.actions';
import { ErrorModalService } from 'src/app/shared/components/modals/error-modal/modal.service';

@Component({
  selector: 'no-id-modal',
  templateUrl: './no-id-modal.component.html',
  styleUrls: ['./no-id-modal.component.scss']
})
export class NoIdModalComponent implements OnInit {

  public uiStrings;
  constructor(
    private homepageService: HomepageService,
    private store: Store<AppState>,
    private router: Router,
    private analyticService: AnalyticsService,
    private ocrService: OcrService,
    private zone: NgZone,
    private errorModalService: ErrorModalService
  ) {
    this.uiStrings = uiStrings.noidmodal;
  }

  ngOnInit() {
  }
  navigateTo(route) {
    if (route !== '/onboarding/credit') {
      //User pressed "save no id" button
      this.analyticService.trackByMixpanel("User Registration - FDC - RemindMe - Click");
      this.analyticService.trackByAppFlyer("User Registration - FDC - RemindMe - Click", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    } else {
      //User pressed credit card identification button
      this.analyticService.trackByMixpanel("User Registration -  FDC - VerifyCreditCard - Click");
      this.analyticService.trackByAppFlyer("User Registration -  FDC - VerifyCreditCard - Click", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    }
    this.ocrService.creditToBack = true;
    this.store.select(selectRegisterState).pipe(map((data: any) => data.user)).subscribe(obj => {
      let user = { phoneNumber: obj.phoneNumber, email: obj.email };

      this.store.select(selectAnonymous).subscribe((data: any) => {
        if (data.anonymous !== null) {
          if (route === '/onboarding/credit')
            this.router.navigate(['/onboarding/credit']);
          else {
            if (this.homepageService.firstTimeQuestionnaire === true) {
              this.router.navigate(['/homepage'])
            } else {
              data.anonymous.FilledQuistionnaire ? this.router.navigate(['/homepage']) : this.router.navigate([`/questionnaire`]);
            }
          }
        } else {
          this.homepageService.anonymousStatus(user).subscribe((data: any) => {

            if (data.Code === 0) {

              this.store.dispatch(new CalledAnonymous({ anonymous: data.Data }));

              if (route === '/onboarding/credit')
                this.zone.run(() => this.router.navigate(['/onboarding/credit']));
              else {
                if (data.Data.FilledQuistionnaire === false)
                  this.zone.run(() => this.router.navigate([`/questionnaire`]));
                else
                  this.zone.run(() => this.router.navigate(['/homepage']));
              }
            } else {
              this.errorModalService.setNewError({
                title: 'אופס...',
                description: 'נראה שמשהו השתבש בדרך',
                type: 'error',
                buttonText: 'נסה שנית',
              }).open();
            }


            
          });
        }
      });
    });
  }
}
