import { Component, OnInit, ElementRef, ViewChild, NgZone, AfterViewInit } from '@angular/core';
import { selectRegisterState, AppState, selectToken } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
declare const window;
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, AfterViewInit {
  phoneNumber;
  email;
  token;
  src;
  @ViewChild('iframe') iframe: ElementRef;

  constructor(private store: Store<AppState>,
    private zone: NgZone,
    private router: Router,
    private cordovaSrevice: CordovaService,
    private analyticService: AnalyticsService) {

    window.addEventListener('message', (e) => {
      switch (e.data) {
        case 'closeclal':
          this.backToApp();
          if (this.cordovaSrevice.isMobile) window.Keyboard.hide()
          break;
        case 'pdf1':
          this.zone.run(() => {
            this.cordovaSrevice.open('pdf1');
          });
          break;
        case 'pdf2':
          this.zone.run(() => {
            this.cordovaSrevice.open('pdf2');
          });
          break;
        case 'bot':
          this.zone.run(() => {
            this.analyticService.trackByMixpanel("DP - Clal Briut - Quotation Questions");
            this.analyticService.trackByAppFlyer("DP - Clal Briut - Quotation Questions", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

            
          });
          break;
        case 'offer':
          this.zone.run(() => {
            this.analyticService.trackByMixpanel("DP - Clal Briut - Quotation");
            this.analyticService.trackByAppFlyer("DP - Clal Briut - Quotation", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          });
          break;
        case 'form':
          this.zone.run(() => {
            this.analyticService.trackByMixpanel("DP - Clal Briut - Pre Purchase");
            this.analyticService.trackByAppFlyer("DP - Clal Briut - Pre Purchase", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          });
          break;
        case 'step1':
          this.zone.run(() => {
            this.analyticService.trackByMixpanel("DP - Clal Briut - Details Completion");
            this.analyticService.trackByAppFlyer("DP - Clal Briut - Details Completion", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          });
          break;
        case 'step2':
          this.zone.run(() => {
            this.analyticService.trackByMixpanel("DP - Clal Briut - Health Declaration");
            this.analyticService.trackByAppFlyer("DP - Clal Briut - Health Declaration", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          });
          break;
        case 'step3':
          this.zone.run(() => {
            this.analyticService.trackByMixpanel("DP - Clal Briut - Sign Declaration");
            this.analyticService.trackByAppFlyer("DP - Clal Briut - Sign Declaration", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
          });
          break;
        case 'step4':
          this.zone.run(() => {
            this.analyticService.trackByMixpanel("DP - Clal Briut - Credit Card");
            this.analyticService.trackByAppFlyer("DP - Clal Briut - Credit Card", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          });
        case 'done':
          this.zone.run(() => {
            this.analyticService.trackByMixpanel("DP - Clal Briut - End");
            this.analyticService.trackByAppFlyer("DP - Clal Briut - End", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
          });
          break;
        case 'closeKeyboard':
          this.zone.run(() => {
            window.Keyboard.hide();
          });
          break;
      }

    }, false
    );
  }

  ngAfterViewInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.store.select(selectRegisterState).subscribe((data: any) => {
      if (data.user !== null) {
        this.phoneNumber = data.user.phoneNumber;
        this.email = data.user.email;
      }
    });
    this.store.select(selectToken).subscribe((data: any) => {
      this.token = data.token;



      // PROD
      this.src = "https://rh-prod-clal.azurewebsites.net/#/auth/login?param" + this.token + "param" + this.email + "param" + this.phoneNumber;
      // DEV
      // this.src = "https://rh-dev-clal.azurewebsites.net/#/auth/login?param" + this.token + "param" + this.email + "param" + this.phoneNumber;
      //LOCALHOST
      // this.src = "http://localhost:9000/app/#/auth/login?param" + this.token + "param" + this.email + "param" + this.phoneNumber;

      console.log(this.src);
      let a: any = document.querySelectorAll('#clal')[0];
      a.src = this.src;
      // this.zone.run(()=> {
      //   this.iframe.nativeElement.src = this.src;

      // })
      // this.src = "http://rh-dev-clal.azurewebsites.net/#/auth/login?param";
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    window.removeEventListener('closeclal', () => { });

  }

  backToApp() {
    // var someIframe = window.parent.document.getElementById('clal');
    // someIframe.parentNode.removeChild(window.parent.document.getElementById('clal'));
    this.zone.run(() => {
      this.router.navigate(['/account/insurance/myhealth']);
    });
  }

  ngOnInit() {
  }

}
