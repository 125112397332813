import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MbscDatetimeOptions } from 'src/lib/mobiscroll-package/dist/js/mobiscroll';
import { selectRegisterState, AppState } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';
import { UtilityService } from 'src/app/core/services/utility.service';
import { AccountService } from '../../account.service';
import { ModalService } from 'src/app/shared/components/robin-modal/modal.service';
import { NavManagerService } from 'src/app/shared/components/robin-navbar/robin-navbar.service';
import { Response } from 'src/app/models/global.models';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { idValidator } from 'src/app/shared/services/validators';

declare const window;

@Component({
  selector: 'app-credit-update-mislaka',
  templateUrl: './credit-update-mislaka.component.html',
  styleUrls: ['./credit-update-mislaka.component.scss']
})
export class CreditUpdateMislakaComponent implements OnInit {

  creditcardForm: FormGroup;
  phoneNumber;
  submitted = false;
  creditcardErrorMessageShow = false;
  creditErrorMessage = '';
  aYearFromNow = new Date();
  expOutput = '';
  @ViewChild('exp') exp: ElementRef;
  mobiScrollClick;

  options: MbscDatetimeOptions = {
    theme: 'robin-v2',
    lang: 'he',
    max: new Date(new Date(this.aYearFromNow.setFullYear(this.aYearFromNow.getFullYear() + 7)).getFullYear(), new Date().getMonth(), 1),
    min: Date.now(),
    dateWheels: 'yyyy mm',
    dateFormat: 'mm/y'
  };


  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private utilService: UtilityService,
    private accountService: AccountService,
    private modalService: ModalService,
    private navbarService: NavManagerService,
    private cordovaService: CordovaService,

  ) { }

  ngOnInit() {
    this.createForm();

    this.store.select(selectRegisterState).subscribe((data: any) => {
      this.phoneNumber = data.user.phoneNumber;
      console.log('phoneNumber',this.phoneNumber);
    })
  }
  ngAfterViewInit(): void {
    this.createForm();
  }

  createForm() {
    this.creditcardForm = this.fb.group({
      id: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9), idValidator]],
      creditcard: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
      expiration: [this.expOutput, [Validators.required]],
      cvv: ['', [Validators.required, Validators.pattern(/^[0-9]/), Validators.minLength(3), Validators.maxLength(4)]],
    });
  }

  updateDataWithCredit() {
    if (this.creditcardForm.valid && !this.submitted) {
      let data = this.prepareFormData();
      this.submitted = true;
      console.log('data', data)
      this.accountService.creditCardPay(data).subscribe((data: Response) => {
        if (data.Code === 0 && data.Data) {
          this.submitted = false;
          this.accountService.numDaysMislaka.next(-1);
          this.modalService.open('success-update-data');
          // if (this.navbarService.history[this.navbarService.history.length-2].url==="/account/pension")
          this.navbarService.next('pension');
        }
        else {
          this.submitted = false;
          this.setErrorMessage('נראה שפרטי האשראי שהוזנו אינם תואמים למס׳ תעודת הזהות', true);
        }
      },
        err => {
          this.submitted = false;
          this.setErrorMessage('נראה שפרטי האשראי שהוזנו אינם תואמים למס׳ תעודת הזהות', true);
        });
    }
    else {
      this.creditcardErrorMessageShow = true;
      this.creditErrorMessage = 'יש למלא את כל השדות';
      setTimeout(() =>{
        this.creditcardErrorMessageShow = false;
      }, 1000 * 3);
    }
  }

  prepareFormData() {
    let Month;
    let tempMonth = new Date(this.creditcardForm.controls['expiration'].value).getMonth() + 1;
    let tempYear = new Date(this.creditcardForm.controls['expiration'].value).getFullYear();
    if (tempMonth < 10)
      Month = '0' + tempMonth.toString();
    else
      Month = tempMonth.toString();

    console.log('tempmonth', tempMonth);
    console.log('tempYear', tempYear)

    let temp = { ...this.creditcardForm.controls };
    const IdentityNumber = temp.id.value;
    const Cvv = temp.cvv.value;
    const CardNumber = temp.creditcard.value;

    // let parsedData = temp.expiration.value.split('/');
    const Year = tempYear.toString().substring(2, 4);
    const PhoneNumber = this.phoneNumber;
    return { IdentityNumber, Cvv, CardNumber, Month, Year, PhoneNumber };
  }

  setErrorMessage(text, value) {
    this.creditcardErrorMessageShow = value;
    setTimeout(() => {
      this.creditcardErrorMessageShow = false;
    }, 1000 * 3);
    this.creditErrorMessage = text;
  }

  check(e, len) {
    console.log('e', e);
    this.utilService.digitOnly(e);
    this.utilService.inputLength(e, len);
  }

  hideKeyboard() {
    if (window.Keyboard && this.cordovaService.platform === 'Android') window.Keyboard.hide(true);
  }

  showExp(event) {
    // show
    this.mobiScrollClick = true;
    window.scrollTo(0, 0);
    document.body.classList.add('disableOverflow');
  }

  closeExp(event) {
    // close
    document.body.classList.remove('disableOverflow');
    this.mobiScrollClick = false;
    document.querySelectorAll('robin-input input')[2].classList.add('set-exp-date');
  }

  setExp(event, i) {
    console.log('fff', this.expOutput, event.valueText);
    this.expOutput = event.valueText;
  }

}
