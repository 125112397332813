import { CALLEDANONYMOUS } from './../../store/user.actions';
import { Action } from '@ngrx/store';
import { All } from "../../store/user.actions";

export interface State {
    anonymous: {} | null
}

export const initialState: State = {
    anonymous: null
};

export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case CALLEDANONYMOUS: {
            return {
                ...state,
                anonymous: action.payload.anonymous
            };
        }
        default: {
            return state;
        }
    }
}
