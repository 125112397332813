import { Router } from "@angular/router";
import { NavManagerService } from "../../../shared/components/robin-navbar/robin-navbar.service";
import { Component, OnInit } from "@angular/core";
import { RegisterService } from "../../services/register/register.service";
import { Store } from "../../../../../node_modules/@ngrx/store";
import { AppState, selectRegisterState, selectAnonymous } from "../../../store/app.states";
import { HomepageService } from "../../../homepage/homepage.service";
import { Response } from '../../../models/global.models';

import { FetchingSuccess } from "../../../store/backend.actions";
import { InsuranceService } from "src/app/account/insurance/services/insurance.service";
import { advanceActivatedRoute } from "@angular/router/src/router_state";
import { CalledAnonymous } from "src/app/store/user.actions";
import { ErrorModalService } from "src/app/shared/components/modals/error-modal/modal.service";

@Component({
  selector: "app-collect-success",
  templateUrl: "./collect-success.component.html",
  styleUrls: ["./collect-success.component.scss"]
})


export class CollectSuccessComponent implements OnInit {
  phoneNumber;
  email;
  anonymousDataObject;
  constructor(
    private serMock: RegisterService,
    private router: Router,
    private navbarService: NavManagerService,
    private store: Store<AppState>,
    private homepageService: HomepageService,
    private insuranceService: InsuranceService,
    private errorModalService: ErrorModalService

  ) {
    this.store.select(selectAnonymous).subscribe((data: any) => {
      this.anonymousDataObject = data.anonymous;
    });
  }

  ngOnInit() {
    if (this.navbarService.history[this.navbarService.history.length - 3].url === "/account/insurance" || this.navbarService.history[this.navbarService.history.length - 4].url === "/account/insurance")
      this.insuranceService.inProgressFirstTime = true;
    else {
      this.homepageService.firstTime = true;
    }

    localStorage.setItem("mock", "true");
    // this.navbarService
    setTimeout(() => {
      // this.router.navigate(["/questionnaire"]);

      this.store.select(selectRegisterState).subscribe((data: any) => {
        if (data.user !== null) {
          this.phoneNumber = data.user.phoneNumber;
          this.email = data.user.email;
          let user = { email: this.email, phoneNumber: this.phoneNumber };

          if (!this.anonymousDataObject) {

            this.homepageService.anonymousStatus(user).subscribe((data: Response) => {

              if (data.Code === 0) {

                // this.store.dispatch(new FetchingSuccess());
                this.store.dispatch(new CalledAnonymous({ anonymous: data.Data }));
                console.log('dor => ', data);
                if (this.navbarService.history[this.navbarService.history.length - 3].url === "/account/insurance/myhealth" || this.navbarService.history[this.navbarService.history.length - 4].url === "/account/insurance/myhealth")
                  this.router.navigate([`/account`, 'insurance', 'myhealth']);
                else {
                  if (this.homepageService.firstTimeQuestionnaire === true) {
                    this.router.navigate(['/homepage'])
                  } else {
                    data.Data.FilledQuistionnaire ? this.router.navigate(["/homepage"]) : this.router.navigate(["/questionnaire"]);
                  }

                }
              } else {
                this.errorModalService.setNewError({
                  title: 'אופס...',
                  description: 'נראה שמשהו השתבש בדרך',
                  type: 'error',
                  buttonText: 'נסה שנית',
                }).open();
              }
              
              
            });

          } else {
            if (this.navbarService.history[this.navbarService.history.length - 3].url === "/account/insurance/myhealth" || this.navbarService.history[this.navbarService.history.length - 4].url === "/account/insurance/myhealth")
              this.router.navigate([`/account`, 'insurance', 'myhealth']);
            else {
              this.anonymousDataObject.FilledQuistionnaire ? this.router.navigate(["/homepage"]) : this.router.navigate(["/questionnaire"]);
            }
          }

        }
      });


    }, 3550);
  }
}
