
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ModalService } from '../robin-modal/modal.service';
import { CacheService } from '../../../onboarding/services/cache.service';
import { NavManagerService } from './robin-navbar.service';
import { info } from './info.mock';
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { TouchSequence } from 'selenium-webdriver';
import { ObjectUnsubscribedError } from 'rxjs';
import { RobinNavBar } from './robin-navbar.model';
import { shareReplay, map, filter, last } from 'rxjs/operators';
import { RobinSideNavService } from '../modals/robin-side-nav/robin-side-nav.service';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { SupportService } from '../support/support.service';
import { AccountService } from 'src/app/account/account.service';
import { CordovaService } from 'src/app/core/services/cordova.service';
@Component({
  selector: 'robin-navbar',
  templateUrl: './robin-navbar.component.html',
  styleUrls: ['./robin-navbar.component.scss'],
  animations: []
})
export class RobinNavbarComponent implements OnInit {
  public _background;
  @Input() type;
  _title: string;
  _hamburgerStatic = true;
  _hamburgerMenu: any = false;
  showModal = false;
  // showSideNav;
  _showBack = true;
  navbar;
  howManyFriendsSend;
  _support = true;
  navState;
  @ViewChild('temp') modal;
  @Input() set background(color) {
    this._background = color;
  }
  @Input() set static(isStatic) {
    this._hamburgerStatic = isStatic;
  }
  @Input() set support(sprt) {
    this._support = sprt;
  }
  @Input() isInfoIcon;
  @Input() set showBack(sb) {
    if (this.navbarService.showback === false)
      this._showBack = sb;
    else
      this._showBack = true;



  }
  // isBack = true;
  @Input() set menu(menu) {
    this._hamburgerMenu = menu;
  }

  @Output() drawerOpen = new EventEmitter();

  constructor(private navbarService: NavManagerService,
    public _cacheService: CacheService,
    public _modalService: ModalService,
    public locationService: Location,
    public route: ActivatedRoute,
    public sideNavService: RobinSideNavService,
    public supportService: SupportService,
    public router: Router,
    private analyticService: AnalyticsService,
    public accountService: AccountService,
    private cordovaSrevice: CordovaService,
  ) {
    this.navbarService.getTitle$.subscribe((obj: any) => {
      this._title = obj.title || 'NULL';
      if (obj.color)
        this._background = obj.color;
      if (obj.static)
        this._hamburgerStatic = obj.static;
      if (obj.menu)
        this._hamburgerMenu = obj.menu;
      if (!obj.support) {
        console.log('got that shit');
        this._support = obj.support;
      }
    });
    // this.sideNavService.currentState$.subscribe(data => this.showSideNav = data);

  }

  ngOnInit() {
    this.navbar = new RobinNavBar(this.type);
    this.navState = this.navbar.getState();

  }
  @Input() set title(ttl) {
    this._title = ttl;
  }

  setTitle() {

  }
  info() {
    let g = info.find(p => p.path === this.router.url)
    if (g.data.length > 0) {
      this.drawerOpen.emit(g.data);
      console.log('lazanga', this.router.url)

    }


  }

  back() {
    if (this._hamburgerMenu && this._hamburgerMenu !== 'x') {
      this.sideNavService.open();
      document.body.classList.add('disableOverflow');

      // this.showSideNav = true;
    } else {
      switch (this._title) {
        case "ביטוח":
          break;
        case "פנסיה":
          break;
        case "הרשמה":
          this.analyticService.trackByMixpanel("User Registration - Form - Back");
          this.analyticService.trackByAppFlyer("User Registration - Form - Back", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          break;
        case "סיסמה":
          this.analyticService.trackByMixpanel("User Registration - OTP - Back");
          this.analyticService.trackByAppFlyer("User Registration - OTP - Back", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          break;
        case "שאלון להמלצות אישיות":
          this.analyticService.trackByMixpanel("User Registration - RQ - Back");
          this.analyticService.trackByAppFlyer("User Registration - RQ - Back", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          break;
      }
      this.navbarService.prev();
    }

  }

  closeModal(id = null) {
    if (id) {
      this._modalService.close(id);

      // setTimeout(() => body.classList.remove('disableOverflow'), 1000);

    }
    // else
    //   this.showModal = false;
  }

  openSupport() {
    this._modalService.open('support');
    document.getElementById("rateTask").style.zIndex = "1";
    console.log('this router', this.router.url)
    this.supportService.whereFrom = this.navbarService.formatToCamelCase(this.router.url);
    console.log('this.supportService.whereFrom', this.supportService.whereFrom)

    // this.navbarService.lastRoute
    // let body = document.querySelector('body');
    // body.classList.add('disableOverflow');
  }

  forceBack(id) {
    this._modalService.close(id);
    this.navbarService.prev(true);
  }

  yesCoins(id) {
    let coins;
    this._modalService.close(id);
    this.accountService.robinCoins().subscribe((data: any) => {
      if (data.Code === 0 && data.Data) {
        this.accountService.coin$.subscribe(data => coins = data);
        this.accountService.numDaysMislaka.next(-1);
        this.accountService.coin.next(coins - 3);

        this._modalService.open('success-update-data');
        this.router.navigate([`/account`, 'pension']);
      }
      else {

      }
    });
  }

  goToRegister(id) {
    this.router.navigate(['/onboarding', 'login']);
    this._modalService.close(id);
  }



}
