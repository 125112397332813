import { CacheService } from './../../services/cache.service';
import { ApiService } from './../../services/api.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectInsurance } from 'src/app/store/app.states';
import { map } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'upgrade-product',
  templateUrl: './shifted-life-insurance.component.html',
  styleUrls: ['./shifted-life-insurance.component.scss']
})
export class UpgradeProductComponent implements OnInit {
combinedFromArr = []
recommendation:any = {}
fromProduct;
toProduct
amount;
  constructor(private store: Store<AppState>, 
              private router: Router, 
              private apiService: ApiService, 
              private cacheService: CacheService,
              private analyticService: AnalyticsService) { 
    this.store.select(selectInsurance).pipe(map((data:any) => data.insuranceDetails && data.insuranceDetails.LifeInsurance)).subscribe(data => {
      this.combinedFromArr = data.CombinedFrom
      this.recommendation = data.SummaryInsuranceRecommendation
      this.fromProduct = this.recommendation && this.recommendation.FromProduct && this.combinedFromArr[this.recommendation.FromProduct.Id]
      this.amount = this.recommendation && this.recommendation.RecommendedInfo && this.recommendation.RecommendedInfo.Amount
      this.toProduct = this.recommendation && this.recommendation.RecommendedManufacturer
    })
  }

  ngOnInit() {
    this.analyticService.trackByMixpanel("DP - Shihluf Risk - Preaction Page");
    this.analyticService.trackByAppFlyer("DP - Purchase Risk - Preaction Page", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });


  }
  next(){
    this.apiService.startProcess().subscribe((data:any) => {
      if(!!!(data.Code)){
      this.cacheService.generatedProcessId = data.Data
      this.router.navigate(['/risk'])
      }
      else{
        //TODO: error handling
        console.error('ERROR');
      }
    })
  }
}
