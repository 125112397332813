import { Timer } from './../../../homepage/components/home-screen/timer/time.model';
import { UtilityService } from './../../../core/services/utility.service';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { OcrService } from './../../services/ocr/ocr.service';
import { animate } from '@angular/animations';
import { ConnectableObservable, Observable } from 'rxjs';
import { NavManagerService } from '../../../shared/components/robin-navbar/robin-navbar.service';
import { CanActivate, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as ui_strings from '../../assets/heb.ui_strings';
import { Response } from '../../../models/global.models';
import { OnboardingService } from '../../services/onboarding.service';
import { ModalService } from '../../../shared/components/robin-modal/modal.service';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { SupportService } from '../../../shared/components/support/support.service';

@Component({
  selector: 'app-please-wait',
  templateUrl: './please-wait.component.html',
  styleUrls: ['./please-wait.component.scss']
})
export class PleaseWaitComponent implements OnInit {

  uiStrings;
  loadObj;
  animationObj = [];
  types = ['standby', 'loading', 'done'];
  timeout;
  isError;
  ok = false;
  proccessing = true;
  proccessSuccessful  = false;
  pleaseWaitCounter;
  fromSupport;
  fromCredit;
  constructor(private onboardingService: OnboardingService,
    private utilService: UtilityService,
    private http: HttpClient,
    private router: Router,
    private modalService: ModalService,
    private navbarService: NavManagerService,
    private ocrService: OcrService,
    private supportService: SupportService,
    private analytcService: AnalyticsService) {
    this.uiStrings = ui_strings.uiStrings.pleaseWait;
    this.loadObj = this.uiStrings.bullets;
    this.setVirtualObj();
    this.fromSupport = this.onboardingService.goTosupport;
    this.fromCredit = this.onboardingService.goToCredit;
    this.pleaseWaitCounter = this.onboardingService.pleaseWaitCounter;
    if ( this.onboardingService.goTosupport === false || this.onboardingService.goToCredit === 0) {
      this.ocrVerification();
    }

  }

  ngOnInit() {
    if (this.fromSupport === true || this.fromCredit === 1 || this.fromCredit === 2) 
      this.proccessing = false;
    
    this.analytcService.trackByMixpanel("User Registration - FDC - Please Wait");
    this.analytcService.trackByAppFlyer("User Registration - FDC - Please Wait", { phoneNumber: this.analytcService.phoneNumber, uuid: this.analytcService.uuid });

    this.startAnimation();
    // if(!environment.production){
    //   this.navbarService.next();
    // }

  }


  setVirtualObj() {
    this.uiStrings.bullets.map(el => this.animationObj.push({ ...el, type: this.types[0] }));
  }

  getCurrentType(num) {
    return this.animationObj[num].type;
  }
  getNextTypeIndex(type) {
    var nextType;
    this.types.map((el, index) => {
      if (el === type) nextType = index;
    })
    return nextType + 1;
  }

  getCurrentTypeIndex(type) {
    var nextType;
    this.types.map((el, index) => {
      if (el === type) nextType = index;
    })
    return nextType;
  }

  setNextStep(num) {
    setTimeout(() => {
      let currentType = this.getCurrentType(num);
      if (this.getCurrentTypeIndex(currentType) < this.types.length - 1) {
        this.animationObj[num].type = this.types[this.getNextTypeIndex(currentType)];
        if (currentType !== 'standby' || num !== 2) {
          this.setNextStep(num++);
        }
      }
    }, 2000);
  }


  startAnimation() {
    this.setNextStep(0);
    setTimeout(() => {
      this.setNextStep(1);
      this.timeout = setTimeout(() => {
        this.setNextStep(2);
      }, 2000);
    }, 2000);
  }

  ocrVerification() {
    setTimeout(() => {
      if(!this.proccessSuccessful){
        this.onboardingService.setWaitCounter();
        this.throwError();
      }
    }, 60 * 1000);
    try{

      this.ocrService.data$.subscribe((data: Response) => {
        if (data.Data.IsVerified) {
          this.proccessSuccessful = true;
          this.onboardingService.IdentityNumber = data.Data.PrimaryIdentifier;
          //success
          // clearTimeout(this.timeout);
          // this.ok = true;
  
          this.onboardingService.setIdentityNumber(data.Data.PrimaryIdentifier);
          this.navbarService.next();
          this.onboardingService.resetWaitCounter();
  
        }
        else if (data.Code === 1) {
          //wrong details
          // clearTimeout(this.timeout);
          this.isError = data.Description;
          this.throwError();
        }
        else {
          //server error
          // clearTimeout(this.timeout);
          this.isError = data.Description;
          this.throwError();
  
        }
      });
    } catch{
      this.onboardingService.setWaitCounter();
      this.throwError();
    }
  }

  throwError() {
    this.proccessing = false;
  }

  redirectAgain(route) {
    if (route === 'credit') {
      this.ocrService.creditToBack = true;
        this.onboardingService.goToCredit = 1;
        this.onboardingService.goToCredit = 2;
    }

    this.router.navigate([`/onboarding/${route}`]);
  }
  support() {
    this.modalService.open('support');
    this.supportService.whereFrom = this.navbarService.formatToCamelCase(this.router.url);
    this.onboardingService.goTosupport = true;
  }

}

