import { trigger, transition, animate, style, state } from '@angular/animations';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, ElementRef, Input, ViewChild, forwardRef, Output, EventEmitter, AfterViewInit } from '@angular/core';


@Component({
  selector: 'robin-dropdown',
  templateUrl: './robin-dropdown.component.html',
  styleUrls: ['./robin-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RobinDropdownComponent),
      multi: true
    }
  ],
  animations: [trigger('simpleFadeAnimation', [
    state('in', style({ opacity: 1 })),
    transition(':enter', [
      style({ opacity: 0 }),
      animate(600)
    ]),
    transition(':leave',
      animate(600, style({ opacity: 0 })))
  ]),
  trigger('dropdownAnimation', [
    transition('void => *', [
      style({
        opacity: 0,
        transform: 'scale(0)',
        transformOrigin: '50% 0%'
      }),
      animate('0.1s ease-in', style({
        opacity: 1,
        transform: 'scale(1)',
        transformOrigin: '50% 0%'
      }))
    ]),
    transition('* => void', [
      style({
        opacity: 1,
        transform: 'scale(1)',
        transformOrigin: '50% 0%'
      }),
      animate('0.1s ease-out', style({
        opacity: 0,
        transform: 'scale(0)',
        transformOrigin: '50% 0%'
      }))
    ]),
  ])
  ]
})
export class RobinDropdownComponent implements OnInit {
  @Input() answers: string[];
  @Input() placeholder: string;
  @ViewChild('drop') drop: ElementRef;
  public yourAnswer = null;
  @Output() checked = new EventEmitter();
  public up = true;
  @Input() gender = false;
  @Input() hmo = false;

  public active = false;
  public open = false;
  _error
  _value
  _touched
  _errorMessage
  @Input() set error(er) { this._error = er }
  @Input() set value(er) { this._value = er }
  @Input() set touched(er) { this._touched = er }
  @Input() set errorMessage(er) { this._errorMessage = er }

  constructor() { }

  ngOnInit() {
    this.gender;
    this.refactorRangeOfAnswers();
  }

  refactorRangeOfAnswers() {
    if (this.answers && this.answers[1] === '-') {
      let elZero = this.answers[0];
      let elThree = this.answers[2];
      let tmpArr: string[] = [];
      for (let i = Number(elZero), j = 0; i <= Number(elThree); i++ , j++) tmpArr[j] = String(i);
      this.answers = tmpArr;
    }
  }
  writeValue(val: string): void {
    this.yourAnswer = val;
  }
  onChange = (rating) => { };
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void { }
  setDisabledState(isDisabled: boolean): void { }
  toggleDropdowns(e) {
    let val = window.innerHeight - Number(this.drop.nativeElement.getBoundingClientRect().bottom);
    if (val < 300) {
      this.up = true;
    }
    else {
      this.up = false;
    }
    this.open = !this.open;
    this.active = this.open ? true : false;

  }
  selectAnswer(answer) {
    this.yourAnswer = answer;
    this.checked.emit(this.yourAnswer);
    this.onChange(this.yourAnswer);
    this.placeholder = answer;
  }
  outside(e) {
    if (!e && this.open) {
      this.open = false;
    };
  }
}
