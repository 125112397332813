import { CacheService } from 'src/app/risk/services/cache.service';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { Store } from '@ngrx/store';
import { AppState, selectInsurance } from 'src/app/store/app.states';
import { map } from 'rxjs/operators';
import { AccountService } from 'src/app/account/account.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-electronic-signature',
  templateUrl: './electronic-signature.component.html',
  styleUrls: ['./electronic-signature.component.scss']
})
export class ElectronicSignatureComponent implements OnInit {
  signed = false
  signHere = 'חתום כאן'
  signature = ''
  manufacture;
  shift;
  error = false
  identityNumber;
  currentAmount;
  @Output() completed = new EventEmitter()
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  signaturePadOptions: Object = {
    'canvasWidth': 325,
    'canvasHeight': 145
  };
  fromTo: any = {
    from: {},
    to: {}
  }

  constructor(private cacheService: CacheService, 
              private store:Store<AppState>,
              private accountService: AccountService,
              private analyticService: AnalyticsService) { 
    this.store.select(selectInsurance).pipe(map((data:any) => data.insuranceDetails && data.insuranceDetails.LifeInsurance)).subscribe(
      data => {
        if (data.CurrentAmount === 0){
          this.currentAmount = 0;
        }
        if (data.SummaryInsuranceRecommendation.FromProduct) { // MENORS
          this.manufacture = 'menora'
          this.shift = true;
        } else { //CLAL
          this.manufacture = 'menora'
          this.shift = false;
        }
        let combinedFromArr = data.CombinedFrom;
        let fromId = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.FromProduct && data.SummaryInsuranceRecommendation.FromProduct.Id
        let recommendedProduct = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.RecommendedManufacturer
        let recommendedInfo = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.RecommendedInfo
        this.fromTo.to.number = recommendedProduct.PrivateCompany
        this.fromTo.to.name = recommendedProduct.Name
        //TODO: 
        //TODO: 
        this.fromTo.from.number  = 0
        this.fromTo.from.name = 'רמי'
        this.fromTo.companyName = recommendedProduct.Name
        this.fromTo.amount = recommendedInfo.Amount
      })
      this.identityNumber = this.accountService.authenticatedModel.IdentityNumber
  }

  ngAfterViewInit(): void {
    this.signaturePad.set('penColor', 'rgb(0, 0, 0)');
    let canvas: any = document.querySelector('canvas')
    canvas.width = window.innerWidth - (24*2)
    canvas.style.background = 'rgb(244, 247, 251)'
  }
  ngOnInit() {
    this.analyticService.trackByMixpanel("DP - Risk - End");
    this.analyticService.trackByAppFlyer("DP - Risk - End", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }
  cleanDraw() {
    if(this.signed){
      this.signaturePad.clear();
      // let canvas: any = document.querySelector('canvas')
      // canvas.width = window.innerWidth
      this.signed = false
      this.handleDraw()
    }
  }
  drawStart() {
    this.signed = true
    this.handleDraw()
    if(this.error) this.error = false
  }
drawComplete(e) {
}
handleDraw() {
  this.signHere = this.signed ? 'נקה חתימה' : 'חתום כאן'
}

next(){
  if(this.signed){
    this.error = false;
    let signature = this.signaturePad.toDataURL()
    this.completed.emit(signature);
    this.cacheService.cache[5] = signature
    this.cacheService.cache[6] = {}
    this.cacheService.currentTab.next(6)
  }
  else{
    this.error = true
  }
}

}
