import { Fetching, FetchingFailure, FetchingSuccess } from './../../../store/backend.actions';
import { environment } from './../../../../environments/environment';
import { AppState, selectPension } from './../../../store/app.states';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '../../../../../node_modules/@ngrx/store';
import { PensionSummary, Response, PensionProduct } from '../../../models/global.models';
import { map, shareReplay } from '../../../../../node_modules/rxjs/operators';
import { Pension } from '../../../store/user.actions';
import { Subject, Observable } from '../../../../../node_modules/rxjs';
import { PensionProductExtensionViewComponent } from '../components/pension-product-extension-view/pension-product-extension-view.component';

@Injectable({
  providedIn: 'root'
})
export class PensionService {
  private baseUrl;
  pensionData;
  data;
  pensionProduct= new Subject();
  pensionFirstTime = new Subject();
  pensionProduct$ =this.pensionProduct.asObservable().pipe(shareReplay(1));
  pensionFirstTime$ =this.pensionFirstTime.asObservable().pipe(shareReplay(1));
  product;
  productDetails;
  beneficiaries;
  firstTimeDigitalProccess = [];
  categoryType;
  public singleProduct;

  public showSpinner: boolean = false;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.baseUrl = environment.endPoint;
    this.store.select(selectPension).subscribe(data =>
      { 
      this.data = data;
      });
  }

  getProducts() {
    this.store.dispatch(new Fetching());
    return this.http.get(`${this.baseUrl}/products/pension`).pipe(map((data: Response) => {
      let d = {};
      if(data.Code === 0) {
        d = data.Data;
      }
      return d;
    }));
  }

  getProductByID(id) {
    this.store.dispatch(new Fetching());
    return this.http.get(`${this.baseUrl}/products/pension/${id}`).pipe(map((data: Response) => {
      let d = {};
      if(data.Code === 0) {
        d = data.Data;
      }
      return d;
    }));
  }
  
  getProductRecommendationsByID(id) {
    this.store.dispatch(new Fetching());
    return this.http.get(`${this.baseUrl}/products/recommendations/${id}`).pipe(map((data: Response) => {
      let d = {};
      if(data.Code === 0) {
        d = data.Data;
      }
      return d;
    }));
  }

}

