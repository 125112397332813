import { Question } from './../../../models/global.models';
import { QuestionnaireService } from '../../../questionnaire/questionnaire.service';
import { element } from 'protractor';
import { style } from '@angular/animations';
import { Component, OnInit, Input, ViewChild, ElementRef, HostBinding, Directive, HostListener } from '@angular/core';

@Component({
  selector: 'robin-question',
  templateUrl: './robin-question.component.html',
  styleUrls: ['./robin-question.component.scss']
})
export class RobinQuestionComponent implements OnInit {
  @Input() type: string;
  @ViewChild('ddd') ddd: ElementRef;
  @Input() question: string;
  @Input() steps: Number;
  @Input() answers: string[];
  @Input() placeholder: string;
  _subQuestion: Question = null;
  @Input() set subQuestion(sub) {
    this._subQuestion = sub;
  }
  iterate = [''];
  myflag = true;
  subHeight = 0;
  subOpacity = 0;

  private show = false;
  constructor(private questionnaireService: QuestionnaireService) {
    this.questionnaireService.answersToIterate$.subscribe(data => {
      for (let i = 0; i < Number(data) - 1; i++)
        this.iterate.push('');
      // console.log('dasdasadsd',data,this.iterate);

    });
  }

  ngOnInit() {
  }

  changeVal(val) {
    // console.log('val', this._subQuestion)
    this.show = true;
    this.questionnaireService.answered.next({ value: val, subQuestion: this._subQuestion });
    if (this._subQuestion) {
      if (this._subQuestion.id === 4) {
        console.log('ok in!', this._subQuestion)
       
        setTimeout(() => {
          this.subOpacity = 1;
          this.subHeight = 6;
          console.log(this.subHeight)
        }, 1000);

        // if (this.myflag) {
        //   console.log('this.subHeight', this.subHeight)
        //   setTimeout(() => {
        //     this.subOpacity = 1;
        //     this.subHeight = 6;

        //     this.myflag = false;
        //   }, 1000);
          
        // }

      }
    }

  }

  animateStuff() {
    console.log('ok ok ok ??');
    //(document.querySelector('.dropdownQuestion') as HTMLElement).style.height = '6rem';
    // document.querySelector('.dropdownQuestion').elem
    this.subHeight = 6;
    console.log('this.subHeight', this.subHeight)
    setTimeout(() => {
      this.subOpacity = 1;
      // console.log('this.subHeight', this.subHeight)
    }, 500);

  }

}
