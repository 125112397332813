import { Injectable } from '@angular/core';
import { CordovaService } from './cordova.service';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private cordovaService: CordovaService) { }

  coupon = '';
  public source;
  public link;

  initBranch() {
    try {
      this.cordovaService.deviceReady$.subscribe(() => {
        console.log('branch');
        const Branch = window["Branch"];
        console.log('Branch=', Branch)
        Branch.initSession().then(data => {
          console.log('aaa',data)
          if (data["+clicked_branch_link"]) {
            // read deep link data on click
            console.log('data', data);
            let arr = data["~referring_link"].split("/");
            this.coupon = arr[arr.length - 1];
            console.log('coupon', this.coupon);
          } else {
            this.coupon = 'aaaa';

            console.log('not from branch');
          }
        });
        console.log('coupon2',this.coupon);

      });
    }
    catch (err) {
      console.log('err=>',err.message);
    }
  }

}
