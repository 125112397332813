export const uiStrings = {
    homescreen:{
        button:"my click",
        button2: "כניסה",
        secondarybutton1:"כניסה עם טביעת אצבע",
        secondarybutton2:"כניסה עם faceid",
        entry:"כניסה לחשבון האישי",
        forgotpassword:"שכחתי סיסמה",
        bottomtitle: "זיהוי באמצעות טביעת אצבע",
        bottomsubtiitle: "הנח את האצבע על חיישן ההזדהות",
        bottombutton: "כניסה עם סיסמה",
        or: "or",
    }, 
    homescreeneerror: {
        title:'אופס...',
        text: "משהו השתבש בדרך ולא הצלחתי להשלים את תהליך איסוף הנתונים",
        bottomtext: "לפתרון ניתן לפנות אלי לתמיכה טכנית באחת מהפלטפורמות הבאות:",
    },
    newrobin:{
        title: "התחדשתי!",
        subtitle: "נתנו לי עיצוב חדש דנדש, חוזקתי בטכנולוגיה ופיצ'רים מתקדמים!",
        button: "בוא נתחיל לחסוך",
    }
}