import { CordovaService } from './../../core/services/cordova.service';
import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appLastInput]'
})
export class LastInputDirective implements AfterViewInit{

  constructor(private el?:ElementRef, private cordovaService?:CordovaService) { }
ngAfterViewInit(){
  if(this.cordovaService.platform === 'iOS'){

  }
}
}
