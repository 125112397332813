import { CacheService } from './../../services/cache.service';
import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MbscDatetimeOptions } from 'src/lib/mobiscroll/js/mobiscroll';
import { CordovaService } from 'src/app/core/services/cordova.service';
declare const window;


@Component({
  selector: 'add-new-beneficiary',
  templateUrl: './add-new-beneficiary.component.html',
  styleUrls: ['./add-new-beneficiary.component.scss']
})
export class AddNewBeneficiaryComponent implements OnInit {
  singleBeneficiary: FormGroup;
  _saved = false
  @Output() saved = new EventEmitter()


  constructor(private fb: FormBuilder, private router: Router, private cacheService: CacheService, private cordovaService: CordovaService) {
    this.singleBeneficiary = this.fb.group({
      FirstName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(/^[\sa-z\u0590-\u05fe]+$/i)]],
      LastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(/^[\sa-z\u0590-\u05fe]+$/i)]],
      Id: ['', []],
      BirthDate: ['', []],
    })
   }
   @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.cordovaService.skipToNextInput()
    }
  }
   @ViewChild('exp') exp: ElementRef;
   mobiScrollClick;
 
   options: MbscDatetimeOptions = {
     theme: 'robin-v2',
     lang: 'he',
     defaultValue: new Date(1980, 1, 1),

     max: Date.now(),
     //min: Date.now(),
     dateWheels: 'yyyy mm dd',
     dateFormat: 'dd/mm/y'
   };


  ngOnInit() {
  }
  
  saveDetails() {
    if (this.singleBeneficiary.status === "VALID") {
      this.saved.emit(this.singleBeneficiary.value)
      let newBeneficiary = this.singleBeneficiary.value
      newBeneficiary.Selected = true
      this.cacheService.cache[2].push(newBeneficiary)
      this.router.navigate(['/risk/addbeneficiaries'])
      this._saved = true;
      setTimeout(() => {
        this._saved = false
      }, 100)
    }
    else {
      //TODO: error handling 
    }
  }
  exitToPrev(){
    this.router.navigate(['/risk/addbeneficiaries'])
  }

  closeKeyboard(e) {
    // this.cordovaService.closeKeyBoard();
  }

  closeExp(event){
    // close
    document.body.classList.remove('disableOverflow');
    this.mobiScrollClick = false;
    document.querySelectorAll('.birth')[0].classList.add('set-exp-date');
  }

  hideKeyboard(){
    if(window.Keyboard && this.cordovaService.platform === 'Android')window.Keyboard.hide(true);
  }
}
