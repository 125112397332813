import { FetchService } from './../../services/fetch.service';
import { ApplicationRated } from './../../../redux/rate_the_app/rate.reducer';
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NavManagerService } from 'src/app/shared/components/robin-navbar/robin-navbar.service';
import { AppState } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';
import { fadeInRight, fadeIn, bounceIn } from 'ng-animate';
import { trigger, transition, useAnimation, animate } from '@angular/animations';
@Component({
  selector: 'publish-an-idea',
  templateUrl: './publish-an-idea.component.html',
  styleUrls: ['./publish-an-idea.component.scss'],
  animations: [
    trigger('fadeInRight1', [
      transition(':enter', [
        useAnimation(fadeInRight,
          {
            params: {
              timing: 0.3,
              fromOpacity: 0,
              toOpacity: 1,
              a: '10px',
              b: '0px',
            }
          }),
      ])]),
    trigger('fadeInRight2', [
      transition(':enter', [
        useAnimation(fadeInRight, {
          params: {
            timing: 0.6,
            fromOpacity: 0,
             toOpacity: 1,
            a: '10px',
            b: '0px',
          }
        }),
      ]),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        useAnimation(bounceIn, {
          params: {
            timing: 1,
          }
        }),
      ]),
    ])
  ]
})
export class PublishAnIdeaComponent implements OnInit {
  sent = false;
  cancel = false;
  anotherOne = false;
  idOfSentMessage;
  @ViewChild('text') text: ElementRef;
  receivedComment = [];
  active = false;

  constructor(private cdr: ChangeDetectorRef,private store: Store<AppState>, private navManager: NavManagerService, private fetchService: FetchService) { }

  ngOnInit() {
    this.fetchService.fetchComments().subscribe((data: any) => {
      console.log('ngOnInit ', data);
      this.receivedComment = data.Data;
    })
  }

  sumbit() {
    let comment = this.text.nativeElement.value;
    if(comment){
    this.fetchService.sendAComment({ Text: comment }).subscribe((data: any) => {
      this.idOfSentMessage = data.Data;
      this.sent = true;
      this.store.dispatch(new ApplicationRated({}));
    });
  }
    //TODO: check and filter the text inserted and navigate accordingly
    // this.navManager.prev();
  }
  cancelSumbission() {
    this.fetchService.deleteAComment(this.idOfSentMessage || 0).subscribe(data => {
      this.sent = false;
      this.cancel = true;
      this.idOfSentMessage = null;
    });
  }
  anotherSumbission() {
    this.sent = false;
  }
  
  out(e) {
    if (!e) {
      this.active = false;
    }
    else this.active = true;
    this.cdr.detectChanges();
  }
}
