import { environment } from './../../../../environments/environment';
import { OnboardingService } from './../../../onboarding/services/onboarding.service';
import { NavManagerService } from './../../../shared/components/robin-navbar/robin-navbar.service';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { uiStrings } from '../../assets/heb.ui_strings';
import { Location } from '@angular/common';
import { OtpService } from 'src/app/onboarding/services/otp/otp.service';
import { AppState } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';
import { Login, LoginFailed } from 'src/app/store/user.actions';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorModalService } from 'src/app/shared/components/modals/error-modal/modal.service';
declare const window;
@Component({
  selector: 'join-finger-id',
  templateUrl: './join-finger-id.component.html',
  styleUrls: ['./join-finger-id.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ]),
    trigger('Fading', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('800ms ease-out')),
      transition(':leave', animate('800ms ease-in')),
    ])
  ]
})
export class JoinFingerIdComponent implements OnInit {
  readMore = false;
  login = false;
  public uiStrings;
  password = null;
  error;

  constructor(private errorModalService: ErrorModalService,private zone: NgZone, private router: Router, private cordovaService: CordovaService, private store: Store<AppState>, private onboardingService: OnboardingService, private otpService: OtpService) {
    this.uiStrings = uiStrings.joinfingerid;
  }

  ngOnInit() {
  }
  continiue() {
    this.login = true;
  }

  modalClosed() {
    this.login = false;
  }
  passwordCaptured(e) {
    this.password = e;
  }

  nextPhase() {
    let successCB = (token) => {
      console.log('within successCB');
      this.store.dispatch(new Login({ token }));
      this.zone.run(() => {
        localStorage.setItem('loginMethod', 'touch');
        this.router.navigate(['/authentication/authsuccessfullycompleted', { finger: true }]);
      });
    }

    if(this.password){
    this.otpService.createToken(this.password).subscribe(
      (token) => {
        if (token) {
          this.store.dispatch(new Login({ token }));
          if (!environment.production) {
            this.zone.run(() => {
              localStorage.setItem('loginMethod', 'touch');
              this.router.navigate(['/authentication/authsuccessfullycompleted', { finger: true }]);
            });
          }
          else {
            this.otpService.registerFingerPrint({ uuid: window.device && window.device.uuid || -1 }).subscribe((data: any) => {
              this.store.dispatch(new LoginFailed({}));
              if (data.Code === 0) {
                  this.cordovaService.loginWithFingerid((err, success) => {
                    if (err) {
                      if(err === 'errorModal'){
                        this.zone.run(() => {
                          this.login = false;
                          this.errorModalService.setNewError({
                            title: 'שיטת הזדהות זו נחסמה',
                            description: 'באפשרותך להזדהות באמצעות סיסמה אישית וכך לשחרר את החסימה',
                            type: 'error',
                            buttonText: 'אישור',
                          }).open();
                        });
                      }
                    }
                    else{
                      successCB(token);
                    }
                  });
                // if(this.cordovaService.platform === "iOS"){
                //   this.cordovaService.loginWithFingerid((err, success) => {
                //     if(err){
                //         this.zone.run(() => this.login = false);
                //     }
                //     else{
                //       successCB(token);
                //     }
                //   });
                // }
                // else{
                //   this.cordovaService.androindFingerPrint({}, (err, res) => {
                //     if (err) {
                //       console.log('error => ', err);
                //       if (err === 'fail') {
                //         this.zone.run(() => this.login = false);
                //       }
                //     }
                //     else {
                //      successCB(token);
                //     }
                //   });
                // }
              }
              else{

              }
            });
          }
        }
        else {
          this.error = 'סיסמה שגוייה';
          setTimeout(() => this.error = '', 3000);
        }
      },
      err => { });
    }
    else{
      this.error = 'סיסמה שגוייה';
      setTimeout(() => this.error = '', 3000);
    }
  }
}
