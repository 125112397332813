import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'robin-bubble-step',
  templateUrl: './robin-bubble-step.component.html',
  styleUrls: ['./robin-bubble-step.component.scss']
})
export class RobinBubbleStepComponent implements OnInit {



  @Input() options;

  // options = {
  //   currentStep: 0,
  //   bubbleList : [
  //     {
  //       title: 'נקלטה בקשה אצל היצרן',
  //       date: '01.08.2018',
  //       image_active: '../../../../assets/images/shared/bubble-step/send.active.png', 
  //       image_inactive: '../../../../assets/images/shared/bubble-step/send.active.png', 
  //     },
  //     {
  //       title: 'נקלטה בקשה אצל היצרן',
  //       date: '01.08.2018',
  //       image_active: '../../../../assets/images/shared/bubble-step/open.active.png', 
  //       image_inactive: '../../../../assets/images/shared/bubble-step/open.unactive.png', 
  //     },
  //     {
  //       title: 'נקלטה בקשה אצל היצרן',
  //       date: '01.08.2018',
  //       image_active: '../../../../assets/images/shared/bubble-step/open.active.png', 
  //       image_inactive: '../../../../assets/images/shared/bubble-step/open.unactive.png', 
  //     }
  //   ]
  // }
  
  

  constructor() { }



  ngOnInit() {
  
  }

}
