import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'robin-incrementor',
  templateUrl: './robin-incrementor.component.html',
  styleUrls: ['./robin-incrementor.component.scss']
})
export class RobinIncrementorComponent {

  constructor() { }

  @Input()  isPrecentage;
  @Input()  isLockable;
  @Input()  currentNumber;
  @Output() change = new EventEmitter();
  isLocked = false;
  _isClickedPlus = false;
  _isClickedMinus = false;

  lock(){
    this.isLocked = !this.isLocked;
  }

  clicked(valueChange) {

    this._circleButtonClicked(valueChange);
    
    if(this._canIncrement(valueChange) && !this.isLocked) 
      this.change.emit(parseInt(this.currentNumber) + valueChange);
    
  }

  private _canIncrement(valueChange){
    return this.isPrecentage ? 
        this._canIncrementPrecentage(valueChange) : true;
  }

  private _circleButtonClicked(valueChange){
    if(valueChange === 1){
      this._isClickedPlus = true;
      setTimeout(() => {
        this._isClickedPlus = false;
      }, 100);
    } else{
      this._isClickedMinus = true;
      setTimeout(() => {
        this._isClickedMinus = false;
      }, 100);
    }
    
  }

  private _canIncrementPrecentage(valueChange){
    let nextValue = valueChange + this.currentNumber;
    return nextValue < 101 && nextValue > -1;
  }
}
