import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'robin-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({ opacity: 0 })))
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PickerComponent),
      multi: true
    }
  ]
})
export class PickerComponent implements OnInit, ControlValueAccessor {
  active = false;
  _placeholder = 'הזן';
  _postfix = ''
  options = []
  _range = '';
  _gap = 1;
  _selectedElement = null;
  _error
  _value
  _touched
  _errorMessage;
  @Input() set error(er) { this._error = er }
  @Input() set errorMessage(er) { this._errorMessage = er }
  @Input() set value(er) { this._value = er }
  @Input() set touched(er) { this._touched = er }
  @Output() selected = new EventEmitter()
  @Input() set postfix(pf) {
    this._postfix = pf;
  }
  @Input() set gap(pf) {
    this._gap = pf;
  }
  @Input() set range(pf) {
    if (pf.indexOf('-') > -1) {
      this._range = pf;
      this.prepareOptions()
    }
    else {
      this.options = pf;
    }
  }
  @Input() set placeholder(ph) {
    this._placeholder = ph;
  }
  constructor() {
    if (this._range) this.prepareOptions()
  }

  ngOnInit() {
  }
  prepareOptions() {
    let sepIndex = this._range.indexOf('-')
    let from: any = ''
    let to: any = ''
    for (let i = 0; i < sepIndex; i++) {
      from += this._range[i]
    }
    for (let i = sepIndex + 1; i < this._range.length; i++) {
      to += this._range[i]
    }

    to = Number(to)
    from = Number(from)
    for (let i = from; i <= to; i += this._gap)
      this.options.push(`${String(i)} ${this._postfix}`);
      this.options = ['---','---', ...this.options, '---','---']
  }
  focus() {
    this.active = true;
  }
  outside(e) {
    if (!e) {
      this.active = false;
    }
  }
  scroll(e) {
    let content: any = document.querySelector('div.options-container div.content')
    const OFFSET_PER_SCROLL = 3 * 16
    let scrollHeight = content.scrollHeight
    let currentPos = content.scrollTop
    let closestOffset = Math.round(currentPos / OFFSET_PER_SCROLL)
    if (closestOffset !== currentPos / OFFSET_PER_SCROLL) {
      content.scrollTo(0, closestOffset * OFFSET_PER_SCROLL);
    }
    this.calculateSelectedElement()
  }
  calculateSelectedElement() {
    let content: any = document.querySelector('div.options-container div.content')
    const OFFSET_PER_SCROLL = 3 * 16
    let currentPos = content.scrollTop
    this._selectedElement = (currentPos / OFFSET_PER_SCROLL) + 2
    return this._selectedElement
  }
  done() {
    this.active = false;
    let answerSelected = this.options[this.calculateSelectedElement()]
    this.writeValue(answerSelected)
    this._placeholder = answerSelected
    this.selected.emit(answerSelected)
  }

  /*
      Form interface
  */
  writeValue(obj: any): void {
    if (!!(obj)) {
      this.valueChanged(obj)
      this.valueTouched(obj)
    }
  }
  registerOnChange(fn: any): void {
    this.valueChanged = fn
  }
  registerOnTouched(fn: any): void {
    this.valueTouched = fn
  }

  valueChanged(val) { }
  valueTouched(val) { }
}
