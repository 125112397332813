import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { PleaseWaitComponent } from './components/please-wait/please-wait.component';
import { IntroComponent } from './components/intro/intro.component';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LetsKnowComponent } from './components/lets-know/lets-know.component';
import { OtpComponent } from './components/otp/otp.component';
import { TakanonComponent } from './components/takanon/takanon.component';
import { OnboardingGuard } from './services/onboarding.guard';
import { IdCardMainComponent } from './components/identification/id-card/id-card-main/id-card-main.component';
import { IdCardPreviewComponent } from './components/identification/id-card/id-card-preview/id-card-preview.component';
import { SelfieComponent } from './components/identification/selfie/selfie.component';
import { CheckInfoComponent } from './components/check-info/check-info.component';
import { ReminderComponent } from './components/reminder/reminder.component';
import { CollectSuccessComponent } from './components/collect-success/collect-success.component';
import { LastStepComponent } from './components/last-step/last-step.component';
import { SelfiePreviewComponent } from './components/identification/selfie/id-card-preview/selfie-preview.component';
import { SupportComponent } from './components/support/support.component';
import { FirstPageComponent } from './components/first-page/first-page.component';





const routes: Routes = [
 
  {
    path: 'support', component: SupportComponent,
    data: {
      state: 'support'
    }
  },
  {
    path: 'credit',
    // loadChildren: () => AuthenticationModule,
    component: CreditCardComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
    data: {
      state: 'login'
    }
  },
  {
    path: 'letsknow', component: LetsKnowComponent,
    data: {
      state: 'letsknow'
    }
  },
  {
    path: 'firstpage', component: FirstPageComponent,
    data: {
      state: 'firstpage'
    }
  },
  {
    path: 'otp',  component: OtpComponent,
    data: {
      state: 'otp'
    }
    // canActivate: [OnboardingGuard]
  },
  {
    path: 'takanon',  component: TakanonComponent,
    data: {
      state: 'takanon'
    }
  },
  {
    path: 'idcardmain',  component: IdCardMainComponent,
    data: {
      state: 'idcardmain'
    }
  },
  {
    path: 'idcardpreview',  component: IdCardPreviewComponent,
    data: {
      state: 'idcardpreview'
    }
  },
  {
    path: 'selfiepreview', component: SelfiePreviewComponent,
    data: {
      state: 'selfiepreview'
    }
  },
  {
    path: 'collectsuccess',  component: CollectSuccessComponent,
    data: {
      state: 'collectsuccess'
    }
  },
  {
    path: 'selfie', component: SelfieComponent,
    data: {
      state: 'selfie'
    }
  },
  {
    path: 'laststep',  component: LastStepComponent,
    data: {
      state: 'laststep'
    }
  },
  {
    path: 'checkinfo',  component: CheckInfoComponent,
    data: {
      state: 'checkinfo'
    }
  },
  {
    path: 'howitworks',  component: HowItWorksComponent,
    data: {
      state: 'howitworks'
    }
  },
  {
    path: 'pleasewait',  component: PleaseWaitComponent,
    data: {
      state: 'pleasewait'
    }
  },
  {
    path: 'reminder',  component: ReminderComponent,
    data: {
      state: 'reminder'
    }
  },
  {
    path: 'idcard',  component: IdCardMainComponent,
    data: {
      state: 'idcard'
    }
  },
  {
    path: 'intro',  component: IntroComponent, data: { state: 'intro' }
  },
  // {
  //   path: '**',
  //   redirectTo: '/intro'
  // },
  

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [OnboardingGuard]
})
export class OnBoardingRoutingModule { }