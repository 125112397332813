import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageCaptureService {
  // public imageCapture = new BehaviorSubject<any>(1);
  public selfieImage=new BehaviorSubject<any>(1);
  public idImage=new BehaviorSubject<any>(1);

  public idImage3=new BehaviorSubject<any>(1);
  public idImage2=new BehaviorSubject<any>(1);
  public idImage1=new BehaviorSubject<any>(1);

  public independentImage=new BehaviorSubject<any>(1);
  public onetime = false;

  // public test=new BehaviorSubject<any>('1');


  constructor() { 
    // console.log('cons');
    // this.test.asObservable().toPromise().then(data => console.log(data));
    // this.test.subscribe(data => {if(data){
    //   console.log(data);
    //   this.test.subscribe(data => {if(data){console.log(data)}});
    // }});
    // this.test.next(2);
  }
}
