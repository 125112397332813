import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'robin-cover-icon',
  templateUrl: './robin-cover-icon.component.html',
  styleUrls: ['./robin-cover-icon.component.scss']
})
export class RobinCoverIconComponent implements OnInit {

  @Input() text;
  @Input() mistakeText;
  @Input() duplicate;
  @Input() image;
  @Input() fadeImage;
  @Input() smallicon;

  

  constructor() { 
  }

  ngOnInit() {
  }

}
