import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {uiStrings} from '../../assets/heb.ui_strings';
import { RemindService } from '../../services/reminder/remind.service';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss']
})
export class ReminderComponent implements OnInit {
  uiStrings;
  reminderForm: FormGroup;
  done = false;
  isError = false;
  obj = [
    'יותר מאוחר היום',
    'מחר בבוקר',
    'מחר בצהריים',
    'מחר בערב',
  ]


  constructor(private reminderService: RemindService,private fb: FormBuilder) { 
    this.uiStrings = uiStrings.reminder;
    this.createForm();
  }

  ngOnInit() {
  }

  doneComplete(event){
    // this.done = false;
    
    console.log('event', this.reminderForm)
  }
  
  save(){
    if(this.reminderForm.controls["radio"].value !== ''){
      this.isError = false;
      // this.reminderService.remindMe({data: this.reminderForm.value});
    }else{
      this.isError = true;
    }
  }
  
  createForm() {
    this.reminderForm = this.fb.group({
      radio: ['', []]
      // firstName: ['' , [Validators.required, Validators.minLength(5)] ],
      // lastName: ['' , Validators.required ],
      // email: ['' , [Validators.required , Validators.email]],
      // phone: ['' , Validators.required ],
      // checkbox: [false, []],
      // updates: [false, []],
      // policy: [false, []]
    });
  }
}
