import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnnaireComponent } from './components/questionnnaire/questionnnaire.component';
import { IndicationComponent } from './components/indication/indication.component';
import { QuestioncardComponent } from './components/questioncard/questioncard.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [QuestionnnaireComponent, IndicationComponent, QuestioncardComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [QuestionnnaireComponent]
})
export class QuestionnaireModule { }
