import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'robin-v',
  templateUrl: './robin-v.component.html',
  styleUrls: ['./robin-v.component.scss']
})
export class RobinVComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
