import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SwipeItem } from './swipe-item';
import { RobinMorseModel } from '../indicators/robin-morse/robin-morse.model';

@Component({
  selector: 'robin-swipe',
  templateUrl: './robin-swipe.component.html',
  styleUrls: ['./robin-swipe.component.scss']
})
export class RobinSwipeComponent implements OnInit {

  @Input() isMorse;
  @Input() swipeItem: SwipeItem;
  @Input() isMorseDown = true;
  _currentItem;
  @Input() set currentItem(id){
    this._currentItem = id;
  };
  @Output() fireCurrent = new EventEmitter();
  @Output() transitionPerformed = new EventEmitter();
  @Input()  set xAxis(num){
    this._left = num;
  }
  _wrapperWidth;
  _virtualArr = [];
  _left = 0;
  morse: RobinMorseModel;
  obj = { count: 0, current: 0 }
  constructor() { }

  ngOnInit() {
    let curr = this._currentItem ? this._currentItem : 0; 
    this.obj = { count: this.swipeItem._itemCount, current: curr } 
    console.log('_currentItem', this._currentItem);

  }

  swipe(value) {
    console.log('_currentItem', this._currentItem);
    var width = this.swipeItem._singleItemWidth + this.swipeItem._marginItem;
    if (value === 'left') {
      if (this.obj.current > 0) {
        this._left -= width;
        this.obj.current -= 1;
      }
    }
    else {
      if (this.setLimit()) {
        this._left += width;
        this.obj.current += 1;
      }
    }
    this.transitionPerformed.emit(this._left);
    this.fireCurrent.emit(this.obj.current);
  }

  setLimit() {
    return this.obj.current < this.obj.count - 1;
  }

  setVirtualArray() {
    for (let i = 0; i < this.swipeItem._itemCount; i++) {
      this._virtualArr.push(this.setValue(i));
    }
  }

  setValue(i) {
    return i === 0 ? true : false;
  }

  setWrapperWidth() {
    return (this.swipeItem._itemCount * (this.swipeItem._singleItemWidth + this.swipeItem._marginItem)) + this.swipeItem._unit;
  }

}
