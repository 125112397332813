import { State } from './../redux/insurance/insurance.reducer';
import * as register from '../redux/register/register.reducers';
import { createFeatureSelector } from '@ngrx/store';
import * as login from '../redux/login/login.reducer';
import * as pension from '../redux/pension/pension.reducer';
import * as insurance from '../redux/insurance/insurance.reducer';
import * as backend from '../store/backend.actions';
import * as anonymous from '../redux/anonymous/anonymous.reducer';
import * as androidlock from '../redux/android_fingerprint/android_fingerprint.reducer';
import * as applicationRate from '../redux/rate_the_app/rate.reducer';
import * as deeplink from '../redux/deeplink/deeplink.reducer';
import * as authenticated from '../redux/authenticated/autenticated.reducer';

import { AuthenticationModule } from '../authentication/authentication.module';

export interface AppState {
    registerState: register.State;
    token: login.State;
    pension: pension.State;
    insurance: insurance.State;
    backend: backend.State;
    anonymous: anonymous.State
    android_fingerprint_lock: androidlock.State
    rate: applicationRate.State
    deeplink: deeplink.State
    authenticated: authenticated.State
}

export const reducers = {
    registerState: register.reducer,
    token: login.reducer,
    pension: pension.reducer,
    insurance: insurance.reducer,
    backend: backend.reducer,
    anonymous: anonymous.reducer,
    android_fingerprint_lock: androidlock.reducer,
    rate: applicationRate.reducer,
    deeplink: deeplink.reducer,
    authenticated: authenticated.reducer
};

export const selectRegisterState = createFeatureSelector<AppState>('registerState');
export const selectToken = createFeatureSelector<AppState>('token');
export const selectPension = createFeatureSelector<AppState>('pension');
export const selectInsurance = createFeatureSelector<AppState>('insurance');
export const selectBackend = createFeatureSelector<AppState>('backend');
export const selectAnonymous = createFeatureSelector<AppState>('anonymous');
export const selectAndroidLock = createFeatureSelector<AppState>('android_fingerprint_lock');
export const selectApplicationRated = createFeatureSelector<AppState>('rate');
export const selectDeeplink = createFeatureSelector<AppState>('deeplink');
export const selectAuthenticated = createFeatureSelector<AppState>('authenticated');

