import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private baseUrl;
  configModel;

  constructor(private http: HttpClient,) { 
    this.baseUrl = environment.endPoint;
  }

  getConfing() {
    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // return this.http.post(`${this.baseUrl}/opportunities/pension`, null);
    return this.http.get('https://rh-dev-app.azurewebsites.net/config.json', {headers: header});
    // return this.http.get('assets/config.json');
    // return this.http.get(`${this.baseUrl}/config.json`, {headers: header});



  }
}
