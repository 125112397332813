import { NavManagerService } from '../../../../shared/components/robin-navbar/robin-navbar.service';
import { ImageCaptureService } from '../../../services/image-capture.service';
import { LoggerService } from '../../../../core/services/logger.service';
import { CordovaService } from '../../../../core/services/cordova.service';
import { Router } from '@angular/router';
import { Component, OnInit, NgZone } from '@angular/core';
import { uiStrings } from '../../../assets/heb.ui_strings';
import { NgAnalyzedFile } from '@angular/compiler';
import { AnalyticsService } from '../../../../analytics/analytics.service';
import { ErrorModalService } from '../../../../shared/components/modals/error-modal/modal.service';
import { ImageCompressService, IImage, ResizeOptions } from 'ng2-image-compress';

@Component({
  selector: 'app-selfie',
  templateUrl: './selfie.component.html',
  styleUrls: ['./selfie.component.scss']
})
export class SelfieComponent implements OnInit {
  public uiStrings;
  isProd = true;
  constructor(private zone: NgZone,
    private navService: NavManagerService,
    private router: Router,
    public cordovaService: CordovaService,
    private loggerService: LoggerService,
    private captureService: ImageCaptureService,
    private navbarService: NavManagerService,
    private analyticService: AnalyticsService,
    private errorModalService: ErrorModalService,
    private imgCompressService: ImageCompressService) {
    this.uiStrings = uiStrings.selfie;
  }
  errorMessage = false;
  ngOnInit() {
    // this.errorModalService.setNewError({
    //   title: 'אופס...',
    //   description: 'נראה שמשהו השתבש בדרך',
    //   type: 'error',
    //   buttonText: 'נסה שנית',
    // }).open();
    this.analyticService.trackByMixpanel("User Registration - FDC - Selife");
    this.analyticService.trackByAppFlyer("User Registration - FDC - Selife", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }


  openSelfieCamera() {
    if (this.cordovaService.isMobile) {
      this.cordovaService.captureImage({ frontCamera: true }, (err, data) => err ? this.onErr(err) : this.onData(data))
    } else {
      // $("#file-input").click();
      let el: any = document.querySelectorAll("#file-input2")[1];
      var file = document.getElementById("file-input2")

      file.addEventListener('change', (e: any) => {
        console.log('e', e);
        this.compressImage(e.target.files);
        // this.doSomethingWithFiles(e.target.files);
      });
    }
  }

  compressImage(fileInput) {
    let fileList: FileList;
 
    let images: Array<IImage> = [];
    
    let option:ResizeOptions = { Resize_Max_Height : 600 , Resize_Max_Width : 600, Resize_Quality:30, Resize_Type : 'image/jpg'  };

    ImageCompressService.filesToCompressedImageSourceEx(fileInput,option).then(observableImages => {
      observableImages.subscribe((image) => {
        images.push(image);
        if (images !== null) {
          // let src = URL.createObjectURL(file);
          this.captureService.selfieImage.next(images[0].compressedImage.imageDataUrl);
          this.zone.run(() => {
            this.redirectTo();
          });
        }
      }, (error) => {
        console.log("Error while converting");
      }, () => {
          // this.processedImages = images;      
          // this.showTitle = true;          
      });
    });

 
    // or you can pass File[] 
    // let files = Array.from(fileInput);
 
    // ImageCompressService.filesArrayToCompressedImageSource(files).then(observableImages => {
    //   observableImages.subscribe((image) => {
    //     images.push(image);
    //   }, (error) => {
    //     console.log("Error while converting");
    //   }, () => {
    //             this.processedImages = images;      
    //             this.showTitle = true;          
    //   });
    // });
  }


  doSomethingWithFiles(fileList) {
    let file = null;
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/^image\//)) {
        file = fileList[i];
        break;
      }
    }

    if (file !== null) {
      // let src = URL.createObjectURL(file);
      this.getBase64(file).then(data =>{
        this.captureService.selfieImage.next(data);
        this.zone.run(() => {
          this.redirectTo();
        });
      });
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onData(data) {
    // this.captureService.imageCapture.next(data);
    // this.captureService.selfieImage = data;
    this.captureService.selfieImage.next(data);
    this.zone.run(() => {
      this.redirectTo();
    });
  }

  onErr(err) {
    this.loggerService.error(err);
    //this.errorMessage = true;
    // this.errorModalService.setNewError({
    //   title: 'אופס...',
    //   description: 'נראה שמשהו השתבש בדרך',
    //   type: 'error',
    //   buttonText: 'נסה שנית',
    // }).open();
  }

  redirectTo() {
    this.navService.next();
    // this.router.navigate(['/onboarding', 'selfiepreview']);
    //this.navbarService.next();
  }

}
