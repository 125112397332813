import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'robin-boolean-question',
  templateUrl: './robin-boolean.component.html',
  styleUrls: ['./robin-boolean.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RobinBooleanComponent),
      multi: true
    }
  ]
})
export class RobinBooleanComponent implements OnInit {
  @Input() answers: string[];
  @Input() image: boolean;
  @Input() forceAnswer: any; // int for selcted answer, for example, 1, will select "true" for answer in [1] place.
  private yourAnswer = null;
  @Output() checked = new EventEmitter();
  answerClass = 'answer';
  answeringClass = 'answering';
  virtualArray = [];

  constructor() { }

  ngOnInit() {
    this.virtualArrayInit(this.forceAnswer);
  }

  choseBooleanAnswer(e, index){
    
    this.virtualArray.map((el, i) => {
      this.yourAnswer = el.text;
      el.selected = index === i ? this.selectedAnswer(el): false;
    })
  }

  selectedAnswer(el){
    this.yourAnswer = el.text;
    this.checked.emit(this.yourAnswer);
    this.onChange(this.yourAnswer);
    return true;
  }

  virtualArrayInit(forceAnswer){
    for (let i = 0; i < this.answers.length; i++) {
      var currentimage;
      if(this.image) {currentimage = this.image;}

      if(this.forceAnswer !== undefined && this.forceAnswer === i) {
        this.virtualArray.push({text: this.answers[forceAnswer], image: currentimage, selected: true})  
      } else{
        this.virtualArray.push({text: this.answers[i], image: currentimage, selected: false})  
      }
    }
    
  }

  writeValue(val: string): void {
    this.yourAnswer = val;
  }
  onChange = (rating) => { };
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void { }
  setDisabledState(isDisabled: boolean): void { }
}
