import { Injectable } from '@angular/core';
import { Validators } from './validators';


@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() {
    // let validator = new Validators();
  }

}
