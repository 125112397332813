import { HomepageService } from './../../../../homepage/homepage.service';
import { Component, OnInit } from '@angular/core';
import { CordovaService } from '../../../../core/services/cordova.service';
import { Store } from '../../../../../../node_modules/@ngrx/store';
import { AppState, selectRegisterState } from '../../../../store/app.states';
import { SupportService } from '../support.service';
import { ErrorModalService } from '../../modals/error-modal/modal.service';
import { Router } from '../../../../../../node_modules/@angular/router';
import { NavManagerService } from '../../robin-navbar/robin-navbar.service';
import { ModalService } from '../../robin-modal/modal.service';
import { FetchingSuccess } from '../../../../store/backend.actions';

@Component({
  selector: 'tech-support',
  templateUrl: './tech-support.component.html',
  styleUrls: ['./tech-support.component.scss']
})
export class TechSupportComponent implements OnInit {
  phoneNumber;
  description = '';
  error = false;

  constructor(
    private cordovaService: CordovaService,
    private store: Store<AppState>,
    private supportService: SupportService,
    private errorModalService: ErrorModalService,
    private homepageService: HomepageService,
    private router: Router,
    private navbarService: NavManagerService,
    private modalService: ModalService

  ) { }

  ngOnInit() {
  }

  // whatsAppRequest() {
  //   window.open('https://api.whatsapp.com/send?phone=+972544598589&text=', '_system', 'location=yes');
  // }

  openFacebook() {
    if (this.cordovaService.isMobile) {
      this.cordovaService.open('messenger');
    } else {
      window.open('https://www.messenger.com/t/1721348884812257', '_system', 'location=yes');
    }
  }

  openWhatsapp() {
    if (this.cordovaService.isMobile) {
      this.cordovaService.open('whatsapp');
    } else {
      window.open('https://wa.me/%E2%80%AA972544598589%E2%80%AC', '_system', 'location=yes');
    }
  }

  // Facebook() {
  //   window.open('https://m.me/RobinhoodproAPP');
  // }

  textAreaChange(event) {
    console.log(event.target.value);
    this.description = event.target.value;
  }

  goNext(user) {
    console.log('are we here?', user)
    // console.log('gggggg', this.navbarService.getNextOf(this.supportService.whereFrom))
    if (this.supportService.whereFrom === 'questionnaire')
      this.router.navigate(['/homepage']);
    else if (this.isGoNext(this.supportService.whereFrom)) {
      //this.router.navigate(['/questionnaire']);
      this.willSkipQuestionnaire(user);
      console.log('nexxxxt')
    } else {
      console.log('preeevvv')
      this.navbarService.prev();
    }
  }

  willSkipQuestionnaire(user){
    this.homepageService.anonymousStatus(user).subscribe((data:any)=>{
      if (data.Code === 0) {
        this.store.dispatch(new FetchingSuccess());
        data.Data.FilledQuistionnaire ? this.router.navigate(["/homepage"]) : this.router.navigate(["/questionnaire"]);
      } else {
        this.errorModalService.setNewError({
          title: 'אופס...',
          description: 'נראה שמשהו השתבש בדרך',
          type: 'error',
          buttonText: 'נסה שנית',
        }).open();
      }
     
    })
  }

  isGoNext(term) {
    var flag = false;
    console.log('termm', term);
    const arr = ['onboardingIdcardmain', 'onboardingIdcardpreview', 'onboardingSelfie', 'onboardingSelfiepreview', 'onboardingPleasewait', 'onboardingCheckinfo', 'onboardingLaststep', 'onboardingCredit', 'onboardingHowitworks'];
    arr.map((el, index) => {
      if (el === term) flag = true;
    });
    console.log('flag', flag);
    return flag;
  }

  sendInfo() {
    if (this.description !== '') {
      this.store.select(selectRegisterState).subscribe((data: any) => {
        if (data.user !== null) {
          var user = data.user;
          this.phoneNumber = data.user.phoneNumber;
          let info = { Description: this.description, PhoneNumber: this.phoneNumber };
          this.supportService.postContactUs(info).subscribe((data) => {
            if (data.Code === 0) {
              this.modalService.open('success');
              this.goNext(user);
            }
            else {
              this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך', 'נסה שנית');
            }

          }, err => {
             this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך', 'נסה שנית');
          });
        }
      });
    } else {
      this.error = true;
    }
  }

  openErrorMessage(title, desc, textButton) {
    this.errorModalService
      .setNewError({
        title: title,
        description: desc,
        type: 'error',
        buttonText: textButton,
      })
      .open();
  }
}
