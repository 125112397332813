export const barArr = [
    {
        name: 'present',
        color: 'red',
        icon: 'assets/images/shared/indicators/robin-state/heart_pin_red.png',
    },
    {
        name: 'recommend',
        color: 'green',
        icon: 'assets/images/shared/indicators/robin-state/heart_pin_green.png'
    }
]