import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'stepper-bar-item',
  templateUrl: './stepper-bar-item.component.html',
  styleUrls: ['./stepper-bar-item.component.scss']
})
export class StepperBarItemComponent implements OnInit {

  constructor() { }


  @Input() item;

  ngOnInit() {
  }

}
