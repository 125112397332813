export const stateArr = [
    {
        name: 'broken_heart',
        color: 'red',
        icon: 'assets/images/shared/indicators/robin-state/heart.png',
    },
    {
        name: 'success',
        color: 'green',
        icon: 'assets/images/shared/indicators/robin-state/heart_green.png'
    },
    {
        name: 'warning',
        color: 'orange',
        icon: 'assets/images/shared/indicators/robin-state/exclamation_mark.png'
    }
]