export class RobinMorseModel{
    
    private _obj;
    private _virtualArray = [];
    private _current;

    constructor(obj){
        this._obj = obj; 
        this._current = obj.current;       
        this.updateVirtuialArray(this._obj.count)
    }


    public getVirtualArray() {
        return this._virtualArray;
    }

    private getCurrentValue() {
        return this._current;
    }

    public setCurrentValue(value) {
        this._current = value;
        return this;
    } 

    private setValueVirtualArray(value){
        this._virtualArray.push(value);
        return this;
    }

    public clearArray(){
        this._virtualArray = [];
        return this;
    }

    public updateVirtuialArray(count){
        for (let i = 0; i < count; i++) {

            this.getCurrentValue() === i ?  
                this.setValueVirtualArray(true) : 
                this.setValueVirtualArray(false);
        }
        return this;
    }

}