import { LastStateService } from './../../../../core/services/last-state.service';
import { NavManagerService } from './../../../../shared/components/robin-navbar/robin-navbar.service';
import { Router } from '@angular/router';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BottomNavBar } from './bottom-navbar.model';
//import { BottomNavBar } from './bottom-navbar.model';
import * as navBar from './bottom-navbar.mock';
import { flash } from 'ng-animate';
import { AccountService } from '../../../account.service';



@Component({
  selector: 'bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss']
})
export class BottomNavbarComponent implements OnInit {

  nav;
  activePension = true;
  activeInsurance = false;
  activeCoin = false;
  coins;

  @Output() navBarClicked = new EventEmitter();

  constructor(private router: Router, private navbarService: NavManagerService, private accountService: AccountService, private lastStateService:LastStateService) {
    this.nav = new BottomNavBar(navBar.bottomNavBar, 0);
    console.log(this.navbarService.history[this.navbarService.history.length - 1].url);
    if (this.navbarService.history[this.navbarService.history.length - 1].url === "/account/insurance" || this.navbarService.history[this.navbarService.history.length - 1].url === "/account/insurance/myhealth" || this.navbarService.history[this.navbarService.history.length - 1].url === "/account/insurance/mylife") {
      this.activeInsurance = true;
      this.activePension = false;
      this.activeCoin = false;
    } else if (this.navbarService.history[this.navbarService.history.length - 1].url === "/account/pension") {
      this.activeInsurance = false;
      this.activePension = true;
      this.activeCoin = false;
    } else {
      this.activeInsurance = false;
      this.activePension = false;
      this.activeCoin = true;
    }
  }

  tabClicked(item, index) {
    this.nav.setActiveTab(index);
    console.log('item', item);
    if (item.name === 'pension') {
      this.activePension = true;
      this.activeInsurance = false;
      this.activeCoin = false;
    } else if (item.name === 'insurance') {
      this.activeInsurance = true;
      this.activePension = false;
      this.activeCoin = false;
    } else {
      this.activePension = false;
      this.activeInsurance = false;
      this.activeCoin = true;
    }

    this.navBarClicked.emit(item);
    this.lastStateService.handleBottomNav.next();
    this.navbarService.next(item.name);
    // this.router.navigate(['/account',item.name]);
  }

  ngOnInit() {
    this.accountService.coin$.subscribe(data => this.coins = data);
  }

  coinsClick() {
    this.activePension = false;
    this.activeInsurance = false;
    this.activeCoin = true;
    this.router.navigate(['/account', 'invitefriends']);
  }

}
