import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Router} from '@angular/router'
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})

export class CriticalillnessService {
  private baseUrl;
  generatedProcessId:any = {}
  currentTab = new BehaviorSubject(0)
  currentTab$ = this.currentTab.asObservable()
  ciSummary;
  whoToCover; // 1 - me, 2 - only children, 3 - me & children
  numOfChildren;
  amount;
  amountChildren;
  premium;
  premiumPer100k;
  premiumPer100kChild;
  premiumChildren;
  premiumSummary;
  digitalEventId;
  amountRecommend;
  ManufacturerCode;
  smoking;

  cacheCritical: any = {
    1: null, // hmo
    2: null, // height & weight
    3: null, // children
    4: null, // health questionnaire
    5: null, // electronic signature
    6: null, // payment
  }

  constructor(private router: Router,
              private http: HttpClient) { 
    this.baseUrl = environment.endPoint;
  }

  clearCache() {
    // (this.router.routerState as any).snapshot = {};
    this.cacheCritical = {} ;
    setTimeout(() => (this.router.routeReuseStrategy as any).storedRoutes = {}, 0);
  }

  setCache(controls) {
    let cache = {}
    let keys = Object.keys(controls)
    let values = keys.map(key => controls[key].value)
    keys.map((el, ind) => cache[el] = values[ind])
    return cache
  }

  setErrorInEmptyFields(form: FormGroup) {
    Object.keys(form.value).map(key => {
      if (!!!(form.value[key])) {
        form.controls[key].markAsTouched()
      }
    })
  }

  initDigitalEvent(subCategory) {
    console.log('subcategory ', subCategory)
    return this.http.post(`${this.baseUrl}/events/initdigitalevent`, { SubCategory: subCategory });
  }

  getCriticalIllness() {
    return this.http.get(`${this.baseUrl}/modules/criticalillness/summary`);
  }

  getCriticalIllnessRecommendations(salary,smoking) {
    return this.http.get(`${this.baseUrl}/modules/criticalillness/recommendation/${salary}/${smoking}`);
  }

  getCriticalIllnessTazrim(obj) {
    return this.http.post(`${this.baseUrl}/modules/criticalillness/recommendationpayments`,obj);
  }

  uploadFiles(fileArray, digitalEventId, subCategoryId, fileTypeId) {
    const formdata: FormData = new FormData();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let fileArrayTemp = this.convertBase64ArrayToFileArray(fileArray, fileTypeId, '.jpg');
    fileArrayTemp.forEach(el => {
      formdata.append('file', el);
    });
    let options = {
      headers: headers,
    }
    const req = new HttpRequest('POST', `${this.baseUrl}/files/uploadfiles/${digitalEventId}/${subCategoryId}`, formdata, options);
    return this.http.request(req);
  }

  convertBase64ArrayToFileArray(base64Array, filename, extention) {
    let fileArray: any = [];
    base64Array.forEach((file, i) => {
      let builtFileName = `${filename}${this.fileNamePlural(base64Array.length, i)}${extention}`;
      fileArray.push(this.convertBase64ToFile(file, builtFileName));
    });
    console.log('filearray', fileArray)

    return fileArray;
  }

  convertBase64ToFile(str, filename) {
    console.log('base64::', str)
    var base64 = this.isValidBase64(str), arr = base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], filename, { type: mime });
  }

  isValidBase64(str) {
    return str.indexOf('data:image/png') !== -1 ? str : `data:image/png;base64,${str}`;
  }

  fileNamePlural(arrayLength, i) {
    return arrayLength > 0 ? `_${i}` : ``;
  }

  createFinalObject(){
    const obj: any = {}

    obj.DigitalProcessId = this.digitalEventId;
    obj.FilesLinks = [this.cacheCritical[5][0]];
    obj.PaymentMethod = 2;
    obj.SubCategory = 1024;
    obj.EventType = 1;
    obj.ManufacturerCode = 6;
    obj.Amount = this.amount;
    obj.Price = this.premiumSummary;
    //Data:
    obj.Data = {};
    obj.Data.Hmo = this.cacheCritical[1];
    if (this.cacheCritical[2]) {
      obj.Data.Height = this.cacheCritical[2].height;
      obj.Data.Weight = this.cacheCritical[2].weight;
    } else {
      obj.Data.Height = null;
      obj.Data.Weight = null;
    }
    obj.Data.Children = this.cacheCritical[3];
    obj.Data.Questionnaire = this.cacheCritical[4];
    obj.Data.PaymentMethod =  this.cacheCritical[6];

    obj.Data.Amount = this.amount
    obj.Data.AmountChildren = this.amountChildren
    obj.Data.Premium = this.premium
    obj.Data.PremiumChildren = this.premiumChildren
    
    obj.Data = JSON.stringify(obj.Data);
    return obj;

  }

  createEvents(object){
    object;
    // console.log('files', files)
    // let cleanedObj = this.cleanBase64(this._finalObject);
    // let data = JSON.stringify(cleanedObj);
    // return this.http.post(`${this.baseUrl}/events`, {
    //   Data : data,
    //   DigitalProcessId: digitalProccessId,
    //   FilesLinks: files,
    //   ProductId: this._finalObject.product.id,
    //   RecommendedProductId: this._finalObject.product.recommendationId ,
    //   PaymentMethod: this._finalObject.product.paymentMethod,
    //   SubCategory: this._categoryType,
    //   EventType: this._finalObject.product.eventType,
    //   ManufacturerCode: this._finalObject.summary.futurePolicy.id,
    //   Amount: this._finalObject.summary.totalAmount
    // })
    return this.http.post(`${this.baseUrl}/events`, object)

  }

  creditCardPayment(data) {
    return this.http.post(`${this.baseUrl}/creditcard/tokinize`, data)
  }
}



enum PaymentMethod {
  RobinCoin = 1,
  CreditCard = 2,
  None = 4
}

enum EventType {
  Purcharse = 1,
  Transfer = 2
}

enum SubCategory {
  UpdateMislakaData = 1,
  Pension = 2,
  Risk = 4,
  Gemel = 8,
  Kranot = 16,
  DefaultPension = 32,
  HealthInsurance = 64,
  Bet2 = 128,
  CancelPolicy = 256,
  RequestInsurancePolicies = 512,
  CriticalIllness = 1024
}

enum DocType {
  FaceRecognition = 1,
  IdentificationDocuments = 2,
  BusinessDocuments = 4,
  ElectronicSignature = 8,
  Mislaka = 16,
  InsuranceMountain = 32
}
