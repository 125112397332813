import { AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '../../../../../../node_modules/@ngrx/store';
import { AppState, selectInsurance } from '../../../../store/app.states';
import { InsuranceService } from '../../services/insurance.service';
import { AccountService } from '../../../account.service';
import { SwipeItem } from '../../../../shared/components/robin-swipe/swipe-item';
import { LastStateService } from 'src/app/core/services/last-state.service';
import { map } from 'rxjs/operators';

export enum SummaryStatus
    {
        CannotAdvise = 0,
        Good = 1,
        Under = 2,
        Over = 3,
        Upgrade = 4,

    }

@Component({
  selector: 'app-insurance-my-life',
  templateUrl: './insurance-my-life.component.html',
  styleUrls: ['./insurance-my-life.component.scss']
})
export class InsuranceMyLifeComponent implements OnInit, AfterViewInit, OnDestroy {

  tabs;
  data;
  //Holds only Health products
  fetchedDataHealth: any[] = [];
  //Holds only Life products
  fetchedDataLife: any[] = [];
  //Holds Life products Amounts
  fetchedAmountLife: any[] = [];
  //holds Health products detais
  fetchedHealthDetails: any[] = [];
  //there is no noLifeIsuranceInfo
  public noLifeIsuranceInfo: boolean = false;
  //there is notenought info about nursing, criticalness, personalAccident or healthinsurance
  public notHealthProductInfo: boolean = false;

  mountainStatus; //1 - no, 2 - progress, 4 - yes
  mislakaStatus = 1; //1 - yes, 2 - waiting, 3 - no
  public arrayToSend: string[] = [];
  insuranceDetailsLife;

  //broken heart / completed heart etc...
  extractProductState = -1
  extractProductDescription = ''

  constructor(private lastStateService:LastStateService,private route: ActivatedRoute, private accountService: AccountService, private router: Router, private insuranceservice: InsuranceService, private store: Store<AppState>) {
    
    //this line should determine what is the state of the risk
    this.store.select(selectInsurance).pipe(map((data:any) => 
        data.insuranceDetails && data.insuranceDetails.LifeInsurance && data.insuranceDetails.LifeInsurance.SummaryInsuranceRecommendation
        && data.insuranceDetails.LifeInsurance.SummaryInsuranceRecommendation.SummaryStatus
    )).subscribe(data => {
      this.extractProductState = data
      switch(data){
        case SummaryStatus.CannotAdvise:
        this.extractProductDescription = 'לא ניתן להציג המלצות ללא שאלון'
        break;
        case SummaryStatus.Good:
        this.extractProductDescription = 'בכיוון הנכון'
        break;
        case SummaryStatus.Over:
        this.extractProductDescription = 'עודף בגובה הכיסוי'
        break;
        case SummaryStatus.Under:
        this.extractProductDescription = 'כיסוי ביטוחי מצוי בחוסר'
        break;
        case SummaryStatus.Upgrade:
        this.extractProductDescription = 'ניתן להוזיל כיסוי'
        break;
      }
    })

    this.store.select(selectInsurance).subscribe(result => {
      this.data = result;
      // this.insuranceDetailsLife = result.insuranceDetails;
      // if (this.insuranceDetailsLife) {
      //   for (var key in this.insuranceDetailsLife) {
      //     if (key !== 'LifeInsurance' && key !== 'LossWorkabillity' && key !== 'Survivors') {
      //       this.insuranceDetailsLife[key];
      //       delete this.insuranceDetailsLife[key];
      //     }
      //   }
      //   // this.insuranceDetailsLife.push()
      // }
      // if (this.data.insuranceDetails === null) {
      //   this.insuranceservice.getProducts();
      // }

      for (var key in this.data) {
        // skip loop if the property is from prototype
        if (!this.data.hasOwnProperty(key)) continue;

        var obj = this.data[key];
        for (var prop in obj) {
          if (!obj.hasOwnProperty(prop)) continue;

          if (prop.toString() == "LifeInsurance" || prop.toString() == "LossWorkabillity" || prop.toString() == "Survivors") {
            console.log(prop + " dor= ");
            if (obj[prop] !== null && obj[prop] !== undefined) {
              if (prop.toString() == "LifeInsurance") {
                if (Object.values(obj[prop])[3] == 32) {
                  this.arrayToSend.push(prop.toString());
                  this.noLifeIsuranceInfo = true;
                }
              }
              if (prop.toString() == "LifeInsurance" || prop.toString() == "LossWorkabillity") {
                if (obj[prop].CurrentAmount === 0) {

                } else {
                  console.log(Object.values(obj[prop])[3])
                  this.fetchedAmountLife.push(Object.values(obj[prop]));
                  this.fetchedDataLife.push(prop);
                }
              } else {
                console.log(Object.values(obj[prop])[3])
                this.fetchedAmountLife.push(Object.values(obj[prop]));
                this.fetchedDataLife.push(prop);
              }

            }

          } else if (prop.toString() == "CriticalIllness" || prop.toString() == "Nursing" || prop.toString() == "PersonalAccidents" || prop.toString() == "HealthInsurance") {
            console.log(prop + " = ");
            if (obj[prop] !== null && obj[prop] !== undefined) {
              if (Object.values(obj[prop])[3] == 32) {
                this.notHealthProductInfo = true;
              }
              console.log(Object.values(obj[prop]))
              this.fetchedHealthDetails.push(Object.values(obj[prop]));
              this.fetchedDataHealth.push(prop);
            }
          }
        }
      }
      this.fetchedDataLife = this.removeDups(this.fetchedDataLife);
      console.log("MICKAEL => ", this.fetchedDataLife)
      this.fetchedDataHealth = this.removeDups(this.fetchedDataHealth);
      this.insuranceservice.insuranceHealthDetails = this.fetchedHealthDetails;
      this.insuranceservice.insuranceHealthProduct = this.fetchedDataHealth;

    });


    //this.insuranceservice.getProducts();
  }

  ngOnInit() {
    this.route.data.subscribe(v => {
      this.tabs = v[0].tabs;
      console.log('tabs is ::', this.tabs);
    })
  }
  ngAfterViewInit() {
    window.scrollTo(0, this.lastStateService.insuranceLife);
  }
  ngOnDestroy() {
    this.lastStateService.insuranceLife = window.scrollY;
  }

  redirectTo(problem, type, name, details, convertDescItem, description, problemDesc, problemNum, item) {
    if (problem) {
      this.insuranceservice.insuranceProduct = name;
      this.insuranceservice.insuranceProductDetails = details;
      this.insuranceservice.insuranceType = type;
      this.insuranceservice.insuranceProductDesc = convertDescItem;
      this.insuranceservice.whenPay = description;
      this.insuranceservice.problemDesc = problemDesc;
      this.insuranceservice.problemNum = problemNum;
      this.insuranceservice.singleInsuranceProduct = item;
      if (details) {
        this.insuranceservice.productCard = details[5];
        this.insuranceservice.totalAmount = details[0];
      }
      if (problemNum === 0 || problemNum === 1 || problemNum === 5)
        this.router.navigate([`/account`, 'insurance', 'insuranceextendeddeatailscard']);
      else 
        this.router.navigate([`/account`, 'insurance', 'insuranceextendeddetails']);
    }
  }

  checkProductType(item): string {
    console.log(item);
    return "life";
  }

  removeDups(names) {
    let unique = {};
    names.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  sendFooterNumber(i: any, item) {
    if (item === 'LifeInsurance' &&  this.fetchedAmountLife[i][7]) {
      if (this.insuranceservice.firtsTimeRisk === true) {
        return 5;
      } else {
        return this.fetchedAmountLife[i][7].SummaryStatus;
      }
    } else {
      return this.fetchedAmountLife[i][3];
    }

  }


  footerTextToSend(i: any, item): string {
    if (item === 'LifeInsurance' && this.fetchedAmountLife[i][7]) {
      switch (this.fetchedAmountLife[i][7].SummaryStatus) {
        case 0:
          return "לא ניתן להציג המלצות ללא שאלון";
        case 1:
          return "ביטוח תקין";
        case 2:
          return "חוסר בגובה הכיסוי";
        case 3:
          return "עודף בגובה הכיסוי";
        case 4:
          return "ניתן להוזיל את עלות הכיסוי";
        case 5:
          return "בתהליך שדרוג";
      }

    } else {

      switch (this.fetchedAmountLife[i][3]) {
        case 1:
          return "ביטוח תקין";

        case 2:
          return "";

        case 4:
          return "עודף בגובה הכיסוי";

        case 8:
          return "חוסר בגובה הכיסוי";

        case 16:
          return "כפל כיסוי ביטוחי";

        case 32:
          return "בתהליך שדרוג";
      }
    }
  }

  convertItem(item): string {
    switch (item) {
      case "LifeInsurance":
        return "ביטוח חיים";
      case "LossWorkabillity":
        return "אובדן כושר עבודה";
      case "Survivors":
        return "כיסוי שארים";
      // case "Apartment":
      //   return "ביטוח משכנתא";
    }
  }

  convertDescItem(item): string {
    switch (item) {
      case "LifeInsurance":
        return "הגנה כלכלית ליקיריך במקרה מוות";
      case "LossWorkabillity":
        return "הגנה כלכלית מפני איבוד היכולת לעבוד";
      case "Survivors":
        return "תשלום חודשי המשמש כתמיכה כלכלית לבן הזוג וילדיך במקרה פטירתך";
      // case "Apartment":
      //   return "הגנה כלכלית ליקיריך המכסה את יתרת המשכנתא במקרה מוות";
    }
  }

  convertWhenUse(item): string {
    switch (item) {
      case "LifeInsurance":
        return "חד פעמי";
      case "LossWorkabillity":
        return "חודשי";
      case "Survivors":
        return "חודשי";
      // case "Apartment":
      //   return "חודשי";  
    }
  }

  checkIfFecthDataLifeArrayHasMissingProducts() {
    this.arrayToSend = [];
    if (this.fetchedDataLife.length < 3) {
      if (!this.fetchedDataLife.includes('LossWorkabillity')) {
        this.arrayToSend.push(this.convertItem('LossWorkabillity'));
      }
      if (!this.fetchedDataLife.includes('LifeInsurance')) {
        this.arrayToSend.push(this.convertItem('LifeInsurance'));
      }
      if (!this.fetchedDataLife.includes('Survivors')) {
        this.arrayToSend.push(this.convertItem('Survivors'));
      }
      return this.arrayToSend;
    }
  }
}
