import { share } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  private baseUrl;
  public source: string;
  public phoneNumber;


  constructor(private http: HttpClient) {
    this.baseUrl = environment.endPoint;
   }

  register(data) {
    return this.http.post(`${this.baseUrl}/accounts`, data).pipe(share());
  }

  sendPhone(data) {
    const headers = new HttpHeaders();
    // headers.append('Access-Control-Allow-Origin', '*');
    // headers.append('Access-Control-Allow-Credentials', 'true');
    const options = {
      headers: headers,
    };
    return this.http.post(`https://robinaudit.azurewebsites.net/api/AuditAppend?code=tOtMaGUwPDG55MoWJa9wqrPa6GZaKe452ykjNaUpGz/imnQacZZi4A==`, data).pipe(share());
  }
}
