import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'disturbing-popup',
  templateUrl: './disturbing-popup.component.html',
  styleUrls: ['./disturbing-popup.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ]),
    trigger('Fading', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('800ms ease-out')),
      transition(':leave', animate('800ms ease-in')),
    ])
  ]
})
export class DisturbingPopupComponent implements OnInit {
  _title = 'תסכים לעזור לנו להשתפר?';
  _done = false;
  _btn1Text = 'כן';
  _btn2Text = 'לא כל כך';
  @Input() set btn1Text(txt) {
    this._btn1Text = txt;
  }
  @Input() set btn2Text(txt) {
    this._btn2Text = txt;
  }
  @Input() set done(done) {
    this._done = done;
  }

  @Input() set title(ttl) {
    this._title = ttl;
  };
  @Output() yes = new EventEmitter();
  @Output() no = new EventEmitter();
  @Output() close = new EventEmitter();


  clickedYes() {
    this.yes.emit();
  }
  clickedNo() {
    this.no.emit();
  }

  clickX() {
    this.close.emit();
  }

  constructor() { }

  ngOnInit() {
  }

}
