export interface  ISwipeItem{
    _singleItemWidth: number;
    _marginItem: number;
    _itemCount: number;
    _unit: string; // em / px etc.. 
}

export class SwipeItem implements ISwipeItem{
    _singleItemWidth: number;
    _marginItem: number;
    _itemCount: number;
    _unit: string; // em / px etc.. 

    constructor(singleItemWidth: number,
                marginItem: number,
                itemCount: number,
                unit: string)
    {
        this._singleItemWidth = singleItemWidth;
        this._marginItem = marginItem;
        this._itemCount = itemCount;
        this._unit = unit;
    }
}