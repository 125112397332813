import { Component, OnInit } from '@angular/core';
import { CordovaService } from '../../../../../../core/services/cordova.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(private cordovaService: CordovaService) { }

  ngOnInit() {
  }
  send(){
    
  }

  openWhatsapp() {
    if (this.cordovaService.isMobile) {
      this.cordovaService.open('whatsapp');
    }
    else {

    }
  }


}
