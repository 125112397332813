import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'robin-secondary-button',
  templateUrl: './robin-secondary-button.component.html',
  styleUrls: ['./robin-secondary-button.component.scss']
})
export class RobinSecondaryButtonComponent implements OnInit {
  @Input() text;
  @Input() color; // white , blue, green
  @Input() isDone;
   _icon = null;
  doneState = false;
  myClass;
  isDeafult = true;
  isLoader = false;
  isV = false;

  constructor() {
    this.setDoneState(false)
  }

@Input()
set icon(str){
this._icon = str;
}
  ngOnInit() {
    this.myClass = 'button-container ' + this.color;
  }

  setDoneState(value) {
    this.doneState = value;
  }

  pressed() {
    this.myClass = `button-container ${this.color} pressed`;
  }

  pressup() {
    this.myClass = `button-container ${this.color}`;
  }

  setLoadState(value) {
    this.isLoader = value;
  }
  setVState(value) {
    this.isV = value;
  }

  setLoadingStart(value) {
    return new Promise((res, rej) => {
      this.setLoadState(value);
      setTimeout(() => {
        this.setLoadState(!value);
        this.setVState(value);
        res();
      }, 1000);
    })
  }

  setVStart(value) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        this.setVState(value);
      }, 1000);
    })
  }


  clicked() {
    if (this.isDone) {
      this.setDoneState(true);
      this.setLoadingStart(true)
      .then(() => {
      })
    }

    this.myClass = `button-container ${this.color} pressed`;
    setTimeout(() => {
      this.myClass = `button-container ${this.color}`;
    }, 100)
  }

}
