import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'robin-textarea',
  templateUrl: './robin-textarea.component.html',
  styleUrls: ['./robin-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RobinTextareaComponent),
      multi: true
    }
  ]
})
export class RobinTextareaComponent implements OnInit {
  @Input() placeholder: string = 'הוסף הסבר...';
  @Output() change = new EventEmitter();
  private yourAnswer = null;

  constructor() { }

  ngOnInit() {
  }
  textareaAnswer(e){
    this.yourAnswer = e.target.value;
    this.onChange(this.yourAnswer);
  }
  writeValue(val: string): void {
    this.yourAnswer = val;
    this.change.emit(this.yourAnswer);
  }
  onChange = (rating) => { };
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void { }
  setDisabledState(isDisabled: boolean): void { }
}
