import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private url:String = ''; 
  firstTime;
  pleaseWaitCounter = -1;
  IdentityNumber = '';
  goTosupport = false;
  goToCredit = 0;
  constructor(private http: HttpClient) {
    this.url = environment.endPoint;
  }
  
  resetWaitCounter(){
    this.pleaseWaitCounter = -1;
  }

  setIdentityNumber(id){
    this.IdentityNumber; 
  }

  getIdentityNumber(){
    return this.IdentityNumber;
  }

  setWaitCounter(){
    this.pleaseWaitCounter++;
    console.log('this.pleaseWaitCounter', this.pleaseWaitCounter)
  }

  login(params):Observable<any>{
    return this.http.post(`${this.url}/auth/token`, params);
  }

  register(params):Observable<any>{
    return this.http.post(`${this.url}/accounts/register`, params);
  }

  consultExpertCheckbox(params):Observable<any>{
    return this.http.post(`${this.url}/accounts/consult-expert-checkbox-toggle`, params);
  }
  

}
