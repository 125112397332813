import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'robin-checkbox',
  templateUrl: './robin-checkbox.component.html',
  styleUrls: ['./robin-checkbox.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RobinCheckboxComponent), multi: true },
  ]
})
export class RobinCheckboxComponent implements OnInit {
  @Output() toggled = new EventEmitter();
  checkBoxState = false;
  @Input() value;
  @Input() text;
  vClass = '';
  containerClass = '';

  constructor() { }

  ngOnInit() {
    if(this.value) this.toggleCheckbox();
    this._render();
  }

  toggleContainerClass(value){
    this.containerClass = value ? 'checkbox-container active': 'checkbox-container';
  }

  toggleVClass(value){
    this.vClass = value ? 'v active': 'v';
  }

  toggleCheckbox(){
    this.checkBoxState = !this.checkBoxState;
    this.propegateChange(this.checkBoxState);
    this.toggled.emit(this.checkBoxState);
    this._render();
  }
  _render(){
    this.toggleContainerClass(this.checkBoxState);
    this.toggleVClass(this.checkBoxState);
  }

  writeValue(value: any) {
    this.checkBoxState = value;
    this._render();
  }

  propegateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propegateChange = fn;
  }
  registerOnTouched() { }

}
