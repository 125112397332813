import { Injectable } from '@angular/core';

import { NGXLogger } from 'ngx-logger';



@Injectable({
  providedIn: 'root',

})
export class LoggerService {

  constructor(private logger: NGXLogger) {};

  log(msg){
    this.logger.log(msg);
  }
  debug(msg){
    this.logger.debug(msg);
  }

  error(msg){
    this.logger.error(msg);
  }
}
