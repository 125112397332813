export const bottomNavBar = [
    {
        name: 'pension', 
        heb_name: 'פנסיה',
        icon_active: 'assets/images/account/core/Pie_active.png',
        icon_inactive: 'assets/images/account/core/Pie_disable.png',
        url: '#'
    },
    {
        name: 'insurance', 
        heb_name: 'ביטוח',
        icon_active: 'assets/images/account/core/shield_active.png',
        icon_inactive: 'assets/images/account/core/shield_disable.png',
        url: '#'
    }

]