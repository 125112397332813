import { Action } from '@ngrx/store';

export interface State {
    loading: boolean
}

export const initialState: State = {
    loading: false
}; 

export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case BackendActionTypes.FETCHING: {
            return {
                ...state,
                loading: true
            };
        }
        case BackendActionTypes.FETCH_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case BackendActionTypes.FETCH_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        default: {
            return state;
        }
    }
}

export enum BackendActionTypes {
  FETCHING = '[Backend] Fetching',
  FETCH_SUCCESS = '[Backend] Fetching Success',
  FETCH_FAILURE = '[Backend] Fetching Failed'
}


export class Fetching implements Action {
  readonly type = BackendActionTypes.FETCHING;
  constructor() { }
}
export class FetchingSuccess implements Action {
    readonly type = BackendActionTypes.FETCH_SUCCESS;
    constructor() { }
  }
  export class FetchingFailure implements Action {
    readonly type = BackendActionTypes.FETCH_FAILURE;
    constructor() { }
  }

export type All = FetchingFailure | FetchingSuccess | Fetching;