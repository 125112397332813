import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { AppState, selectInsurance } from 'src/app/store/app.states';

import {
  ModalService,
} from '../../../shared/components/robin-modal/modal.service';
import { beneficiary, CacheService } from '../../services/cache.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'add-beneficiaries',
  templateUrl: './add-beneficiaries.component.html',
  styleUrls: ['./add-beneficiaries.component.scss']
})
export class AddBeneficiariesComponent implements OnInit {
  constructor(private modalService: ModalService,
    private router: Router,
    private cacheService: CacheService,
    private store: Store<AppState>,
    private analyticService: AnalyticsService) {
    if (!!(this.cacheService.cache[2]) && Object.keys(this.cacheService.cache[2]).length > 0) {
      this.beneficiaries = this.cacheService.cache[2]
    }
    else {
      this.fetchBeneficiaries()
    }
  }
  ngOnInit() {
    this.analyticService.trackByMixpanel("DP - Risk - Beneficiaries");
    this.analyticService.trackByAppFlyer("DP - Risk - Beneficiaries", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }

  updateDetails(details: beneficiary, index) {
    this.beneficiaries[index] = details
  }
  addNew() {
    // this.next()
    this.cacheService.cache[2] = this.beneficiaries
    this.router.navigate(['/risk/addnewbeneficiary'])
  }

  beneficiaries: beneficiary[];
  fetchBeneficiaries() {
    // this.store.select(selectInsurance).pipe(map((data: any) => data.insuranceDetails && data.insuranceDetails.LifeInsurance)).subscribe(
    //   data => {
    //     let combinedFromArr = data.CombinedFrom
    //     let id = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.FromProduct && data.SummaryInsuranceRecommendation.FromProduct.Id
    //     if (combinedFromArr && id && combinedFromArr[id]) {
    //       let beneficiaries = combinedFromArr[id].Beneficiaries
    //       this.beneficiaries = beneficiaries.map(el => {
    //         let bene: any = {};
    //         bene.FirstName = el.FirstName;
    //         bene.LastName = el.LastName;
    //         bene.Id = el.IdentityNumber;
    //         bene.BirthDate = el.BirthDate;
    //         return bene;
    //       })
    //     }
    //   }
    // )
    // this.beneficiaries = [
    //   {
    //     firstname: 'אור',
    //     lastname: 'לוי',
    //     id: '1',
    //     dateofbirth: '16/05/1992'
    //   },
    //   {
    //     firstname: 'אברהם',
    //     lastname: 'טל',
    //     id: '2',
    //     dateofbirth: '16/05/1980'
    //   }
    // ]
    if (this.beneficiaries) {
      this.beneficiaries = this.beneficiaries.map(el => {
        el.Selected = true
        return el
      })
    } else {
      this.beneficiaries = [];
    }

  }
  checkedStatusChanged(data, i) {
    this.beneficiaries[i].Selected = data;
  }

  next() {
    if (this.beneficiaries.length === 1) {
      this.beneficiaries[0].Percentage = 100;
    }
    this.cacheService.cache[2] = this.beneficiaries
    this.cacheService.cache[3] = {}
    const requiredBeneficiaries = this.beneficiaries.filter(el => el.Selected)
    this.cacheService.cache[3] = requiredBeneficiaries
    if (requiredBeneficiaries.length === 0) {
      this.modalService.open('noBeneficiaries');
    }
    else if (requiredBeneficiaries.length === 1) {
      //skip the distribution page
      this.cacheService.currentTab.next(3)
    }
    else {
      this.cacheService.currentTab.next(3)
    }
  }
  closeModal() {
    this.modalService.close('noBeneficiaries');
  }
  defaultBeneficiaries() {
    this.modalService.close('noBeneficiaries');
    this.cacheService.currentTab.next(3)
  }
}
