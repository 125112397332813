import { UpdatePhoneNumber } from './models/global.models';
import { HomepageService } from './homepage/homepage.service';
import { InsuranceService } from './account/insurance/services/insurance.service';

import { info } from './shared/components/robin-navbar/info.mock';
import { RegisterSuccess, Login, LoginActionTypes } from './store/user.actions';
import { map, last } from 'rxjs/operators';
import { BootstrapService } from './core/services/bootstrap.service';
import { NavManagerService, instruction } from './shared/components/robin-navbar/robin-navbar.service';
import { PensionService } from './account/pension/services/pension.service';
import { CordovaService } from './core/services/cordova.service';
import { Component, OnInit, OnChanges } from '@angular/core';
import { LoggerService } from './core/services/logger.service';
import { Router, NavigationEnd } from '../../node_modules/@angular/router';
import { AppState, selectRegisterState } from './store/app.states';
import { Store } from '@ngrx/store';
import { trigger, animate, style, group, animateChild, query, stagger, transition, state } from '@angular/animations';
import { RobinSideNavService } from './shared/components/modals/robin-side-nav/robin-side-nav.service';
import { AnalyticsService } from './analytics/analytics.service';
import { UtilityService } from './core/services/utility.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { FetchingSuccess } from './store/backend.actions';
import { BranchService } from './core/services/branch.service';
import { LastStateService } from './core/services/last-state.service';
import { ErrorModalService } from './shared/components/modals/error-modal/modal.service';
import { DeeplinkService } from './core/services/deeplink.service';
import { ConfigService } from './core/services/config.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class AppComponent implements OnInit {


  display;
  isLoading = false;
  background;
  title;
  static = true;
  menu = false;
  showSide = false;
  showBack = true;
  showSideNav = 'out';
  private baseUrl;
  splash = true;
  isDrawerOpen = false;
  info;
  isInfoIcon = false;
  constructor(
    private lastStateService: LastStateService,
    private logSerivce: LoggerService,
    private cordovaService: CordovaService,
    private navbarService: NavManagerService,
    private BootstrapService: BootstrapService,
    private utilityService: UtilityService,
    private router: Router,
    private store: Store<AppState>,
    private analyticService: AnalyticsService,
    private sidenavService: RobinSideNavService,
    private http: HttpClient,
    private homepageService: HomepageService,
    private insuranceService: InsuranceService,
    private branchService: BranchService,
    private errorModalService: ErrorModalService,
    private deeplinkService: DeeplinkService,
    private configService: ConfigService
  ) {
    this.router.events.subscribe(e => {
      this.isInfoIcon = this.isInfo();
      // console.log('router changes!');
    });
    this.baseUrl = environment.endPoint;
    this.navbarService.getTitle$.subscribe((obj: any) => {
      this.display = obj.display;
      this.background = obj.background;
      this.title = obj.title;
      this.static = obj.static;
      this.menu = obj.menu;
      this.showBack = obj.showBack;
    });
    this.cordovaService.deviceReady$.subscribe(() => {
      console.log('hi2');

      this.cordovaService.unableFontPreference();
    });

    this.sidenavService.currentState$.subscribe(state => {
      this.showSideNav = state ? 'in' : 'out';
      console.log('showSideNav => ', this.showSideNav);
    });
    let urlToNavigate = localStorage.getItem('valid');
    let phoneNumber = localStorage.getItem('phoneNumber');
    let email = localStorage.getItem('email');
    this.store.dispatch(new RegisterSuccess({ user: { phoneNumber, email } }));
    this.store.select(selectRegisterState).subscribe((data: any) => this.analyticService.phoneNumber = data.user.phoneNumber);

    //displaying the splash screen
    // if (this.cordovaService.isMobile) {
    //   setTimeout(() => {
    //     this.splash = false;
    //     this.router.navigate([urlToNavigate ? '/homepage' : '/']);
    //   }, 5000)
    // }
    // else {
    //   this.splash = false;
    //   this.router.navigate([urlToNavigate ? '/homepage' : '/']);
    // }
    if (window.location.href.toString().includes('login') ||
        window.location.href.toString().includes('letsknow') ||
        window.location.href.toString().includes('firstpage')) {

    } else {
      this.router.navigate([urlToNavigate ? '/homepage' : '/']);
    }


  }

  ngOnInit() {
    this.branchService.initBranch();

    // for external navigate to app
    if (window.location.href.toString().includes("param=")) {
      let param = window.location.href.split("param=");
      let token = param[1];
      let mail = param[2];
      let phone = param[3];

      console.log('token', token);
      console.log('mail', mail);
      console.log('phone', phone);
      if (token && mail && phone) {
        this.store.dispatch(new Login({ token: token }));

        let user = { email: mail, phoneNumber: phone };
        this.homepageService.anonymousStatus(user).subscribe((data: any) => {
          if (data.Code === 0) {
            this.store.dispatch(new FetchingSuccess());
            this.insuranceService.insuranceMountain = data.Data.InsuranceMountainStatus;
            this.deeplinkService.apiCalls();

          } else {
            this.errorModalService.setNewError({
              title: 'אופס...',
              description: 'נראה שמשהו השתבש בדרך',
              type: 'error',
              buttonText: 'נסה שנית',
            }).open();
          }


        });
        this.router.navigate([`/account`, 'pension',]);
      }
    }

    // this.router.navigate(['/test']);
    // this.router.navigate(['/onboarding/idcardmain']);


    this.isLoader();
  }

  isLoader() {
    try {
      this.utilityService.getLoaderStatus().subscribe((val: any) => {
        this.isLoading = val;
      });
    } catch (error) {
      console.log('error: ', error)
    }
  }

  drawer(e){
    this.isDrawerOpen = true;
    this.info = e;
    console.log(e)
  }

  close() {
    this.sidenavService.currentState.next(false);
    document.body.classList.remove('disableOverflow');
    document.body.classList.remove('fixed');
  }

  isItArray(data) {
    return Array.isArray(data);
  }

  isInfo() {
    
    let ok = false;
    let g = info.find(p => p.path === this.router.url);
    // console.log('gagagaga', g)
    if (g !== undefined) {
      ok = true;
    }
    return ok;
  }

  closeDrawer(e) {
    this.isDrawerOpen = false;
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  routerChanged(e) {
    console.log('e', e)

    let elements: any = document.querySelectorAll('router-outlet');
    let tags = [];
    elements.forEach((el: any, ind) => {
      tags.push(el.nextSibling.tagName);
    });
    if (tags.length > 0)
      this.utilityService.resizeDOM(tags[tags.length - 1]);
  }


}