import { Component, OnInit } from '@angular/core';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
import { AccountService } from 'src/app/account/account.service';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-electronic-signature',
  templateUrl: './electronic-signature.component.html',
  styleUrls: ['./electronic-signature.component.scss']
})
export class ElectronicSignatureComponent implements OnInit {
  width = window.innerWidth - 48;
  signature = '';
  onscrollIndication = true;
  signatureErrorMessageShow = false;
  numOfCoins;
  cleanDeclaration;
  constructor(private ciservice: CriticalillnessService,
              private accountService: AccountService,
              private router: Router,
              private analyticService: AnalyticsService) {
    this.cleanDeclaration = Object.keys(this.ciservice.cacheCritical[4]).filter(el => {
      return this.ciservice.cacheCritical[4][el] === "כן"
    }).length === 0
  }

  ngOnInit() {
    this.analyticService.trackByAppFlyer("DP - Mahalot Kashot  - Signature", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }

  signatureChange(event) {
    this.signature = event;
  }


  clear(event) {
    this.signature = '';
  }

  next() {

    if (this.cleanDeclaration) { // Go To Payment

      if (this.signature.length > 0) {
        this.ciservice.uploadFiles([this.signature], this.ciservice.digitalEventId, 1024, 8).subscribe((data: any) => {
          if (data.body && data.body.Code === 0) {
            this.ciservice.cacheCritical[5].push(data.body.Data[0].FilePath); 
            this.ciservice.currentTab.next(6)     
          }
        });
      } else
        this.signatureErrorMessageShow = true;


      
    } else { //End Process
      if (this.signature.length > 0) {
        this.router.navigate(['/criticalillness', 'success'])

        this.ciservice.uploadFiles([this.signature], this.ciservice.digitalEventId, 1024, 8).subscribe((data: any) => {
          if (data.body && data.body.Code === 0) {
            this.ciservice.cacheCritical[5].push(data.body.Data[0].FilePath);
             
            this.ciservice.createEvents(this.ciservice.createFinalObject()).subscribe((data: any) => {
              if (data.Code === 0) {
                this.accountService.coin$.subscribe(data => {
                  this.numOfCoins = data + 1 || 1
                });
                this.accountService.coin.next(this.numOfCoins);
                this.router.navigate(['/criticalillness', 'success'])
              }
            });

          }
        });
      } else
        this.signatureErrorMessageShow = true;
    }
  }
}
