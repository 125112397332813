import { idValidator } from 'src/app/shared/services/validators';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CacheService } from '../../services/cache.service';
import { MbscDatetimeOptions } from 'src/lib/mobiscroll/js/mobiscroll';
import { CordovaService } from 'src/app/core/services/cordova.service';


declare const window;

@Component({
  selector: 'single-beneficiary',
  templateUrl: './single-beneficiary.component.html',
  styleUrls: ['./single-beneficiary.component.scss']
})
export class SingleBeneficiaryComponent implements OnInit {
  singleBeneficiary: FormGroup;
  _saved = false;
  _checked = true;
  _active = false;
  _firstname = '';
  _lastname = '';
  _id;
  _dateofbirth;
  @Output() saved = new EventEmitter()
  @Output() setChecked = new EventEmitter()
  @Input() set firstname(active) {
    this._firstname = active;
    this.singleBeneficiary = this.updateDetails()
  }
  @Input() set lastname(active) {
    this._lastname = active;
    this.singleBeneficiary = this.updateDetails()
  }
  @Input() set id(active) {
    this._id = active;
    this.singleBeneficiary = this.updateDetails()
  }
  @Input() set dateofbirth(active) {
    this._dateofbirth = active;
    this.singleBeneficiary = this.updateDetails()
  }
  @Input() set active(active) {
    this._active = active;
  }
  @Input() set checked(checked) {
    this._checked = true;
  }

  constructor(private fb: FormBuilder, private cacheService: CacheService,
              private cordovaService: CordovaService) {
    this.singleBeneficiary = this.updateDetails()
  }

  @ViewChild('exp') exp: ElementRef;
  mobiScrollClick;

  options: MbscDatetimeOptions = {
    theme: 'robin-v2',
    lang: 'he',
    defaultValue: new Date(1980, 1, 1),

    max: Date.now(),
    //min: Date.now(),
    dateWheels: 'yyyy mm dd',
    dateFormat: 'dd/mm/y'
  };

  updateDetails() {
    return this.fb.group({
      FirstName: [this._firstname, [Validators.required]],
      LastName: [this._lastname, [Validators.required]],
      Id: [this._id, [Validators.required]],
      BirthDate: [this._dateofbirth, [Validators.required]],
    })
  }

  closeExp(event){
    // close
    document.body.classList.remove('disableOverflow');
    this.mobiScrollClick = false;
    document.querySelectorAll('.birth')[0].classList.add('set-exp-date');
  }

  ngOnInit() {
  }
  openBox() {
    this._active = !this._active;
  }

  hideKeyboard(){
    if(window.Keyboard && this.cordovaService.platform === 'Android')window.Keyboard.hide(true);
  }
  saveDetails() {
    if (this.singleBeneficiary.status === "VALID") {
      this.saved.emit(this.cacheService.setCache(this.singleBeneficiary.controls))
      this._saved = true;
      setTimeout(() => {
        this._saved = false
        this._active = false;
      }, 100)
    }
    else {
      //TODO: error handling 
    }
  }
  toggleCheckbox() {
    this._checked = !this._checked
    this.setChecked.emit(this._checked)
  }

}
