import { CacheService } from './../../services/cache.service';
import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { selectInsurance, AppState } from 'src/app/store/app.states';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AnalyticsService } from 'src/app/analytics/analytics.service';


@Component({
  selector: 'purchase-product',
  templateUrl: './recommended-package.component.html',
  styleUrls: ['./recommended-package.component.scss']
})
export class PurchaseProductComponent implements OnInit {
  recommendedInfo;
  recommendedManufacturer

  constructor(private apiService: ApiService, 
              private cacheService: CacheService, 
              private router: Router,
              private store: Store<AppState>,
              private analyticService: AnalyticsService) { 
    this.store.select(selectInsurance).pipe(map((data: any) => data.insuranceDetails && data.insuranceDetails.LifeInsurance)).subscribe(
      data => {
        this.recommendedInfo = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.RecommendedInfo;
        this.recommendedManufacturer = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.RecommendedManufacturer;
      }
      )
  }

  ngOnInit() {
    this.analyticService.trackByMixpanel("DP - Purchase Risk - Preaction Page");
    this.analyticService.trackByAppFlyer("DP - Purchase Risk - Preaction Page", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });


  }

  next() {
    this.apiService.startProcess().subscribe((data: any) => {
      if (!!!(data.Code)) {
        this.cacheService.generatedProcessId = data.Data
        this.router.navigate(['/risk'])
      }
      else {
        //TODO: error handling
        console.error('ERROR');
      }
    })
  }
}
