import { Injectable, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RobinSideNavService {
  currentState = new BehaviorSubject<boolean>(false);
  currentState$ = this.currentState.asObservable();

  constructor() {}
  open() {
    this.currentState.next(true);
  }
  close() {
    this.currentState.next(false);
  }

}
