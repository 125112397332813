import { PensionSummary } from './../../models/global.models';
import { Action } from '@ngrx/store';
import { All, AUTHENTICATED } from "../../store/user.actions";

export interface State {
    authenticated: {} | null
}

export const initialState: State = {
    authenticated: null
};

export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case AUTHENTICATED: {
            return {
                ...state,
                authenticated: action.payload.authenticated
            };
        }
        default: {
            return state;
        }
    }
}