import { Component, OnInit, EventEmitter } from '@angular/core';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { AccountService } from 'src/app/account/account.service';
import { NavManagerService } from 'src/app/shared/components/robin-navbar/robin-navbar.service';
import { MbscDatetimeOptions } from 'src/lib/mobiscroll/js/mobiscroll';
import { ErrorModalService } from '../../../../error-modal/modal.service';
import { FormBuilder, Validators } from "@angular/forms";


@Component({
  selector: 'app-update-children',
  templateUrl: './update-children.component.html',
  styleUrls: ['./update-children.component.scss']
})
export class UpdateChildrenComponent implements OnInit {
  answer = new EventEmitter();
  sumOfChildren = this.accountService.authenticatedModel.numberofchildren;
  answered = this.accountService.authenticatedModel.NumberOfChildrens;
  errorLabel = false;
  placeHolder;
  placeholderInput;
  mobiScrollClick;
  currentI;
  options: MbscDatetimeOptions = {
    theme: 'robin-v2',
    lang: 'he',
    max: new Date(Date.now()),
    min: new Date(1920, 1, 1),
    dateWheels: 'yyyy',
    dateFormat: 'yyyy',
    defaultValue: new Date(1980, 1, 1),
  };
  virtualChildrenArray = [];
  childrenArray;

  generalDetailsForm;


  constructor(private cordovaService: CordovaService,
    private accountService: AccountService,
    private navbarService: NavManagerService,
    private errorModalService: ErrorModalService,
    private fb: FormBuilder
    ) {
    this.childrenArray = this.accountService.authenticatedModel.Childrens;
    if (this.accountService.authenticatedModel.NumberOfChildrens > 0) {
      this.placeHolder = this.accountService.authenticatedModel.NumberOfChildrens;
      for (let i=0; i<this.childrenArray.length; i++) {
        this.virtualChildrenArray.push(this.childrenArray[i]); 
      }     
    }
    else {
      this.placeHolder = 'מספר הילדים שלי';
    }
  }


  ngOnInit() {

  }

  closeKeyboard(e) {
    console.log('closeKeyboard ', e);
    if (!e) {
      this.cordovaService.closeKeyBoard()
    }
  }

  childrenDD(event, category) {
    this.childrenArray = this.setChildrenArray(event);
    this.virtualChildrenArray = this.setChildrenArray(event);
    if (event) {
      // this.scrollToBottom(this.scrollOptions.short);
      // this.setHeightOfContainer();
      // this.answered.emit({value: event, category: 'sumOfchildren'});
      this.answered = event;
      // this.errorLabel = false;
    }
  }

  setChildrenArray(numbers) {
    let array = [];
    for (let i = 0; i < numbers; i++) {
      array.push('');
    }
    return array;
  }

  showChild(i) {
    this.currentI = i;
    document.querySelectorAll('.click-' + i)[0].querySelector('input').click();
    // document.activeElement.querySelector

    //this.mobiScrollClick = true;

    console.log('i>>>')
  }

  dropdownChild(event) {
    this.currentI;
    console.log('iii', event)
    // 
    // this.answer.emit({answer : event.valueText, index : this.index});
    // this.mobiScrollClick = false;
    // console.log('this.childrenArray', this.childrenArray, this.currentI)
    this.childrenArray[this.currentI] = event.valueText;
    // this.scrollToBottom(this.scrollOptions.bottom);
    // if (this.isWholeChildren()) this.sendChildrenArray();
  }

  daysToString(index) {
    let arr = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שביעי', 'שמיני', 'תשיעי', 'עשירי'];
    return arr[index];
  }

  save() {
    var ok = true;
    for (let i = 0; i < this.childrenArray.length; i++) {
      if (this.childrenArray[i] === '') {
        ok = false;
        break;
      }
    }
    if (parseInt(this.answered) === this.childrenArray.length && ok) {
      this.errorLabel = false;
      var sendNewDetails = {
        RiskLevel: this.accountService.authenticatedModel.RiskLevel,
        City: this.accountService.authenticatedModel.City,
        StreetName: this.accountService.authenticatedModel.StreetName,
        HouseNumber: this.accountService.authenticatedModel.HouseNumber,
        PostalCode: this.accountService.authenticatedModel.PostalCode,
        Country: this.accountService.authenticatedModel.Country,
        AccountMaritalStatus: this.accountService.authenticatedModel.AccountMaritalStatus,
        LastSalary: this.accountService.authenticatedModel.Salary,
        JobTitle: this.accountService.authenticatedModel.JobTitle,
        Position: this.accountService.authenticatedModel.Position,
        EmployerName: this.accountService.authenticatedModel.EmployerName,
        // NumberOfChildrens: parseInt(this.answered)
        ChildrensBirthdates: this.childrenArray
        // BirthdatesOfChildren: this.accountService.authenticatedModel.BirthdatesOfChildren
      };

      this.accountService.updateProfile(sendNewDetails).subscribe((data: any) => {
        if (data.Code === 0) {
          this.accountService.authenticatedModel.NumberOfChildrens = parseInt(this.answered);
          this.accountService.authenticatedModel.Childrens = this.childrenArray;
          this.accountService.showSuccessUpdate = true;
          setTimeout(() => {
            this.accountService.showSuccessUpdate = false;
          }, 3000);
          this.navbarService.prev();
        } else {
          this.errorModalService
            .setNewError({
              title: "אופס...",
              description: "נראה שמשהו השתבש בדרך",
              type: "error",
              buttonText: "נסה שנית"
            })
            .open();
        }
      });
    } else {
      this.errorLabel = true;
    }
  }
}



