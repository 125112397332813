import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss']
})
export class AssistantComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
