export const tabMock = [
    {
        name: 'החיים שלי',
        url: 'mylife'
    },
    {
        name: 'הבריאות שלי',
        url: 'myhealth'
    },
    // {
    //     name: 'הרכוש שלי',
    //     url: 'my-property'
    // }

]