import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'phone'
})

export class PhonePipe implements PipeTransform {
    transform(val, args) {
        let newStr = '';

        if (val.length === 10) {
            newStr = val.substr(0,3) + '-';
            //return val;
             return newStr + val.substr(3);
        }
        if (val.length === 9) {
            newStr = val.substr(0,2) + '-';
           // return val;
             return newStr + val.substr(2);
        }
        else{
            return val;
        }
    }
}