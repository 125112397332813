import { Injectable } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache = {};

  constructor(private _router: Router) { 
    _router.events.subscribe((val) => {
      if(val instanceof NavigationStart){
        let route = this.parseRoute(val.url);
        if(!this.cache[route]) this.cache[route] = {};
      }
      else if(val instanceof NavigationEnd){
      }
      else{
        // console.log(this.cache);
      }
  });
  }

  setCache(route, cache){
    this.cache[route] = cache;
    // this.forward(route);
  }
  getCache(route){
    return this.cache[route];
  }
  parseRoute(route: string){
     let arr = route.split('/');
     return arr[arr.length-1];
  }
  forward(url: string){
    this._router.navigate([url]);
  }

}
