export const info = [
    {
        path: '/account/pension',
        data: [
           
            { // 32
                title:'סך חיסכון פנסיוני',
                data: 'כל הכספים שיש ברשותך בכל קופות גמל הפנסיה וההשתלמות.'
            },
            { // 31
                title:'קצבה צפויה בגיל פרישה ',
                data: 'סכום שיעמוד לרשותך מידי חודש בעת הפרישה.'
            },
            { // 29
                title:'תחזית לגיל פרישה',
                data: 'סכום הכסף הצפוי לך בגיל הפרישה.'
            },
            { // 2
                title:'קרן פנסיה',
                data: [
                    {
                        title: 'מה זה?',
                        data: `היא מכשיר חסכון לטווח ארוך או בינוני לשכירים ועצמאים, המיועד להבטיח הכנסה בעת פרישה. הכספים לא ניתנים למשיכה עד גיל פרישה, אלא בשיעור מס גבוהה. 
                        קרן הפנסיה עובדת על בסיס ערבות הדדית בין העמיתים בקרן וייעודה הוא להבטיח קצבת זקנה חודשית בעת הפרישה. בנוסף, הקרן מכילה בתוכה כיסויים ביטוחים כגון קצבת שארים ונכות.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `הפקדות באופן קבוע במהלך הקריירה, תוכל להבטיח פרישה בכבוד. הכיסויים הביטוחים בקרן יגנו על המבוטח ויקיריו במהלך תקופת עבודתו.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין קרנות הפנסיה השונות וממקסמת את החיסכון תוך שמירה על רמת סיכון רצויה.`
                    }
                ]
            },
            { // 4
                title:'קרן השתלמות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `תכנית חיסכון לטווח קצר-בינוני הפטורה ממס לתקופה של 6 שנים ואילך. קרנות ההשתלמות שהסתיימו לאחר שש שנים הופכות להיות נזילות וניתן למשוך את הכסף בהן.                        `
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `עבור שכירים, מופקדים כספים באופן שוטף הן על ידי העובד והן על ידי המעביד. 
                        עבור עצמאים, ניתן להפקיד עד 7% מההכנסה השנתית ולקבל הטבות מס אטרקטיביות.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין הקרנות השונות וממקסמת את החיסכון על ידי בחירה בתכנית המשתלמת ביותר.`
                    }
                ]
            },
            { // 3
                title:'קופת גמל',
                data: [
                    {
                        title: 'מה זה?',
                        data: `מוצר חסכון לטווח ארוך או בינוני לשכירים ועצמאים, המיועד להבטיח הכנסה בעת פרישה. `
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `בניגוד לקרן פנסיה וביטוח מנהלים, קופת גמל נועדה לחיסכון בלבד, ללא כיסויים ביטוחים.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין הקופות השונות וממקסמת את החיסכון על ידי בחירה בקופה המשתלמת ביותר.`
                    }
                ]
            },
            { // 5
                title:'ביטוח מנהלים',
                data: [
                    {
                        title: 'מה זה?',
                        data: `מוצר לחיסכון ארוך טווח בדמות חוזה בין המבוטח לחברת הביטוח, המיועד להעניק קצבה בעת הפרישה. בנוסף מכיל בתוכו כיסויים ביטוחים כגון ביטוח חיים ואובדן כושר עבודה.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `הפקדות באופן קבוע במהלך הקריירה, תוכל להבטיח פרישה בכבוד. הכיסויים הביטוחים בקרן יגנו על המבוטח ויקיריו במהלך תקופת עבודתו.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין הקופות השונות וממקסמת את החיסכון על ידי בחירה בקופה המשתלמת ביותר.`
                    }
                ]
            }

        ]
    },
    {
        path: '/account/pension/forecast',
        data: [
            { // 32
                title:'סך חיסכון פנסיוני',
                data: 'כל הכספים שיש ברשותך בכל קופות גמל הפנסיה וההשתלמות.'
            },
            { // 2
                title:'קרן פנסיה',
                data: [
                    {
                        title: 'מה זה?',
                        data: `היא מכשיר חסכון לטווח ארוך או בינוני לשכירים ועצמאים, המיועד להבטיח הכנסה בעת פרישה. הכספים לא ניתנים למשיכה עד גיל פרישה, אלא בשיעור מס גבוהה. 
                        קרן הפנסיה עובדת על בסיס ערבות הדדית בין העמיתים בקרן וייעודה הוא להבטיח קצבת זקנה חודשית בעת הפרישה. בנוסף, הקרן מכילה בתוכה כיסויים ביטוחים כגון קצבת שארים ונכות.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `הפקדות באופן קבוע במהלך הקריירה, תוכל להבטיח פרישה בכבוד. הכיסויים הביטוחים בקרן יגנו על המבוטח ויקיריו במהלך תקופת עבודתו.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין קרנות הפנסיה השונות וממקסמת את החיסכון תוך שמירה על רמת סיכון רצויה.`
                    }
                ]
            },
            { // 32
                title:'קרן פנסיה ותיקה',
                data: 'כל הכספים שיש ברשותך בכל קופות גמל הפנסיה וההשתלמות.'
            },   
            { // 4
                title:'קרן השתלמות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `תכנית חיסכון לטווח קצר-בינוני הפטורה ממס לתקופה של 6 שנים ואילך. קרנות ההשתלמות שהסתיימו לאחר שש שנים הופכות להיות נזילות וניתן למשוך את הכסף בהן.                        `
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `עבור שכירים, מופקדים כספים באופן שוטף הן על ידי העובד והן על ידי המעביד. 
                        עבור עצמאים, ניתן להפקיד עד 7% מההכנסה השנתית ולקבל הטבות מס אטרקטיביות.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין הקרנות השונות וממקסמת את החיסכון על ידי בחירה בתכנית המשתלמת ביותר.`
                    }
                ]
            },
            { // 3
                title:'קופת גמל',
                data: [
                    {
                        title: 'מה זה?',
                        data: `מוצר חסכון לטווח ארוך או בינוני לשכירים ועצמאים, המיועד להבטיח הכנסה בעת פרישה. `
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `בניגוד לקרן פנסיה וביטוח מנהלים, קופת גמל נועדה לחיסכון בלבד, ללא כיסויים ביטוחים.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין הקופות השונות וממקסמת את החיסכון על ידי בחירה בקופה המשתלמת ביותר.`
                    }
                ]
            },
            { // 5
                title:'ביטוח מנהלים',
                data: [
                    {
                        title: 'מה זה?',
                        data: `מוצר לחיסכון ארוך טווח בדמות חוזה בין המבוטח לחברת הביטוח, המיועד להעניק קצבה בעת הפרישה. בנוסף מכיל בתוכו כיסויים ביטוחים כגון ביטוח חיים ואובדן כושר עבודה.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `הפקדות באופן קבוע במהלך הקריירה, תוכל להבטיח פרישה בכבוד. הכיסויים הביטוחים בקרן יגנו על המבוטח ויקיריו במהלך תקופת עבודתו.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין הקופות השונות וממקסמת את החיסכון על ידי בחירה בקופה המשתלמת ביותר.`
                    }
                ]
            },
            { // 30
                title: 'סכום חד פעמי',
                data: 'סכום שיהיה ניתן למשיכה חד פעמית בעת פרישה.'
            },
            { // 31
                title:'קצבה צפויה בגיל פרישה ',
                data: 'סכום שיעמוד לרשותך מידי חודש בעת הפרישה.'
            },
        ]
    },
    {
        path: '/account/pension/extentionview',
        data: [
            { // 34
                title:'שם מסלול השקעה',
                data: 'בכל מסלול השקעה מוגדרים הטווחים (כמה אחוזים מהתיק) המרכיבים אותו – מניות, אגרות חוב ממשלתיות, אגרות חוב קונצרניות.'
            },
            { // 35
                title:'סטטוס',
                data: ' קופה פעילה או לא פעילה. קופה לא פעילה - קופה שלא הופקדו בה כספים חצי שנה ומעלה.'
            },
            { // 22
                title:'תאריך נזילות',
                data: 'התאריך בו הכסף המצוי בקופה ניתן למשיכה ללא קנס. '
            },
            { // 36
                title:'רמת סיכון',
                data: `מגדריה את רמת החשיפה של המוצר לסיכון ושמתנה בהתאם למסלול ההשקעה הקיים.
                1- נמוך ביותר
                2- הגבוה ביותר
                רמת הסיכון שלך - מגדירה של רמת החשיפה הרצויה לסיכון בתיק הפנסיוני ועקב כך יוצגו המלצות בהתאם.`
            },
            { // 19
                title:'תשואה',
                data: 'הרווח שתקבלו על כסף שמושקע בחיסכון או בשוק ההון.'
            },
            { // 27
                title:'דמי ניהול מצבירה',
                data: 'עמלה המשולמת לגוף המנהל את החשבון מידי חודש. האחוז נגזר מסך הנכסים בקופה.'
            },
            { // 28
                title:'דמי ניהול מפרמיה',
                data: 'עמלה המשולמת לגוף המנהל את החשבון מידי חודש. האחוז נגזר מסך ההפקדה החודשית לקופה.'
            },
            { // 25
                title:'מוטבים',
                data: 'מי שנקבעו על ידך כזכאים לקבל את הכספים בשעת מותך.'
            },
            
        ]
    },
    {
        path: '/account/pension/gemelextensioneview',
        data: [
            { // 34
                title:'שם מסלול השקעה',
                data: 'בכל מסלול השקעה מוגדרים הטווחים (כמה אחוזים מהתיק) המרכיבים אותו – מניות, אגרות חוב ממשלתיות, אגרות חוב קונצרניות.'
            },
            { // 35
                title:'סטטוס',
                data: ' קופה פעילה או לא פעילה. קופה לא פעילה - קופה שלא הופקדו בה כספים חצי שנה ומעלה.'
            },
            { // 22
                title:'תאריך נזילות',
                data: 'התאריך בו הכסף המצוי בקופה ניתן למשיכה ללא קנס. '
            },
            { // 36
                title:'רמת סיכון',
                data: `מגדריה את רמת החשיפה של המוצר לסיכון ושמתנה בהתאם למסלול ההשקעה הקיים.
                1- נמוך ביותר
                2- הגבוה ביותר
                רמת הסיכון שלך - מגדירה של רמת החשיפה הרצויה לסיכון בתיק הפנסיוני ועקב כך יוצגו המלצות בהתאם.`
            },
            { // 19
                title:'תשואה',
                data: 'הרווח שתקבלו על כסף שמושקע בחיסכון או בשוק ההון.'
            },
            { // 27
                title:'דמי ניהול מצבירה',
                data: 'עמלה המשולמת לגוף המנהל את החשבון מידי חודש. האחוז נגזר מסך הנכסים בקופה.'
            },
            { // 28
                title:'דמי ניהול מפרמיה',
                data: 'עמלה המשולמת לגוף המנהל את החשבון מידי חודש. האחוז נגזר מסך ההפקדה החודשית לקופה.'
            },
            { // 25
                title:'מוטבים',
                data: 'מי שנקבעו על ידך כזכאים לקבל את הכספים בשעת מותך.'
            },
            
        ]
    },
    {
        path: '/account/pension/hishtalmutextensioneview',
        data: [
            { // 34
                title:'שם מסלול השקעה',
                data: 'בכל מסלול השקעה מוגדרים הטווחים (כמה אחוזים מהתיק) המרכיבים אותו – מניות, אגרות חוב ממשלתיות, אגרות חוב קונצרניות.'
            },
            { // 35
                title:'סטטוס',
                data: ' קופה פעילה או לא פעילה. קופה לא פעילה - קופה שלא הופקדו בה כספים חצי שנה ומעלה.'
            },
            { // 22
                title:'תאריך נזילות',
                data: 'התאריך בו הכסף המצוי בקופה ניתן למשיכה ללא קנס. '
            },
            { // 36
                title:'רמת סיכון',
                data: `מגדריה את רמת החשיפה של המוצר לסיכון ושמתנה בהתאם למסלול ההשקעה הקיים.
                1- נמוך ביותר
                2- הגבוה ביותר
                רמת הסיכון שלך - מגדירה של רמת החשיפה הרצויה לסיכון בתיק הפנסיוני ועקב כך יוצגו המלצות בהתאם.`
            },
            { // 19
                title:'תשואה',
                data: 'הרווח שתקבלו על כסף שמושקע בחיסכון או בשוק ההון.'
            },
            { // 27
                title:'דמי ניהול מצבירה',
                data: 'עמלה המשולמת לגוף המנהל את החשבון מידי חודש. האחוז נגזר מסך הנכסים בקופה.'
            },
            { // 28
                title:'דמי ניהול מפרמיה',
                data: 'עמלה המשולמת לגוף המנהל את החשבון מידי חודש. האחוז נגזר מסך ההפקדה החודשית לקופה.'
            },
            { // 25
                title:'מוטבים',
                data: 'מי שנקבעו על ידך כזכאים לקבל את הכספים בשעת מותך.'
            },
            
        ]
    },
    {
        path: '/account/insurance',
        data: [
            { // 6
                title:'ביטוח חיים / ריסק',
                data: [
                    {
                        title: 'מה זה?',
                        data: `ביטוח המעניק הגנה כלכלית ליקירי המבוטח במקרה פטירתך.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `הביטוח נועד להבטיח ביטחון כלכלי בצורה של תשלום חד פעמי למוטבים שהוגדרו מראש במקרה של מוות המבוטח בטרם עת. `
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `האם קיים כיסוי ביטוחי המותאם למצבך האישי בהתחשב בגילך, בשכרך ובסטטוס המשפחתי שלך. במידה וקיים פער בין הכיסוי הנוכחי לבין המצב האופטימלי, המערכת יתריע ויציב המלצות.`
                    }
                ]
            },
            { // 11
                title:'אובדן כושר עבודה',
                data: [
                    {
                        title: 'מה זה?',
                        data: `זהו סכום חודשי שישולם במקרה שהמבוטח יאבד את כושר עבודתו עקב מחלה, תאונה, וכו', לתקופה או באופן קבוע.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `כיסוי זה מאפשר למבוטח לקבל תשלום חודשי, כתחליף למשכורת, כדי להבטיח מקור הכנסה אשר יגן על בני המשפחה וישמור על רמת חייהם הנוכחית.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין גובה הכיסוי הקיים מול השכר שהוזן ומתריע כאשר למבוטח קיים כיסוי נמוך מהתקרה המומלצת או כאשר המבוטח משלם לחינם על כיסוי שלא יוכל לתבוע בשעת הצורך. (במקרים של מעל 75% מגובה השכר)`
                    }
                ]
            },
            { // 12
                title:'כיסוי שארים',
                data: [
                    {
                        title: 'מה זה?',
                        data: `תשלום חודשי ליקיריו של המבוטח במקרה של מותו בטרם עת. כיסוי זה נרכש לרוב כחלק מקרן הפנסיה.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `ביטוח זה נועד להבטיח ביטחון כלכלי, בצורה של תשלום חודשי ליקיריי המבוטח במקרה של פטירת המבוטח בטרם עת. `
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `האם קיים כיסוי ביטוחי המותאם לפרופיל שלך. במידה וקיים פער בין הכיסוי הנוכחי לבין המצב האופטימלי, המערכת תתריע ותיתן המלצות בהתאם.`
                    }
                ]
            },
            { // 7
                title:'ביטוח בריאות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `ביטוח המכסה הוצאות הקשורות לטיפול רפואי, כגון ניתוחים, השתלות, תרופות,  ועוד.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `בלא מעט מקרים, הביטוח המשלים של קופות החולים אינו מספיק לכיסוי הטיפול הנדרש ולכן יש צורך לרכוש ביטוח בריאות פרטי `
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `ראשית המערכת בודקת האם קיים לך ביטוח בריאות. לאחר מכן נבדוק האם קיים כפל ביטוחים או כפל בכיסויים קיימים, שלא יזכו אותך בפיצוי כפול. בנוסף, נבדוק האם חסרים לך כיסויים ובהתאם לתוצאות הבדיקה המערכת תמליץ לך על דרך פעולה מתאימה עבורך`
                    }
                ]
            },
            { // 8
                title:'ביטוח סיעודי',
                data: [
                    {
                        title: 'מה זה?',
                        data: `ביטוח סיעודי מעניק תגמול כספי חודשי למבוטח לתקופה מוגדרת במקרה של מצב סיעודי.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `עלות הטיפול באדם סיעודי יכולה להסתכם בעשרות אלפי שקלים בחודש, רוב המשפחות לא יכולים לאפשר לעצמם טיפול בראש שקט.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `ראשית המערכת בודקת האם קיים לך ביטוח סיעודי. במידה וכן, נבדוק האם קיים כיסוי כפול. בהתאם לתוצאות הבדיקה המערכת תמליץ לך על דרך פעולה מתאימה עבורך.`
                    }
                ]
            },
            { // 10
                title:'תאונות אישיות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `ביטוח המעניק למבוטח פיצוי לאירועים כגון: שברים, נכות, סיעוד ואשפוז כתוצאה מתאונה, ובנוסף מעניק פיצוי ליקירך במקרה של מוות מתאונה.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `במקרה של תאונה, פוליסת הביטוח מעניקה פיצוי כספי, בגין תאונה אישית בה הייתם מעורבים לפי חומרת הפגיעה. `
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `ראשית המערכת בודקת האם קיים לך ביטוח תאונות אישיות. במידה וכן, נבדוק האם קיים כיסוי כפול. בהתאם לתוצאות הבדיקה המערכת תמליץ לך על דרך פעולה מתאימה עבורך.`
                    }
                ]
            },
            { // 9
                title:'ביטוח מחלות קשות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `ביטוח המעניק פיצוי חד פעמי בעת גילוי מחלה קשה.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `לעיתים רבות למרות קבלת תשלום מביטוח הבריאות ואובדן כושר העבודה, המשפחה נמצאת במצב כלכלי לא פשוט, ביטוח זה יכול להוות מקור כספי נוסף.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `ראשית המערכת בודקת האם קיים לך ביטוח מחלות קשות. במידה וכן, נבדוק האם קיים כיסוי כפול. בהתאם לתוצאות הבדיקה המערכת תמליץ לך על דרך פעולה מתאימה עבורך.`
                    }
                ]
            },
        ]
    },
    {
        path: '/account/insurance/myhealth',
        data: [
            { // 7
                title:'ביטוח בריאות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `ביטוח המכסה הוצאות הקשורות לטיפול רפואי, כגון ניתוחים, השתלות, תרופות,  ועוד.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `בלא מעט מקרים, הביטוח המשלים של קופות החולים אינו מספיק לכיסוי הטיפול הנדרש ולכן יש צורך לרכוש ביטוח בריאות פרטי `
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `ראשית המערכת בודקת האם קיים לך ביטוח בריאות. לאחר מכן נבדוק האם קיים כפל ביטוחים או כפל בכיסויים קיימים, שלא יזכו אותך בפיצוי כפול. בנוסף, נבדוק האם חסרים לך כיסויים ובהתאם לתוצאות הבדיקה המערכת תמליץ לך על דרך פעולה מתאימה עבורך`
                    }
                ]
            },
            { // 8
                title:'ביטוח סיעודי',
                data: [
                    {
                        title: 'מה זה?',
                        data: `ביטוח סיעודי מעניק תגמול כספי חודשי למבוטח לתקופה מוגדרת במקרה של מצב סיעודי.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `עלות הטיפול באדם סיעודי יכולה להסתכם בעשרות אלפי שקלים בחודש, רוב המשפחות לא יכולים לאפשר לעצמם טיפול בראש שקט.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `ראשית המערכת בודקת האם קיים לך ביטוח סיעודי. במידה וכן, נבדוק האם קיים כיסוי כפול. בהתאם לתוצאות הבדיקה המערכת תמליץ לך על דרך פעולה מתאימה עבורך.`
                    }
                ]
            },
            { // 10
                title:'תאונות אישיות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `ביטוח המעניק למבוטח פיצוי לאירועים כגון: שברים, נכות, סיעוד ואשפוז כתוצאה מתאונה, ובנוסף מעניק פיצוי ליקירך במקרה של מוות מתאונה.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `במקרה של תאונה, פוליסת הביטוח מעניקה פיצוי כספי, בגין תאונה אישית בה הייתם מעורבים לפי חומרת הפגיעה. `
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `ראשית המערכת בודקת האם קיים לך ביטוח תאונות אישיות. במידה וכן, נבדוק האם קיים כיסוי כפול. בהתאם לתוצאות הבדיקה המערכת תמליץ לך על דרך פעולה מתאימה עבורך.`
                    }
                ]
            },
            { // 9
                title:'ביטוח מחלות קשות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `ביטוח המעניק פיצוי חד פעמי בעת גילוי מחלה קשה.`
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `לעיתים רבות למרות קבלת תשלום מביטוח הבריאות ואובדן כושר העבודה, המשפחה נמצאת במצב כלכלי לא פשוט, ביטוח זה יכול להוות מקור כספי נוסף.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `ראשית המערכת בודקת האם קיים לך ביטוח מחלות קשות. במידה וכן, נבדוק האם קיים כיסוי כפול. בהתאם לתוצאות הבדיקה המערכת תמליץ לך על דרך פעולה מתאימה עבורך.`
                    }
                ]
            },
        ]
    },
    {
        path: '/digitalproccess/gemel',
        data: [
            { // 4
                title:'קרן השתלמות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `תכנית חיסכון לטווח קצר-בינוני הפטורה ממס לתקופה של 6 שנים ואילך. קרנות ההשתלמות שהסתיימו לאחר שש שנים הופכות להיות נזילות וניתן למשוך את הכסף בהן.                        `
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `עבור שכירים, מופקדים כספים באופן שוטף הן על ידי העובד והן על ידי המעביד. 
                        עבור עצמאים, ניתן להפקיד עד 7% מההכנסה השנתית ולקבל הטבות מס אטרקטיביות.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין הקרנות השונות וממקסמת את החיסכון על ידי בחירה בתכנית המשתלמת ביותר.`
                    }
                ]
            },
            { // 19
                title:'תשואה',
                data: 'הרווח שתקבלו על כסף שמושקע בחיסכון או בשוק ההון.'
            },
            
            { // 37
                title:' סך צבירה להעברה',
                data: 'סך כל הכספים שנמצאים בקופה '
            },
        ]
    },
    {
        path: '/digitalproccess/hishtalmut',
        data: [
            { // 4
                title:'קרן השתלמות',
                data: [
                    {
                        title: 'מה זה?',
                        data: `תכנית חיסכון לטווח קצר-בינוני הפטורה ממס לתקופה של 6 שנים ואילך. קרנות ההשתלמות שהסתיימו לאחר שש שנים הופכות להיות נזילות וניתן למשוך את הכסף בהן.                        `
                    },
                    {
                        title: 'למה זה חשוב?',
                        data: `עבור שכירים, מופקדים כספים באופן שוטף הן על ידי העובד והן על ידי המעביד. 
                        עבור עצמאים, ניתן להפקיד עד 7% מההכנסה השנתית ולקבל הטבות מס אטרקטיביות.`
                    },
                    {
                        title: 'מה המערכת בודקת?',
                        data: `המערכת משווה בין הקרנות השונות וממקסמת את החיסכון על ידי בחירה בתכנית המשתלמת ביותר.`
                    }
                ]
            },
            { // 19
                title:'תשואה',
                data: 'הרווח שתקבלו על כסף שמושקע בחיסכון או בשוק ההון.'
            },
           
            { // 37
                title:' סך צבירה להעברה',
                data: 'סך כל הכספים שנמצאים בקופה '
            },
        ]
    },
    {
        path: '/account/pension/productalternative',
        data: [
            { // 19
                title:'תשואה',
                data: 'הרווח שתקבלו על כסף שמושקע בחיסכון או בשוק ההון.'
            },
            { // 27
                title:'דמי ניהול מצבירה',
                data: 'עמלה המשולמת לגוף המנהל את החשבון מידי חודש. האחוז נגזר מסך הנכסים בקופה.'
            },
            { // 28
                title:'דמי ניהול מפרמיה',
                data: 'עמלה המשולמת לגוף המנהל את החשבון מידי חודש. האחוז נגזר מסך ההפקדה החודשית לקופה.'
            },
        ]
    }
]