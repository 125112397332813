import { Action } from '@ngrx/store';

export enum RankingActions {
    RATED = '[RATING] APPLICATION HAS BEEN RATED',
    NOT_RATED = '[RATING] APPLICATION HAS NOT BEEN RATED'
}

export interface State {
    RATED: boolean
}

export const initialState: State = {
    RATED: localStorage.getItem('applicationRated') === 'true'
};
export class ApplicationRated implements Action {
    readonly type = RankingActions.RATED;
    constructor(public payload: any) {
        localStorage.setItem('applicationRated', 'true');
     }
}
export class ApplicationHasNotRated implements Action {
    readonly type = RankingActions.NOT_RATED;
    constructor(public payload: any) { }
}
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case RankingActions.RATED: {
            return {
                RATED: true
            };
        }
        case RankingActions.NOT_RATED: {
            return {
                RATED: false
            };
        }
        default: {
            return state;
        }
    }
}