import { AuthenticationModule } from './../authentication/authentication.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomepageRoutingModule } from './homepage-routing.module';
import { SharedModule } from '../shared/shared.module';
import { HomeScreenComponent } from './components/home-screen/home-screen.component';
import { HomeScreenHeaderComponent } from './components/home-screen/home-screen-header/home-screen-header.component';
import { HomeScreenErrorComponent } from './components/home-screen/home-screen-error/home-screen-error.component';
import { ModalService } from '../shared/components/robin-modal/modal.service';
import { TimerComponent } from './components/home-screen/timer/timer.component';
import { httpInterceptorProviders } from '../core/services/http-interceptor.service';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    HomepageRoutingModule,
    AuthenticationModule
  ],
  exports:[
    
  ],
  declarations: [HomeScreenComponent, HomeScreenHeaderComponent, HomeScreenErrorComponent, TimerComponent],
  providers: [httpInterceptorProviders],
})
export class HomepageModule { 
  constructor(){
  }
}
