import { RadioButtonService } from '../radio-button.service';
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'robin-radio-item',
  templateUrl: './robin-radio-item.component.html',
  styleUrls: ['./robin-radio-item.component.scss']
})
export class RobinRadioItemComponent implements OnInit {
  @Input() obj;
  
  
  @Input() isSelected;
  mySelection = false;
  @Output() itemClicked = new EventEmitter();
  constructor(private service : RadioButtonService) {
     this.service.ev$.subscribe( e => {
       if(e !== this) this.setSelected(false);
     })
  }

  ngOnInit() {
     console.log('SELECTED', this.isSelected)
     if(this.isSelected) {
       this.mySelection = this.isSelected;
     }
    if(this.obj.hasOwnProperty('label')) {
      this.mySelection = true;
    }
  }

  setSelected(value){
    this.mySelection = value;
  }

  clicked(){
    this.setSelected(true);
    this.itemClicked.emit(this)
  }

}
