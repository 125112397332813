import { NavManagerService } from './../../../shared/components/robin-navbar/robin-navbar.service';
import { Component, OnInit } from '@angular/core';
import { uiStrings } from '../../assets/heb.ui_strings';

@Component({
  selector: 'app-takanon',
  templateUrl: './takanon.component.html',
  styleUrls: ['./takanon.component.scss']
})
export class TakanonComponent implements OnInit {

  uiStrings;

  constructor(private navbarService:NavManagerService) {
    this.uiStrings = uiStrings.takanon;
   }

  ngOnInit() {
  }
  next(){
this.navbarService.next();
  }

}
