import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'recommendation-bar',
  templateUrl: './recommendation-bar.component.html',
  styleUrls: ['./recommendation-bar.component.scss']
})
export class RecommendationBarComponent implements OnInit {
  @Input() percentPresent;
  @Input() percentRecommend;

  constructor() { }

  ngOnInit() {
  }

}
