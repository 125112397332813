import { Component, OnInit, Output,EventEmitter,Input, ViewChild, ElementRef } from '@angular/core';
import {slideInLeft, slideInRight} from 'ng-animate'
import { trigger, transition, useAnimation } from '@angular/animations';

@Component({
  selector: 'app-scroll-bar',
  templateUrl: './scroll-bar.component.html',
  styleUrls: ['./scroll-bar.component.scss'],
})
export class ScrollBarComponent implements OnInit {
  _sections = ['asd', 'asdsad', 'dsfsf', 'dh', 'cxv', 'xcvc']
  _elementWidth = 9;
  _disableBack = false
  currentSelected = 0;
  @ViewChild('wrapper') wrapper: ElementRef;
  
@Output() itemClicked = new EventEmitter()
  @Input() set elementWidth(width) {
    this._elementWidth = width;
  }
  @Input() set startElement(el) {
    // this.currentSelected < el ? this.back() : this.next()
    this.currentSelected = el;
  }
  @Input() set sections(sec) {
    this._sections = sec;
  }
  @Input() set disableBack(dis){
    this._disableBack = dis
  }
  constructor() { }

  ngOnInit() {
  }
  transition(direction){
    switch(direction){
      case 'back':
      this.itemClicked.emit('back')
      break;
      case 'next':
      this.itemClicked.emit('next')
      break;
    }
  }
  back(){
    if(this.currentSelected < this._sections.length && this.currentSelected > 0)
    this.currentSelected--
  }
  next(){
    if(this.currentSelected < this._sections.length - 1){
      this.currentSelected++
    }

  }
}
