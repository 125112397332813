import { InsuranceExpandedSummary } from './../../../models/global.models';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '../../../../../node_modules/@ngrx/store';
import { AppState } from '../../../store/app.states';
import { environment } from '../../../../environments/environment';
import { Response } from '../../../models/global.models';
import { Insurance } from '../../../store/user.actions';
import { Fetching, FetchingSuccess, FetchingFailure } from '../../../store/backend.actions';
import { Subject } from '../../../../../node_modules/rxjs';
import { ErrorModalService } from 'src/app/shared/components/modals/error-modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {
  private baseUrl;
  insuranceProduct;
  insuranceProductDetails;
  insuranceType;
  insuranceDescription;
  insuranceProductDesc;
  whenPay;
  problemDesc;
  problemNum;
  productCard;
  insuranceData;
  totalAmount;
  insuranceHealthDetails;
  insuranceHealthProduct;
  insuranceMountain;
  singleInsuranceProduct: any[] = [];
  insuranceProduct$: any;
  inProgressFirstTime;
  usecache = 'true';
  firtsTimeRisk = false;

  constructor(private http: HttpClient, private store: Store<AppState>, private errorModalService: ErrorModalService) {
    this.baseUrl = environment.endPoint;
   }

   getProducts() {
    this.store.dispatch(new Fetching());
    return this.http.get(`${this.baseUrl}/products/insurance`).pipe(map((data: Response) => {
      let d = {};
      if(data.Code === 0){
        d = data.Data;
      }
      return d;
    })).subscribe(
      (data: any) => {
        console.log('haaa shufi.. ', data)
         if(data) {
          //console.log(data)
          this.store.dispatch(new FetchingSuccess());
          this.store.dispatch(new Insurance({ insuranceDetails: data }));
          this.insuranceData = data;
         } else {
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open();
         }
       
      },
      err => { 
        this.store.dispatch(new FetchingFailure());
      }
    );
  }
}
