import { AppState, selectToken } from './../../../../store/app.states';
import { CordovaService } from '../../../../core/services/cordova.service';
import { transition, animate, style, trigger, query, group } from '@angular/animations';
import { ModalService } from '../../robin-modal/modal.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { modalStrings } from '../assets/heb.strings';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { NavManagerService } from '../../robin-navbar/robin-navbar.service';
import { Router } from '@angular/router';
@Component({
  selector: 'support-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    // trigger(
    //   'enterAnimation', [
    //     transition(':enter', [
    //       style({transform: 'translateX(100%)', opacity: 0}),
    //       animate('500ms', style({transform: 'translateX(0)', opacity: 1}))
    //     ]),
    //     transition(':leave', [
    //       style({transform: 'translateX(0)', opacity: 1}),
    //       animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
    //     ])
    //   ]
    // ),
    trigger('easeInOut', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate("1s ease-in-out", style({
          opacity: 1
        }))
      ]),
      transition(':leave', [
        style({
          opacity: 1
        }),
        animate("0s ease-in-out", style({
          opacity: 0
        }))
      ])
    ])
    // trigger('easeInOut', [
    //   transition(':enter', [
    //     style({transform: 'translateX(-100%)'}),
    //     animate(500, style({transform: 'translateX(0)'}))
    //   ]),
    //   transition(':leave', [
    //     animate(500, style({transform: 'translateX(100%)'}))
    //   ])
    // ])
  ],
})
export class SupportMainComponent implements OnInit {
  str = modalStrings.main;
  public sms = false;
  public sendTheCompain = false;
  hasToken = false;

  constructor(private router: Router, private _modalService: ModalService, private cordovaService: CordovaService, private store: Store<AppState>, private navbarService: NavManagerService) {
    this.store.select(selectToken).pipe(map((data: any) => data.token)).subscribe(data => data ? this.hasToken = true : this.hasToken = false);
  }
  ngOnInit() {
  }

  sendUsAMessage() {
    // this.sms = !this.sms;
    this.router.navigate(['/support']);
    this._modalService.close('support');
  }

  submitCompain() {
    this.sendTheCompain = !this.sendTheCompain;
  }

  open(app) {
    if (this.cordovaService.isMobile) {
      this.cordovaService.open(app);
    } else {
      if (app === 'whatsapp') {
        this.openWhatsapp();
      } else if (app === 'messenger') {
        this.openFacebook();
      }
    }
  }

  openFacebook() {
    window.open('https://www.messenger.com/t/1721348884812257', '_system', 'location=yes');
  }

  openWhatsapp() {
      window.open('https://wa.me/%E2%80%AA972544598589%E2%80%AC', '_system', 'location=yes');
  }
}
