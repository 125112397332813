import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CacheService } from '../../services/cache.service';
import { UtilService } from '../../services/util.service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { CordovaService } from 'src/app/core/services/cordova.service';

@Component({
  selector: 'process-general-details',
  templateUrl: './general-details.component.html',
  styleUrls: ['./general-details.component.scss']
})
export class GeneralDetailsComponent implements OnInit {
  generalDetails: FormGroup;
  form: any = {}
  occ = ['1', '2']
  familyStatus = ['רווק', 'גרוש', 'אלמן', 'נשוי']
  @Output() completed = new EventEmitter()

  constructor(private cacheService: CacheService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private router: Router,
    private accountService: AccountService,
    private analyticService: AnalyticsService,
    private cordovaService: CordovaService) {
    this.form.city = this.accountService.authenticatedModel.City,
      this.form.street = this.accountService.authenticatedModel.StreetName,
      this.form.number = this.accountService.authenticatedModel.HouseNumber
    this.form.occ = this.accountService.authenticatedModel.JobTitle;

    if (!!(this.cacheService.cache[0])) {
      const { city, number, street, occ, familyStatus } = this.cacheService.cache[0]
      this.form.city = city
      this.form.number = number
      this.form.street = street
      this.form.familyStatus = familyStatus
      this.form.occ = occ
    }
  }

  ngOnInit() {
    this.generalDetails = this.fb.group({
      city: [this.form.city || '', [Validators.required]],
      number: [this.form.number || '', [Validators.required, Validators.pattern(/^\d+$/)]],
      street: [this.form.street || '', [Validators.required]],
      familyStatus: [this.form.familyStatus || '', [Validators.required]],
      occ: [this.form.occ || '', [Validators.required]],
    })
    console.log(this.generalDetails);
    this.analyticService.trackByMixpanel("DP - Risk - Detail Completion");
    this.analyticService.trackByAppFlyer("DP - Risk - Detail Completion", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });


  }

  updateDropdown(ind, e) {
    ind === 0 ? this.form.familyStatus = e : this.form.occ = e
  }



  submit() {
    console.log('here');
    if (this.generalDetails.valid) {
      this.cacheService.cache[0] = this.cacheService.setCache(this.generalDetails.controls)
      this.cacheService.cache[1] = {}
      this.cacheService.currentTab.next(1)
      this.router.navigate(['/risk/heightandweight'])
    }
    else {
      console.log('there');
      this.utilService.setErrorInEmptyFields(this.generalDetails)
      console.log(this.generalDetails);
      //TODO: error handling
    }
  }
  closeKeyboard(e) {
    this.cordovaService.closeKeyBoard();
  }
}
