import { Action } from '@ngrx/store';

export enum AndroidFingerprintActions {
    UNLOCK = '[ANDROID_FINGERPRINT] Android unlock action',
    LOCK = '[ANDROID_FINGERPRINT] Android lock action'
}

export interface State {
    LOCK: boolean
}

export const initialState: State = {
    LOCK: false
};
export class AndroidLock implements Action {
    readonly type = AndroidFingerprintActions.LOCK;
    constructor(public payload: any) { }
}
export class AndroidUnlock implements Action {
    readonly type = AndroidFingerprintActions.UNLOCK;
    constructor(public payload: any) { }
}
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case AndroidFingerprintActions.LOCK: {
            return {
                LOCK: true
            };
        }
        case AndroidFingerprintActions.UNLOCK: {
            return {
                LOCK: false
            };
        }
        default: {
            return state;
        }
    }
}