import { Router } from '@angular/router';
import { trigger, transition, style, state, animate, query, stagger, keyframes, sequence } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NavManagerService } from '../../../shared/components/robin-navbar/robin-navbar.service';
import { uiStrings } from '../../assets/heb.ui_strings';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { OnboardingService } from '../../services/onboarding.service';
import { OtpService } from '../../services/otp/otp.service';
import { Store } from '@ngrx/store';
import { AppState, selectAnonymous } from 'src/app/store/app.states';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  animations:[
    
    // trigger('phaseOuttitle', [
      
    //   state('inactive', style({ width: `${window.innerWidth}px` })),
    //   state('active',   style({
    //     transform: `translate( calc(12.5%) , calc((${window.innerHeight / 2}px - 95px ) ))`,
    //     width: `${window.innerWidth}px`,
    //     textIndent: `${window.innerWidth / 2}px`
        
    //   })),
    //   transition('inactive => active', 
    //     animate('2s ease-in')
    //   ),

    //   //transition('active => inactive', animate('100ms ease-out'))
    // ]),

    trigger('phaseOut', [
      
      state('inactive', style({  })),
      state('active',   style({
         transform: `translate3d( calc( (59px - 16px) -  (${window.innerWidth}px / 2)) , calc((${window.innerHeight / 2}px - 165px) / 2),0) scale(1.5)`,
         willChange: 'transform'
      })),
      transition('inactive => active', 
        animate('2s ease-in-out', keyframes([
          style({ transform: 'translate3d(0px, 0px,0) scale(1)', offset: 0,willChange: 'transform'}),
          style({ transform: `translate3d( calc((59px - 16px) -  (${window.innerWidth}px / 2)) , 0px,0) scale(1.5)`,  offset: 0.3,willChange: 'transform'}),
          style({ transform: `translate3d( calc((59px - 16px) -  (${window.innerWidth}px / 2)) , calc((${window.innerHeight / 2}px - 165px) / 2),0) scale(1.5)`,  offset: 1,willChange: 'transform'}),
        ]))
      ),

      //transition('active => inactive', animate('100ms ease-out'))
    ])
  ]
})

export class HowItWorksComponent implements OnInit {

  public uiStrings;
  constructor(private router: Router, 
              private navbarService: NavManagerService,
              private onboardingService: OnboardingService,
              private analyticService: AnalyticsService,
              private otpService: OtpService,
              private store: Store<AppState>) {
    this.uiStrings = uiStrings.howitworks;
    this.onboardingService.goTosupport = false;
    this.onboardingService.goToCredit = 0;
  }
  clientWidthTitle = 0;
  index = 0;
  isTitle = false;
  isFirstSectionText = false;
  isFirstSectionIcon = false;
  isSecondSectionText = false;
  isSecondSectionIcon = false;
  isButton = false;
  height = 0;
  fadeIn = false;
  active = 'inactive';

  ngOnInit() {
    this.animateEnterance();
    this.onboardingService.resetWaitCounter();
    this.updateAds(this.otpService.allowads);

  }

  letsStrat(){
    this.active = 'active';
    this.height =  window.innerHeight / 2 ;
    let h = this.height * 0.25;
    this.height = this.height + h;
    console.log('height', this.height)
    // document.querySelector('.upper')
    //document.querySelector('#image1').classList.add('phaseOut');
    //this.clientWidthTitle = document.querySelector('#title1').clientWidth;
    document.querySelector('#title1').classList.add('opcityOut');
    document.querySelector('#image2 img').classList.add('opcityOut');
    document.querySelector('#title2').classList.add('opcityOut');
    document.querySelector('.section-body1').classList.add('opcityOut');
    document.querySelector('.section-body2').classList.add('opcityOut');
    document.querySelector('.number1').classList.add('opcityOut');
    document.querySelector('.number2').classList.add('opcityOut');
    document.querySelector('#title').classList.add('opcityOut');
    document.querySelector('.btn').classList.add('opcityOut');

    // setTimeout(() => {
      
      // this.fadeIn = true;
      // this.router.navigate(['/idCard']);
      this.navbarService.next();
    // }, 4*1000);

  }

  animateEnterance(){
    this.enterTitle().then((res)=>{
      this.enterTextual();
    }).then(()=>{
      this.enterIcon();
    }).then(()=>{
      this.enterTextual2();
    }).then(()=>{
      this.enterIcon2();
    }).then(()=>{
      this.enterButton();
    })

  }

  enterTextual(){
    return new Promise((resolve, reject)=> {
      setTimeout(() => {
        this.isFirstSectionText = true;  
        resolve();
      }, 100);
    })
  }
  enterIcon(){
    return new Promise((resolve, reject)=> {
      setTimeout(() => {
        this.isFirstSectionIcon = true;  
        resolve();
      }, 200);
    })
  }

  enterTitle(){
    return new Promise((resolve, reject)=> {
      setTimeout(() => {
        this.isTitle = true;  
        resolve();
      }, 1000);
    })
  }
  enterTextual2(){
    return new Promise((resolve, reject)=> {
      setTimeout(() => {
        this.isSecondSectionText = true;  
        resolve();
      }, 1000);
    })
  }
  enterIcon2(){
    return new Promise((resolve, reject)=> {
      setTimeout(() => {
        this.isSecondSectionIcon = true;  
        resolve();
      }, 1100);
    })
  }

  enterButton(){
    return new Promise((resolve, reject)=> {
      setTimeout(() => {
        this.isButton = true;  
        resolve();
      }, 2000);
    })
  }

  updateAds(val) {
    if (!val) {
      this.store.select(selectAnonymous).subscribe((data: any) => {
        if (!data.anonymous) {
        } else {
          val = data.anonymous.AllowAds;
        }
      });
    }
    if (val) {
      this.otpService.allowAds(val).subscribe((data: any) => { },
        err => { }
      );
    }
  }
}
