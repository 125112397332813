import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { routes } from '../critical-illness-upgrade.module'

import { AppState } from 'src/app/store/app.states';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
@Component({
  selector: 'app-upgrade-process-critical',
  templateUrl: './upgrade-process-critical.component.html',
  styleUrls: ['./upgrade-process-critical.component.scss']
})
export class UpgradeProcessCriticalComponent implements OnInit {
  sections = []
  currentCategory = -1
  purchaseOrUpgrade = -1
  disableBack = false
  androidBack$;
  array;
  constructor(private router: Router,
    private store: Store<AppState>,
    private cdf: ChangeDetectorRef,
    private ciservice: CriticalillnessService) {
    this.ciservice.currentTab$.subscribe(data => {
      //need to refactor => this code takes care to current active tab indicator
      const url = window.location.href
      const seperatedUrl = url.split('/')
      const currentRoute = seperatedUrl[seperatedUrl.length - 1]
      const index = routes.findIndex(el => {
        return el.path.toLowerCase() === currentRoute.toLowerCase()
      })
      this.currentCategory = this.sections.findIndex(el => el === routes[index].data.title)
      this.currentCategory = this.currentCategory > -1 ? this.currentCategory + 1 : 0

      // this.router.navigate(['digitalproccess/pensionupgrade/generaldetails'])

      if (!!!(this.currentCategory)) this.router.navigate(['criticalillness/criticalupgrade/hmo'])
      else {

        //if the current route is payment disable the back
        // if (routes[index + 1].data.title === 'תשלום') {
        //   this.disableBack = true
        // }

        this.newManipulation(this.currentCategory)
      }
    })

    if (ciservice.whoToCover === 1) {
      this.sections = ['קופת חולים','משקל וגובה','הצהרת בריאות','חתימה אלקטרונית','תשלום'];
    } else if (ciservice.whoToCover === 2) {
      this.sections = ['קופת חולים','ילדים','הצהרת בריאות','חתימה אלקטרונית','תשלום'];
    } else if (ciservice.whoToCover === 3) {
      this.sections = ['קופת חולים','משקל וגובה','ילדים','הצהרת בריאות','חתימה אלקטרונית','תשלום'];
    }

    this.createRoutingManipulation()
  }

  ngOnInit() {
  }

  createRoutingManipulation() {
    let obj = {}
    this.sections.forEach((elem, index) => {
      const routeIndex = routes.map(el => el && el.data && el.data.title).findIndex(el => el === elem)
      obj[elem] = routes[routeIndex].path
    })
    this.obj = obj
  }

  obj = {}

  newManipulation(i) {
    console.log(this.obj[Object.keys(this.obj)[i]]);
    this.router.navigate(['/criticalillness/criticalupgrade/' + this.obj[Object.keys(this.obj)[i]]])
  }

  
  transitionPerformed(direction) {
    switch (direction) {
      case 'next':
        if (this.currentCategory < this.sections.length - 1 && this.ciservice.cacheCritical [this.currentCategory] && Object.keys(this.ciservice.cacheCritical[this.currentCategory]).length !== 0) {
          this.currentCategory++;
          this.newManipulation(this.currentCategory);

        }
        break;
      case 'back':
        if (this.currentCategory < this.sections.length && this.currentCategory > 0) {
          this.currentCategory--;
          this.newManipulation(this.currentCategory);
        }
        break;
    } 
    // if (this.cacheService.cachePension[this.currentCategory]) {
      // this.newManipulation(this.currentCategory)
    // }
  }

}
