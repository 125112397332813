import { HomepageService } from 'src/app/homepage/homepage.service';
import { DeeplinkService } from './../../../core/services/deeplink.service';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { uiStrings } from '../../../authentication/assets/heb.ui_strings';
import { selectDeeplink, AppState } from 'src/app/store/app.states';
import { DeeplinkRemovelink } from 'src/app/redux/deeplink/deeplink.reducer';

@Component({
  selector: 'auth-successfully-completed',
  templateUrl: './auth-successfully-completed.component.html',
  styleUrls: ['./auth-successfully-completed.component.scss']
})
export class AuthSuccessfullyCompletedComponent implements OnInit {
  type;
  public uiStrings;
  deeplinkUrl = null;
  constructor(private homepageService: HomepageService, private deeplinkService: DeeplinkService, private route: ActivatedRoute, private router: Router, private store: Store<AppState>) {
    this.uiStrings = uiStrings.authsuccesscompleted;
    this.route.params.subscribe((data: any) => {
      this.type = data && data.finger === 'true' ? 'טביעת אצבע' : 'זיהוי פנים';
    });
    this.deeplinkUrl = this.deeplinkService.deeplinkUrl;
  }

  ngOnInit() {
  }
  getBackToLogin() {
    if (this.deeplinkUrl) {
      this.store.dispatch(new DeeplinkRemovelink({}));
      this.router.navigate([this.deeplinkUrl]);
    }
    // else this.router.navigate(['/account/pension']);
    else {
      this.deeplinkService.apiCalls((err, result) => {
        err ? this.deeplinkService.setError() : this.router.navigate([this.getNavigatedStringUrl(`${this.homepageService.action}`)])
      });
    }
  }
  getNavigatedStringUrl(url) {
    switch (url) {
      case 'howitworks':
        return '/onboarding/howitworks';

      case 'pension':
        return '/account/pension';

      case 'homepage':
        return url;

      case 'questionnaire':
        return url;

      default:
        return url;
    }
  }

}
