import { Component, OnInit, Input } from '@angular/core';
import { RobinHamburger } from './robin-hamburger.model';
import { AnalyticsService } from '../../../../analytics/analytics.service';

@Component({
  selector: 'robin-hamburger',
  templateUrl: './robin-hamburger.component.html',
  styleUrls: ['./robin-hamburger.component.scss']
})
export class RobinHamburgerComponent implements OnInit {
  _isStatic;
  _isMenu;
  public hambuger;
  @Input() set isMenu(as){
    this._isMenu = as;
  }
  @Input() set isStatic(as){
    this._isStatic = as;
  }
  constructor( private analyticService: AnalyticsService) {}
  

  ngOnInit() {
    this.hambuger = new RobinHamburger();
    this.hambuger.setAllStates(this._isMenu, this._isStatic);
  }


  setMenu(state) {
    if(window.location.pathname == '/onboarding/credit'){
      this.analyticService.trackByMixpanel('User Registration -  FDC - CreditCard - Back');
      this.analyticService.trackByAppFlyer('User Registration -  FDC - CreditCard - Back', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    }
    this.hambuger.setNewState(state);
  }

  

}
