
import * as states from './navbar-states.model';

export class RobinNavBar{
    
    _type;

    constructor(type){
        this._type = type;
    }

    getType(){
        return this._type;
    }

    public getState(){
        return states.navState.find( t => t.name === this.getType());
    }

}

