import { environment } from './../../environments/environment';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Injectable, ComponentRef, ComponentFactory, ViewContainerRef, ComponentFactoryResolver, Component, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  private componentRef: ComponentRef<any>;
  private componentRemoved = new Subject<any>();
  public componentRemoved$ = this.componentRemoved.asObservable();
  public answered = new Subject<any>();
  public answered$ = this.answered.asObservable();
  public sliderValue = new Subject<number>();
  public sliderValue$ = this.sliderValue.asObservable();
  public answersToIterate = new Subject<number>();
  public answersToIterate$ = this.answersToIterate.asObservable();
  private dest;
  private baseUrl;

  constructor(private resolver: ComponentFactoryResolver,private http: HttpClient) {
    this.baseUrl = environment.endPoint;
   }

  createComponent(destination: ViewContainerRef,component: any, type, options) {
    this.dest = destination;
     const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(component);
     this.componentRef = destination.createComponent(factory);
     this.componentRef.instance.type = type;
     this.componentRef.instance.answers = options.answers ?  options.answers : null;
     this.componentRef.instance.question = options.question ?  options.question : null;
     this.componentRef.instance.steps = options.steps ? options.steps : null;
     this.componentRef.instance.placeholder = options.placeholder ? options.placeholder : null;
   }

   clearComponent(){
    // this.dest.clear();
    this.componentRemoved.next();
   }

   postQuestionnaireAnswers(answers){
     return this.http.put(`${this.baseUrl}/customers`, answers);
   }

}
