import { Injectable } from '@angular/core';
import { Observable } from '../../../../../node_modules/rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '../../../../../node_modules/@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class SupportService {
  baseUrl;
  whereFrom;
  constructor(private http: HttpClient) 
  { 
    this.baseUrl = environment.endPoint;
  }

  postContactUs(data): Observable<any> {
      return this.http.post(`${this.baseUrl}/contactus/technical`, data);
  }

}
