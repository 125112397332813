import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionnaireComponent } from './components/bid/questionnaire/questionnaire.component';
import { SummaryComponent } from './components/bid/summary/summary.component';
import { ProcessComponent } from './components/bid/process/process.component';
import { AmountComponent } from './components/bid/amount/amount.component';
import { UpgradeProcessCriticalComponent } from './components/digitalprocess/upgrade-process-critical/upgrade-process-critical.component';
import { RiskModule } from '../risk/risk.module';
import { EndOfProcessCriticalComponent } from './components/digitalprocess/end-of-process/end-of-process.component';



export const routes: Routes = [
  {
    path: 'questionnairecriticalillnes',
    component: QuestionnaireComponent,
  },
  {
    path: 'amount',
    component: AmountComponent,
  },
  {
    path: 'process',
    component: ProcessComponent,
  },
  {
    path: 'summary',
    component: SummaryComponent,
  },
  {
    path: 'criticalupgrade',
    component: UpgradeProcessCriticalComponent ,
    children: [
      {
        path: '',
        loadChildren: './components/digitalprocess/critical-illness-upgrade.module#CriticalupgradeModule'

      }
    ]
  },
  {
    path: 'success',
    component: EndOfProcessCriticalComponent
  },
  // {
  //   path: 'heightandweight',
  //   component: HeightAndWeightComponent,
  //   data: {title:'משקל וגובה'}
  // },
  // {
  //   path: 'addbeneficiaries',
  //   component: AddBeneficiariesComponent,
  //   data: {title:'מינוי מוטבים'}
  // },
  // {
  //   path: 'distributionofpercentages',
  //   component: DistributionOfPercentagesComponent,
  //   data: {title:'חלוקת אחוזים'}
  // },
  // {
  //   path: 'healthquestionnnaire',
  //   component: HealthDeclarationComponent,
  //   data: {title:'הצהרת בריאות'}
  // },
  // {
  //   path: 'electronicsignature',
  //   component: ElectronicSignatureComponent,
  //   data: {title:'חתימה אלקטרונית'}
  // },
  // {
  //   path: 'cancelpreviouspolicy',
  //   component: CancelPrevPolicyComponent,
  //   data: {title:'ביטול פוליסה קודמת'}
  // },
  // {
  //   path: 'payment',
  //   component: PaymentComponent,
  //   data: {title:'תשלום'}
  // },
  // {
  //   path: 'addnewbeneficiary',
  //   component: AddNewBeneficiaryComponent
  // },
  // //  {
  // //   path: 'success',
  // //   component: EndOfProcessComponent
  // // },

];

@NgModule({
  declarations: [
    QuestionnaireComponent,
    SummaryComponent,
    ProcessComponent,
    AmountComponent,
    UpgradeProcessCriticalComponent,
    EndOfProcessCriticalComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    SharedModule,
    SignaturePadModule,
    FormsModule,
    ReactiveFormsModule,
    RiskModule
  ],
  exports: [

  ],
  providers: [
    // { provide: RouteReuseStrategy, useClass: RoutingCacheService },
  ]
})
export class CriticalillnessModule { }
