import { CalledAnonymous } from './../../../store/user.actions';
import { ErrorModalService } from './../../../shared/components/modals/error-modal/modal.service';
import { Response } from './../../../models/global.models';
import { OtpService } from './../../services/otp/otp.service';
import { NavManagerService } from './../../../shared/components/robin-navbar/robin-navbar.service';
import { Router } from '@angular/router';
import { CordovaService } from '../../../core/services/cordova.service';
import { UtilityService } from '../../../core/services/utility.service';
import { User } from '../../../core/models/user.model';
import { OnboardingService } from '../../services/onboarding.service';
import { Component, OnInit, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { uiStrings } from '../../assets/heb.ui_strings';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.states';
import { Register, RegisterSuccess } from '../../../store/user.actions';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { ModalService } from '../../../shared/components/robin-modal/modal.service';
declare const window;
// import { Store } from '@ngrx/store';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,OnDestroy {
  private user: User;
  public loginForm: FormGroup;
  public uiStrings;
  public noUserFound: boolean = false;
  public messageError: string = '';
  public numOfRetries = 0;

  constructor(private errorModalService: ErrorModalService,
    private cdr:ChangeDetectorRef,
    private modalService: ModalService,
    private otpService: OtpService,
    private navbarService: NavManagerService,
    private router: Router,
    private loginService: OnboardingService,
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private store: Store<AppState>,
    private analyticsService: AnalyticsService) {
    this.uiStrings = uiStrings.login;
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^05[.\d]+$/)]]
    });
    window.addEventListener('keyboardWillHide', () => { console.log('keyboardWillHide');this.keyboardOpen = false;this.cdr.detectChanges(); });
    window.addEventListener('keyboardWillShow', () => { console.log('keyboardWillShow');this.keyboardOpen = true;this.cdr.detectChanges(); });
  }

  keyboardOpen = false;
  ngOnInit() { }
  

  // login() {

  //   this.loginService.login(this.loginForm.value).subscribe((data: { access_token }) =>
  //     // this.addToken({text: data.access_token})
  //     console.log(data)
  //   );
  // }

  nav(value) {
    // this.router.navigate(['/onboarding', 'letsknow'])
    this.navbarService.next(value);
  }
  ngOnDestroy(): void {
    window.removeEventListener('keyboardWillHide', () => {console.log('removed keyboardWillHide'); })
    window.removeEventListener('keyboardWillShow', () => {console.log('removed keyboardWillShow'); })

  }
  //   addToken(data: {text: string}) {
  //     this.store.dispatch({
  //       // type: `[${this.utilityService.getModuleName(),this.utilityService.getComponentName()}] ADD_TOKEN`,
  //       type: 'ADD_TOKEN',
  //       payload: data
  //     });
  // }
  onSubmit() {
    //If error displayerd, switch it off
    if (this.noUserFound)
      this.noUserFound = false;
    if (this.loginForm.valid) {
      let { phoneNumber } = this.loginForm.controls;
      let { email } = this.loginForm.controls;
      phoneNumber = phoneNumber.value;
      email = email.value;

      let data = { phoneNumber, email };
      this.store.dispatch(new Register({ user: data }));
      this.otpService.anonymousStatus(data).subscribe((data: Response) => {
        this.store.dispatch(new CalledAnonymous({ anonymous: data.Data }));
        console.log('save my anon => ', data.Data)

        if (data.Code === 0) {
          this.store.dispatch(new Register({}));
          if (data.Data.HasPinCode) {
            this.navbarService.next('homepage');
          }
          else {
            this.otpService.resendOtp({ PrimaryIdentifier: phoneNumber, SecondaryIdentifier: email }).subscribe((data: Response) => {
              if (data.Code === 0) {
                this.navbarService.next('otp');
              } else {
                this.numOfRetries++;
                if (this.numOfRetries >= 2) {
                  this.modalService.open('forgodemailorpassword');
                }
                this.messageError = 'אחד או שניים מהפרטים שהזנת שגויים';
                this.noUserFound = true;
              }
            },
              err => {
                this.errorModalService.setNewError({
                  title: 'אופס...',
                  description: 'נראה שמשהו השתבש בדרך',
                  type: 'error',
                  buttonText: 'נסה שנית',
                }).open()
              }
            );
          }
        }
        else {
          this.numOfRetries++;
          if (this.numOfRetries >= 2) {
            this.modalService.open('forgodemailorpassword');
          }
          this.messageError = 'אחד או שניים מהפרטים שהזנת שגויים';
          this.noUserFound = true;
        }
      },
        err => {
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open()
        }
      );
    } else {
      this.messageError = 'יש למלא את כל השדות כדי להמשיך';
      this.noUserFound = true;
      return;
    }

  }
  openSupport() {
    this.modalService.close('forgodemailorpassword');
    this.modalService.open('support');
  }

  keydown(e, len) {
    if (this.noUserFound) {
      this.noUserFound = false;
    }
    // this.utilityService.goToNextInput(e);
    this.utilityService.detectKey(e);
    this.utilityService.inputLength(e, len)
  }

  paste(e) {
    let clipboardData = e.clipboardData || window.clipboardData;
    let pastedText = clipboardData.getData('text');
    // let currentInput = e.target;
    let inputs: any = document.querySelectorAll('form input');
    inputs[0].value = pastedText;
  }

  errorIndicatorCheck() {
    if (this.noUserFound) {
      this.noUserFound = false;
    }
  }
}

