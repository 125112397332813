import { Injectable } from '@angular/core';
import { EventEmitter } from 'protractor';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RobinBottomDrawerService {
  private modalShowed;
  // public closeModal: EventEmitter = new EventEmitter();
  // show = new Observable();

  constructor() { 
    this.modalShowed = false;

  }
  getModalState() : Observable<any>{
    return this.modalShowed;
  }

  toggleModal(value, cb){
    this.modalShowed = value;
    cb();
    // this.show.emit(this.modalShowed);
  }
}
