import { NG_VALIDATORS } from '@angular/forms';
import { Component, Input, OnInit, OnChanges, ElementRef, Renderer2, Renderer, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'robin-button',
  templateUrl: './robin-button.component.html',
  styleUrls: ['./robin-button.component.scss']
})
export class RobinButtonComponent implements OnInit, OnChanges {

  @Input() text;
  @Input() color; // white , blue, green
  @Input() isDone;
  @Output() doneComplete = new EventEmitter();
  doneState = false;
  myClass;
  isDeafult = true;
  isLoader = false;
  isV = false;
 
  constructor() {
    this.setDoneState(false)
  }

  ngOnChanges(){
    this.myClass = 'button-container ' + this.color;
  }

  ngOnInit() {
    // this.myClass = 'button-container ' + this.color;
  }

  setDoneState(value) {
    this.doneState = value;
  }

  pressed() {
    this.myClass = `button-container ${this.color} pressed`;
  }

  pressup() {
    this.myClass = `button-container ${this.color}`;
  }

  setLoadState(value) {
    this.isLoader = value;
  }
  setVState(value) {
    this.isV = value;
  }

  setLoadingStart(value) {
    return new Promise((res, rej) => {
      this.setLoadState(value);
      setTimeout(() => {
        this.setLoadState(!value);
        this.setVState(value);
        res();
      }, 1000);
    })
  }

  setVStart(value) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        this.setVState(value);
      }, 1000);
    })
  }


  clicked() {
    if (this.isDone) {
      this.setDoneState(true);
      this.setLoadingStart(true)
      .then(() => {
        this.doneComplete.emit('done is compelete');
      })
    }

    this.myClass = `button-container ${this.color} pressed`;
    setTimeout(() => {
      this.myClass = `button-container ${this.color}`;
    }, 1)
  }

}
