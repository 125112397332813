import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-robin-boolean-item',
  templateUrl: './robin-boolean-item.component.html',
  styleUrls: ['./robin-boolean-item.component.scss']
})
export class RobinBooleanItemComponent implements OnInit {

  @Input() text;
  @Input() image

  constructor() { }

  ngOnInit() {
  }

}
