
import {LoggerConfig, NgxLoggerLevel} from 'ngx-logger'

const version = '3.6.3';
export const environment = {
    production: true,
    version: version,
    // endPoint: `https://robinapidev.robinpro.co.il/v${version}`,
    // endPoint: `https://rh-dev-api.azurewebsites.net`,
    // endPoint: `https://robinplatformapi.robinpro.co.il`,
    endPoint: `https://wallaapi.robinpro.co.il`,


    loggerConfig: { level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR },
    enableTracing: false,
    thirdParties: {
      appsee: '70cf73d0e63742168ea5a031e3f6cc14',
      appsFlyer: {
        devKey: '944af8bb-634d-4caa-b22b-0ea2eb3646fa',
        appId: '944af8bb-634d-4caa-b22b-0ea2eb3646fa', //ios app id
      },
      mixpanel: '96d93b4833de41ada3c3e82a478bd986',
    },
    social:{
      whatsapp:{
        customerSupport:{
          message: '',
          number: '+972544598589'
        }
      },
      messenger:{
        customerSupport:{
          message: '',
          number:''
        }
      }
    }
  };



