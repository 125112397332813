import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
// import { Bar } from './bar.model';
import * as BAROBJ from './model/barObj';
import { Bar } from './bar.model';


@Component({
  selector: 'bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit, AfterViewInit {
  @Input() percentRecommend;
  @Input() percentPresent;
  @Input() type;
  @Input() height;
  bar;
  currentState;
  newHeight = 0;
  heightStr = '0rem';


  constructor() { }

  ngOnInit() {
    this.bar = new Bar(BAROBJ.barArr);
    this.currentState = this.bar.getCurrentState(this.type)
    // this.heightStr = this.claculateHeight(this.height);
    console.log(this.currentState.name);

  }

  ngAfterViewInit() {
    // this.heightStr = this.claculateHeight(this.height);
  }

  claculateHeight(height) {
    if (this.currentState.name === 'recommend') {
      if (this.percentPresent >= 0) {
        this.newHeight = height;
      } else {
        this.newHeight = 0.75 * height;
      }
    }
    else if (this.currentState.name === 'present') {
      if (this.percentPresent >= 1) {
        this.newHeight = (this.percentPresent / this.percentRecommend) * height;
        if (this.newHeight < 1.4375) {
          this.newHeight = 1.4375;
        }
      }
      else if (this.percentPresent > 0 && this.percentPresent < 1) {
        this.newHeight = 0.375;
      }
      else if (this.percentPresent === 0) {
        this.newHeight = 0;
      }
      else { // this.percentPresent < 0
        this.newHeight = 1.4375;
      }
    }
    return this.newHeight.toString() + 'rem';
  }

}

