import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'robin-signature',
  templateUrl: './robin-signature.component.html',
  styleUrls: ['./robin-signature.component.scss']
})
export class RobinSignatureComponent implements OnInit {

  constructor() { }

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @Output() change = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Input() width;
  @Input() height;
  @Input() penWidth;
  signaturePadOptions: Object;
  isClear = false;

  ngOnInit() {
    this.signaturePadOptions = {
      'minWidth': this.penWidth,
      'canvasWidth': this.width,
      'canvasHeight': 300
    };
  }

  clearSignature(){
    this.isClear = false;
    this.signaturePad.clear();
    this.clear.emit();
  }

  drawStart(){
    this.isClear = true;
    console.log('start!>SD>FSD>DSF>', this.signaturePadOptions, this.width)
  }

  drawComplete() {
    this.change.emit(this.signaturePad.toDataURL());
    console.log('base64 image!!', this.signaturePad.toDataURL()) 
    
  }

}
