import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { RobinMorseModel } from './robin-morse.model';

@Component({
  selector: 'robin-morse',
  templateUrl: './robin-morse.component.html',
  styleUrls: ['./robin-morse.component.scss']
})
export class RobinMorseComponent implements OnInit, DoCheck {

  @Input() arr;

  /* EXAMPLE : 
   arr = {
     count: 3,
     current: 0
   }
  */
  morse:RobinMorseModel;

  
  constructor() { }

  ngOnInit() {
    this.morse = new RobinMorseModel(this.arr);
  }


  ngDoCheck() {
  }


  clickme() {
    this.arr.current++;
  }


  changeStyle(i) {
    return i === this.arr.current ? '1rem' : '0rem';
  }




}
