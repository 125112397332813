export class BottomNavBar {

    private _navArr;
    private _activeTab;

    constructor(navArr, activeTab?){
        this._navArr = navArr;
        this._activeTab = activeTab;
    }

    getNavArray(){
        return this._navArr;
    }

    getActiveTab(){
        return this._activeTab;
    }

    setActiveTab(index){
        this._activeTab = index;
        return this;
    }

    getIcon(item, index){
        var r = index === this.getActiveTab() ? item.icon_active : item.icon_inactive;
        return r ;
    }
}