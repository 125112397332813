import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ModalService } from './modal.service';
import {
    trigger,
    state,
    style,
    animate,
    transition,
    query,
    animateChild
} from '@angular/animations';
@Component({
    selector: 'robin-modal',
    templateUrl: './robin-modal.component.html',
    //template: '<ng-content></ng-content>',
    styleUrls: ['./robin-modal.component.scss'],
    animations: [
        trigger('flyInOutBackground', [
            state('inactive', style({ opacity: 0 })),
            state('active', style({ opacity: 1 })),
            transition('inactive => active', [
                animate('300ms ease-in-out'),
                query('@flyInOutModal', [
                    animateChild(),
                ])
            ]),
            transition('active => inactive', [
                query('@flyInOutModal', [
                    animateChild(),
                ]),
                animate('300ms ease-in-out'),
            ])
        ]),
        trigger('flyInOutModal', [
            state('inactive', style({ opacity: 0, transform: 'translateY(-1rem)' })),
            state('active', style({ opacity: 1, transform: 'translateY(0rem)' })),
            transition('inactive => active', [
                animate('300ms ease-in-out'),
            ]),
            transition('active => inactive', [
                animate('300ms ease-in-out'),
            ])
        ]),
    ]
})

export class RobinModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    private element: any;
    public animationState = 'active';

    constructor(private modalService: ModalService,
        private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        const modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', function (e: any) {
            if (e.target.className === 'modal') {
                modal.close();
            }
        });
        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.close();
        this.modalService.add(this);
    }

    toggleAnimationState() {
        this.animationState = this.animationState === 'active' ? 'inactive' : 'active';
    }



    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.animationState = 'active';
        this.element.style.display = 'block';
        let body = document.querySelector('body');
        body.classList.add('disableOverflow');
        window.scrollTo(0, 0);
        // document.body.classList.add('modal-open');
    }

    // close modal
    close(): void {
        this.animationState = 'inactive';
        setTimeout(() => {
            this.element.style.display = 'none';
            let body = document.querySelector('body');
            body.classList.remove('disableOverflow');
            if(document.getElementById("rateTask") !== undefined && document.getElementById("rateTask") !== null){
                document.getElementById("rateTask").style.zIndex = "9";
            }

        }, 700);
        // document.body.classList.remove('modal-open');
    }
    check(e: any) {
        if (e.path[0].classList[0] === 'container') {
            this.close();
        }
    }
}
