import { Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { AppState, selectInsurance } from '../../../store/app.states';
import { CacheService } from './../../services/cache.service';
import {map} from 'rxjs/operators'
import {Store} from '@ngrx/store'
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'cancel-prev-policy',
  templateUrl: './cancel-prev-policy.component.html',
  styleUrls: ['./cancel-prev-policy.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ]),
  ]
})
export class CancelPrevPolicyComponent implements OnInit,AfterViewInit{
  signed = false
  data;
  signHere = 'חתום כאן'
  error = false
  @Output() completed = new EventEmitter()
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
   signaturePadOptions: Object = {
    'canvasWidth': 325,
    'canvasHeight': 145
  };
  constructor(private cacheService: CacheService, 
              private store:Store<AppState>,
              private analyticService: AnalyticsService) {
this.store.select(selectInsurance).pipe(map((data:any) => data.insuranceDetails && data.insuranceDetails.LifeInsurance && data.insuranceDetails.LifeInsurance.SummaryInsuranceRecommendation && data.insuranceDetails.LifeInsurance.SummaryInsuranceRecommendation.FromProduct))
.subscribe(data => this.data = data)
   }

  ngAfterViewInit(): void {
    this.signaturePad.set('penColor', 'rgb(0, 0, 0)');
    let canvas: any = document.querySelector('canvas')
    canvas.width = window.innerWidth - (24*2)
    canvas.style.background = 'rgb(244, 247, 251)'
  }

  ngOnInit() {
    this.analyticService.trackByMixpanel("DP - Shihluf Risk - Prev Policy");
    this.analyticService.trackByAppFlyer("DP - Shihluf Risk - Prev Policy", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }

  cleanDraw() {
    if(this.signed){
      this.signaturePad.clear();
      // let canvas: any = document.querySelector('canvas')
      // canvas.width = window.innerWidth
      this.signed = false
      this.handleDraw()
    }
  }
  drawStart() {
    this.signed = true
    this.handleDraw()
    if(this.error) this.error = false
  }
  drawComplete() { }
  handleDraw() {
    this.signHere = this.signed ? 'נקה חתימה' : 'חתום כאן'
  }

  next(){
    if(this.signed){
      this.error = false
    let signature = this.signaturePad.toDataURL()
    this.completed.emit(signature);
    this.cacheService.cache[6] = signature
    this.cacheService.cache[7] = {}
    this.cacheService.currentTab.next(7)
  }
  else{
    this.error = true
  }
}
}
