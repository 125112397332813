import { Component, OnInit } from '@angular/core';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
import { FormBuilder } from '@angular/forms';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-hmo',
  templateUrl: './hmo.component.html',
  styleUrls: ['./hmo.component.scss']
})
export class HmoComponent implements OnInit {
  radioForm;
  objClalit;
  objMaccabi;
  objLeumit;
  objMeohedet;
  error = false

  hmo;
  constructor(private ciservice: CriticalillnessService,
    private fb: FormBuilder,
    private analyticService: AnalyticsService ) {
    this.radioForm = this.fb.group({
      radio: [],
    });
    this.objClalit = [
      {
        text: 'כללית מושלם פלטינום',
        value: 1
      },
      {
        text: 'כללית מושלם זהב',
        value: 2
      },
      {
        text: 'לא קיים ברשותי ביטוח משלים',
        value: 3
      },
    ];
    this.objMaccabi = [
      {
        text: 'מכבי שלי',
        value: 4
      },
      {
        text: 'מכבי מגן זהב',
        value: 5
      },
      {
        text: 'מכבי מגן כסף',
        value: 6
      },
      {
        text: 'לא קיים ברשותי ביטוח משלים',
        value: 7
      },
    ];
    this.objMeohedet = [
      {
        text: 'מאוחדת עדיף',
        value: 8
      },
      {
        text: 'מאוחדת שיא',
        value: 9
      },
      {
        text: 'מאוחדת זהב',
        value: 10
      },
      {
        text: 'מאוחדת כסף',
        value: 11
      },
      {
        text: 'לא קיים ברשותי ביטוח משלים',
        value: 12
      },
    ];
    this.objLeumit = [
      {
        text: 'לאומית זהב',
        value: 13
      },
      {
        text: 'לאומית כסף',
        value: 14
      },
      {
        text: 'לא קיים ברשותי ביטוח משלים',
        value: 15
      },
    ];
  }

  ngOnInit() {
    this.analyticService.trackByAppFlyer('DP - Mahalot Kashot - HMO', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }

  hmoRadio(e) {
    this.hmo = e;
  }

  convertHmo(hmo) {
    switch (hmo) {
      case 1:
        return 'כללית מושלם פלטינום'
      case 2:
        return 'כללית מושלם זהב'
      case 3:
        return 'כללית'
      case 4:
        return 'מכבי שלי'
      case 5:
        return 'מכבי מגן זהב'
      case 6:
        return 'מכבי מגן כסף'
      case 7:
        return 'מכבי'
      case 8:
        return 'מאוחדת עדיף'
      case 9:
        return 'מאוחדת שיא'
      case 10:
        return 'מאוחדת זהב'
      case 11:
        return 'מאוחדת כסף'
      case 12:
        return 'מאוחדת'
      case 13:
        return 'לאומית זהב'
      case 14:
        return 'לאומית כסף'
      case 15:
        return 'לאומית'
    }
  }


  next() {
    if (this.radioForm.value.radio) {

      this.ciservice.cacheCritical[1] = this.convertHmo(this.radioForm.value.radio);
      this.ciservice.currentTab.next(2);
    } else {
      this.error = true;
    }
  }


}
