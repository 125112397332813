import { CacheService } from 'src/app/risk/services/cache.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';
import { Observable } from 'rxjs';


export enum DocType {
  FaceRecognition = 1,
  IdentificationDocuments = 2,
  BusinessDocuments = 4,
  ElectronicSignature = 8,
  Mislaka = 16,
  InsuranceMountain = 32
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.endPoint
  constructor(private http: HttpClient, private cacheSerice: CacheService, private utilService: UtilService) { }

  startProcess() {
    return this.http.post(`${this.baseUrl}/events/initdigitalevent`, { SubCategory: 'Risk' })
  }
  
  finishProcess(typeOfRisk) {
    return new Promise((res, rej) => {
      const data: any = this.utilService.prepareObject(typeOfRisk).then((data: any) => {
        console.log('data after utilService.prepareObject => ', data);
        const promises = []
        for (let i = 0; i < data.FilesLinks.length; i++) {
          const blob = this.utilService.imagetoblob(data.FilesLinks[i]);
          promises.push(this.uploadFiles(i, blob))
        }
        Promise.all(promises).then(result => {
          data.FilesLinks = []
          result.forEach((el: any) => {
            data.FilesLinks.push(el.Data[0].FilePath)
          })
          this.http.post(`${this.baseUrl}/events`, data).toPromise().then(data => {
            res(data);
          }).catch(err => rej(err));
        })
        .catch(err => {
          //TODO: error handling in case couldnt resolve
          rej(err)
        })
      }).catch(err => console.log('error => ', err))
    });
  }
  creditCardPayment(data) {
    return this.http.post(`${this.baseUrl}/creditcard/tokinize`, data)
  }

  uploadFiles(fileName, data) {
    const digitalProcessInfo: any = this.cacheSerice.generatedProcessId
    const id = digitalProcessInfo.DigitalEventId
    const photoArr = new FormData();
    const file: File = new File([data], '8_' + fileName + '.png');
    photoArr.append('image', file)
    return this.http.post(`${this.baseUrl}/files/uploadfiles/${id}/4`, photoArr).toPromise()
  }
}

