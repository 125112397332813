import { UtilityService } from './../../../core/services/utility.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OcrService {
  data$: Observable<any>;
  checkInfoObject;
  creditToBack = false;
  private baseUrl;

  constructor(private utilService: UtilityService, private http: HttpClient) {
    this.baseUrl = environment.endPoint;
  }


  fdcRegistration(data) {
    return this.http.post(`${this.baseUrl}/customers/fdcregistration`, data);
  }

  dataAggregationRegistration(data) {
    return this.http.post(`${this.baseUrl}/dataaggregationregistration`, data);
  }

  mislakaRegistration(data) {
    return this.http.post(`${this.baseUrl}/dataaggregationregistration/mislaka`, data);
  }

  insuranceRegistration(data) {
    return this.http.post(`${this.baseUrl}/dataaggregationregistration/mountaininsurance`, data);
  }



  ocrVerification(data) {
    //data:image/jpeg;base64,
    // const formData: FormData = new FormData();
    // let selfieImage = this.utilService.dataURLtoFile(data.SelfieBase64, 'selfieImage');
    // let idImage = this.utilService.dataURLtoFile(data.DocumentPhotoBase64, 'idImage');
    // formData.append('file', selfieImage, selfieImage.name);
    // formData.append('file', idImage, idImage.name);
    // return this.http.post(`${this.baseUrl}/ocrverification`, data);
    return this.http.post(`${this.baseUrl}/accounts/identitydocumentsupload`, data);

  }
}
