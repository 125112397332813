import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'robin-indication',
  templateUrl: './robin-indication.component.html',
  styleUrls: ['./robin-indication.component.scss']
})
export class RobinIndicationComponent implements OnInit, OnChanges {
  @Input() type;

  circleClass = ''
  srcClass = '';
  constructor() { }

  ngOnInit() {
    this.selectType(this.type);
  }

  
  ngOnChanges(){
    this.selectType(this.type);
  }

  selectType(type) {

    switch (type) {
      case 'error':
        this.setClasses(true);
        break;

      case 'success':
        this.setClasses(false);
        break;

      default:
        break;
    }

  }

  setClasses(isError) {
    this.circleClass = isError ? 'circle red' : 'circle green';
    this.srcClass = isError ? 'assets/images/shared/indicators/robin-state/exclamation_white.png' : 'assets/images/forms/v_icon.png';

  }



}
