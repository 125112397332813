import { Action } from '@ngrx/store';

export enum DeeplinkActions {
    SET_LINK = '[Deeplink] set link action',
    REMOVE_LINK = '[Deeplink] remove link action'
}

export class DeeplinkSetlink implements Action {
    readonly type = DeeplinkActions.SET_LINK;
    constructor(public payload: any) { }
}
export class DeeplinkRemovelink implements Action {
    readonly type = DeeplinkActions.REMOVE_LINK;
    constructor(public payload: any) { }
}

export interface State {
    deeplink: string | null
}

export const initialState: State = {
    deeplink: null
};

export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case DeeplinkActions.SET_LINK: {
            return {
                deeplink: action.payload
            };
        }
        case DeeplinkActions.REMOVE_LINK: {
            return {
                deeplink: null
            };
        }
        default: {
            return state;
        }
    }
}