import { RoutingCacheService } from './services/routing-cache.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { ScrollBarComponent } from './components/scroll-bar/scroll-bar.component';
import { UpgradeProductComponent } from './components/shifted-life-insurance/shifted-life-insurance.component';
import { SharedModule } from '../shared/shared.module';
import { PurchaseProductComponent } from './components/recommended-package/recommended-package.component';
import { GeneralDetailsComponent } from './components/general-details/general-details.component';
import { HeightAndWeightComponent } from './components/height-and-weight/height-and-weight.component';
import { QuestionnaireModule } from './components/questionnaire/questionnaire.module';
import { CancelPrevPolicyComponent } from './components/cancel-prev-policy/cancel-prev-policy.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PaymentComponent } from './components/payment/payment.component';
import { AddBeneficiariesComponent } from './components/add-beneficiaries/add-beneficiaries.component';
import { SingleBeneficiaryComponent } from './components/single-beneficiary/single-beneficiary.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddNewBeneficiaryComponent } from './components/add-new-beneficiary/add-new-beneficiary.component';
import { DistributionOfPercentagesComponent } from './components/distribution-of-percentages/distribution-of-percentages.component';
import { HealthDeclarationComponent } from './components/health-declaration/health-declaration.component';
import { ElectronicSignatureComponent } from './components/electronic-signature/electronic-signature.component';
import { EndOfProcessComponent } from './components/end-of-process/end-of-process.component';
import { HorizontalScrollerComponent } from './components/add-beneficiaries/risk/components/horizontal-scroller/horizontal-scroller.component';

export const routes: Routes = [
  {
    path: 'generaldetails',
    component: GeneralDetailsComponent,
    data: {title:'פרטים כלליים'}
  },
  {
    path: 'heightandweight',
    component: HeightAndWeightComponent,
    data: {title:'משקל וגובה'}
  },
  {
    path: 'addbeneficiaries',
    component: AddBeneficiariesComponent,
    data: {title:'מינוי מוטבים'}
  },
  {
    path: 'distributionofpercentages',
    component: DistributionOfPercentagesComponent,
    data: {title:'חלוקת אחוזים'}
  },
  {
    path: 'healthquestionnnaire',
    component: HealthDeclarationComponent,
    data: {title:'הצהרת בריאות'}
  },
  {
    path: 'electronicsignature',
    component: ElectronicSignatureComponent,
    data: {title:'חתימה אלקטרונית'}
  },
  {
    path: 'cancelpreviouspolicy',
    component: CancelPrevPolicyComponent,
    data: {title:'ביטול פוליסה קודמת'}
  },
  {
    path: 'payment',
    component: PaymentComponent,
    data: {title:'תשלום'}
  },
  {
    path: 'addnewbeneficiary',
    component: AddNewBeneficiaryComponent
  },
  //  {
  //   path: 'success',
  //   component: EndOfProcessComponent
  // },

];

@NgModule({
  declarations: [
    ScrollBarComponent,
    UpgradeProductComponent,
    PurchaseProductComponent,
    GeneralDetailsComponent,
    HeightAndWeightComponent,
    CancelPrevPolicyComponent,
    PaymentComponent,
    AddBeneficiariesComponent,
    SingleBeneficiaryComponent,
    AddNewBeneficiaryComponent,
    DistributionOfPercentagesComponent,
    HealthDeclarationComponent,
    ElectronicSignatureComponent,
    EndOfProcessComponent,
    HorizontalScrollerComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    SharedModule,
    QuestionnaireModule,
    SignaturePadModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ScrollBarComponent,
    UpgradeProductComponent,
    PurchaseProductComponent,
    EndOfProcessComponent,
    HorizontalScrollerComponent,
    PaymentComponent,
    ElectronicSignatureComponent
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: RoutingCacheService },
  ]
})
export class RiskModule { }
