import { Injectable } from '@angular/core';
import { Store } from '../../../node_modules/@ngrx/store';
import { AppState } from '../store/app.states';
import { Observable } from '../../../node_modules/rxjs';
import { Fetching } from '../store/backend.actions';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from '../../../node_modules/rxjs/operators';
import { Response } from '../models/global.models';


@Injectable({
  providedIn: 'root'
})

export class HomepageService {
  private baseUrl;
  action;
  firstTime;
  firstTimeQuestionnaire;
  banUrl = '';

  constructor(private store: Store<AppState>, private http: HttpClient ) {
    this.baseUrl = environment.endPoint;
  }

  anonymousStatus(data){
    console.log('fggg', data)
    const params = new HttpParams()
    .set('email', data.email);
    return this.http.get(`${this.baseUrl}/customers/${data.phoneNumber}/status`, {params})
    .pipe(

      map((data: Response) => {
        console.log('data.Data', data.Data)
        return data;
      })
    );
  }


  getAnonymousStatusModel(phoneNumber: string): Observable<object> {
    this.store.dispatch(new Fetching());

    return this.http.get(`${this.baseUrl}/customers/${phoneNumber}/status`)
    .pipe(

      map((data: Response) => {
        console.log('data.Data', data.Data)
        return data.Data;
      })
    );
  }

  getSupport(data){
    console.log('fggg', data)
    const params = new HttpParams()
    .set('phoneNumber', data.phoneNumber).set('email', data.email);
    return this.http.get(`${this.baseUrl}/contactus/lastrequest`, {params})
    .pipe(

      map((data: Response) => {
        return data;
      })
    );
  }

  getHtmlBanner() {
    return this.http.get(`${this.baseUrl}/appsettings/banner`);
  }
}
