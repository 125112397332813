import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'heart',
  templateUrl: './heart.component.html',
  styleUrls: ['./heart.component.scss']
})
export class HeartComponent implements OnInit {

  @Input() color;
  @Input() type;  // 'min' or 'max'
  @Input() amount = '';
  @Input() current: boolean = false;


  _bgColor = 'bg-';
  _color = 'color-';

  constructor() { }

  ngOnInit() {
    this.setClassName(this.color);    
  }

  setClassName(color){
    this._color += color;
    this._bgColor += color;
  }
}
