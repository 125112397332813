import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as validator from 'validator-js';

export class Validators {
    static compose(validators: ValidatorFn[]): any {
        throw new Error("Method not implemented.");
    }
    value: string;
    constructor(value: string) {
        this.value = value;
    }


    public validEmail() {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.value);
    }
    public validId() {
        var re = /^([0-9]{9})$/g;
        return re.test(this.value);
    }
    public validPhoneNumber() {
        var re = /^([0-9]{10})$/g;
        return re.test(this.value);
    }
    public validDate() {
        let d = validator.toDate();
        return d ? true : false;
    }
    public minLength(min) {
        return this.value.length >= min;
    }
    public maxLength(max) {
        return this.value.length <= max;
    }
    public isEmpty() {
        return this.value.length === 0;
    }
    public hasSpecialCharecters() {
        var re = /^[a-zA-Z]*$/
        return re.test(this.value);
    }
    public validCreditCardDate() {
        let re = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
        return re.test(this.value);
    }
    public validCreditCardNumber() {
        return this.value.length === 16;
    }


}

function isValidIsraeliID(id) {
    id = String(id).trim();
    if (id === "000000000")
        return false;
    if (id.length > 9 || id.length < 5 || isNaN(id)) return false;

    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    return Array.from(id, Number)
        .reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
        }) % 10 === 0;
}



export function idValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (!isValidIsraeliID(control.value)) {
        return { 'id': true };
    }
    return null;
}

function dateSeperatedBySlash(str) {
    let date = str.split('/').map((el) => Number(el));
    function validDay(num) {
        return num > 0 && num <= new Date(date[2], date[1], 0).getDate();
    }
    function validMonth(num) {
        return num > 0 && num < 13;
    }
    function validYear(num) {
        return num > 1899 && num <= Number(new Date().getFullYear())
    }
    console.log(date,validDay(date[0]) && validMonth(date[1]) && validYear(date[2]));
    return validDay(date[0]) && validMonth(date[1]) && validYear(date[2]);
}

export function dataValidator (control: AbstractControl): { [key: string]: boolean } | null {
    if (!dateSeperatedBySlash(control.value)) {
        return { 'idDate': true };
    }
    return null;
}




