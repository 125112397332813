import { CalledAnonymous } from './../../../store/user.actions';
import { ErrorModalService } from './../../../shared/components/modals/error-modal/modal.service';
import { Response } from './../../../models/global.models';
import { OtpService } from './../../services/otp/otp.service';
import { NavManagerService } from './../../../shared/components/robin-navbar/robin-navbar.service';
import { Router } from '@angular/router';
import { CordovaService } from '../../../core/services/cordova.service';
import { UtilityService } from '../../../core/services/utility.service';
import { User } from '../../../core/models/user.model';
import { OnboardingService } from '../../services/onboarding.service';
import { Component, OnInit, HostListener, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { uiStrings } from '../../assets/heb.ui_strings';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.states';
import { Register, RegisterSuccess } from '../../../store/user.actions';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { ModalService } from '../../../shared/components/robin-modal/modal.service';
import { RegisterService } from '../../services/register/register.service';
import { BranchService } from 'src/app/core/services/branch.service';
declare const window;

@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.scss'],
})
export class FirstPageComponent implements OnInit, OnDestroy {  private user: User;
  public loginForm: FormGroup;
  public uiStrings;
  public noUserFound: boolean = false;
  public messageError: string = '';
  public numOfRetries = 0;
  errorOnSubmit: boolean = false;
  submitted = false;


  constructor(private errorModalService: ErrorModalService,
    private cdr:ChangeDetectorRef,
    private modalService: ModalService,
    private otpService: OtpService,
    private navbarService: NavManagerService,
    private router: Router,
    private loginService: OnboardingService,
    private formBuilder: FormBuilder,
    private utilityService: UtilityService,
    private store: Store<AppState>,
    private analyticsService: AnalyticsService,
    private cordovaService: CordovaService,
    private registerService: RegisterService,
    private branchService: BranchService) {
    this.uiStrings = uiStrings.login;
    this.loginForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^05[.\d]+$/)]]
    });
    window.addEventListener('keyboardWillHide', () => { console.log('keyboardWillHide');this.keyboardOpen = false;this.cdr.detectChanges(); });
    window.addEventListener('keyboardWillShow', () => { console.log('keyboardWillShow');this.keyboardOpen = true;this.cdr.detectChanges(); });
  }

  keyboardOpen = false;
  ngOnInit() { 
    if (window.location.href.toString().includes('?')) {
      this.registerService.source = window.location.href.split('?')[1];
    }
    if (this.branchService.source) {
      this.registerService.source = this.branchService.source;
    }
  }

  // login() {

  //   this.loginService.login(this.loginForm.value).subscribe((data: { access_token }) =>
  //     // this.addToken({text: data.access_token})
  //     console.log(data)
  //   );
  // }

  nav(value) {
    // this.router.navigate(['/onboarding', 'letsknow'])
    this.navbarService.next(value);
  }
  ngOnDestroy(): void {
    window.removeEventListener('keyboardWillHide', () => {console.log('removed keyboardWillHide'); })
    window.removeEventListener('keyboardWillShow', () => {console.log('removed keyboardWillShow'); })

  }
  // onSubmit() {
  //   //If error displayerd, switch it off
  //   if (this.noUserFound)
  //     this.noUserFound = false;
  //   if (this.loginForm.valid) {
  //     let { phoneNumber } = this.loginForm.controls;
  //     let { email } = this.loginForm.controls;
  //     phoneNumber = phoneNumber.value;
  //     email = email.value;

  //     let data = { phoneNumber, email };
  //     this.store.dispatch(new Register({ user: data }));
  //     this.otpService.anonymousStatus(data).subscribe((data: Response) => {
  //       this.store.dispatch(new CalledAnonymous({ anonymous: data.Data }));
  //       console.log('save my anon => ', data.Data)

  //       if (data.Code === 0) {
  //         this.store.dispatch(new Register({}));
  //         if (data.Data.HasPinCode) {
  //           this.navbarService.next('homepage');
  //         }
  //         else {
  //           this.otpService.resendOtp({ PrimaryIdentifier: phoneNumber, SecondaryIdentifier: email }).subscribe((data: Response) => {
  //             if (data.Code === 0) {
  //               this.navbarService.next('otp');
  //             } else {
  //               this.numOfRetries++;
  //               if (this.numOfRetries >= 2) {
  //                 this.modalService.open('forgodemailorpassword');
  //               }
  //               this.messageError = 'אחד או שניים מהפרטים שהזנת שגויים';
  //               this.noUserFound = true;
  //             }
  //           },
  //             err => {
  //               this.errorModalService.setNewError({
  //                 title: 'אופס...',
  //                 description: 'נראה שמשהו השתבש בדרך',
  //                 type: 'error',
  //                 buttonText: 'נסה שנית',
  //               }).open()
  //             }
  //           );
  //         }
  //       }
  //       else {
  //         this.numOfRetries++;
  //         if (this.numOfRetries >= 2) {
  //           this.modalService.open('forgodemailorpassword');
  //         }
  //         this.messageError = 'אחד או שניים מהפרטים שהזנת שגויים';
  //         this.noUserFound = true;
  //       }
  //     },
  //       err => {
  //         this.errorModalService.setNewError({
  //           title: 'אופס...',
  //           description: 'נראה שמשהו השתבש בדרך',
  //           type: 'error',
  //           buttonText: 'נסה שנית',
  //         }).open()
  //       }
  //     );
  //   } else {
  //     this.messageError = 'יש למלא את כל השדות כדי להמשיך';
  //     this.noUserFound = true;
  //     return;
  //   }

  // }
  openSupport() {
    this.modalService.close('forgodemailorpassword');
    this.modalService.open('support');
  }

  keydown(e, len) {
    if (this.noUserFound) {
      this.noUserFound = false;
    }
    // this.utilityService.goToNextInput(e);
    this.utilityService.detectKey(e);
    this.utilityService.inputLength(e, len)
  }

  paste(e) {
    let clipboardData = e.clipboardData || window.clipboardData;
    let pastedText = clipboardData.getData('text');
    // let currentInput = e.target;
    let inputs: any = document.querySelectorAll('form input');
    inputs[0].value = pastedText;
  }

  errorIndicatorCheck() {
    if (this.noUserFound) {
      this.noUserFound = false;
    }
  }

    onSubmit() {
    //if this.errorOnSubmit displayed switch it off
    if (this.errorOnSubmit)
      this.errorOnSubmit = false;
    if (this.loginForm.controls['phoneNumber'].value == "") 
    {
      this.messageError = 'יש למלא מספר טלפון כדי להמשיך';
      this.errorOnSubmit = true;
    }

    if (this.loginForm.valid && !this.submitted) {
      this.submitted = true;


      let SourceParams;

      if (!this.registerService.source) {
        if (this.cordovaService.isMobile) {
          this.registerService.source = 'Robin';
          SourceParams = 'Robin';
        } else {
          this.registerService.source = 'Web';
          SourceParams = 'Web';
        }
      } else {
        SourceParams = this.registerService.source;
      }
      const register = {
        mobile: this.loginForm.value.phoneNumber,
        source: this.registerService.source ? this.registerService.source : '',
        link: this.branchService.link ? this.branchService.link : '',
      };


      this.registerService.phoneNumber = this.loginForm.value.phoneNumber;
      this.registerService.sendPhone(register).subscribe((data: Response) => {});
      this.navbarService.next('letsknow');
      // this.nav.next('letsknow');

    } else {
      return;
    }
  }
}





  // onSubmit() {
  //   //if this.errorOnSubmit displayed switch it off
  //   if (this.errorOnSubmit)
  //     this.errorOnSubmit = false;
  //   if ((this.registerForm.controls['phoneNumber'].value == "") &&
  //     (!this.isFocused0 && !this.isFocused1 && !this.isFocused2 && !this.isFocused3)) {
  //     this.messageError = 'יש למלא מספר טלפון כדי להמשיך';
  //     this.errorOnSubmit = true;
  //   }

  //   if (this.registerForm.valid && !this.submitted) {
  //     this.submitted = true;


  //     let SourceParams;

  //     if (!this.registerService.source) {
  //       if (this.cordovaService.isMobile) {
  //         this.registerService.source = 'Robin';
  //         SourceParams = 'Robin';
  //       } else {
  //         this.registerService.source = 'Web';
  //         SourceParams = 'Web';
  //       }
  //     } else {
  //       SourceParams = this.registerService.source;
  //     }
  //     const register = {
  //       mobile: this.registerForm.value.phoneNumber,
  //       source: this.registerService.source ? this.registerService.source : '',
  //       link: this.branchService.source ? this.registerService.source : '',
  //     };


  //     this.registerService.phoneNumber = this.registerForm.value.phoneNumber;
  //     this.registerService.sendPhone(register).subscribe((data: Response) => {});
  //     this._nav.next('letsknow');

  //   } else {
  //     return;
  //   }
  // }

