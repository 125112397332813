import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  isAuthenticate = false;
  constructor() { }


  setAuthenticationStatus(value){
    this.isAuthenticate = value;
  }

  get getAuthenticateStatus(){
    return this.isAuthenticate;
  }
}
