import { Component, OnInit, EventEmitter, ViewChild, Input, forwardRef, ElementRef, DoCheck, Output, HostListener } from '@angular/core';
import { RobinInput } from './model/input.model';
import { ValidatorsService } from '../../services/validators.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, NG_VALIDATORS, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { validatorsStrings } from '../../assets/heb.validators';

@Component({
  selector: 'robin-input',
  templateUrl: './robin-input.component.html',
  styleUrls: ['./robin-input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RobinInputComponent), multi: true },
  ]
})

export class RobinInputComponent implements ControlValueAccessor {
  @Input() placeholderInput;
  @Input() inputType;
  private _validators = [];
  @Input() errorMessage;
  @Input() icon;
  @Input() text;
  @Input() birth;
  @Input() errors;
  @Input() errorType;
  @Input() mobiScrollClick;
  @Input() children
  @Output() blurEvent = new EventEmitter();
  @Output() keydownEvent = new EventEmitter();
  @Output() pastEvent = new EventEmitter();
  @ViewChild('target') target: ElementRef;
  datePattern;
  errorsClass = 'error-message';
  showError = false;
  blured = false;

  @Input() validators(arr) {
    this._validators = arr;
  }
  public input: RobinInput;
  public myFocusTriggeringEventEmitter = new EventEmitter<boolean>();

  public placeholderClass = 'placeholder';
  public inputTextClass = 'input-class';
  public containerClass = 'input-container';


  constructor(private serivce: ValidatorsService, private elem: ElementRef) {
    this.input = new RobinInput(this.placeholderInput, this.text);
    if (!this.errorType) this.errorType = 'error';
    // if(this.inputType === 'date'){
    //   this.datePattern = '\d{1,2}/\d{1,2}/\d{4}';
    // }
    this.isInitProperly();
    this._render();

  }

  ngDoCheck() {

    if (this.mobiScrollClick === true) {
      this.inputClicked();
    } else if (this.mobiScrollClick === false) {
      this.inputBlued();
    }

    if (this.errors) {

      if (!this.errors.pristine && this.blured)
        this.errors.status === "INVALID" ? this.setErrorClass(true) : this.setErrorClass(false);

      if (this.errorType === 'success' || this.errorType === 'loading' || (this.errorType === 'error' && this.errorMessage === '')) {
        if (this.errorType === 'error') {
          this.setErrorClass(true);
        } else {
          this.setErrorClass(false);
        }

        this.showError = true;

      }
    }

  }

  setErrorClass(isError) {
    this.input.setContainerError(isError);
    this.input.setPlaceholderError(isError);
    this.showError = isError;
    this._render();
  }

  ngAfterViewInit(): void {
    this.input.setText(this.target.nativeElement.value);
    this.isInitProperly();
    this._render(); 
  }

  ngOnInit(): void {
    if (this.children === true) {
      this.input.text = this.text;
    }
    
  }

  isInitProperly() {
    if (this.children === true) {
      this.input.text = this.text;
      this.toggleTexts(true, false);
    } else {
      this.isEmpty() ? this.toggleTexts(false, false) : this.toggleTexts(true, false);
    }
  }

  inputKeypress(e) {
    this.input.setText(e.target.value);
    this.propegateChange(this.input.getText());
    this.isEmpty() ? this.toggleTexts(false, false) : this.toggleTexts(true, true);
  }

  isEmpty() {
    return this.input.getText() === '' ? true : false;
  }

  toggleTexts(isSmall, active) {

      this.input.setPlaceholderFocus(isSmall);
      this.input.setInputActive(isSmall);  
      active ? this.input.setPlaceholderActive(isSmall) : this.input.setPlaceholderActive(false);
      this._render();
  }

  toggleInputContainer(isActive) {
    isActive ? this.input.setContainerActive(true) : this.input.setContainerActive(false);
    this._render();
  }

  inputClicked() {
    this.input.setInputActive(true);
    this.input.setContainerActive(true);
    this.input.setPlaceholderFocus(true);
    this._render();
    this.target.nativeElement.focus();
  }

  inputBlued() {
    this.blurEvent.emit();
    this.errors.status === "INVALID" ? this.setErrorClass(true) : this.setErrorClass(false);
    this.blured = true;
    this.input.setContainerActive(false);
    this.input.setPlaceholderActive(false);
    this.isEmpty() ? this.input.setPlaceholderFocus(false) : this.input.setPlaceholderFocus(true);
    this._render();
  }

  _render() {
    this.placeholderClass = this.input.renderPlaceholder();
    this.inputTextClass = this.input.renderInput();
    this.containerClass = this.input.renderContainer();
  }



  
  writeValue(value: any) {
    this.input.setText(value);
  }

  propegateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propegateChange = fn;
  }
  registerOnTouched() {  }

  keydown(e) {
    this.keydownEvent.emit(e);
  }

  paste(e) {
    this.pastEvent.emit(e);
    
  }

}
