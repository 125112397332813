import { uiStrings } from './../../../../assets/heb.ui_strings';
import { ErrorModalService } from './../../../../../shared/components/modals/error-modal/modal.service';
import { NavManagerService } from '../../../../../shared/components/robin-navbar/robin-navbar.service';
import { style } from '@angular/animations';
import { CordovaService } from '../../../../../core/services/cordova.service';
import { ImageCaptureService } from '../../../../services/image-capture.service';
import { ModalService } from '../../../../../shared/components/robin-modal/modal.service';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../../core/services/logger.service';
import { last } from 'rxjs/operators';
import { ImageCompressService, IImage } from 'ng2-image-compress';

@Component({
  selector: 'id-card-preview',
  templateUrl: './id-card-preview.component.html',
  styleUrls: ['./id-card-preview.component.scss']
})
export class IdCardPreviewComponent implements OnInit {
  myParam: string;
  public img = null;
  err = false;
  public uiStrings;
  isDrawerOpen = false;
  isTipsOpen = false;

  @ViewChild('picture') picture: ElementRef;

  constructor(
    private zone: NgZone, 
    private errorModalService: ErrorModalService, 
    private router: Router, 
    private captureService: ImageCaptureService, 
    private loggerService: LoggerService, 
    public cordovaService: CordovaService, 
    private navbarService: NavManagerService) {
    this.uiStrings = uiStrings.idcardpreview;
  }

  ngOnInit() {

    // this.captureService.imageCapture.subscribe(data => {
    //   this.img = data;
    //   console.log('this is the img: ',data);
    // });
    this.captureService.idImage.subscribe(data => {
      this.img = data;
      this.reRenderPicture();
    });
    // this.img = this.captureService.idImage;
    // this.reRenderPicture();
  }

  redirectTo() {
    this.zone.run(() => {
      this.navbarService.next('selfie');
    });
  }

  // openCamera() {
  //   if (this.cordovaService.isMobile) {
  //     let el = document.getElementById('idCardPreviewPicDiv');
  //     let width = el.getBoundingClientRect().width;
  //     this.cordovaService.captureImage({ targetWidth: width }, (err, data) => err ? this.onErr(err) : this.onData(data));
  //   }
  // }

  onData(data) {
    // this.captureService.imageCapture.next(data);
    this.img = data;
    this.captureService.idImage.next(data);
    this.reRenderPicture();
  }
  reRenderPicture() {
    this.picture.nativeElement.style.backgroundRepeat = 'no-repeat';
    this.picture.nativeElement.style.backgroundSize = '100%';
    this.picture.nativeElement.style.backgroundPosition = 'center';
    this.picture.nativeElement.style.backgroundImage = 'url(' + this.img + ')';
  }
  onErr(err) {
    this.loggerService.error(err);
    this.errorModalService.setNewError({
      title: 'אופס...',
      description: 'נראה שמשהו השתבש בדרך',
      type: 'error',
      buttonText: 'נסה שנית',
    }).open();
  }

  closeTipsModal() {
    this.showModalTips(false);
  }
  showModalTips(value) {
    // this.htmlInsideModal.open();
    this.isTipsOpen = value;
    this.showDrawer(false);
  }
  showDrawer(value) {
    if (this.cordovaService.isMobile) {
      this.isDrawerOpen = value;
    } else {
      this.openCamera();
    }
  }

  closeDrawer(event) {
    this.showDrawer(false);
  }

  openCamera() {
    let el: any = document.querySelectorAll("#file-input2")[1];
    var file = document.getElementById("file-input2")

    file.addEventListener('change', (e: any) => {
      console.log('e', e);
      this.compressImage(e.target.files);
      // this.doSomethingWithFiles(e.target.files);
    });
  }

  compressImage(fileInput) {
    let fileList: FileList;

    let images: Array<IImage> = [];

    ImageCompressService.filesToCompressedImageSource(fileInput).then(observableImages => {
      observableImages.subscribe((image) => {
        images.push(image);
        if (images !== null) {
          // let src = URL.createObjectURL(file);
          this.captureService.idImage.next(images[0].compressedImage.imageDataUrl);
          this.zone.run(() => {
            this.navbarService.next('idcardpreview');
          });
        }
      }, (error) => {
        console.log("Error while converting");
      }, () => {
        // this.processedImages = images;
        // this.showTitle = true;
      });
    });
  }
}
