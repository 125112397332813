
interface Placeholder{
    focus: boolean;
    active: boolean;
    error: boolean;
}

interface Container{
    active: boolean;
    error: boolean;
}
interface Text{
    active: boolean;
}

export class RobinInput{
    public placeholder? : string;
    public text?: string;
    private styles = {
        placeholder: "placeholder",
        text: "input-class",
        container: "input-container"
    }

    private state: 
        { placeholder: Placeholder, container: Container, text: Text } = 
    {
        placeholder: {
            focus: false,
            active: false,
            error: false
        },
        container: {
            active: false,
            error: false
        },
        text: {
            active: false
        }
    }

    constructor( _placeholder, text){
        this.placeholder = _placeholder;
        this.setInitializedState(text);
    }

    public setText(text){
        this.text = text;
    }

    public getText(){
        return this.text;
    }
    
    /** @description checks rather the input is onfocus or not
     *  @returns boolean 
     */
    public isFocused() {
        console.log('get status', this.state)
        return this.state.container.active ? true : false;
    }
      
    private setInitializedState(text){
        this.state.placeholder.focus = text ? true : false;
        this.state.text.active = text ? true : false;
        this.state.container.active = false;
        this.setText(text);
    }
  

    public setContainerActive(isActive){
        this.state.container.active = isActive;
    }

    public setPlaceholderError(isActive){
        this.state.placeholder.error = isActive;
    }

    public setContainerError(isActive){
        this.state.container.error = isActive;
    }

    public setInputActive(isActive){
        this.state.text.active = isActive;
        // this.state.placeholder.active = isActive;
    }

    public setPlaceholderFocus(isActive){        
        this.state.placeholder.focus = isActive;
    }

    /** @description set placeholder state
     * @param  {} isActive
     * @returns 
     */
    public setPlaceholderActive(isActive){
        this.state.placeholder.active = isActive;
    }
    
    
    public renderPlaceholder(){
        return this.render(this.state.placeholder, this.styles.placeholder);
    }
    
    public renderContainer(){
        return this.render(this.state.container, this.styles.container);
    }

    public renderInput(){
        return this.render(this.state.text, this.styles.text);
    }

    /** @description iterate over state
     * @param  {} obj
     * @param  {} domain
     */
    private render(obj, domain){ 
        let str = domain;
        Object.keys(obj).forEach((el, i)=>{
            if(obj[el]) str += ' ' + el;  
        })
        return str;
    }

 
  
}

