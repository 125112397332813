import { JoinFaceIdComponent } from './components/join-face-id/join-face-id.component';
import { LoginComponent } from './components/login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordOtpComponent } from './components/forgot-password/password-otp/password-otp.component';
import { PasswordEmailComponent } from './components/forgot-password/password-email/password-email.component';
import { JoinFingerIdComponent } from './components/join-finger-id/join-finger-id.component';
import { AuthSuccessfullyCompletedComponent } from './components/auth-successfully-completed/auth-successfully-completed.component';

const routes:Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'jointouchid',
    component: JoinFingerIdComponent
  },
  {
    path: 'joinfaceid',
    component: JoinFaceIdComponent
  },
  {
    path: 'authsuccessfullycompleted',
    component: AuthSuccessfullyCompletedComponent
  },
  {
    path: 'passwordemail',
    component: PasswordEmailComponent
  },
  {
    path: 'passwordotp',
    component: PasswordOtpComponent
  },
  // {
  //   path: '**',
  //   component: IntroComponent
  // }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
