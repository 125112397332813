import { Subject } from 'rxjs/Subject';
import { NavManagerService } from './../../robin-navbar/robin-navbar.service';
import { map } from 'rxjs/operators';
import { selectToken, selectRegisterState, selectAnonymous, selectAuthenticated } from './../../../../store/app.states';
import { Store } from '@ngrx/store';
import { FadeAnimation } from './../../../animations/fade.animation';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { RobinSideNavService } from './robin-side-nav.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as sideNav from './side-nav.modal';
import { AppState } from '../../../../store/app.states';
import { BehaviorSubject } from 'rxjs';
import { environment } from './../../../../../environments/environment'
import { AccountService } from 'src/app/account/account.service';
import { Router } from '@angular/router';
import { CordovaService } from 'src/app/core/services/cordova.service';
@Component({
  selector: 'robin-side-nav',
  templateUrl: './robin-side-nav.component.html',
  styleUrls: ['./robin-side-nav.component.scss'],
  animations: [

    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1, backgroundColor: 'rgba(1, 1, 1, 0.3)' })),
      state('out', style({
        opacity: 0,
        backgroundColor: 'rgba(1, 1, 1, 0)',

      })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(0)
      ]),
      transition(':leave',
        animate(0, style({ opacity: 0 }))),

      transition('in => out', animate('100ms ease-in-out')),
      transition('out => in', animate('100ms ease-in-out'))]),
  ]


})
export class RobinSideNavComponent implements OnInit {
  @Input() id: string;
  private element: any;
  // @Input() isShow;
  // @Output() closeModal = new EventEmitter<any>();
  sideNav = sideNav.sideNav;
  show;
  loggedIn = false;
  enableLogout = true;
  version;
  user;
  userAuthenticated;
  risklevelWords;
  // logoutSubject = new BehaviorSubject(false);
  // logoutSubject$ = this.logoutSubject.asObservable();

  constructor(private store: Store<AppState>,
    private sideNavService: RobinSideNavService,
    private navbarService: NavManagerService,
    public accountService: AccountService,
    private router: Router,
    private cordovaservice: CordovaService) {

    this.navbarService.displayLogout$.subscribe((data: boolean) => this.enableLogout = data);
    this.sideNavService.currentState$.subscribe(state => {
      this.show = state ? 'in' : 'out';
    });
    this.store.select(selectToken).pipe(map(data => data.token)).subscribe(data => {
      this.loggedIn = data ? true : false;

    });
    this.store.select(selectAnonymous).subscribe((data: any) => {
      this.user = data.anonymous;
      console.log(this.user, 'this.user');
    });
    this.store.select(selectAuthenticated).pipe(map(data => data.authenticated)).subscribe(data => {
      this.userAuthenticated = data;
      if (this.userAuthenticated)
        this.risklevelWords = this.convertRiskLevelToWords(this.userAuthenticated.RiskLevel)
      console.log(this.userAuthenticated, 'this.userAuthenticated');

    });
    if (this.accountService.authenticatedModel) {
      this.risklevelWords = this.convertRiskLevelToWords(this.userAuthenticated.RiskLevel)
    }
    // this.userAuthenticated = this.accountService.authenticatedModel;
    // if (this.userAuthenticated)
    //   this.risklevelWords = this.convertRiskLevelToWords(this.userAuthenticated.RiskLevel)

    this.version = environment.version;
  }
  openClass = 'container animated slideInRight';

  ngOnInit() {
    this.changeClass();
  }

  changeClass() {
    this.openClass = this.show ? 'container animated slideInRight' : 'container animated slideOutRight';

    this.show ? setTimeout(() => { document.body.classList.add('disableOverflow'); window.scrollTo(0, 0); }, 10) : setTimeout(() => document.body.classList.remove('disableOverflow'), 10);
  }
  close() {
    // this.show = 'out';
    this.show ? setTimeout(() => { document.body.classList.add('disableOverflow'); window.scrollTo(0, 0); }, 10) : setTimeout(() => document.body.classList.remove('disableOverflow'), 10);
    this.sideNavService.currentState.next(false);
  }

  goToPersonalProfile() {
    console.log('personal-profile');
    this.router.navigate(['/personalprofile']);
  }

  convertRiskLevelToWords(risklevel) {
    switch (risklevel) {
      case 1:
        return 'שמרן'
      case 2:
        return 'זהיר'
      case 3:
        return 'מאוזן'
      case 4:
        return 'צמיחה'
      case 5:
        return 'מקצוען'
      default: 
        return 'לא הוגדר'
    }
  }
  openTermOfUse() {
    if (this.cordovaservice.platform === 'Android') {
      window.open('https://www.robinhoodpro.com/term-of-use/termsofuse-heb', '_system', 'location=no');
    } else {
      window.open('https://www.robinhoodpro.com/term-of-use/termsofuse-heb', '_system', 'location=yes');
    }
  }

  showPwaItem(item) {
    if (item === true) {
      return true;
    } else {
      if (this.cordovaservice.isMobile) {
        return true;
      } else {
        return false;
      }
    }
  }

  navigateTermOfUse() {
    if (this.cordovaservice.platform === 'Android') {
      window.open('https://www.robinhoodpro.com/term-of-use/termsofuse-heb', '_system', 'location=no');
    } else {
      window.open('https://www.robinhoodpro.com/term-of-use/termsofuse-heb', '_system', 'location=yes');
    }
  }

  navigatePrivacy() {
    if (this.cordovaservice.platform === 'Android') {
      window.open('https://www.robinhoodpro.com/term-of-use/privacy-heb', '_system', 'location=no');
    } else {
      window.open('https://www.robinhoodpro.com/term-of-use/privacy-heb', '_system', 'location=yes');
    }
  }


}
