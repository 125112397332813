import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  menoraShort = [{
    id: 1,
    question: 'מעשן/ת ',
    answers: ['כן', 'לא'],
    type: 'bool',
    yesNext: 4,
    noNext: 2,
    // bullets: ['מערכת העצבים והמוח', 'נפש', ' דרכי נשימה, ריאות', 'עור', 'לחץ דם מערכת הלב וכלי הדם (לרבות יתר לחץ דם ב-10 השנים האחרונות)', 'מערכת העיכול'],
    // hasExtension: true
  }, {
    id: 2,
    answers: ['כן','לא'],
    question: 'עישנתי בשנתיים האחרונות',
    type: 'bool',
    yesNext: 4,
    noNext: 3,
    isExtension: true
  },
  {
    id: 3,
    answers: ['כן', 'לא'],
    question: 'לא מעשן ב5 שנים האחרונות?',
    type: 'bool',
    // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית', 'מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
    yesNext: 4,
    noNext: 6,
    isExtension: true
  },
  {
    id: 4,
    question: 'סיגריות/אחר',
    type: 'textarea',
    // answers: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית', 'מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
    yesNext: 5,
    noNext: 5,
    isExtension: true
  },
  {
    id: 5,
    question: '5/10/15/20/30/40/50',
    type: 'textarea',
    // bullets: ['מערכת השלד והשרירים', ' מערכת הראיה', 'אף, אוזן, גרון', ' מערכת המין והרביה ', ' מחלות ראומטיות ', 'הנך צורך/צרכת סמים כעת או בעבר'],
    // answers: ['כן', 'לא'],
    yesNext: 6,
    noNext: 6,
    isExtension: true
  },
  {
    id: 6,
    question: 'נכות אחוזי נכות ומום מולד: האם נקבעה לך נכות (כיום או בעבר) בגין: מום מולד או נרכש, פציעה, סיבה רפואית?',
    type: 'bool',
    answers:['כן','לא'],
    yesNext: 7,
    noNext: 7,
  },
  {
    id: 7,
    question: 'אשפוזים: האם אושפזת ב-5 שנים האחרונות או שידוע לך על צורך באשפוז או בדיקה פולשנית? (למעט אשפוז למטרת לידה)',
    type: 'bool',
    answers: ['כן', 'לא'],
    yesNext: 8,
    noNext: 8,
  },
  {
    id: 8,
    question: 'האם הנך נוטל תרופות באופן קבוע בשל מחלה',
    type: 'bool',
    answers: ['כן', 'לא'],
    yesNext: 9,
    noNext: 11,
  },
  {
    id: 9,
    question: 'שם התרופה',
    type: 'textarea',
    // answers: ['כן', 'לא'],
    yesNext: 10,
    noNext: 10,
    isExtension: true
  },
  {
    id: 10,
    question: 'סיבה לנטילת התרופה',
    type: 'textarea',
    answers: ['כן', 'לא'],
    yesNext: 11,
    noNext: 11,
    isExtension: true
  },
  {
    id: 11,
    question: 'האם אובחנת במהלך חייך במחלות/ הפרעות/ ו/או בבעיות הרפואיות הנל: ',
    type: 'bool',
    bullets:['לב, כלי דם ויתר לחץ דם','סוכרת (כולל סוכרת הריון)','גידולים כולל שפירים, פוליפים, סרטן, מחלות ממאירות וטרום ממאירות','מחלת איידס/ נשא (HIV)'],
    answers: ['כן', 'לא'],
  },
  ];
  clalShort = [{
    id: 1,
    question: 'האם אובחנה אצלך מחלה ו/או הפרעה ו/או מום, אחד או יותר הקשורים ב:',
    answers: ['כן', 'לא'],
    type: 'bool',
    yesNext: 2,
    noNext: 3,
    bullets: ['מערכת העצבים והמוח', 'נפש', ' דרכי נשימה, ריאות', 'עור','לחץ דם מערכת הלב וכלי הדם (לרבות יתר לחץ דם ב-10 השנים האחרונות)','מערכת העיכול'],
    // hasExtension: true
  }, {
    id: 2,
    answers: ['מערכת העצבים והמוח', 'נפש', ' דרכי נשימה, ריאות', 'עור','לחץ דם מערכת הלב וכלי הדם (לרבות יתר לחץ דם ב-10 השנים האחרונות)','מערכת העיכול'],
    question: 'יש לסמן את הבעיה הרלוונטית: ',
    type: 'checkbox',
    yesNext: 3,
    noNext: 3,
    isExtension: true
  },
  {
    id: 3,
    answers: ['כן', 'לא'],
    question: 'האם אובחנה אצלך מחלה ו/או הפרעה ו/או מום, אחד או יותר הקשורים ב:',
    type: 'bool',
    bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
    yesNext: 4,
    noNext: 5,
  },
  {
    id: 4,
    question: 'יש לסמן את הבעיה הרלוונטית: ',
    type: 'checkbox',
    answers: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
    yesNext: 5,
    noNext: 5,
    isExtension: true
  },
  {
    id: 5,
    question: 'האם אובחנה אצלך מחלה ו/או הפרעה ו/או מום, אחד או יותר הקשורים ב:',
    type: 'bool',
    bullets:['מערכת השלד והשרירים',' מערכת הראיה','אף, אוזן, גרון',' מערכת המין והרביה ',' מחלות ראומטיות ','הנך צורך/צרכת סמים כעת או בעבר'],
    answers: ['כן', 'לא'],
    yesNext: 6,
    noNext: 7,
  },
  {
    id: 6,
    question: 'יש לסמן את הבעיה הרלוונטית: ',
    type: 'checkbox',
    answers:['מערכת השלד והשרירים',' מערכת הראיה','אף, אוזן, גרון',' מערכת המין והרביה ',' מחלות ראומטיות ','הנך צורך/צרכת סמים כעת או בעבר'],
    yesNext: 7,
    noNext: 7,
    isExtension: true
  },
  {
    id: 7,
    question: 'עברת או הומלץ לך לעבור גמילה מאלכוהול? ',
    type: 'bool',
    answers: ['כן', 'לא'],
    yesNext: 8,
    noNext: 8,
  },
  {
    id: 8,
    question: 'האם ב-5 השנים האחרונות אושפזת ו/או נותחת ו/או עברת בדיקה פולשנית ו/או בדיקת הדמיה ו/או בדיקות מעבדה עם ממצאים חריגים?',
    type: 'bool',
    answers: ['כן', 'לא'],
    yesNext: 9,
    noNext: 9,
  },
  {
    id: 9,
    question: 'האם קיימת נכות כלשהי ו/או האם הנך נמצא בתהליך לקבלת נכות? ',
    type: 'bool',
    answers: ['כן', 'לא'],
    yesNext: 10,
    noNext: 10,
  },
  {
    id: 10,
    question: 'האם הנך נוטל תרופות באופן קבוע?',
    type: 'bool',
    answers: ['כן', 'לא'],
    yesNext: 11,
    noNext: 12,
  },
  {
    id: 11,
    question: 'שם התרופה והסיבה לנטילתה?',
    type: 'textarea',
    answers: ['כן', 'לא'],
    yesNext: 12,
    noNext: 12,
    isExtension: true
  },
  {
    id: 12,
    question: 'האם אתה מועמד לעבור או קיימת המלצה לעבור ניתוח ו/או בדיקה פולשנית ו/או בדיקת הדמיה? ',
    type: 'bool',
    answers: ['כן', 'לא'],
    yesNext: 13,
    // noNext: 13,
  },
  {
    id: 13,
    question: 'פירוט:',
    type: 'textarea',
    answers: ['כן', 'לא'],
    isExtension: true
    
  },
  ]
menoraLong = [
    {
      id: 1,
      answers: ['כן', 'לא'],
      question: 'מעשן/ת?',
      type: 'bool',
      // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
      yesNext: 2,
      noNext: 3,
    },
    {
      id: 2,
      answers: ['כן', 'לא'],
      question: 'מספר סיגריות ליום',
      type: 'bool',
      // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
      yesNext: 3,
      noNext: 3,
      isExtension: true,
    },
    {
      id: 3,
      answers: ['כן', 'לא'],
      question: 'עברת או הומלץ לך לעבור גמילה מאלכוהול',
      type: 'bool',
      // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
      yesNext: 4,
      noNext: 4,
    },
    {
      id: 4,
      answers: ['כן', 'לא'],
      question: 'האם, ככל שידוע לך, קיימת מחלה ו/או הפרעה אצל קרובי משפחה מדרגה ראשונה, הקשורים ללב,סרטן,כלי דם, כליות,סוכרת, מערכת העצבים, הפרעה תורשתית',
      type: 'bool',
      // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
      yesNext: 5,
      noNext: 5,
    },
    {
      id: 5,
      answers: ['כן', 'לא'],
      question: 'האם צרכת סמים כעת או בעבר',
      type: 'bool',
      // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
      yesNext: 6,
      noNext: 6,
    },
    {
      id: 6,
      answers: ['כן', 'לא'],
      question: 'אם אובחנת עם אחת או יותר מהמחלות/הפרעות/מומים או ביצוע הליך רפואי כלהלן: ',
      type: 'bool',
      bullets: ['במערכת העצבים והמוח - לרבות אפילפסיה, כאבי ראש חריפים, מיגרנה, אירוע מוחי כלשהו, גידול מוחי, אדנומה, סחרחורות, התעלפויות, ניוון שרירים, טרשת נפוצה, פרקינסון, פימר, אוטיזם, פוליו, הפרעות קשב וריכוז, הפרעה ו/או עיכוב התפתחותי', `בנפש - לרבות דיכאון, חרדה, מצב רוח (ציקלותמיה), סכיזופרניה, מחלה דו קוטבית, תסמונת פוסט טראומטית 
    (PTSD)`],
      // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
      yesNext: 7,
      noNext: 7,
    },
    {
      id: 7,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת עם אחת או יותר מהמחלות/הפרעות/מומים או ביצוע הליך רפואי כלהלן: ',
      type: 'bool',
      bullets: ['בדרכי הנשימה, ריאות - לרבות אסטמה, מחלת ריאות חסימתית (COPD), דלקות ריאה ו/או ברוכניטיס חוזרות, סרקואידוזיס, דום נשימה בשינה. ', 'בעור - פסוריאזיס, פמפיגוס, אקזמה, נגע ו/או גידול בעור '],
      yesNext: 8,
      noNext: 8,
    },
    {
      id: 8,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת עם אחת או יותר מהמחלות/הפרעות/מומים או ביצוע הליך רפואי כלהלן:',
      type: 'bool',
      bullets: [`בלחץ דם מערכת הלב וכלי הדם - לרבות יתר לחץ דם ב-10 השנים האחרונות, כאבי חזה, התקף לב, הפרעת קצב ו/או הולכה, איושה, הפרעה במסתמים, מום, צינתור, טרומבוזה, מפרצת, ורידים מורחבים, מחלת כלי דם היקפית (PVD) `, `במערכת העיכול - לרבות להחזר ושטי, קרוהן, קוליטיס, פרוקטיטיס, טחורים, פיסורה, פיסטולה, אבסס, ניתוח לקיצור קיבה `],
      yesNext: 9,
      noNext: 9,
    },
    {
      id: 9,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת עם אחת או יותר מהמחלות/הפרעות/מומים או ביצוע הליך רפואי כלהלן: ',
      type: 'bool',
      bullets: [`בקע/הרניה - לרבות מפשעתי, בטני, טבורי,סרעפתי, ו/או בצלקת ניתוחית `, `בכבד, במרה, בלבלב - לרבות הפטיטיס, כבד מוגדל, כבד שומני, שחמת (צירוזיס), אבנים בכיס ו/או דרכי מרה, דלקת בלבלב (פנקריאטיטיס) `, `בכליות בדרכי השתן וערמונית - לרבות כליה קטנה ו/או חסרה, אבנים, ריפלוקס דרכי שתן, צניחת שלפוחית השתן,דלקות, דם ו/או חלבון בשתן, אי ספיקה, כליה פוליציסטית, ערמונית מוגדלת`],
      yesNext: 10,
      noNext: 10,
    },
    {
      id: 10,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת עם אחת או יותר מהמחלות/הפרעות/מומים או ביצוע הליך רפואי כלהלן',
      type: 'bool',
      bullets: [`מחלה מטבולית, הורמונלית (אנדוקרינולוגיה) - לרבות סוכרת, שומנים בדם, שיגדון (גאוט), FMF, גושה, בלוטת התריס, פרולקטינומה, הפרעות גדילה, הזעת יתר `, `בדם, בטחול, במערכת החיסון - לרבות ערכים חריגים בספירת דם, אנמיה, הפרעה בקרישת דם, טחול מוגדל, הפרעה במערכת החיסון `, `מחלות זיהומיות, מחלות מין - לרבות איידס (גם נשאות), שחפת `, `מחלות ממאירות, גידולים ממאירים - לרבות גידול סרטני ו/או טרום סרטני `],
      yesNext: 11,
      noNext: 11,
    },
    {
      id: 11,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת עם אחת או יותר מהמחלות/הפרעות/מומים או ביצוע הליך רפואי כלהלן:',
      type: 'bool',
      bullets: [`במערכת השלד והשרירים - לרבות שבר, פריקה, פגיעה בגידים/רצועות, כאבי גב, בעיה בחוליות, בכטרב, בריחת סידן (אוסטאופרוזיס/ אוסטאופניה), פרטס`, `במערכת הראיה - לרבות כבדות ראיה, הפרדות רשתית, גלאוקומה, קטרקט, משקפיים מעל מספר 7, אובאיטיס, קרטוקונוס `, `אף, אוזן, גרון - פגיעה בשמיעה, מנייר, טינטון, סינוזיטיס חוזרת, סטיית מחיצת האף, פוליפים, שקדים, דלקות אוזניים חוזרות, פגיעה כלשהי במיתרי הקול, אילמות`],
      yesNext: 12,
      noNext: 12,
    },
    {
      id: 12,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת עם אחת או יותר מהמחלות/הפרעות/מומים או ביצוע הליך רפואי כלהלן: ',
      type: 'bool',
      bullets: [`במערכת המין והרביה - לרבות גוש בשד, הגדלת שדיים, דימומים בלא קשר למחזור, כעת בהיריון, ניתוח קיסרי, רחם שרירני, צניחת הרחם ו/או נרתיק, בעיות פריון (גם אצל גברים), קונדילומה, אשך טמיר, וריקוצלה, הידרוצלה, היפוספדיאס`, `מחלות ראומטיות - (מפרקים) לרבות דלקת מפרקים ניוונית (אוסטאואטרוזיס), דלקת מפרקים שיגרונית (ראומטיד ארטריטיס), לופוס (זאבת), פיברומיאלגיה`],
      yesNext: 13,
      noNext: 13,
    },
    {
      id: 13,
      answers: ['כן', 'לא'],
      question: 'האם הנך נוטל תרופות באופן קבוע ',
      type: 'bool',
      yesNext: 14,
      noNext: 15,
    },
    {
      id: 14,
      question: 'פירוט שם התרופה והסיבה לנטילתה: ',
      type: 'textarea',
      yesNext: 15,
      noNext: 15,
      isExtension: true
    },
    {
      id: 15,
      answers: ['כן', 'לא'],
      question: 'האם אתה מועמד לעבור או קיימת המלצה לעבור ניתוח ו/או בדיקה פולשנית ו/או בדיקת הדמיה',
      type: 'bool',
      yesNext: 16,
      noNext: 17,
    },
    {
      id: 16,
      question: 'פירוט:',
      type: 'textarea',
      yesNext: 17,
      noNext: 17,
      isExtension: true
    },
    {
      id: 17,
      answers: ['כן', 'לא'],
      question: 'האם ב-5 השנים האחרונות אושפזת ו/או נותחת ו/או עברת בדיקה פולשנית ו/או הדמיה ו/או בדיקות מעבדה עם ממצאים חריגים',
      type: 'bool',
      yesNext: 18,
      noNext: 18,
    },
    {
      id: 18,
      answers: ['כן', 'לא'],
      question: 'האם קיימת נכות כלשהי ו/או האם הנך נמצא בתהליך לקבלת נכות ',
      type: 'bool',
      yesNext: 19,
      noNext: 19,
    },
    {
      id: 19,
      answers: ['כן', 'לא'],
      question: 'האם הומלץ על אשפוז סיעודי ו/או ניתן לך טיפול סיעודי בבית ו/או במוסד מקבל ו/או קיבלת גמלת סיעוד, משתמש בעזרי ניידות כדי ללכת, שימוש בקטטר לשתן/מעיים/קיבה או אחר, אי שליטה על סוגרים, נפילות חוזרות, מוגבל בביצוע אחת מהפעולות הבאות: לאכול, לשתות, להתרחץ, להתגלח, להתלבש, להתפשט, לקום, לשכב.  ',
      type: 'bool',
    },
  ];
  meenoraLong = [
  {
    id: 1,
    answers: ['כן', 'לא'],
    question: 'מעשן/ת?',
    type: 'bool',
    // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
    yesNext: 4,
    noNext: 2,
  },
  {
    id: 2,
    answers: ['כן', 'לא'],
    question: 'עישנתי בשנתיים האחרונות',
    type: 'bool',
    // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
    yesNext: 4,
    noNext: 3,
    isExtension: true,
  },
  {
    id: 3,
    answers: ['כן', 'לא'],
    question: 'לא מעשן ב5 שנים האחרונות?',
    type: 'bool',
    isExtension: true,
    // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
    yesNext: 4,
    noNext: 6,
  },
  {
    id: 4,
    // answers: ['כן', 'לא'],
    question: 'סיגריות/אחר',
    type: 'textarea',
    // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
    yesNext: 5,
    noNext: 5,
    isExtension: true,
  },
  {
    id: 5,
    // answers: ['כן', 'לא'],
    question: '5/10/15/20/30/40/50',
    type: 'textarea',
    // bullets: ['כבד, במרה, בלבלב', 'כליות בדרכי השתן והערמונית','מחלה מטבולית (חילוף חומרים לרבות סכרת ו/או במערכת האנדוקרינולוגית (בלוטות) ', 'דם, טחול, מערכת החיסון', ' מחלות זיהומיות, מחלות מין (לרבות איידס) ', 'מחלות ממאירות, גידולים ממאירים (לרבות גידול סרטני ו/או טרום סרטני)'],
    yesNext: 6,
    noNext: 6,
    isExtension: true,
  },
  {
    id: 6,
    answers: ['כן', 'לא'],
    question: 'אלכוהול: צריכת אלכוהול כעת ו/או בעבר יותר מכוס ביום של בירה/ יין או משקה אלכוהולי אחר',
    type: 'bool',
    yesNext: 7,
    noNext: 7,
  },
  {
    id: 7,
    answers: ['כן', 'לא'],
    question: 'סמים: האם צרכת סמים בהווה או בעבר?',
    type: 'bool',
    yesNext: 8,
    noNext: 8,
  },
  {
    id: 8,
    answers: ['כן', 'לא'],
    question: 'היסטוריה משפחתית: האם למיטב ידיעתך, בקרב אחד מקרובייך (אב/אם אחים/ אחיות) אובחנו המחלות הבאות: מחלות לב, שבץ מוחי, סרטן המעי הגס, סרטן שחלות, סרטן שד, סוכרת, כליות פוליציסטיות, טרשת נפוצה, ניוון שרירים, מחלת הנטינגון, מחלות נפשיות, מחלת פרקינסון, מחלת אלצהיימר',
    type: 'bool',
    yesNext: 9,
    noNext: 9,
  },
  {
    id: 9,
    answers: ['כן', 'לא'],
    question: 'נכות אחוזי נכות ומום מולד: האם נקבעה לך נכות (כיום או בעבר) בגין: מום מולד או נרכש, פציעה, סיבה רפואית? האם הנך נמצא/ת בתהליך לקביעת אחוזי נכות ו/או קיימת נכות מביטוח לאומי/ ממשרד הביטחון ו/או נמצא/ת באי כושר עבודה כעת',
    type: 'bool',
    yesNext: 10,
    noNext: 10,
  },
  {
    id: 10,
    answers: ['כן', 'לא'],
    question: 'בדיקות אבחנתיות: האם ב-10 שנים האחרונות עברת או הומלת לך לבצע אחת או יותר מן הבדיקות הבאות: צינטור, מיפוי, אקו-לב, CT, ביופסה דם סמוי?',
    type: 'bool',
    yesNext: 11,
    noNext: 11,
  },
  {
    id: 11,
    answers: ['כן', 'לא'],
    question: 'אשפוזים: האם אושפזת ב-5שנים האחרונות או שידוע לך על צורך באשפוז או בדיקה פולשנית (למעט אשפוז למטרת לידה)',
    type: 'bool',
    yesNext: 12,
    noNext: 12,
  },
  {
    id: 12,
    answers: ['כן', 'לא'],
    question: 'ניתוחים: האם ב-10 שנים האחרונות עברת או הומלץ לך לעבור ניתוח?',
    type: 'bool',
    yesNext: 13,
    noNext: 13,
  },
  {
    id: 13,
    answers: ['כן', 'לא'],
    question: 'תרופות: האם הנך נוטל תרופות באופן קבוע בשל מחלה כרונית',
    type: 'bool',
    yesNext: 14,
    noNext: 16,
  },
  {
    id: 14,
    question: 'שם התרופה:',
    type: 'textarea',
    yesNext: 15,
    noNext: 15,
    isExtension: true
  },
  {
    id: 15,
    answers: ['כן', 'לא'],
    question: 'סיבה לנטילת התרופה:',
    type: 'textarea',
    yesNext: 16,
    isExtension: true,
    noNext: 16,
  },
  {
    id: 16,
    question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בבעיות הרשומות הנל',
    type: 'bool',
    answers: ['כן', 'לא'],
    bullets:['מערכת העצבים והמוח - לרבות טרשת נפוצה, תסמונת דאון, אוטיזם, גושה, ניוון שרירים, אפילפסיה, פרקינסון ושיתוק, האם פנית לרופא בתלונות הקשורות לירידה בזכרון ב-3 השנים האחרונות?','מערכת הלב וכלי דם - לרבות לב, כלי דם, אירוע מוחי (לרבות אירוע מוחי חולף), יתר לחץ דם ב-10 שנים האחרונות ומערכת כלי דם היקפית (פריפריאלי)'],
    yesNext: 17,
    noNext: 17,
  },
  {
    id: 17,
    answers: ['כן', 'לא'],
    question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בבעיות הרשומות הנל',
    type: 'bool',
    bullets:['בעיה, הפרעה ו/או מחלה נפשית מאובחנת ונסיונות התאבדות','מערכת דם - לרבות מחלות דם ובעיות קרישה ','מערכת מוטובולית - לרבות סוכרת (כולל סוכרת הריון) שומנים/ כולסטרול/ טריגליצרידים בדם מחלות הקשורות בבלוטות: בלוטת המגן, בלוטת יותרת המוח, בלוטת יתרת הכליה, בלוטת הפאראתירואיד'],
    yesNext: 18,
    noNext: 18,
  },
  {
    id: 18,
    answers: ['כן', 'לא'],
    question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בבעיות הרשומות הנל',
    type: 'bool',
    bullets:['מחלה ממארת, גידולים לרבות גידול שפיר, סרטני וטרום סרטני','מערכת העיכול - לרבות קיבה, מעיים, קרוהן, רוליטיס, כיס המרה, הטחול, הלבלב, פי הטבעת','מחלת כבד, צהבת, הפרעות בתפקוד כבד','מערכת כליות ודרכי השתן- לרבות אי ספיקת כליות, כליה פוליציסטית, מום בכליה, אבנים, רפלוקס, דרכי שתן, דלקות, דם ו/או חלבון בשתן '],
    yesNext: 19,
    noNext: 19,
  },
  {
    id: 19,
    answers: ['כן', 'לא'],
    question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בבעיות הרשומות הנל',
    bullets:['מערכת ריאות ודרכי נשימה - לרבות ריאות, אסטמה, ברונכיטיס, COPD (מחלת ריאות חסימתית), אמפיזמה, סרקואידוזיס, דום נשימה בשינה, סיסטיק פיברוזיס, דלקת ריאות חוזרות, שחפת ואלרגיות','מחלות ראומטולוגיות, רקמות חיבור ודלקות פרקים, לרבות גאוט, לופוס/זאבת, פיברומיאלגיה וקדחת ים תיכונית (FMF), תסמונת תשישות כרונית, סקלרודרמה '],
    type: 'bool',
    yesNext:20,
    noNext:20,
  },
  {
    id: 20,
    answers: ['כן', 'לא'],
    question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בבעיות הרשומות הנל',
    bullets:['אורטופדיה ומערכות השלד - לרבות גב, עמוד שדרה,שרירים, מרפקיםמחלות פרקים, בעיות ברכיים, שבר, פריקה, פגיעה בגידים/רצועות, גב, בכטרב, ירידה בצפיפות העצם (אוסטאופורוזיס/אוסטיאופניה), פרטס, מחלת פרקים ניוונית (אוסטאוארטרוזיס)','מערכת עיניים וראייה - לרבות בעיות הקשורות לעיניים, ליקוי ראיה מעל מספר 8 בעדשות, הפרדה רשתית, גלאוקומה (לחץ תוך עיני) קטרקט, אובאיטיס, קרטקונוס'],
    type: 'bool',
    yesNext:21,
    noNext:21,
  },
  {
    id: 21,
    answers: ['כן', 'לא'],
    question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בבעיות הרשומות הנל',
    bullets:['מערכת אף אוזן גרון - לרבות פגיעה בשמיעה, דלקות אזניים חוזרות, מנייר, טינטון, סינוזיטיס חוזרת, סטיית מחיצת אף, פוליפים, שקדים, פגיעה במיתרי הקול, גרון וחלל הפה','עור ומין - לרבות מחלות עור, בעיות עור ואלרגיה, פסוריאזיס, פמפיגוס, צלקת, נגע ו/או גידול בעור, מחלת עור דלקתית, פפילומה/ קונדילומה'],
    type: 'bool',
    yesNext:22,
    noNext:22,
  },
  {
    id: 22,
    answers: ['כן', 'לא'],
    question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בבעיות הרשומות הנל',
    bullets:['מחלות זיהומית, לרבות מחלות אוטואימוניות, פוליו, מחלות מין ואיידס/ נשא HIV ','לנשים: בעיות גניקולוגיות ושדיים, הגדלה/הקטנת שדיים, גוש בשד, דימומים (ללא קשר לווסת), רחם שרירני, אנדומטריוזיס, ציסטות שחלתיות, צניחת רחם ו/או הנרתיק, בעיות פיריון.'],
    type: 'bool',
  },
  ];
  constructor() {}
 
  ngOnInit() { }

}
