import { AfterViewInit, OnDestroy } from '@angular/core';
import { CordovaService } from './../../../core/services/cordova.service';
import { style, trigger, transition, animate, state, useAnimation } from '@angular/animations';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, HostListener } from '@angular/core';
import { RobinBottomDrawerService } from './service/robin-bottom-drawer.service';
//import { RobinBottomDrawerService } from './service/robin-bottom-drawer.service';
import { slideInUp, slideInDown } from 'ng-animate';
import { AccountService } from '../../../account/account.service';

declare const window;

@Component({
  selector: 'robin-bottom-drawer',
  templateUrl: './robin-bottom-drawer.component.html',
  styleUrls: ['./robin-bottom-drawer.component.scss'],
  // animations: [trigger('slideInDown', [transition('* => *', useAnimation(slideInDown, {
  //   params: { timing: 0.5 }
  // }))])]
})
export class RobinBottomDrawerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() show;
  _height = 0;

  @Output() closeModal = new EventEmitter<any>();
  private _enableClosing = true;
  @Input()
  set enableClosing(bool) {
    this._enableClosing = bool;
  }
  @Input() set height(hgt) {
    this._height = hgt;
  }
  constructor(private bottomDrawerService: RobinBottomDrawerService,
    private cordovaService: CordovaService,
    private accountService: AccountService) { }


  // @HostListener('click', ['$event'])
  // public onClick(targetElement) {
  //   console.log('i am here');
  //   // document.querySelector()
  //   let xIcon = document.getElementById('robinBtnDrawerDrawer');
  //   console.log('xIcon: ', xIcon);
  //   console.log(targetElement);
  //   if (targetElement.target === xIcon) {
  //     console.log('triggered!');
  //     this.close('');
  //   }
  // }

  ngOnInit() { }

  ngOnChanges() {
    if (this.show) {
      this.open();
    } else {
      this.close(false);
    }
  }

  //TODO: refactor bottom drawer service and handle that from here
  outside(e) {
    let maxOffset: any = document.querySelector('.drawer-content').getBoundingClientRect();
    if (this._enableClosing) {
      // if (e.clientY < maxOffset.y) {
      if (e.screenY < maxOffset.y) {
        this.close(e);
      }
    }
  }

  open() {
    this.bottomDrawerService.toggleModal(true, () => {
      setTimeout(() => {
        document.body.classList.add('disableOverflow');
        // document.body.classList.add('fixed');
        // window.scrollTo(0, 0);
      }, 700);

    })
  }


  close(e) {
    if (this.accountService.consultCancelled)
      this.accountService.consultCancelled = false;
    if (this.cordovaService.isMobile) {
      if (window.Keyboard) {
        window.Keyboard.hide();
      }
    }
    this.bottomDrawerService.toggleModal(false, () => {
      this.closeModal.emit(e);
      this.show = false;
      document.body.style.overflow = 'auto';
      setTimeout(() => {
        document.body.classList.remove('disableOverflow');
        document.body.classList.remove('fixed');
      }, 100);
    })
  }
  ngAfterViewInit(): void {
    // console.log('ngAfterViewInit');
    // document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    // console.log(`document.getElementsByTagName('html')[0].style.overflow `, document.getElementsByTagName('html')[0].style.overflow);

    // setTimeout(() => {
    //   let el: any = document.querySelectorAll('form input')[0];
    //   console.debug('this is the element => ', el);
    //   if (el) { console.debug('about to focus...'); el.focus(); }
    // }, 500);

    // document.querySelector('html').classList.add('hidden');
    // // setTimeout(() => document.activeElement.scrollIntoView(), 1100);
    // setTimeout(() => {
    //   document.activeElement.scrollIntoView();
    // }, 600);

  }

  ngOnDestroy(): void {
    document.getElementsByTagName('html')[0].style.overflow = '';


  }
}
