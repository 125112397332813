import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import {Router} from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  generatedProcessId:any = {}
  currentTab = new BehaviorSubject(0)
  currentTab$ = this.currentTab.asObservable()
  //risk module includes 8 pages 
  // cache: {1: any, 2:secondPage, 3:beneficiary[], 4:fourthPage, 5:any, 6: sixthPage, 7: seventhPage, 8: eighthPage} = {
    cache: any = {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
    }

    cachePension: any = {
      1: null,
      2: null,
      3: null,
      4: null,
      5: null,
      6: null,
      7: null,
      8: null,
      9: null,
      10: null,
    }

    cacheCritical: any = {
      1: null, // hmw
      2: null, // height & weight
      3: null, // children
      4: null, // health questionnaire
      5: null, // electronic signature
      6: null, // payment
    }
    constructor(private router: Router) { }
    
    clearCache() {
      // (this.router.routerState as any).snapshot = {};
      this.cache = {} ;
      setTimeout(() => (this.router.routeReuseStrategy as any).storedRoutes = {}, 0);
    }

    setCache(controls) {
      let cache = {}
      let keys = Object.keys(controls)
      let values = keys.map(key => controls[key].value)
      keys.map((el, ind) => cache[el] = values[ind])
      return cache
    }

  }



  interface firstPage {
    city: String;
    street: String;
    number: String;
    occ: Number;
    familyStatus: Number;
  }
  interface secondPage {
    height: Number;
    weight: Number;
  }
  interface thirdPage {
    beneficiaries: beneficiary[];
  }
  export interface beneficiary {
    FirstName: String;
    LastName: String;
    Id: String;
    BirthDate: String;
    Percentage: Number;
    Selected?: Boolean;
  }

  export interface child {
    FirstName: String;
    LastName: String;
    Id: String;
    BirthDate: String;
  }
  interface fourthPage {
    beneficiaries: precentageDevision[];
  }
  interface precentageDevision {
    id: String;
    precent: Number;
  }
  interface fifthPage {
    userAnswers: String[]
  }
  interface sixthPage {

  }
  interface seventhPage {
    signature: String;
  }
  interface eighthPage {
    cardHolder: String;
    id: String;
    cardNum: String;
    expDate: String;
    cvv: Number;
  }