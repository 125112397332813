import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MbscDatetimeOptions } from 'src/lib/mobiscroll/js/mobiscroll';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss']
})
export class ChildrenComponent implements OnInit {
  children = new Array(this.ciservice.numOfChildren);
  radioForm = new Array(this.ciservice.numOfChildren);
  hmo = new Array(this.ciservice.numOfChildren);
  objLeumit;
  objClalit;
  objMaccabi;
  objMeohedet;
  error = false;
  constructor(public ciservice: CriticalillnessService,
    private fb: FormBuilder,
    private cordovaService: CordovaService,
    private analyticService: AnalyticsService) {


    this.objClalit = [
      {
        text: 'כללית מושלם פלטינום',
        value: 1
      },
      {
        text: 'כללית מושלם זהב',
        value: 2
      },
      {
        text: 'לא קיים ברשותי ביטוח משלים',
        value: 3
      },
    ];
    this.objMaccabi = [
      {
        text: 'מכבי שלי',
        value: 4
      },
      {
        text: 'מכבי מגן זהב',
        value: 5
      },
      {
        text: 'מכבי מגן כסף',
        value: 6
      },
      {
        text: 'לא קיים ברשותי ביטוח משלים',
        value: 7
      },
    ];
    this.objMeohedet = [
      {
        text: 'מאוחדת עדיף',
        value: 8
      },
      {
        text: 'מאוחדת שיא',
        value: 9
      },
      {
        text: 'מאוחדת זהב',
        value: 10
      },
      {
        text: 'מאוחדת כסף',
        value: 11
      },
      {
        text: 'לא קיים ברשותי ביטוח משלים',
        value: 12
      },
    ];
    this.objLeumit = [
      {
        text: 'לאומית זהב',
        value: 13
      },
      {
        text: 'לאומית כסף',
        value: 14
      },
      {
        text: 'לא קיים ברשותי ביטוח משלים',
        value: 15
      },
    ];
    for (let i = 0; i < this.ciservice.numOfChildren; i++) {
      this.radioForm[i] = this.fb.group({
        radio: [],
      });
      this.children[i] = this.fb.group({
        FirstName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(/^[\sa-z\u0590-\u05fe]+$/i)]],
        LastName: ['', [Validators.required, Validators.minLength(2), Validators.pattern(/^[\sa-z\u0590-\u05fe]+$/i)]],
        Id: ['', [Validators.required]],
        Gender: ['', [Validators.required]],
        BirthDate: ['', [Validators.required]],
        Height: [''],
        Weight: ['']
      })
    }
  }

  @ViewChild('exp') exp: ElementRef;
  mobiScrollClick;

  options: MbscDatetimeOptions = {
    theme: 'robin-v2',
    lang: 'he',
    max: Date.now(),
    //min: Date.now(),
    dateWheels: 'yyyy mm dd',
    dateFormat: 'dd/mm/y'
  };

  gender(e) {

  }


  ngOnInit() {
    this.analyticService.trackByAppFlyer("DP - Mahalot Kashot  - Children", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }

  hmoRadio(e, i) {
    this.hmo[i] = e;
  }

  closeExp(event) {
    // close
    document.body.classList.remove('disableOverflow');
    this.mobiScrollClick = false;
    document.querySelectorAll('.birth')[0].classList.add('set-exp-date');
  }

  convertHmo(hmo) {
    switch (hmo) {
      case 1:
        return 'כללית מושלם פלטינום'
      case 2:
        return 'כללית מושלם זהב'
      case 3:
        return 'כללית'
      case 4:
        return 'מכבי שלי'
      case 5:
        return 'מכבי מגן זהב'
      case 6:
        return 'מכבי מגן כסף'
      case 7:
        return 'מכבי'
      case 8:
        return 'מאוחדת עדיף'
      case 9:
        return 'מאוחדת שיא'
      case 10:
        return 'מאוחדת זהב'
      case 11:
        return 'מאוחדת כסף'
      case 12:
        return 'מאוחדת'
      case 13:
        return 'לאומית זהב'
      case 14:
        return 'לאומית כסף'
      case 15:
        return 'לאומית'
    }
  }

  checkBetween1419(date) {
    if (date === null || date === "")
      return false;
    else {
      var today = new Date();
      var year = today.getFullYear();
      if (year - date.getFullYear() > 19 || year - date.getFullYear() < 14)
        return false;
      else {
        return true;
      }
    }
  }

  hideKeyboard() {
    this.cordovaService.closeKeyBoard();
  }

  next() {
    var valid;
    for (let j = 0; j < this.ciservice.numOfChildren; j++) {
      if (this.children[j].valid) {
      }
      else {
        this.error = true;
        return;
      }
      valid = true;
    }

    if (valid) {
      var finalArray = new Array(this.ciservice.numOfChildren);
      for (let i = 0; i < this.ciservice.numOfChildren; i++) {
        finalArray[i] = this.children[i].value;
        finalArray[i].Hmo = this.convertHmo(this.radioForm[i].value.radio);
      }
      this.ciservice.cacheCritical[3] = finalArray;
      this.ciservice.currentTab.next(4);  
    } else {
      this.error = true;
    }
  }
}
