import { Login } from '../../../../store/user.actions';
import { AppState } from '../../../../store/app.states';
import { Store } from '@ngrx/store';
import { CordovaService } from '../../../../core/services/cordova.service';
import { Router } from '@angular/router';
import { NavManagerService } from '../../../../shared/components/robin-navbar/robin-navbar.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { OtpService } from '../../../../onboarding/services/otp/otp.service';

@Component({
  selector: 'home-screen-header',
  templateUrl: './home-screen-header.component.html',
  styleUrls: ['./home-screen-header.component.scss']
})
export class HomeScreenHeaderComponent implements OnInit {
  private loginClicked = false;
  private loginFingerId = false;
  private pincode: number;
  icon: string = '';
  buttonText = 'כניסה לחשבון האישי';
  time;
  @Input() lastConnectionDate;
  @Input() name;
  @Input() title;
  @Input() subtitle;
  @Input() progressMislaka;
  featureSupported = null;
  @Output() clickedOn = new EventEmitter();
  @Input() action;
  @Input() actionText;
  @Input() percentage;

  constructor(private routeService: Router, 
              private cordovaService: CordovaService, 
              private otpService: OtpService, 
              private _nav: NavManagerService, 
              private store: Store<AppState>) {
  }

  ngOnInit() {
  }

  redirect(action) {
    // if (action=== 'howitworks') 
    //   this._nav.next(action);
    // else {
    //   this.clickedOn.emit(true);
    // }
    this.clickedOn.emit(true);

    // console.log('action', action)
    //document.body.style.overflow = 'hidden';
    // this.routeService.navigate(['/authentication']);
  }

  getNowTime() {
    var today = new Date();
    var hh = today.getHours();
    if (hh > 6 && hh< 12) 
      return 'בוקר טוב, ';
    else if (hh > 12 && hh < 17)
      return 'צהריים טובים, ';
    else if (hh > 17 && hh < 22)
      return 'ערב טוב, ';
    else (hh>22 || hh<6)
      return 'לילה טוב, '
  }
}
