import { Component, OnInit, Input } from '@angular/core';
import { dataValidator } from 'src/app/shared/services/validators';

@Component({
	selector: 'horizontal-scroller',
	templateUrl: './horizontal-scroller.component.html',
	styleUrls: ['./horizontal-scroller.component.scss']
})
export class HorizontalScrollerComponent implements OnInit {
	// _data = {0: 1, 1: 2, 2: 300, 3: 9999, 4: 0.7488915714533719, 5: 0.9275400087976609, 6: 0.9675546462924034, 7: 0.19086398655520975, 8: 0.5507060641369761, 9: 0.17034311207795727, 10: 0.7223495391867532, 11: 0.5423462510352741, 12: 0.7350710099878353, 13: 0.1979489346676533, 14: 0.10658938706981358, 15: 0.7051877516251104, 16: 0.06503650518461246, 17: 0.04818566537257851, 18: 0.16397717084640973, 19: 0.15063762816077908}
	_prefix = 'שנה';
	_parsedData = []
	@Input() data;
	@Input() set prefix(d){
		if(d)
			this._prefix = d
	}
	// @Input() set data(d){
	// 	if(d) {
	// 		this._data = d;
	// 	}
		
	// }
	constructor() { 
		// this._parsedData = Object.keys(this._data).map(el => Number(el) + 1)
	}

	ngOnInit() {
	}

}
