// import { RoutingCacheService } from './services/routing-cache.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeightAndWeightComponent } from './height-and-weight/height-and-weight.component';
import { HmoComponent } from './hmo/hmo.component';
import { ChildrenComponent } from './children/children.component';
import { HealthDeclarationComponent } from './health-declaration/health-declaration.component';
import { ElectronicSignatureComponent } from './electronic-signature/electronic-signature.component';
import { PaymentComponent } from './payment/payment.component';
import { IndicationComponent } from './health-declaration/indication/indication.component';
import { QuestioncardComponent } from './health-declaration/questioncard/questioncard.component';
import { QuestionnnaireComponent } from './health-declaration/questionnnaire/questionnnaire.component';
import { HorizontalScrollerComponent } from './payment/horizontal-scroller/horizontal-scroller.component';

// import { UpgradeProcessCriticalComponent } from './upgrade-process-critical/upgrade-process-critical.component';
// import { GeneralDetailsComponent } from './general-details/general-details.component';
// import { HeightAndWeightComponent } from './height-and-weight/height-and-weight.component';
// import { CitizenshipComponent } from './citizenship/citizenship.component';
// import { BusinessidComponent } from './businessid/businessid.component';
// import { InvestmentTrackComponent } from './investment-track/investment-track.component';
// import { ChildrenComponent } from './children/children.component';
// import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
// import { SingleBeneficiaryComponent } from './beneficiaries/single-beneficiary/single-beneficiary.component';
// import { AddNewBeneficiaryComponent } from './beneficiaries/add-new-beneficiary/add-new-beneficiary.component';
// import { BeneficiariesPercentagesComponent } from './beneficiaries-percentages/beneficiaries-percentages.component';
// import { PictuteidComponent } from './pictuteid/pictuteid.component';
// import { HealthDeclarationComponent } from './health-declaration/health-declaration.component';
// import { ElectronicSignatureComponent } from './electronic-signature/electronic-signature.component';
// import { AddNewBeneficiaryComponent } from './components/add-new-beneficiary/add-new-beneficiary.component';
// import { DistributionOfPercentagesComponent } from './components/distribution-of-percentages/distribution-of-percentages.component';
// import { HealthDeclarationComponent } from './components/health-declaration/health-declaration.component';
// import { ElectronicSignatureComponent } from './components/electronic-signature/electronic-signature.component';
// import { EndOfProcessPensionComponent } from './end-of-process/end-of-process.component';
// import { SingleChildComponent } from './children/single-child/single-child.component';
// import { AddNewChildComponent } from './children/add-new-child/add-new-child.component';
// import { EmployerDetailsComponent } from './employer-details/employer-details.component';
// import { InvestmentItemComponent } from './investment-track/investment-item/investment-item.component';
// import { InvestmentExplanationComponent } from './investment-explanation/investment-explanation.component';
// import { HorizontalScrollerComponent } from './components/add-beneficiaries/risk/components/horizontal-scroller/horizontal-scroller.component';

export const routes: Routes = [
  {
    path: 'hmo',
    component: HmoComponent,
    data: { title: 'קופת חולים' }
  },
  {
    path: 'heightandweight',
    component: HeightAndWeightComponent,
    data: { title: 'משקל וגובה' }
  },
  {
    path: 'children',
    component: ChildrenComponent,
    data: { title: 'ילדים' }
  },
  {
    path: 'healthquestionnnaire',
    component: HealthDeclarationComponent,
    data: { title: 'הצהרת בריאות' }
  },
    {
    path: 'electronicsignature',
    component: ElectronicSignatureComponent,
    data: { title: 'חתימה אלקטרונית' }
  },
  {
    path: 'payment',
    component: PaymentComponent,
    data: { title: 'תשלום' }
  },

  // {
  //   path: 'employerdetails',
  //   component: EmployerDetailsComponent,
  //   data: { title: 'פרטי מעסיק' }
  // },
  // {
  //   path: 'citizenship',
  //   component: CitizenshipComponent,
  //   data: { title: 'אזרחות' }
  // },
  // {
  //   path: 'businessid',
  //   component: BusinessidComponent,
  //   data: { title: 'צילום עסק מורשה' }
  // },
  // {
  //   path: 'investmenttrack',
  //   component: InvestmentTrackComponent,
  //   data: { title: 'מסלול השקעות' }
  // },
  // {
  //   path: 'children',
  //   component: ChildrenComponent,
  //   data: { title: 'ילדים' }
  // },
  // {
  //   path: 'beneficiaries',
  //   component: BeneficiariesComponent,
  //   data: { title: 'מינוי מוטבים' }
  // },
  // {
  //   path: 'addnewbeneficiary',
  //   component: AddNewBeneficiaryComponent
  // },
  // {
  //   path: 'beneficiariespercentages',
  //   component: BeneficiariesPercentagesComponent,
  //   data: { title: 'חלוקת אחוזים' }
  // },
  // {
  //   path: 'pictureid',
  //   component: PictuteidComponent,
  //   data: { title: 'צילום תעודת זהות' }
  // },
  // {
  //   path: 'healthquestionnnaire',
  //   component: HealthDeclarationComponent,
  //   data: { title: 'הצהרת בריאות' }
  // },
  // {
  //   path: 'electronicsignature',
  //   component: ElectronicSignatureComponent,
  //   data: { title: 'חתימה אלקטרונית' }
  // },
  // {
  //   path: 'addnewchild',
  //   component: AddNewChildComponent
  // },
  // // {
  // //   path: 'cancelpreviouspolicy',
  // //   component: CancelPrevPolicyComponent,
  // //   data: {title:'ביטול פוליסה קודמת'}
  // // },
  // // {
  // //   path: 'payment',
  // //   component: PaymentComponent,
  // //   data: {title:'תשלום'}
  // // },

  // //  {
  // //   path: 'success',
  // //   component: EndOfProcessComponent
  // // },

];

@NgModule({
  declarations: [
    // ScrollBarComponent,
    // UpgradeProductComponent,
    // PurchaseProductComponent,
    // GeneralDetailsComponent,
    // HeightAndWeightComponent,
    // CancelPrevPolicyComponent,
    // PaymentComponent,
    // AddBeneficiariesComponent,
    // SingleBeneficiaryComponent,
    // AddNewBeneficiaryComponent,
    // DistributionOfPercentagesComponent,
    // HealthDeclarationComponent,
    // ElectronicSignatureComponent,
    // HorizontalScrollerComponent
    // GeneralDetailsComponent,
    // HeightAndWeightComponent,
    // CitizenshipComponent,
    // BusinessidComponent,
    // InvestmentTrackComponent,
    // ChildrenComponent,
    // BeneficiariesComponent,
    // SingleBeneficiaryComponent,
    // AddNewBeneficiaryComponent,
    // BeneficiariesPercentagesComponent,
    // PictuteidComponent,
    // HealthDeclarationComponent,
    // ElectronicSignatureComponent,
    // SingleChildComponent,
    // AddNewChildComponent,
    // EmployerDetailsComponent,
    // InvestmentItemComponent,
    // InvestmentExplanationComponent,
    HmoComponent,
    HeightAndWeightComponent,
    ChildrenComponent,
    HealthDeclarationComponent,
    ElectronicSignatureComponent,
    PaymentComponent,
    IndicationComponent,
    QuestioncardComponent,
    QuestionnnaireComponent,
    HorizontalScrollerComponent
    // UpgradeProcessCriticalComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    SharedModule,
    // QuestionnaireModule,
    // SignaturePadModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    // ScrollBarComponent,
    // UpgradeProductComponent,
    // PurchaseProductComponent,
    // EndOfProcessComponent,
    // HorizontalScrollerComponent,
    // PaymentComponent,
    // ElectronicSignatureComponent
    
  ],
  providers: [
    // { provide: RouteReuseStrategy, useClass: RoutingCacheService },

  ]
})
export class CriticalupgradeModule { }

