import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bubble-step-item',
  templateUrl: './bubble-step-item.component.html',
  styleUrls: ['./bubble-step-item.component.scss']
})
export class BubbleStepItemComponent implements OnInit {

  @Input() options;
  @Input() isLast;
  @Input() active;

  constructor() { }

  ngOnInit() {
  }

}
