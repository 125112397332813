import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-end-of-process',
  templateUrl: './end-of-process.component.html',
  styleUrls: ['./end-of-process.component.scss']
})
export class EndOfProcessCriticalComponent implements OnInit {

  constructor(private router: Router, private analyticService: AnalyticsService) { }

  ngOnInit() {
    this.analyticService.trackByAppFlyer("DP - Mahalot Kashot  - Success Status", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
  }

  next() {
    (this.router.routeReuseStrategy as any).storedRoutes = {}
    // this.accountService.coin.next(this.numOfCoins + 1)
    this.router.navigate(['/account/insurance'])
  }
  

}
