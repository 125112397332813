import { CacheService } from 'src/app/risk/services/cache.service';
import { UtilService } from './../../../risk/services/util.service';
import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { mobiScrollOptions } from 'src/app/risk/services/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MbscDatetimeOptions } from '../../../../lib/mobiscroll-package/dist/js/mobiscroll';


@Component({
  selector: 'shared-creditcard',
  templateUrl: './creditcard.component.html',
  styleUrls: ['./creditcard.component.scss']
})
export class CreditcardComponent implements OnInit {
  creditCardForm: FormGroup;
  options: MbscDatetimeOptions = {
    theme: 'robin-v2',
    lang: 'he',
    // max: new Date(new Date(this.aYearFromNow.setFullYear(this.aYearFromNow.getFullYear() + 7)).getFullYear(), new Date().getMonth(), 1),
    // min: Date.now(),
    dateWheels: 'yyyy mm dd',
    dateFormat: 'dd/mm/y'

  };
  idValid;
  expOutput;
  @ViewChild('exp') exp: ElementRef;

  @Output() completed = new EventEmitter()
  constructor(private fb: FormBuilder, private utilService: UtilService, private cacheService: CacheService) { }

  ngOnInit() {
    this.creditCardForm = this.updateDetails()
  }
  updateDetails() {
    return this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      id: ['', [Validators.required, Validators.pattern(/^([0-9]{9})$/g)]],
      creditNumber: ['', [Validators.required, Validators.minLength(8),Validators.maxLength(16)]],
      exp: [this.expOutput, [Validators.required]],
      cvv: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(4)]],
    })
  }
  checkFields() {
    console.log('here');
    if (this.creditCardForm.valid) {
      if (this.validateID(this.creditCardForm.controls.id.value)) {
        this.idValid = true;
        this.cacheService.cache[7] = this.cacheService.setCache(this.creditCardForm.controls)
        this.completed.emit(this.creditCardForm.value)
        // console.log('there');
        // this.utilService.setErrorInEmptyFields(this.creditCardForm)
      } else {
        this.idValid = false;
        setTimeout(() => {
          this.idValid = true;
        }, 3000);
      }

    }
    else {
      console.log('there');
      this.utilService.setErrorInEmptyFields(this.creditCardForm)
    }
  }
  validateID(id) {
    //INPUT VALIDATION
    // Just in case -> convert to string
    var IDnum = String(id);

    // Validate correct input
    // if (IDnum.length !== 9)
    //   return false;
 
    // CHECK THE ID NUMBER
    var mone = 0, incNum;
    for (var i = 0; i < 9; i++) {
      incNum = Number(IDnum.charAt(i));
      incNum *= (i % 2) + 1;
      if (incNum > 9)
        incNum -= 9;
      mone += incNum;
    }
    if (mone % 10 == 0)
      return true;
    else
      return false;
  }

}
