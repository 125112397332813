import { selectBackend, selectRegisterState, selectToken } from './../store/app.states';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { AppState } from '../store/app.states';
import { Subject, Observable, of, BehaviorSubject } from '../../../node_modules/rxjs';
import { Fetching, FetchingSuccess, FetchingFailure } from '../store/backend.actions';
import { map } from '../../../node_modules/rxjs/operators';
import { Response, AuthenticatedStatusModel } from '../models/global.models';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NavManagerService } from '../shared/components/robin-navbar/robin-navbar.service';
import { AnalyticsService } from '../analytics/analytics.service';
import * as uid from 'uuid';
import { ErrorModalService } from '../shared/components/modals/error-modal/modal.service';
import { copyFileSync } from 'fs';


@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public loading = new Subject<any>();
  public loading$ = this.loading.asObservable();
  private baseUrl;
  public coin = new BehaviorSubject(0);
  public coin$ = this.coin.asObservable();

  public numDaysMislaka = new BehaviorSubject(0);
  public numDaysMislaka$ = this.numDaysMislaka.asObservable();
  public isRobinArray = [];
  public showSuccessUpdate;
  singleProduct;


  authenticatedStatusModel = new BehaviorSubject<any>({});
  isShowDrawer = false;
  isSuccess = false;
  consultCancelled = false;
  fromExternConsult = false;
  _productArray = [];
  route;
  numCoins = 0;
  idConsult;
  coupon;
  inviteLink;
  howManyFriendsSend;
  friendsOpen = false;
  mislakaData;
  _investmentTrackList;
  authenticatedModel;
  risklevelWords;
  consultTimeout;

  // numDaysMislaka;
  constructor(private store: Store<AppState>,
    private http: HttpClient,
    private navbarService: NavManagerService,
    private errorModalService: ErrorModalService,
    private analyticService: AnalyticsService) {
    this.store.select(selectBackend).subscribe((data: any) => {
      this.loading.next(data.loading);
    });
    this.navbarService.getTitle$.subscribe((data: any) => {
      this.route = data.route;
    });
    this.baseUrl = environment.endPoint;

    // let prom = this.store.select(selectToken).toPromise();
    // prom.then(data => {
    //   console.log(data);
    // this.getAuthenticatedStatusMode()
    // })
    // .catch(err => console.log('err: ',err));;
  }

  getMislakaData() {
    console.log('thisMISKLAKA', this.mislakaData)
    return this.mislakaData;
  }

  pushProductArray(item) {
    return this._productArray.push(item)
  }

  getProductById(id) {
    return this._productArray.find(p => p.Id === id);
  }

  getInvestmentTrackList() {
    return this._investmentTrackList;
  }


  uploadFiles(fileArray, digitalEventId, subCategoryId, fileTypeId) {
    const formdata: FormData = new FormData();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let fileArrayTemp = this.convertBase64ArrayToFileArray(fileArray, fileTypeId, '.jpg');
    fileArrayTemp.forEach(el => {
      formdata.append('file', el);
    });
    let options = {
      headers: headers,
    }
    const req = new HttpRequest('POST', `${this.baseUrl}/files/uploadfiles/${digitalEventId}/${subCategoryId}`, formdata, options);
    return this.http.request(req);
  }

  convertBase64ArrayToFileArray(base64Array, filename, extention) {
    let fileArray: any = [];
    base64Array.forEach((file, i) => {
      let builtFileName = `${filename}${this.fileNamePlural(base64Array.length, i)}${extention}`;
      fileArray.push(this.convertBase64ToFile(file, builtFileName));
    });
    console.log('filearray', fileArray)

    return fileArray;
  }

  convertBase64ToFile(str, filename) {
    console.log('base64::', str)
    var base64 = this.isValidBase64(str), arr = base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], filename, { type: mime });
  }




  isValidBase64(str) {
    return str.indexOf('data:image/png') !== -1 ? str : `data:image/png;base64,${str}`;
  }

  fileNamePlural(arrayLength, i) {
    return arrayLength > 0 ? `_${i}` : ``;
  }

  getInvestmentTrack(categoryType, recommendationId, productId) {
    console.log('guzim ??', categoryType, recommendationId, productId)
    return this.http.get(`${this.baseUrl}/products/investmenttracks/${categoryType}/${productId}/${recommendationId}`).subscribe(data => {
      console.log('gazzaza', data)
      this._investmentTrackList = data;
    })
  }

  initDigitalEvent(subCategoryId) {
    console.log('subcategory ', subCategoryId)
    return this.http.post(`${this.baseUrl}/events/initdigitalevent`, { SubCategory: subCategoryId });
  }

  check() {
    return this.http.post(`https://robindev.azure-api.net/login`, null);
  }

  // getAuthenticatedStatusMode(cb) {
  //   this.store.dispatch(new Fetching());
  //   console.log('we are here!');
  //   return this.http.get(`${this.baseUrl}/customers/status`).pipe(map((data: Response) => data.Data)).subscribe(
  //     (data: AuthenticatedStatusModel) => {
  //       console.log('dadadad', data)
  //       this.mislakaData = data;
  //       this.store.dispatch(new FetchingSuccess());
  //       // this.numDaysMislaka = this.calculateDaysFromDate(data.MislakaDateRequest);
  //       this.numDaysMislaka.next(this.calculateDaysFromDate(data.MislakaDateRequest));
  //       this.authenticatedStatusModel.next(data);
  //       cb(null, data);
  //       // console.log(this.authenticatedStatusModel);
  //     },
  //     err => {
  //       this.store.dispatch(new FetchingFailure());
  //       cb(new Error('some err msg'));
  //     }
  //   );
  // }


  getAuthenticatedStatusMode(cb) {
    this.store.dispatch(new Fetching());
    console.log('we are here!');
    return this.http.get(`${this.baseUrl}/customers/profile`).pipe(map((data: Response) => {
      let d = {};
      if (data.Code === 0) {
        d = data.Data;
        this.mislakaData = d;
        console.log('this.mislakaData', this.mislakaData)

      }
      return d;
    })).subscribe(
      (data: any) => {
        if (data) {
          this.store.dispatch(new FetchingSuccess());
          // this.numDaysMislaka = this.calculateDaysFromDate(data.MislakaDateRequest);
          this.numDaysMislaka.next(this.calculateDaysFromDate(data.MislakaDateRequest));
          this.authenticatedStatusModel.next(data);
          cb(null, data);
          // console.log(this.authenticatedStatusModel);
        } else {
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open();
        }

      },
      err => {
        this.store.dispatch(new FetchingFailure());
        cb(new Error('some err msg'));
      }
    );
  }

  postConsultExpert(route): Observable<any> {
    if (route === 'accountPension') {
      return this.http.post(`${this.baseUrl}/opportunities/pension`, null);
    }
    else if (route === 'accountInsurance') {
      return this.http.post(`${this.baseUrl}/opportunities/26`, null);
    }
  }



  openAssist() {
    if (/Pension+[A-Z]/.test(this.route) === true)
      this.route = this.route.substr(0, 14);
    if (/Insurance/.test(this.route) === true)
      this.route = this.route.substr(0, 16);
    this.isShowDrawer = true;

  }

  /**
   * The consult expert button was pressed from external source, not from floating button
   */
  openAssistFromExtendedCard() {
    this.fromExternConsult = true;
    //consult expert was clicked from the text button
    this.analyticService.trackByMixpanel("Consult Expert - Text Button");
    this.analyticService.trackByAppFlyer("Consult Expert - Text Button", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    if (/Pension+[A-Z]/.test(this.route) === true)
      this.route = this.route.substr(0, 14);
    if (/Insurance/.test(this.route) === true)
      this.route = this.route.substr(0, 16);
    this.isShowDrawer = true;
    this.isSuccess = true;
    this.consultTimeout = setTimeout(() => {
      this.postConsultExpert(this.route).subscribe((data) => {
        if (data.Code === 0) {
          this.idConsult = data.Data;
        } else {
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open();
        }
      });
      this.isShowDrawer = false;
    }, 1000 * 5);

  }

  closeLoginClicked() {
    if (this.fromExternConsult) {
      this.analyticService.trackByMixpanel("Consult Expert - Round Verified");
      this.analyticService.trackByAppFlyer("Consult Expert - Round Verified", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

      // this.isShowDrawer = false;
    }
    else {
      this.analyticService.trackByMixpanel("Consult Expert - Text Verified");
      this.analyticService.trackByAppFlyer("Consult Expert - Text Verified", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    }

    this.isShowDrawer = false;
    this.isSuccess = false;
  }

  showSuccess() {

    this.isSuccess = true;
    this.consultTimeout = setTimeout(() => {
      this.postConsultExpert(this.route).subscribe((data) => {
        if (data.Code === 0) {
          this.idConsult = data.Data;
        } else {
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open();
        }
      });
      this.isShowDrawer = false;
    }, 1000 * 5);

  }

  showConsultCancel() {
    this.consultCancelled = true;
    clearTimeout(this.consultTimeout);
  }

  cancelAssistance() {
    console.log('aaaaa', `${this.baseUrl}/opportunities/${this.idConsult}`);
    return this.http.delete(`${this.baseUrl}/opportunities/${this.idConsult}`);
  }

  calculateDaysFromDate(date) {
    var today = new Date();
    var requestDate = new Date(date);
    var timeDiff = Math.abs(requestDate.getTime() - today.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  isRobin(data) {
    return this.http.post(`${this.baseUrl}/customers/isrobin`, data);
  }

  creditCardPay(data) {
    return this.http.post(`${this.baseUrl}/orders/mislaka/creditcard`, data);
  }

  robinCoins() {
    return this.http.post(`${this.baseUrl}/orders/mislaka/robincoins`, null);
  }

  inviteFriends(data) {
    return this.http.post(`${this.baseUrl}/customers/invitecustomers`, data);
  }

  updateProfile(data) {
    return this.http.put(`${this.baseUrl}/customers/updatepersonaldetails`, data);
  }

  convertRiskLevelToWords(risklevel) {
    switch (risklevel) {
      case 1:
        return 'שמרן'
      case 2:
        return 'זהיר'
      case 3:
        return 'מאוזן'
      case 4:
        return 'צמיחה'
      case 5:
        return 'מקצוען'
      default:
        return 'לא הוגדר'
    }
  }

}

enum SubCategory {
  UpdateMislakaData = 1,
  Pension = 2,
  Risk = 4,
  Gemel = 8,
  Kranot = 16,
  DefaultPension = 32,
  HealthInsurance = 64,
  Bet2 = 128,
  CancelPolicy = 256,
  RequestInsurancePolicies = 512
}