export class Tabs{

    public tabArray;
    public activeTab;

    constructor(_tabArray, _activeTab?){
        this.tabArray = _tabArray;
        this.activeTab = _activeTab;
    }

    public getTabArrayLength(){
        return this.tabArray.length;
    }
    
    public getCurrentActiveTab(){
        return this.activeTab;
    }

    public setActiveTab(tabId){
        this.activeTab = tabId;
    }

    getWindowWidth(){
        return window.innerWidth;
    }
}