import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/account/account.service';
import { NavManagerService } from 'src/app/shared/components/robin-navbar/robin-navbar.service';
import { ErrorModalService } from '../../../../error-modal/modal.service';

@Component({
  selector: 'app-update-risk-level',
  templateUrl: './update-risk-level.component.html',
  styleUrls: ['./update-risk-level.component.scss']
})
export class UpdateRiskLevelComponent implements OnInit {
  answered = this.accountService.authenticatedModel.RiskLevel || 4 ;;
  mySlide = this.accountService.authenticatedModel.RiskLevel || 4 ;
  errorLabel;
  constructor(private accountService: AccountService, 
              private navbarService: NavManagerService,
              private errorModalService: ErrorModalService) { }

  ngOnInit() {
  }

  changeSlider(e) {
    this.answered = e.target.value;
    this.styleDotByStep(e.target.value);
    // this.answered.emit({value: e.target.value, category: 'RiskLevel'});
  }

  styleDotByStep(step){
    for (let i = 0; i < 4; i++) {
      step > i ? this.setBackgroundForElement('transparent', i) : this.setBackgroundForElement('#ABC2F8', i);
    }
    this.setStepIndicatorIntoRoundedSlider(step);
  }

  setBackgroundForElement(color, place){
    document.getElementsByClassName('mbsc-progress-step-label')[place]['style'].backgroundColor = color;
  }

  ngOnChanges() {
    setTimeout(() => {
      document.getElementsByClassName('mbsc-progress-step-label')[4]['style'].backgroundColor = '#ABC2F8';
      this.InitStepIndicatorIntoRoundedSlider();
    }, 500);
  }

  setStepIndicatorIntoRoundedSlider(step){
    var stepToString = step.toString();
    document.getElementsByClassName('mbsc-slider-handle')[0].children[0].innerHTML = stepToString;                       
  }

  InitStepIndicatorIntoRoundedSlider(){
    var node = document.createElement("SPAN");                 
    var textnode = document.createTextNode("4");         
    node.appendChild(textnode);    
    if(document.getElementsByClassName('mbsc-slider-handle')[0].firstChild == null)
      document.getElementsByClassName('mbsc-slider-handle')[0].appendChild(node);
  }

  save() {
    if (this.answered) {
      this.errorLabel = false;
      var sendNewDetails = {
        RiskLevel: parseInt(this.answered),
        City: this.accountService.authenticatedModel.City,
        StreetName: this.accountService.authenticatedModel.StreetName,
        HouseNumber: this.accountService.authenticatedModel.HouseNumber,
        PostalCode: this.accountService.authenticatedModel.PostalCode,
        Country: this.accountService.authenticatedModel.Country,
        AccountMaritalStatus: this.accountService.authenticatedModel.AccountMaritalStatus,
        LastSalary: this.accountService.authenticatedModel.Salary,
        JobTitle: this.accountService.authenticatedModel.JobTitle,
        Position: this.accountService.authenticatedModel.Position,
        EmployerName: this.accountService.authenticatedModel.EmployerName,
        // NumberOfChildrens: this.accountService.authenticatedModel.NumberOfChildrens
        ChildrensBirthdates: this.accountService.authenticatedModel.Childrens

      };
      this.accountService.updateProfile(sendNewDetails).subscribe((data: any) => {
        if (data.Code === 0) {
          this.accountService.authenticatedModel.RiskLevel  = parseInt(this.answered);
          this.accountService.showSuccessUpdate = true;
          setTimeout(() => {
            this.accountService.showSuccessUpdate = false;
          }, 3000);
          this.navbarService.prev();
        } else {
          this.errorModalService
            .setNewError({
              title: "אופס...",
              description: "נראה שמשהו השתבש בדרך",
              type: "error",
              buttonText: "נסה שנית"
            })
            .open();
        }
      });

    } else {
      this.errorLabel = true;
    }

  }

}
