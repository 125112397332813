import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
  selector: 'feature-support',
  templateUrl: './feature-support.component.html',
  styleUrls: ['./feature-support.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0}),
        animate(600 )
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
  ]
})
export class FeatureSupportComponent implements OnInit {
  //face || touch
_type = ''; 
header = '';
instruction = '';
_erorr = '';
_setSuccess = false;

@Output() logInWithPassword = new EventEmitter();
@Input() set success(t){
  this._setSuccess = t;
}
@Input() set error(t){
  this._erorr = t;
  setTimeout(() => this._erorr = '', 1000 * 5);
}
@Input() set type(t){
  this._type = t;
}

  constructor() { }

  ngOnInit() {
    console.log('this is the type => ',this._type);
    switch(this._type){
      case 'touch':
      this.instruction = 'הנח את האצבע על חיישן ההזדהות';
      this.header = 'זיהוי באמצעות טביעת אצבע';
      break;
      case 'face':
      this.instruction = '';
      this.header = 'זיהוי באמצעות זיהוי פנים';
      break;
    }
  }

  loginwithpassword(){
    this.logInWithPassword.emit();
  }
}
