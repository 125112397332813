import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }
  public validEmail(val) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(val);
  }
  public validId(val) {
    var re = /^([0-9]{9})$/g;
    return re.test(val);
  }
  public validPhoneNumber(val) {
    var re = /^([0-9]{10})$/g;
    return re.test(val);
  }


  public isEmpty(val) {
    return val.length === 0;
  }
  public hasSpecialCharecters(val) {
    var re = /^[a-zA-Z]*$/
    return re.test(val);
  }
  public validCreditCardDate(val) {
    let re = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
    return re.test(val);
  }
  public validCreditCardNumber(val) {
    return val.length === 16;
  }
  isValidIsraeliID(id) {
    id = String(id).trim();
    if (id === "000000000")
      return false;
    if (id.length > 9 || id.length < 5 || isNaN(id)) return false;

    id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

    return Array.from(id, Number)
      .reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) % 10 === 0;
  }


  dateSeperatedBySlash(str) {
    let date = str.split('/').map((el) => Number(el));
    function validDay(num) {
      return num > 0 && num <= new Date(date[2], date[1], 0).getDate();
    }
    function validMonth(num) {
      return num > 0 && num < 13;
    }
    function validYear(num) {
      return num > 1899 && num <= Number(new Date().getFullYear())
    }
    return validDay(date[0]) && validMonth(date[1]) && validYear(date[2]);
  }

}

