import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'contacts-container',
  templateUrl: './contacts-container.component.html',
  styleUrls: ['./contacts-container.component.scss']
})
export class ContactsContainerComponent implements OnInit, OnChanges {

  @Input() contactArray;
  @Input() term = '';
  @Input() markAll;
  @Input() indexArray;
  @Input() isRobinArray = [];

  @Output() clickedOn = new EventEmitter();



  lettersArray = ['#','a','b','c','d','e','f','g','h','i','j','k','l','m','o','p','q','r','s','t','u','v','w','x','y','z','א', 'ב', 'ג', 'ד', 'ה', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת'];
  letterArray = [];



  constructor() {
  }
  

  ngOnInit() {
    console.log('isRobinArray',this.isRobinArray)
  }

  ngOnChanges(changes): void {
  }

  getListByFirstLetter(letter) { //not use
    this.letterArray = [];
    if (letter === '#') {
      for (let i = 0; i < this.contactArray.length; i++) {
        if (this.alph(this.contactArray[i].name.charAt(0)) === true)
          return this.letterArray;
        else 
          this.letterArray.push(this.contactArray[i]);
      }
      return this.letterArray;
    } else {
      for (let i = 0; i < this.contactArray.length; i++) {
        if (letter === this.contactArray[i].name.charAt(0).toLowerCase()) 
          this.letterArray.push(this.contactArray[i]);
      }
      return this.letterArray;
    }
  }

  letterExist(letter) { //not use
    if (letter === '#') {
      for (let i = 0; i < this.contactArray.length; i++) {
        if (this.alph(this.contactArray[i].name.charAt(0)) === true)
          return false;
        else 
          return true;
      }
    } else {
      for (let i = 0; i < this.contactArray.length; i++) {
        if (letter === this.contactArray[i].name.charAt(0).toLowerCase()) {
          return true;
        }
      }
      return false;
    }
  }

  alph(ch) {
    return typeof ch === "string" && ch.length === 1 && (ch >= "a" && ch <= "z" || ch >= "A" && ch <= "Z" || ch >= "א" && ch <= "ת");
  }

  getEvent(i) {
    this.clickedOn.emit(i);
  }

  showFilter(contact) {
    if (this.term === '')
      return true;
    let array = this.contactArray.filter((contact) => (contact.name.toLowerCase().indexOf(this.term.toLowerCase()) !== -1 ))
    if (this.compareObj(contact,array) === false) { 
      return false;
    } else {
      return true;
    }
  }

  compareObj(obj, array) {
    return array.filter((a) => {
      return JSON.stringify(obj) === JSON.stringify(a)
    }).length > 0
  }

}
