import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/account/account.service';
import { FormBuilder } from '@angular/forms';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
  userProfile;
  radioForm;
  obj;
  tempObj: any = {};
  smoke;
  salary;
  numOfChildren;
  placeHolder;
  placeHolderChildren = 'כמות ילדים';
  errorLabel = false;

  constructor(private router: Router,
    private accountService: AccountService,
    private fb: FormBuilder,
    private ciservice: CriticalillnessService,
    private analyticService: AnalyticsService
  ) {

    this.userProfile = this.accountService.authenticatedModel;
    if (this.userProfile.Salary)
      this.salary = this.userProfile.Salary;
    this.radioForm = this.fb.group({
      radio: [],
    });
    this.obj = [
      {
        text: 'רק אותי',
        value: 1
      },
      {
        text: 'רק ילדים',
        value: 2
      },
      {
        text: 'אותי וילדים',
        value: 3
      },
    ];
  }

  ngOnInit() {
    this.analyticService.trackByAppFlyer('DP - Mahalot Kashot - preQuestionnaire', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }


  bid() {
    if (this.radioForm.value.radio) {
      if (this.radioForm.value.radio === 1) {
        this.ciservice.smoking = this.tempObj.smoke;
        if (this.tempObj.smoke !== undefined && this.salary) {
          this.errorLabel = false;
          this.ciservice.whoToCover = this.radioForm.value.radio;
          this.ciservice.numOfChildren = 0;
          this.ciservice.getCriticalIllnessRecommendations(this.salary,this.tempObj.smoke).subscribe((response: any) => {
            if (response.Code === 0) {
              this.ciservice.ManufacturerCode = response.Data.ManufacturerCode
              this.ciservice.amountRecommend = response.Data.CoverageAmount;
              this.ciservice.amount = response.Data.CoverageAmount;
              this.ciservice.premiumPer100k = response.Data.Premium;
              this.ciservice.premiumPer100kChild = 0;
              this.ciservice.amountChildren = 0;
              this.router.navigate([`/criticalillness`, 'amount']);
            }
          });
        } else {
          this.errorLabel = true;
          setTimeout(() => {
            this.errorLabel = false;
          }, 3000);
        }
      } else if (this.radioForm.value.radio === 2) {
        if (this.numOfChildren > 0) {
          this.errorLabel = false;
          this.ciservice.whoToCover = this.radioForm.value.radio;
          this.ciservice.numOfChildren = Number(this.numOfChildren);
          this.ciservice.getCriticalIllnessRecommendations(10000,false).subscribe((response: any) => {
            if (response.Code === 0) {
              this.ciservice.ManufacturerCode = response.Data.ManufacturerCode;
              this.ciservice.amountRecommend = 200000;
              this.ciservice.amount = 0;
              this.ciservice.premiumPer100k = 0;
              this.ciservice.premiumPer100kChild =  response.Data.ChildPremium;
              this.ciservice.amountChildren = 200000;
              this.router.navigate([`/criticalillness`, 'amount']);
            }
          });
        } else {
          this.errorLabel = true;
          setTimeout(() => {
            this.errorLabel = false;
          }, 3000);
        }
      } else if (this.radioForm.value.radio === 3) {
        if (this.tempObj.smoke !== undefined && this.salary && this.numOfChildren > 0) {
          this.errorLabel = false;
          this.ciservice.smoking = this.tempObj.smoke;
          this.ciservice.numOfChildren = Number(this.numOfChildren);
          this.ciservice.whoToCover = this.radioForm.value.radio;
          this.ciservice.getCriticalIllnessRecommendations(this.salary,false).subscribe((response: any) => {
            if (response.Code === 0) {
              this.ciservice.ManufacturerCode = response.Data.ManufacturerCode;
              this.ciservice.amountRecommend = response.Data.CoverageAmount;
              this.ciservice.amount = response.Data.CoverageAmount;;
              this.ciservice.premiumPer100k =  response.Data.Premium;
              this.ciservice.premiumPer100kChild =  response.Data.ChildPremium;
              this.ciservice.amountChildren = 200000;
              this.router.navigate([`/criticalillness`, 'amount']);
            }
          });
        } else {
          this.errorLabel = true;
          setTimeout(() => {
            this.errorLabel = false;
          }, 3000);
        }
      }
    }

    else {
      this.errorLabel = true;
      setTimeout(() => {
        this.errorLabel = false;
      }, 3000);
    }


  }

  salaryDD(event) {
    this.salary = event;
    this.errorLabel = false;
  }

  childrenDD(event) {
    this.numOfChildren = Number(event);
    this.errorLabel = false;
  }

  booleanValue(e, type) {
    this.tempObj[type] = this.convertStringToBoolean(e);
  }

  convertStringToBoolean(answer) {
    return answer === 'לא' ? false : true;
  }

}
