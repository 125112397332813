
export class Bar {

    _barArray;
    constructor(barArray){
        this._barArray = barArray;

    }

    getArray() {
        return this._barArray;
    }

    public getCurrentState(type){ 
        return this.getArray().find( obj => type === obj.name );
    }

    public setClass(color){
        return 'bar ' + color;
    }
}
