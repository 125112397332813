import { Component, OnInit, Input } from '@angular/core';
import { ErrorModalService } from './modal.service';
import { Router } from '@angular/router';
import { NavManagerService } from '../../robin-navbar/robin-navbar.service';

@Component({
  selector: 'error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  constructor(public serviceModal : ErrorModalService, private router: Router, private robinNavService:NavManagerService) { }
  _title;
  _description;
  _type;
  _buttonText;

  ngOnInit() {
    this.serviceModal.setArrayReference({
      title: this._title,
      description: this._description,
      type: this._type, 
      buttonText: this._buttonText
    })
  }

  buttonClicked(event: any){
    if(this.robinNavService.currentRoute === 'authenticationJoinfaceid' || this.robinNavService.currentRoute === 'authenticationJointouchid' || this.robinNavService.currentRoute === 'homepage'){
      this.router.navigate(['/homepage', {loginClicked: true}])
    }
    this.serviceModal.close();
  }
}
