

export const modalStrings = {
    main: {
        title: 'יש לך שאלה?',
        subtext: `אין בעיה, אני זמין עבורך בפלטפורמות הדיגיטליות הבאות:`,
       sendusmail: 'שלח לי הודעה ואחזור אליך בהקדם',
       tellus: 'ספר לי בקצרה מה קרה בבקשה',
       successsend: 'נשלח בהצלחה!',
       callyouback: 'פנייתך נשלחה, אנסה לחזור אליך בהקדם האפשרי רובין',
       sendbutton:'שלח פנייתך לרובין',
        icons: {
            messanger: {
                name: 'messanger',
                url: 'assets/images/modal/icons/messager_facebook.png'
            },
            whatsapp: {
                name: 'whatsapp',
                url: 'assets/images/modal/icons/Whatsapp_icon.png'
            },
            email: {
                name: 'email',
                url: 'assets/images/modal/icons/Envelop.png'
            },
        }
    }

}