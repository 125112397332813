import { RadioButtonService } from './radio-button.service';
import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'robin-radio-buttons',
  templateUrl: './robin-radio-buttons.component.html',
  styleUrls: ['./robin-radio-buttons.component.scss'],
  providers: [
    RadioButtonService,
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RobinRadioButtonsComponent), multi: true },
  ],
  
})
export class RobinRadioButtonsComponent implements OnInit {


  // { 
  //   text: 'שלום לך',
  //   subtext: 'משהו קצת יותר ארוך',    <=== optional
  //   label: {                            <=== optional
  //     text: 'משהו על הלייבל'
  //   },
  //   value: 'stocks'  
  // },
  
  @Input() obj;
  @Input() nag;
  @Input() forcePictureId; // omg, this is unbelievable hack... dont use it.
  @Output() change = new EventEmitter();
  selected;
  constructor(private service: RadioButtonService) { 
  }

  ngOnInit() {
    console.log('nag nag', this.nag)
    if(this.forcePictureId) {

      this.selected = this.nag;
    }
  }
  
  isRecomm(item){
    return item.value === this.nag;
  }

  clicked(e){
    this.service.ev.next(e)
    this.propegateChange(e.obj.value);
    this.change.emit(e.obj.value)
    console.log(e.obj.value)
  }
  
  writeValue(value: any) {
    this.nag = value; // :::TODO ::: set default.
  }

  propegateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propegateChange = fn;
  }
  registerOnTouched() { }
}
