import { environment } from './../../../environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  contacts = [];
  contactsAvailable = new ReplaySubject(1);
  private baseUrl;
  howManyFriendsSend;
  openHasRobin = false;

  constructor( private http: HttpClient, ) {
    this.baseUrl = environment.endPoint;
  }

  mobilePhoneArray(array) {
    var newArray = [];
    for (var i = 0; i < array.length; i++) {
      if (this.isMobilePhone(array[i]) === true) {
        newArray.push(array[i])
      }
    }
    return newArray;
  }

  isMobilePhone(phoneNumber) {
    phoneNumber = this.cleanNumber(phoneNumber);
    if (phoneNumber.length === 10 && phoneNumber.substring(0, 2) === '05') { //0525557428
      return true;
    } else if (phoneNumber.length === 12 && phoneNumber.substring(0, 4) === '9725') { //972525557428
      return true;
    } else if (phoneNumber.length === 13 && phoneNumber.substring(0, 5) === '+9725') { //+97252557428
      return true;
    } else
      return false;
  }

  cleanNumber(phoneNumber) {
    return phoneNumber.replace(/-/gi, '');
  }

  // sms(arr,link,cb) {
  //   let stringArray = arr[0];
  //   let success = 0;
  //   let failed = 0;
  //   var options = {
  //     replaceLineBreaks: false, // true to replace \n by a new line, false by default
  //     android: {
  //       intent: 'INTENT'  // send SMS with the native android SMS messaging
  //       //intent: '' // send SMS without open any other app
  //     }
  //   };
  //   http://onelink.to/robin
  //   var message = "היי, ראיתי מאפליקציית רובין שלי, שאין לך אותה עדיין. הגיע הזמן להוריד ולעשות סדר בכל מה שקשור לפנסיה. חינם." + link;
  //   for (let i = 1; i < arr.length; i++) {
  //     stringArray = stringArray + ', ' + arr[i];
  //   }
  //   console.log('message',message);
  //   console.log('stringArray',stringArray);
  //   window.sms.send(stringArray, message, options, (a) => success += 1, (a) => failed += 1);

  //   cb(null, {
  //     success,
  //     failed
  //   })
  // }

  sms(arr) {
    // var arrEX = ["+972525557428","+972525557428","+972525557428","+972525557428","+972525557428","+972525557428","+972525557428","+972525557428","+972525557428","+972525557428","+972525557428"]
    var data = {
      PhoneNumbers: arr
    };
    return this.http.post(`${this.baseUrl}/accounts/invitefriends/sms`, data);
  }
}

