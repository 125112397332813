import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../account.service';
import { Router } from '@angular/router';
import { NavManagerService } from 'src/app/shared/components/robin-navbar/robin-navbar.service';
import { Store } from '@ngrx/store';
import { AppState, selectAnonymous } from 'src/app/store/app.states';
import { CordovaService } from 'src/app/core/services/cordova.service';

declare const window;

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.scss']
})
export class InviteFriendsComponent implements OnInit {

  coins;
  numLastUpdate;
  friendsOpen = false;
  progressMislaka;
  percentage = 30;

  constructor(public accountService: AccountService,
    private router: Router,
    private navbarService: NavManagerService,
    private store: Store<AppState>,
    private cordovaSrevice: CordovaService
  ) {
    this.store.select(selectAnonymous).subscribe((data: any) => {
      if (!data.anonymous) {
        this.progressMislaka = false;
      } else {
        this.progressMislaka = data.anonymous.MislakaStatus;
        this.percentage = this.calculatePercentage(data.anonymous.MislakaRequestDate);
      }
    });
    this.accountService.coin$.subscribe(data => this.coins = data);
    this.accountService.numDaysMislaka.subscribe(data => this.numLastUpdate = data);
  }

  ngOnInit() {
  }

  updateData() {
    // this.router.navigate(['/account', 'updatedata']);
    this.navbarService.next('updatedata');
  }

  inviteFriendsDrawer() {
    console.log(window.navigator);

    // try{}catch()
    window.navigator.share('Message', 'Title', 'plain/text');
    // this.friendsOpen = true;
 //   this.accountService.friendsOpen = true;
   }

  closeLoginClicked() {
    // this.friendsOpen = false;
    this.accountService.friendsOpen = false;
  }

  choosefriends() {
    this.navbarService.next('choosefriends');
  }

  choosefriendsWhatsapp() {
    // this.friendsOpen = false;
    this.accountService.friendsOpen = false;
    console.log('aaa');
    this.cordovaSrevice.open('inviteWhatsapp', this.accountService.inviteLink);
  }

  calculatePercentage(date) {
    var hours = this.calcultaeHours(date);

    if (hours / 120 >= 1)
      return 0.98;
    else
      return hours / 120;
  }

  calcultaeHours(date) {
    var nowDate = new Date();
    var diff = (nowDate.getTime() - new Date(date).getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(diff);
  }

  get showInviteFriends() {
    if (this.cordovaSrevice.isMobile) {
      return true;
    } else {
      return false;
    }
  }



}
