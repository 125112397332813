import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'indication',
  templateUrl: './indication.component.html',
  styleUrls: ['./indication.component.scss']
})
export class IndicationComponent implements OnInit {

  _numOfQuestions = 12;
  _currentQuestion = 1;
  _indicatorWidth = 16.5625;
  
  @Input() set numOfQuestions(q){this._numOfQuestions = q}
  @Input() set currentQuestion(q){
    this._currentQuestion = q+1
    if (q+1 >this._numOfQuestions) {
      this._currentQuestion = q;
    } else {
      this._currentQuestion = q+1;
    }
  }
  @Input() set indicatorWidth(q){this._indicatorWidth = q}
  
    constructor() { }
  
    ngOnInit() {
    }
  
  }