export const sideNav = [
    // {
    //     text: 'התייעצות עם מומחה',
    //     icon: 'assets/images/shared/arrows/arrow_down_gray.png',
    //     url: '/support',
    //     public: false
    // },
    {
        text: 'הזמנת חברים',
        icon: 'assets/images/navbar/share.png',
        url: 'invitefriends',
        public: true,
        showPwa: false
    },
    {
        text: 'סימון "אהבתי" בפייסבוק',
        icon: 'assets/images/shared/likeus.png',
        url: 'https://www.facebook.com/RobinhoodproAPP/',
        public: false,
        showPwa: true
    },
    {
        text: 'לאתר האינטרנט של רובין',
        icon: 'assets/images/navbar/web.png',
        url: 'https://www.robinhoodpro.com/walla-robin/',
        public: true,
        showPwa: true

    },

    // {
    //     text: 'הישגים',
    //     icon: 'assets/images/navbar/achivements.png',
    //     url: '/support',
    //     public: true
    // },

    {
        text: 'צור קשר',
        icon: 'assets/images/navbar/contactus.png',
        url: '/support',
        public: false
    },
    {
        text: 'הגדרות',
        icon: 'assets/images/navbar/settings_gray.png',
        url: '/settings',
        public: false
    }, 
    // {
    //     text: 'אודות',
    //     icon: 'assets/images/shared/arrows/arrow_down_gray.png',
    //     url: '/about',
    //     public: true
    // },
    // {
    //     text: 'התנתק',
    //     icon: 'assets/images/shared/arrows/arrow_down_gray.png',
    //     url: 'signout',
    //     public: false
    // }


]