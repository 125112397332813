import { RegisterActionTypes, Register, RegisterSuccess, RegisterFailure, Login, LoginSuccess, LoginActionTypes } from '../user.actions';
import { RegisterService } from '../../onboarding/services/register/register.service';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
// import { tap } from 'rxjs/operators';
import { map, filter, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppState } from '../app.states';
import * as uid from 'uuid';

@Injectable()
export class LoginEffects {

    constructor(
        private actions: Actions,
        private router: Router,
        private store: Store<AppState>
    ) { }

    // @Effect()
    // Register: Observable<any> = this.actions
    //     .ofType(RegisterActionTypes.REGISTER).pipe(
    //         map((action: Register) => action.payload),
    //         switchMap(payload => {
    //             return this.registerService.register(payload).pipe(
    //                 // map((user) => new RegisterSuccess(payload)),
    //                 map((user) => ({type: 'REGISTER_SUCCESS', payload: payload})),
    //                 catchError((error) => {
    //                     console.log(error);
    //                     return of(new RegisterFailure({ error: error }));
    //                 }));
    //         }));

    @Effect()
    LoginSuccess: Observable<any> = this.actions
        .ofType(LoginActionTypes.LOGIN).pipe(
            // map((data: Login) => { localStorage.setItem('token', data.payload.token); return new LoginSuccess({ token: data.payload.token }); })
            map((data: Login) => {
                if (localStorage.getItem('deviceFeatureDisabled')) {
                    localStorage.removeItem('deviceFeatureDisabled');
                }
                localStorage.setItem('valid', uid()); 
                return new LoginSuccess({ token: data.payload.token });
            })
        );
}