import { AccountService } from 'src/app/account/account.service';
import { InsuranceService } from 'src/app/account/insurance/services/insurance.service';
import { PensionService } from 'src/app/account/pension/services/pension.service';
import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';
import { selectDeeplink, AppState } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';
import { Pension, Authenticated } from 'src/app/store/user.actions';
import { ErrorModalService } from 'src/app/shared/components/modals/error-modal/modal.service';
import { FetchingFailure } from 'src/app/store/backend.actions';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {
  deeplinkUrl = null;
  deeplinkUrl$;

  constructor(private accountService: AccountService, private errorModalService: ErrorModalService, private logger: LoggerService, private store: Store<AppState>, private insuranceservice: InsuranceService, private pensionService: PensionService,
    private configService: ConfigService) {
    this.cameFromDeeplink();
  }
  cameFromDeeplink() {
    this.deeplinkUrl$ = this.store.select(selectDeeplink).subscribe(data => {
      if (data.deeplink) {
        this.deeplinkUrl = data.deeplink;
      }
    });
  }

  ngOnDestroy(): void {
    this.deeplinkUrl$.unsubscribe();
  }

  apiCalls(cb = null) {
    this.insuranceservice.getProducts();
    this.pensionService.getProducts().subscribe(
      (products: any) => {
          this.store.dispatch(new Pension({ pensionDetails: products }));
          this.accountService.getAuthenticatedStatusMode((err, res) => {
            if (err) return this.setError();
            else {
              this.store.dispatch(new Authenticated({authenticated :res}));
              this.accountService.authenticatedModel = res;
              this.accountService.coin.next(res.Credits);
              this.accountService.coupon = res.Coupon;
              this.accountService.inviteLink = res.InviteLink;
              if(cb)(cb(null))
            }
          })
          // this.data.pensionDetail = products;

      },
      err => {
        this.store.dispatch(new FetchingFailure());
      }
    );
  }
  setError() {
    this.errorModalService.setNewError({
      title: 'אופס...',
      description: 'נראה שמשהו השתבש בדרך',
      type: 'error',
      buttonText: 'נסה שנית',
    }).open();
  }
}
