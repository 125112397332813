import { selectRegisterState } from './../../../../../store/app.states';
import { OcrService } from './../../../../services/ocr/ocr.service';
import { take, last, publish, share, map } from 'rxjs/operators';
import { ErrorModalService } from './../../../../../shared/components/modals/error-modal/modal.service';
import { NavManagerService } from '../../../../../shared/components/robin-navbar/robin-navbar.service';
import { CordovaService } from '../../../../../core/services/cordova.service';
import { ModalService } from '../../../../../shared/components/robin-modal/modal.service';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ImageCaptureService } from '../../../../services/image-capture.service';
import { LoggerService } from '../../../../../core/services/logger.service';
import { uiStrings } from '../../../../assets/heb.ui_strings'
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../store/app.states';
import { OnboardingService } from '../../../../services/onboarding.service';
import { Response } from '../../../../../models/global.models';
import { ImageCompressService, IImage } from 'ng2-image-compress';



@Component({
  selector: 'selfie-preview',
  templateUrl: './selfie-preview.component.html',
  styleUrls: ['./selfie-preview.component.scss']
})
export class SelfiePreviewComponent implements OnInit {
  myParam: string;
  public image = null;
  errorMessage = false;
  @ViewChild('picture') picture: ElementRef;
  public uiStrings;

  constructor(private store: Store<AppState>,
    private ocrService: OcrService,
    private zone: NgZone,
    private errorModalService: ErrorModalService,
    private route: ActivatedRoute,
    private router: Router,
    private captureService: ImageCaptureService,
    public cordovaService: CordovaService,
    private loggerService: LoggerService,
    private navbarService: NavManagerService,
    private onboardingService: OnboardingService,
    private imgCompressService: ImageCompressService) {
    this.uiStrings = uiStrings.selfiepreview;
  }

  ngOnInit() {
    this.captureService.selfieImage.subscribe(data => {
      this.image = data;
      this.reRenderPicture();
    });
  }
  redirectTo() {
    // this.onboardingService.setWaitCounter();
    this.captureService.idImage.subscribe(idImage => {
      console.log('idImage', idImage)
      if (idImage) {
        this.captureService.selfieImage.subscribe(selfieImage => {
          console.log('selfieImage', selfieImage)
          if (selfieImage) {
            this.store.select(selectRegisterState).pipe(map((data: any) => data.user.phoneNumber)).subscribe((phoneNumber) => {
              // this.ocrService.data$ = this.ocrVerification(idImage, selfieImage, phoneNumber);
              if (this.cordovaService.isMobile) {
                let imageFormat = 'jpeg';
                this.ocrService.ocrVerification({
                  DocumentPhotoBase64: `data:image/${imageFormat};base64,${idImage}`,
                  SelfieBase64: `data:image/${imageFormat};base64,${selfieImage}`,
                  DocumentType: 'IdPicture',
                  PrimaryIdentifier: phoneNumber
                }).subscribe((data: Response) => {
                  if (data.Code === 0) {
                    // this.proccessSuccessful = true;
                    // this.onboardingService.IdentityNumber = data.Data.PrimaryIdentifier;
                    //success
                    // clearTimeout(this.timeout);
                    // this.ok = true;

                    // this.onboardingService.setIdentityNumber(data.Data.PrimaryIdentifier);
                    this.navbarService.next();
                    // this.onboardingService.resetWaitCounter();

                  }
         
                  else {
                    console.log('ERROR:',data.Code, data.Description);
  

                  }
                  // });
                });
              } else {
                this.ocrService.ocrVerification({
                  DocumentPhotoBase64: `${idImage}`,
                  SelfieBase64: `${selfieImage}`,
                  DocumentType: 'IdPicture',
                  PrimaryIdentifier: phoneNumber
                }).subscribe((data: any) => {
                  console.log('1');
                  if (data.Code === 0) {
                    // this.proccessSuccessful = true;
                    // this.onboardingService.IdentityNumber = data.Data.PrimaryIdentifier;
                    //success
                    // clearTimeout(this.timeout);
                    // this.ok = true;

                    // this.onboardingService.setIdentityNumber(data.Data.PrimaryIdentifier);
                    this.navbarService.next();
                    // this.onboardingService.resetWaitCounter();

                  } else if (data.Code === 1) {
                    //wrong details
                    // clearTimeout(this.timeout);
                    // this.isError = data.Description;
                    // this.throwError();
                  } else {
                    //server error
                    // clearTimeout(this.timeout);
                    // this.isError = data.Description;
                    // this.throwError();

                  }
                });
              }

              // this.zone.run(() => {
              //   this.ocrService.data$.subscribe((data: Response) => {
              //     if (data.Data.IsVerified) {
              //       // this.proccessSuccessful = true;
              //       this.onboardingService.IdentityNumber = data.Data.PrimaryIdentifier;
              //       //success
              //       // clearTimeout(this.timeout);
              //       // this.ok = true;

              //       this.onboardingService.setIdentityNumber(data.Data.PrimaryIdentifier);
              //       this.navbarService.next();
              //       // this.onboardingService.resetWaitCounter();

              //     }
              //     else if (data.Code === 1) {
              //       //wrong details
              //       // clearTimeout(this.timeout);
              //       // this.isError = data.Description;
              //       // this.throwError();
              //     }
              //     else {
              //       //server error
              //       // clearTimeout(this.timeout);
              //       // this.isError = data.Description;
              //       // this.throwError();

              //     }
              //   });
              // });
            });
          }
        });
      }
    });
  }

  ocrVerification(idImg, selfieImg, primaryIdentifier) {
    if (this.cordovaService.isMobile) {
      let imageFormat = 'jpeg';
      return this.ocrService.ocrVerification({
        DocumentPhotoBase64: `data:image/${imageFormat};base64,${idImg}`,
        SelfieBase64: `data:image/${imageFormat};base64,${selfieImg}`,
        DocumentType: 'IdPicture',
        PrimaryIdentifier: primaryIdentifier
      }).pipe(share());
    } else {
      let imageFormat = 'jpeg';
      return this.ocrService.ocrVerification({
        DocumentPhotoBase64: `${idImg}`,
        SelfieBase64: `${selfieImg}`,
        DocumentType: 'IdPicture',
        PrimaryIdentifier: primaryIdentifier
      }).pipe(share());
    }
  }



  openCamera() {
    if (this.cordovaService.isMobile) {
      let el = document.getElementById('selfiePreviewCompPicDiv');
      let width = el.getBoundingClientRect().width;
      this.cordovaService.captureImage({ targetWidth: width }, (err, data) => err ? this.onErr(err) : this.onData(data))
    } else {
      // $("#file-input").click();
      let el: any = document.querySelectorAll("#file-input2")[1];
      var file = document.getElementById("file-input2")

      file.addEventListener('change', (e: any) => {
        console.log('e', e);
        this.compressImage(e.target.files);
        // this.doSomethingWithFiles(e.target.files);
      })
    }
  }

  compressImage(fileInput) {
    let fileList: FileList;

    let images: Array<IImage> = [];

    ImageCompressService.filesToCompressedImageSource(fileInput).then(observableImages => {
      observableImages.subscribe((image) => {
        images.push(image);
        if (images !== null) {
          // let src = URL.createObjectURL(file);
          this.captureService.selfieImage.next(images[0].compressedImage.imageDataUrl);
          this.zone.run(() => {
            // this.redirectTo();
          });
        }
      }, (error) => {
        console.log("Error while converting");
      }, () => {
        // this.processedImages = images;      
        // this.showTitle = true;          
      });
    });


    // or you can pass File[] 
    // let files = Array.from(fileInput);

    // ImageCompressService.filesArrayToCompressedImageSource(files).then(observableImages => {
    //   observableImages.subscribe((image) => {
    //     images.push(image);
    //   }, (error) => {
    //     console.log("Error while converting");
    //   }, () => {
    //             this.processedImages = images;      
    //             this.showTitle = true;          
    //   });
    // });
  }

  doSomethingWithFiles(fileList) {
    let file = null;
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/^image\//)) {
        file = fileList[i];
        break;
      }
    }

    if (file !== null) {
      // let src = URL.createObjectURL(file);
      this.getBase64(file).then(data => {
        this.captureService.selfieImage.next(data);
        this.zone.run(() => {
          // this.redirectTo();
        });
      });
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  onData(data) {
    console.log('data', data)
    // this.captureService.imageCapture.next(data);
    this.image = data;
    this.captureService.selfieImage.next(data);
    this.zone.run(() => {
      this.reRenderPicture();
    })

  }
  reRenderPicture() {
    this.picture.nativeElement.style.backgroundRepeat = 'no-repeat';
    this.picture.nativeElement.style.backgroundSize = '100%';
    this.picture.nativeElement.style.backgroundPosition = 'center';
    this.picture.nativeElement.style.backgroundImage = 'url(' + this.image + ')';
  }
  onErr(err) {
    this.loggerService.error(err);
    // this.errorModalService.setNewError({
    //   title: '',
    //   description: 'אירעה שגיאה בבחירת התמונה',
    //   type: 'error',
    //   buttonText: 'אישור',
    // }).open();
    this.errorModalService.setNewError({
      title: 'אופס...',
      description: 'נראה שמשהו השתבש בדרך',
      type: 'error',
      buttonText: 'נסה שנית',
    }).open()
  }








}
