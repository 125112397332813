import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {

  @Input() slide;
  @Input() slideIndex;
  
  public lottieConfig: Object;
  private anim: any;

  constructor() {
  }

  ngOnInit() { }

}
