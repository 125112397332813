import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { state, style, trigger, transition, animate } from '@angular/animations';
import { mobiScrollOptions, mobiScrollOptions2 } from 'src/app/risk/services/util.service';

@Component({
  selector: 'or-input',
  templateUrl: './or-input.component.html',
  styleUrls: ['./or-input.component.scss'],
  animations: [trigger('simpleFadeAnimation', [
    state('in', style({ opacity: 1 })),
    transition(':enter', [
      style({ opacity: 0 }),
      animate(600)
    ]),
    transition(':leave',
      animate(600, style({ opacity: 0 })))
  ]),],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrInputComponent),
      multi: true
    }
  ]
})
export class OrInputComponent implements OnInit, ControlValueAccessor {
  writeValue(val: any): void {
    if (!!(val)) {
      this._value = val;
      this.valueChanged(val)
      this.valueTouched(val)
    }

  }
  registerOnChange(fn: any): void {
    this.valueChanged = fn
  }
  registerOnTouched(fn: any): void {
    this.valueTouched = fn
  }

  valueChanged(val) {  }
  valueTouched(val) {  }
  mobiScrollDateOptions = mobiScrollOptions;
  mobiScrollDateCreditOptions =  mobiScrollOptions2
  _active = false
  _error = false
  _errorMessage = ''
  _enableIndication = false
  _value = ''
  _placeholder = ''
  _icon = ''
  _type = ''
  _touched = false
  indicationIcon;
  @Input() set enableIndication(ac) {
    this._enableIndication = ac;
  }
  @Input() set active(ac) {
    this._active = ac;
  }
  @Input() set error(ac) {
    this._error = ac;
    this.indicationIcon = this._error ? 'assets/images/shared/indicators/robin-state/exclamation_white.png' : 'assets/images/forms/v_icon.png'
    console.log('=> ', ac);
  }
  @Input() set errorMessage(ac) {
    this._errorMessage = ac;
  }
  @Input() set placeholder(ac) {
    this._placeholder = ac;
  }
  @Input() set icon(ac) {
    this._icon = ac;
  }
  @Input() set value(ac) {
    this._value = ac;
  }
  @Input() set type(ac) {
    this._type = ac;
  }
  constructor() { }

  ngOnInit() {
  }
  inputFocus() {
    this._active = true
  }
  outside() {
    this._active = false;
    this._touched = true;
  }
  textInserted(el) {
    this._value = el.target.value
    this.writeValue(this._value)
    if (!!!(this._value)) this._touched = false
  }

}
