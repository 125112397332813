import { Component, OnInit } from '@angular/core';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-height-and-weight',
  templateUrl: './height-and-weight.component.html',
  styleUrls: ['./height-and-weight.component.scss']
})
export class HeightAndWeightComponent implements OnInit {

  answers = [];
  formValues:any = {}
  heightAndWeightForm: FormGroup
  
  constructor(private ciservice: CriticalillnessService,
              private analyticService: AnalyticsService,
              private fb: FormBuilder) {
    if(this.ciservice.cacheCritical[1]){
      this.formValues.height = this.ciservice.cacheCritical[1].height;
      this.formValues.weight = this.ciservice.cacheCritical[1].weight;
    }
   }

  ngOnInit() {
    this.heightAndWeightForm = this.fb.group({
      height: [this.formValues.height || '', [Validators.required]],
      weight: [this.formValues.weight || '', [Validators.required]]
    })

    this.analyticService.trackByAppFlyer('DP - Mahalot Kashot - Body Dimensions', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }

  next() {

    console.log(this.heightAndWeightForm);
    if(this.heightAndWeightForm.valid){
      this.ciservice.cacheCritical[2] = this.ciservice.setCache(this.heightAndWeightForm.controls);
      this.ciservice.cacheCritical[3] = [];
      console.log(this.ciservice.cacheCritical[2]);
      this.ciservice.currentTab.next(3)
    }
    else{
      this.ciservice.setErrorInEmptyFields(this.heightAndWeightForm)
    }
  }

}
