import { LoggerService } from './../../../core/services/logger.service';
import { DeeplinkRemovelink } from './../../../redux/deeplink/deeplink.reducer';
import { style } from '@angular/animations';
import { UtilityService } from "./../../../core/services/utility.service";
import { Login, Insurance, CalledAnonymous, Pension } from "./../../../store/user.actions";
import {
  AppState,
  selectRegisterState,
  selectAnonymous,
  selectToken,
  selectDeeplink
} from "./../../../store/app.states";
import { Store } from "@ngrx/store";
import { NavManagerService } from "./../../../shared/components/robin-navbar/robin-navbar.service";
import { OtpService } from "./../../../onboarding/services/otp/otp.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CordovaService } from "./../../../core/services/cordova.service";
import { Component, OnInit, AfterViewInit, ChangeDetectorRef, NgZone, OnDestroy } from "@angular/core";
import { EventEmitter } from "events";
import { ModalService } from 'src/app/shared/components/robin-modal/modal.service';
import { uiStrings } from "../../assets/heb.ui_strings";
import { ErrorModalService } from "../../../shared/components/modals/error-modal/modal.service";
import { Fetching, FetchingSuccess, FetchingFailure } from "../../../store/backend.actions";
import { map } from "../../../../../node_modules/rxjs/operators";
import { HttpClient } from "../../../../../node_modules/@angular/common/http";
import { Observable } from "../../../../../node_modules/rxjs";
import { Response } from "../../../models/global.models";
import { HomepageService } from "../../homepage.service";
import { AccountService } from "../../../account/account.service";
import { AnalyticsService } from "../../../analytics/analytics.service";
import { InsuranceService } from "../../../account/insurance/services/insurance.service";
import { RegisterService } from "../../../onboarding/services/register/register.service";
import { OnboardingService } from "../../../onboarding/services/onboarding.service";
import { Pipe } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.dev';
import { DeeplinkService } from 'src/app/core/services/deeplink.service';
import { PensionService } from 'src/app/account/pension/services/pension.service';

declare const window;
@Component({
  selector: "home-screen",
  templateUrl: "./home-screen.component.html",
  styleUrls: ["./home-screen.component.scss"],
  providers: [ModalService],
})

export class HomeScreenComponent implements OnInit, AfterViewInit, OnDestroy {
  // loading = false;
  AnonymousStatusModel;
  phoneNumber;
  email = null;
  time;
  errorMessage;
  state: number; // 1 - problem screen , 2 - header screen, 3 - progrees screen,
  precentage;
  public loginClicked = false;
  public loginFingerId = false;
  public pincode: number;
  icon: string = "";
  deviceFeatureLoginText = ''
  buttonText = "כניסה לאיזור האישי";
  title: string;
  action;
  subtitle;
  isQuestionnaire: any;
  bannerQuestionnaire = false;
  bannerSupport = false;
  bannerInvestment = true;
  banUrl;
  forgotPasswordOpen = false;
  supportDate;
  openFeature = false;
  fingerPrintSuccess = false;
  featureSupported = '';
  featureLoginError = '';
  progressMislaka;
  data;
  public uiStrings;
  homeScreenObj = {
    title: "",
    subtitle: "",
    action: {
      text: "",
      url: ""
    }
  };

  html;
  showExternalBanner = false;
  storeSubscriber: any = this.store.select(selectToken).subscribe(data => { this.data = data });
  deviceFeatureFailureCounter = 0;
  /*
  *   Deep link handling
  */
  deeplinkUrl = null;

  constructor(
    private cordovaservice: CordovaService,
    private routeService: Router,
    private cordovaService: CordovaService,
    private navbarService: NavManagerService,
    private router: Router,
    private otpService: OtpService,
    private _nav: NavManagerService,
    private store: Store<AppState>,
    private errorModalService: ErrorModalService,
    private mockSer: RegisterService,
    private http: HttpClient,
    private homepageService: HomepageService,
    public insuranceservice: InsuranceService,
    private utilityService: UtilityService,
    public accountService: AccountService,
    private analyticService: AnalyticsService,
    private onboardingservice: OnboardingService,
    private cdf: ChangeDetectorRef,
    private zone: NgZone,
    private activatedRouteService: ActivatedRoute,
    private cordovaSrevice: CordovaService,
    private deeplinkService: DeeplinkService,
    private loggerService: LoggerService,
    private pensionService: PensionService,
    private modalService: ModalService
  ) {

    //listen to the url params in order to know how to render the component
    this.activatedRouteService.params.subscribe(data => {
      if (data && data['loginClicked']) {
        //checking whether or not the user is blocked in order to prevent from the face/finger popup showing
        this.zone.run(() => this.loginClicked = true && !!localStorage.getItem('deviceFeatureDisabled'));
      }
    });
    this.uiStrings = uiStrings.homescreen;
    this.homepageService.getHtmlBanner().subscribe((data: any) => {
      if (data.Code === 0) {
        this.showExternalBanner = data.Data.BannerSettings.Show;
        var parser = new DOMParser;
        var dom = parser.parseFromString(data.Data.BannerSettings.HTML, 'text/html');
        // var dom = parser.parseFromString("&lt;style&gt; .banner-container1 { display: flex; flex-direction: column; justify-content: space-between; } .banner-investment1 { margin-top: 0.5rem; box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24) !important; display: flex; flex-direction: column; justify-content: space-between; padding: 1.5rem; margin-bottom: 0.3rem; } .top1 { display: flex; flex-direction: row; justify-content: space-between; } .right1 { display: flex; flex-direction: column; height: 100%; justify-content: center; } .title1 { font-size: 1rem; font-weight: bold; font-family: &quot;Open Sans Hebrew Bold&quot;; color: #3a4959; line-height: 1.5rem; } .subtitle1 { color: #7f8fa4; line-height: 1.5rem; } .bottom1 { margin-top: 1rem; } .button1 { display: inline-flex; border-radius: 5px; background-color: #427dfb; color: #fff !important; padding: 0.5rem 1rem; } .left1 {  } img { width: 5rem; } &lt;/style&gt; &lt;div class=&quot;banner-container1&quot;&gt; &lt;div class=&quot;banner-investment1&quot;&gt; &lt;div class=&quot;top1&quot;&gt; &lt;div&gt; &lt;div class=&quot;right1&quot;&gt; &lt;div class=&quot;title1&quot;&gt; לייק בפייסבוק כבר עשית? &lt;/div&gt; &lt;div class=&quot;subtitle1&quot;&gt; &lt;/div&gt; &lt;div class=&quot;bottom1&quot;&gt; &lt;div class=&quot;button1&quot;&gt; &lt;span style=&quot;color:white; text-decoration: blink; display:none;&quot;&gt;https://www.facebook.com/RobinhoodproAPP/ &lt;/span&gt; &lt;span&gt;אני רוצה לעשות לייק לרובין!&lt;/span&gt; &lt;/div&gt; &lt;/div&gt; &lt;/div&gt; &lt;/div&gt; &lt;div class=&quot;left1&quot;&gt; &lt;img src=&quot;https://robinblob.blob.core.windows.net/public/MobileAppAssets/money_grow.gif&quot; alt=&quot;&quot;&gt; &lt;/div&gt; &lt;/div&gt; &lt;/div&gt; &lt;/div&gt;", 'text/html');

        this.html = dom.body.textContent;
        // this.html = `<style> .banner-container1 { display: flex; flex-direction: column; justify-content: space-between; } .banner-investment1 { margin-top: 0.5rem; box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24) !important; display: flex; flex-direction: column; justify-content: space-between; padding: 1.5rem; margin-bottom: 0.3rem; } .top1 { display: flex; flex-direction: row; justify-content: space-between; } .right1 { display: flex; flex-direction: column; height: 100%; justify-content: center; } .title1 { font-size: 1rem; font-weight: bold; font-family: "Open Sans Hebrew Bold"; color: #3a4959; line-height: 1.5rem; } .subtitle1 { color: #7f8fa4; line-height: 1.5rem; } .bottom1 { margin-top: 1rem; } .button1 { display: inline-flex; border-radius: 5px; background-color: #427dfb; color: #fff !important; padding: 0.5rem 1rem; } .left1 { margin-left: 2rem; } img { width: 3rem; } </style> <div class="banner-container1"> <div class="banner-investment1"> <div class="top1"> <div> <div class="right1"> <div class="title1"> אוהבים את רובין? </div> <div class="subtitle1"> בואו להיות שותפים שלנו </div> <div class="bottom1"> <div class="button1"> <span style="color:white; text-decoration: blink; display:none;">https://pipelbiz.com/Campaign/52690?utm_source=appbanner&utm_medium=message&utm_campaign=robin&utm_term=Robin</span> <span>לפרטים</span> </div> </div> </div> </div> <div class="left1"> <img src="https://robinblob.blob.core.windows.net/public/MobileAppAssets/robin_green_heart.png" alt=""> </div> </div> </div> </div>
        // `;
        setTimeout(() => {
          let el: any = document.querySelectorAll('.button1 span')[1];
          let urlEl: any = document.querySelectorAll('.button1 span')[0];
          let url = urlEl.innerText;
          el.addEventListener('click', () => {
            if (this.cordovaservice.platform === "Android") window.open(url, '_system', 'location=no');
            else window.open(url, '_system', 'location=yes');
          })
        }, 1000);



      }
      // this.cdf.detectChanges();

    });

    this.store.select(selectRegisterState).subscribe((data: any) => {
      if (data.user !== null) {
        this.phoneNumber = data.user.phoneNumber;
        this.email = data.user.email;
        let user = { email: this.email, phoneNumber: this.phoneNumber };
      }
    });

    this.store.select(selectAnonymous).subscribe((data: any) => {

      if (!data.anonymous) {
        let user = { email: this.email, phoneNumber: this.phoneNumber };
        this.getHomepageData(user);
      } else {
        this.isQuestionnaire = data.anonymous.FilledQuistionnaire;
        this.progressMislaka = data.anonymous.MislakaStatus;
        this.updateAnonymousStatusModel(data.anonymous);
      }
    });

    this.deeplinkUrl = this.deeplinkService.deeplinkUrl;
    this.loggerService.debug('[homescreen component] => constructor ' + this.deeplinkUrl);

  }

  // anonymousStatus$;

  getHomepageData(user) {
    this.homepageService.anonymousStatus(user).subscribe((data: Response) => {
      this.store.dispatch(new CalledAnonymous({ anonymous: data.Data }));
      //this.store.dispatch(new FetchingSuccess());
      this.isQuestionnaire = data.Data.FilledQuistionnaire;
      this.progressMislaka = data.Data.MislakaStatus;
      this.updateAnonymousStatusModel(data.Data);
      // this.insuranceservice.insuranceMountain = data.Data.InsuranceMountainStatus;
      if (data.Code === 0) {
        this.store.dispatch(new CalledAnonymous({ anonymous: data.Data }));
        //this.store.dispatch(new FetchingSuccess());
        this.isQuestionnaire = data.Data.FilledQuistionnaire;
        this.progressMislaka = data.Data.MislakaStatus;
        this.updateAnonymousStatusModel(data.Data);
        // this.insuranceservice.insuranceMountain = data.Data.InsuranceMountainStatus;
      } else {
        this.errorModalService.setNewError({
          title: 'אופס...',
          description: 'נראה שמשהו השתבש בדרך',
          type: 'error',
          buttonText: 'נסה שנית',
        }).open();
      }
    });
  }

  updateAnonymousStatusModel(data) {
    this.isShowQuestionnaireBanner(
      this.homepageService.firstTimeQuestionnaire,
      this.isQuestionnaire
    );
    if (this.homepageService.firstTime === true) {
      this.setPrecentage(0.01).setState(3);
    } else {
      this.insuranceservice.insuranceMountain = data.InsuranceMountainStatus;
      this.AnonymousStatusModel = data;
      this.getUserState(this.AnonymousStatusModel);
    }
    this.homepageService.firstTime === false;
  }



  private getUserState(anon) {
    anon !== null ? this.getAnonymousUserState(anon) : this.setState(1);
  }

  getAnonymousUserState(anon) {
    if (anon.IsVerified === 3) { /// waiting for verification

      if (anon.IsAbleToSeeData === 1) {
        // cannot see data
        this.setState(anon.MislakaStatus === 1 ? 2 : 1);
        if (this.state === 2) {
          this.setHomeScreenObject(
            "שנצא לאתר את מוצריך הפיננסים?",
            "כדי שאוכל לאתר עבורך את מוצריך, עלי לאמת את זהותך.",
            "howitworks",
            "לאימות ואיתור מוצרים"
          );
          this.analyticService.trackByMixpanel("Home screen - Ready for FDC?");
          this.analyticService.trackByAppFlyer('Home screen - Ready for FDC?', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

        } else {
          this.analyticService.trackByMixpanel("Home screen - FDC Error");
          this.analyticService.trackByAppFlyer('Home screen - FDC Error', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
        }
      } else {

        this.setState(3).setPrecentage(
          this.calcultaeTimerPrecentage(-1, anon.MislakaMaximumManufacturersAnswers,
            anon.MislakaRequestDate
          )
        );
      }

    } else if (anon.IsVerified === 2) { /// user is not verified
      setTimeout(() => {
        this.modalService.open('verified');
      }, 800);
      this.setState(2);
      this.setHomeScreenObject(
        "שנצא לאתר את מוצריך הפיננסים?",
        "כדי שאוכל לאתר עבורך את מוצריך, עלי לאמת את זהותך.",
        "howitworks",
        "לאימות ואיתור מוצרים"
      );

    } else if (anon.IsVerified === 1) { /// user is verified

      if (anon.IsAbleToSeeData === 1) {
        // cannot see data
        this.setState(anon.MislakaStatus === 1 ? 2 : 1);
        if (this.state === 2) {
          this.setHomeScreenObject(
            "שנצא לאתר את מוצריך הפיננסים?",
            "כדי שאוכל לאתר עבורך את מוצריך, עלי לאמת את זהותך.",
            "howitworks",
            "לאימות ואיתור מוצרים"
          );
          this.analyticService.trackByMixpanel("Home screen - Ready for FDC?");
          this.analyticService.trackByAppFlyer('Home screen - Ready for FDC?', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

        } else {
          this.analyticService.trackByMixpanel("Home screen - FDC Error");
          this.analyticService.trackByAppFlyer('Home screen - FDC Error', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
        }
      } else if (anon.IsAbleToSeeData === 2 || anon.IsAbleToSeeData === 8) {
        //registered
        this.setState(2)
          .setTime(this.getNowTime())
          .setHomeScreenObject(
            this.time + anon.FirstName,
            "כניסה אחרונה בוצעה בתאריך",
            "pension",
            "כניסה לחשבון האישי"
          ).setPrecentage(
            this.calcultaeTimerPrecentage(
              anon.MislakaActualManufacturersAnswers,
              anon.MislakaMaximumManufacturersAnswers,
              anon.MislakaRequestDate
            )
          );
        this.analyticService.trackByMixpanel("Home screen - Login");
        // this.analyticService.trackByAppFlyer('Home screen - Login', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

      } else if (anon.IsAbleToSeeData === 4) {
        //INprogress
        this.setState(3).setPrecentage(
          this.calcultaeTimerPrecentage(
            anon.MislakaActualManufacturersAnswers,
            anon.MislakaMaximumManufacturersAnswers,
            anon.MislakaRequestDate
          )
        );
      this.analyticService.trackByMixpanel("Home screen - Login");
      this.analyticService.trackByAppFlyer("Home screen - Login", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

      // this.analyticService.trackByAppFlyer('Home screen - Login', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    } else if (anon.IsAbleToSeeData === 4) {
      //INprogress
      this.setState(3).setPrecentage(
        this.calcultaeTimerPrecentage(
          anon.MislakaActualManufacturersAnswers,
          anon.MislakaMaximumManufacturersAnswers,
          anon.MislakaRequestDate
        )
      );
      this.analyticService.trackByMixpanel("Home screen - FDC In Progress");
      this.analyticService.trackByAppFlyer('Home screen - FDC In Progress', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    } else {
      this.analyticService.trackByMixpanel("Home screen - FDC Error");
      this.analyticService.trackByAppFlyer('Home screen - FDC Error', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

        this.setState(1);
      }
    } else { ///problem
      this.setState(1);

    }

  }

  setTime(time) {
    this.time = time;
    return this;
  }

  setState(state) {
    this.state = state;
    return this;
  }

  setPrecentage(prec) {
    this.precentage = (prec >= 1) ? 0.98 : prec;
    return this;
  }

  isShowQuestionnaireBanner(firstTime, isQuestionnaire) {
    if (!firstTime) {
      if (!isQuestionnaire) this.setBannerQuestionnaire(true);
    } else this.setBannerQuestionnaire(false);
  }

  setBannerQuestionnaire(value) {
    this.bannerQuestionnaire = value;
    return this;
  }

  setHomeScreenObject(title, subtitle, url, buttonText) {
    this.homeScreenObj.title = title;
    this.homeScreenObj.subtitle = subtitle;
    this.homeScreenObj.action.url = url;
    this.homeScreenObj.action.text = buttonText;
    return this;
  }

  ngAfterViewInit(): void {
  }
  ngOnInit() {



    // this.accountService.check().subscribe((data: any) => {
    //   console.log('check');
    // });

    //User arrived succesfully to homescreen page
    this.analyticService.trackByMixpanel("User Registration - RQ - End");
    this.analyticService.trackByAppFlyer("User Registration - RQ - End", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }

  getNavigatedStringUrl(url) {
    switch (url) {
      case 'howitworks':
        return '/onboarding/howitworks';

      case 'pension':
        return '/account/pension';

      case 'homepage':
        return url;

      case 'questionnaire':
        return url;

      default:
        return url;
    }
  }

  clickedOn(event) {
    this.homepageService.action = this.homeScreenObj.action.url;
    if (this.data.token) {
      this.insuranceservice.usecache = 'false';
      this.insuranceservice.getProducts();
      this.insuranceservice.usecache = 'true';
      this.router.navigate([this.getNavigatedStringUrl(`${this.homeScreenObj.action.url}`)]);
    } else {
      this.homepageService.banUrl = "";
      this.router.navigate(['/login']);
    }
  }

  clickedOnBan(event, banUrl) {
    this.store.select(selectToken).subscribe(data => {
      if (data.token) {
        this.insuranceservice.usecache = 'false';
        this.insuranceservice.getProducts();
        this.insuranceservice.usecache = 'true';

        this.router.navigate([`${banUrl}`]);
      } else {
        // this.loginClicked = event;
        // this.homepageService.banUrl = banUrl;
        this.homepageService.action = banUrl;
        this.router.navigate(['/login']);
        // document.body.classList.add("fixed");
      }
    })
  }


  // passwordCaptured(e) {
  //   this.pincode = e;
  // }


  // loginBy() {
  //   if (localStorage.getItem('deviceFeatureDisabled') === 'true') {
  //     this.zone.run(() => {
  //       this.openFeature = false;
  //       this.loginClicked = false;
  //       this.errorModalService.setNewError({
  //         title: 'שיטת הזדהות זו נחסמה',
  //         description: 'באפשרותך להזדהות באמצעות סיסמה אישית וכך לשחרר את החסימה',
  //         type: 'error',
  //         buttonText: 'אישור',
  //       }).open();
  //     });
  //   }
  //   else {
  //     const _loginByDeviceFeature = () => {
  //       this.otpService.loginByFingerPrint({ Username: localStorage.getItem('phoneNumber') || -1, UUID: window.device && window.device.uuid || -1 }).subscribe((data: any) => {
  //         this.store.dispatch(new Login({ token: data }));
  //         this.zone.run(() => {
  //           this.fingerPrintSuccess = true;
  //           setTimeout(() => {
  //             // this.openFeature = false;
  //             if (this.deeplinkUrl) {
  //               this.store.dispatch(new DeeplinkRemovelink({}));
  //               this.router.navigate([this.deeplinkUrl]);
  //             }
  //             else this._nav.next(this.homeScreenObj.action.url);
  //           }, 2000);
  //         });
  //         if (data) {
  //           this.store.dispatch(new Login({ token: data }));
  //           this.allApiCall();
  //           this.zone.run(() => {
  //             this.fingerPrintSuccess = true;
  //             setTimeout(() => {
  //               // this.openFeature = false;
  //               this._nav.next(this.homeScreenObj.action.url);
  //             }, 2000);
  //           });
  //         }

  //       }, (err) => { });
  //     }
  //     //check if this is the first time using that service behave accordingly
  //     if (!localStorage.getItem('loginMethod')) {
  //       this.router.navigate([`/authentication/join${this.featureSupported}id`]);
  //     }
  //     else {
  //       this.openFeature = true;
  //       this.cordovaservice.loginWithFingerid((err, res) => {
  //         if (err) {
  //           if (err === 'errorModal') {
  //             this.zone.run(() => {
  //               this.openFeature = false;
  //               this.errorModalService.setNewError({
  //                 title: 'שיטת הזדהות זו נחסמה',
  //                 description: 'באפשרותך להזדהות באמצעות סיסמה אישית וכך לשחרר את החסימה',
  //                 type: 'error',
  //                 buttonText: 'אישור',
  //               }).open();
  //             });
  //           }
  //         }
  //         else {
  //           _loginByDeviceFeature();
  //         }
  //       });
  //       // }
  //     }
  //   }
  // }


  // closeLoginFingerId() {
  //   this.openFeature = false;
  // }
  // loginWithPassword() {
  //   this.closeLoginFingerId();
  //   this.loginClicked = true;
  //   //Change the color of the input because of clickoutside
  //   if (document.querySelectorAll('form input')[0]) {
  //     setTimeout(() => {
  //       let el: any = document.querySelectorAll('form input')[0];
  //       el.classList.remove('not-active');
  //       el.classList.add('active');
  //     }, 150);
  //   }
  // }

  checkIfAllInputAreValid() {
    return this.utilityService.checkIfAllInputsAreValid();
  }

  redirectTo(url) {
    this.router.navigate([`${url}`]);
  }



  // loginWithPincode() {
  //   if (this.pincode) {
  //     this.otpService.createToken(this.pincode).subscribe(
  //       data => {
  //         if (data) {
  //           this.store.dispatch(new Login({ token: data }));
  //           this.cordovaService.resetFingerPrintRestrictions();
  //           if (this.deeplinkUrl) {
  //             this.store.dispatch(new DeeplinkRemovelink({}));
  //             this.router.navigate([this.deeplinkUrl]);
  //           }
  //           else if (this.banUrl === "/questionnaire") {
  //             this.allApiCall();
  //             if (this.banUrl === "/questionnaire") {
  //               document.body.classList.remove("fixed");
  //               this.router.navigate([`${this.banUrl}`]);
  //             } else {
  //               document.body.classList.remove("fixed");
  //               this._nav.next(this.homeScreenObj.action.url);
  //               this.openFeature = false;
  //             }
  //           } else {
  //             this.showErrorMessage();
  //             this.errorMessage = "קוד שגוי";
  //           }
  //         }
  //       },
  //       err => {
  //         this.showErrorMessage();
  //         this.errorMessage = "קוד שגוי";
  //       }
  //     );
  //   }
  //   else {
  //     this.showErrorMessage();
  //     this.errorMessage = "קוד שגוי";
  //   }
  //   // this.openErrorModal('שגיאה', 'אנא הכנס קוד פין', 'אישור');
  // }


  showErrorMessage() {
    this.forgotPasswordOpen = true;
    setTimeout(() => {
      this.forgotPasswordOpen = false;
      this.errorMessage = "";
    }, 3000);
  }


  getNowTime() {
    var today = new Date();
    var hh = today.getHours();
    if (hh >= 6 && hh < 12) return "בוקר טוב, "
    else if (hh >= 12 && hh < 17) return "צהריים טובים, "
    else if (hh >= 17 && hh < 22) return "ערב טוב, "
    else if (hh >= 22 || hh < 6) return "לילה טוב, "
  }

  calcultaeTimerPrecentage(x: number, y: number, date: Date) {
    if (x === -1) {
      return 0.02;
    } else {
      var hours = this.calcultaeHours(date);
      if (x / y >= hours / 120) {
        return x / y;
      } else {
        if (hours / 120 >= 1)
          return 0.98;
        else
          return hours / 120;
      }
    }
  }

  calcultaeHours(date: Date) {
    var nowDate = new Date();
    var diff = (nowDate.getTime() - new Date(date).getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(diff);
  }

  goToInvesment() {
    window.open('https://pipelbiz.com/Campaign/52690?utm_source=appbanner&utm_medium=message&utm_campaign=robin&utm_term=Robin', '_blank');
  }

  ngOnDestroy() {
    this.storeSubscriber.unsubscribe();
  }

  closeFriendsClicked() {
    this.accountService.friendsOpen = false;
  }

  choosefriends() {
    // this.navbarService.next('choosefriends');
    this.accountService.friendsOpen = false;
    this.router.navigate(['/account', 'choosefriends']);
  }

  choosefriendsWhatsapp() {
    this.accountService.friendsOpen = false;
    console.log('aaa');
    this.cordovaSrevice.open('inviteWhatsapp', this.accountService.inviteLink);
  }

  openClal() {
    this.store.select(selectToken).subscribe(data => {
      if (data.token) {
        // this.router.navigate([`${banUrl}`]);
        this.router.navigate(['/account/insurance/healthinsuranceextendedview']);
      } else {
        // this.loginClicked = event;
        // this.homepageService.banUrl = banUrl;
        this.homepageService.action = '/account/insurance/healthinsuranceextendedview';
        this.router.navigate(['/login']);
        // document.body.classList.add("fixed");
      }
    })
    // this.router.navigate(['/account/insurance/healthinsuranceextendedview']);
  }

  closeModal() {
    this.modalService.close('verified');

  }

}
