import { Component, OnInit } from '@angular/core';
import { ForgotpasswordService } from '../services/forgotpassword.service';
import { NavManagerService } from '../../../../shared/components/robin-navbar/robin-navbar.service';
import { FormBuilder } from '@angular/forms';
import { ErrorModalService } from '../../../../shared/components/modals/error-modal/modal.service';
import { OtpService } from '../../../../onboarding/services/otp/otp.service';
import { UtilityService } from '../../../../core/services/utility.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.states';
import { Login } from '../../../../store/user.actions';

@Component({
  selector: 'app-password-otp',
  templateUrl: './password-otp.component.html',
  styleUrls: ['./password-otp.component.scss']
})
export class PasswordOtpComponent implements OnInit {


  passwordform;
  isIndication = false;
  indicationState = '';
  forgotPasswordObj;
  token;
  passcode;
  public timePassed = false;
  to;
  public otpVerified = false;
  textError = 'קוד האימות לא תקין';
  otpErrorMessageShow = false;
  otpErrorMessage = '';




  constructor(
    private forgotpasswordService: ForgotpasswordService,
    private _nav: NavManagerService,
    private fb: FormBuilder,
    private utilityService: UtilityService,
    private otpService: OtpService,
    private store: Store<AppState>,
    private errorModalService: ErrorModalService,) { }

  ngOnInit() {
    this.smsDidntArrive();

    this.forgotPasswordObj = this.forgotpasswordService.getForgotpasswordObj();
    console.log(this.forgotpasswordService.getForgotpasswordObj());
    this.reSendSms()
  }

  passwordCompleted(e){
    console.log(e)
    this.passcode = e;
  }

  pinCloseClicked(){
    console.log('pin close')
  }


  reCall(){
    var phone = this.forgotPasswordObj.phone;
    var email = this.forgotPasswordObj.email;
    let body = {
      PrimaryIdentifier: phone,
      SecondaryIdentifier: email
    };
    this.otpService.resendOtpCall(body).subscribe((data: any) => {
      console.log('data', data)
      if (data.Code === 0) {
        this.timePassed = false;
        this.textError = '';
        this.smsDidntArrive();
      } else if(data.Code === 1006) {
        this.setIndication('error', true);
        this.showErrorMessage();
        this.otpErrorMessage = 'קוד האימות שהזנת שגוי';
      }else{
            //Code send failed
        this.errorModalService.setNewError({
          title: 'אופס...',
          description: 'נראה שמשהו השתבש בדרך',
          type: 'error',
          buttonText: 'נסה שנית',
        }).open()
      }
    })
  }

  reSendSms() {

    var phone = this.forgotPasswordObj.phone;
    var email = this.forgotPasswordObj.email;

    let body = {
      PrimaryIdentifier: phone,
      SecondaryIdentifier: email
    };
    this.otpService.resendOtp(body).subscribe((data: any) => {
      console.log('data', data)
      if (data.Code === 0) {
        this.timePassed = false;
        this.textError = '';
        this.smsDidntArrive();
      } else {
        this.setIndication('error', true);
        this.showErrorMessage();
        this.otpErrorMessage = 'קוד האימות שהזנת שגוי';
      }
    },
      err => {
        this.errorModalService.setNewError({
          title: 'אופס...',
          description: 'נראה שמשהו השתבש בדרך',
          type: 'error',
          buttonText: 'נסה שנית',
        }).open()
      }
    );
  }


  smsDidntArrive() {
    this.timePassed = false;
    this.to = setTimeout(() => {
      if (!this.otpVerified)
        this.timePassed = true;
    }, 30 * 1000);
  }

  otpChanges(e) {
    if(this.otpErrorMessageShow)
      this.otpErrorMessageShow = false;
    let otp = e.target.value;
    this.setIndication('error', false);
    let body = {
      OtpCode: otp,
      SecondaryIdentifier: this.forgotPasswordObj.email,
      PrimaryIdentifier: this.forgotPasswordObj.phone
    };
    if (otp.length === 6) {

      this.setIndication('loading', true);

      e.target.disabled = true;
      this.otpService.verifyOtp(body).subscribe(
        (data: any) => {
          if (data.Code === 0) {
            this.otpVerified = true;
            this.timePassed = true;
            this.textError = '';
            this.setIndication('success', true);
            console.log('data', data)
            this.token = data.Data;
            //User enter right 6 digits OTP + got success response
            // this.tokenGenerated = <string>data.Data;
            // this.otpVerified = !this.otpVerified;
            //Popover of create pin opened
          }
          else if(data.Code = 1006){
            e.target.disabled = false;
            e.target.value = '';
            this.setIndication('error', true);
            this.showErrorMessage();
            this.otpErrorMessage = 'קוד האימות שהזנת שגוי';
          }
        },
        err => {
          this.setIndication('error', true);
          e.target.disabled = false;
          e.target.value = '';
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'בעיה עם הקוד שהוזן',
            type: 'error',
            buttonText: 'אישור',
          }).open()
        }
      );
    }
  }

  
  setIndication(state: string, show: boolean){
    this.isIndication = show;
    this.indicationState = state;
  }

  showErrorMessage() {
    this.otpErrorMessageShow = true;
    return this;

  }

  savePassword() {
    if (this.utilityService.checkIfAllInputsAreValid()) {
      let body = {
        PrimaryIdentifier: this.forgotPasswordObj.phone,
        TokenGenerated: this.token,
        NewPassword: this.passcode
      };
      this.otpService.savePincode(body).subscribe(
        (data: any) => {
          if (data.Code === 0) {
            //User code success
            this.token = '';
            this.otpService.createToken(this.passcode).subscribe((data: string ) => {
              if (data) {
                this.store.dispatch(new Login({ token: data }));
                this._nav.next();
                // this._router.navigate(['/onboarding', 'howitworks']);
              }else {
                this.errorModalService.setNewError({
                  title: 'אופס...',
                  description: 'הקוד שהוכנס איננו תקין',
                  type: 'error',
                  buttonText: 'אישור',
                }).open()
              }
            },
              err => this.errorModalService.setNewError({
                title: 'שגיאה',
                description: 'אנא ודא שכל השדות תקינים',
                type: 'error',
                buttonText: 'אישור',
              }).open()
            );
          }
          else {
            this.errorModalService.setNewError({
              title: 'אופס...',
              description: 'הקוד שהוכנס איננו תקין',
              type: 'error',
              buttonText: 'אישור',
            }).open()
          }
        },
        err => this.errorModalService.setNewError({
          title: 'אופס...',
          description: 'ודא שהקוד שהוזן תקין',
          type: 'error',
          buttonText: 'אישור',
        }).open()
      )
    }
  }

}
