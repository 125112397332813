import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'authentication-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  

  constructor() {
  }

  ngOnInit() {
    
  }

 

}
