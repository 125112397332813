import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, NgZone } from '@angular/core';
import { AccountService } from '../../account.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MbscDatetimeOptions } from 'src/lib/mobiscroll-package/dist/js/mobiscroll';
import { Store } from '@ngrx/store';
import { AppState, selectRegisterState } from 'src/app/store/app.states';
import { idValidator } from 'src/app/shared/services/validators';
import { OtpService } from 'src/app/onboarding/services/otp/otp.service';
import { OcrService } from 'src/app/onboarding/services/ocr/ocr.service';
import { Response } from './../../../models/global.models';
import { NavManagerService } from 'src/app/shared/components/robin-navbar/robin-navbar.service';
import { ErrorModalService } from 'src/app/shared/components/modals/error-modal/modal.service';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { UtilityService } from 'src/app/core/services/utility.service';
import { ModalService } from 'src/app/shared/components/robin-modal/modal.service';
import { Router } from '@angular/router';

declare const window;

@Component({
  selector: 'app-update-data-mislaka',
  templateUrl: './update-data-mislaka.component.html',
  styleUrls: ['./update-data-mislaka.component.scss']
})
export class UpdateDataMislakaComponent implements OnInit, AfterViewInit, OnDestroy {

  numLastUpdate;
  coins;
  friendsOpen;
  creditOpen = false;
  creditcardForm: FormGroup;
  phoneNumber;
  submitted = false;
  creditcardErrorMessageShow = false;
  creditErrorMessage = '';
  aYearFromNow = new Date();
  expOutput = '';
  drawerHeight;
  @ViewChild('exp') exp: ElementRef;
  mobiScrollClick;

  options: MbscDatetimeOptions = {
    theme: 'robin-v2',
    lang: 'he',
    max: new Date(new Date(this.aYearFromNow.setFullYear(this.aYearFromNow.getFullYear() + 7)).getFullYear(), new Date().getMonth(), 1),
    min: Date.now(),
    dateWheels: 'yyyy mm',
    dateFormat: 'mm/y'
  };

  constructor(
    private accountService: AccountService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private creditcardService: OtpService,
    private ocrService: OcrService,
    private navbarService: NavManagerService,
    private errorModalService: ErrorModalService,
    private cordovaService: CordovaService,
    private utilService: UtilityService,
    private modalService: ModalService,
    private router: Router,
    private cordovaSrevice: CordovaService,
    private zone: NgZone
  ) {
    this.drawerHeight = window.innerHeight/16-6;
    console.log('drawerHeight', this.drawerHeight)
    // window.addEventListener('keyboardDidShow', function () {
    //   this.zone.run(()=> {
    //     console.log('keyboardDidShow')
    //     this.drawerHeight = (window.innerHeight/16-6)/2;
    //   })
    // });

    // window.addEventListener('keyboardDidHide', function () {
    //   this.zone.run(()=> {
    //     console.log('keyboardDidHide')
    //     this.drawerHeight = window.innerHeight/16-6;
    //   })  
    // });

    // this.numLastUpdate = this.accountService.numDaysMislaka;
    this.accountService.coin$.subscribe(data => this.coins = data);
    this.accountService.numDaysMislaka.subscribe(data => this.numLastUpdate = data);

  }

  ngOnDestroy(): void {
    // window.removeEventListener("keyboardDidShow");
  }
  ngOnInit() {
    this.store.select(selectRegisterState).subscribe((data: any) => {
      this.phoneNumber = data.user.phoneNumber;
      console.log('phoneNumber',this.phoneNumber);
    })
  }

  ngAfterViewInit(): void {
    this.createForm();
  }

  openCreditDrawer() {
  //  this.creditOpen = true;
    this.navbarService.next('creditupdatemislaka');
  }

  closeLoginClicked() {
    this.drawerHeight = window.innerHeight/16-6;
    console.log('drawerHeight',this.drawerHeight);
    this.creditOpen = false;
  }

  inviteFriendsDrawer() {
    this.friendsOpen = true;
  }

  closeFriendsClicked() {
    this.friendsOpen = false;
  }

  createForm() {
    this.creditcardForm = this.fb.group({
      id: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9), idValidator]],
      creditcard: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
      expiration: [this.expOutput, [Validators.required]],
      cvv: ['', [Validators.required, Validators.pattern(/^[0-9]/), Validators.minLength(3), Validators.maxLength(4)]],
    });
  }

  updateDataWithCredit() {
    if (this.creditcardForm.valid && !this.submitted) {
      let data = this.prepareFormData();
      this.submitted = true;
      console.log('data', data)
      this.accountService.creditCardPay(data).subscribe((data: Response) => {
        if (data.Code === 0 && data.Data) {
          this.creditOpen = false;
          this.drawerHeight = window.innerHeight/16-6;
          this.submitted = false;
          this.accountService.numDaysMislaka.next(-1);
          this.modalService.open('success-update-data');
          // if (this.navbarService.history[this.navbarService.history.length-2].url==="/account/pension")
          this.navbarService.next('pension');
        }
        else {
          this.submitted = false;
          this.setErrorMessage('נראה שפרטי האשראי שהוזנו אינם תואמים למס׳ תעודת הזהות', true);
        }
      },
        err => {
          this.submitted = false;
          this.setErrorMessage('נראה שפרטי האשראי שהוזנו אינם תואמים למס׳ תעודת הזהות', true);
        });
    }
    else {
      this.creditcardErrorMessageShow = true;
      this.creditErrorMessage = 'יש למלא את כל השדות';
    }
  }

  prepareFormData() {
    let Month;
    let tempMonth = new Date(this.creditcardForm.controls['expiration'].value).getMonth() + 1;
    let tempYear = new Date(this.creditcardForm.controls['expiration'].value).getFullYear();
    if (tempMonth < 10)
      Month = '0' + tempMonth.toString();
    else
      Month = tempMonth.toString();

    console.log('tempmonth', tempMonth);
    console.log('tempYear', tempYear)

    let temp = { ...this.creditcardForm.controls };
    const IdentityNumber = temp.id.value;
    const Cvv = temp.cvv.value;
    const CardNumber = temp.creditcard.value;

    // let parsedData = temp.expiration.value.split('/');
    const Year = tempYear.toString().substring(2, 4);
    const PhoneNumber = this.phoneNumber;
    return { IdentityNumber, Cvv, CardNumber, Month, Year, PhoneNumber };
  }

  updateDataWithCoins() {
    this.modalService.open("areYouSureCoins");
  }


  setErrorMessage(text, value) {
    this.creditcardErrorMessageShow = value;
    setTimeout(() => {
      this.creditcardErrorMessageShow = false;
    }, 1000 * 3);
    this.creditErrorMessage = text;
  }

  check(e, len) {
    console.log('e', e);
    this.utilService.digitOnly(e);
    this.utilService.inputLength(e, len);
  }

  blur() {
    console.log('blur');
    this.drawerHeight = window.innerHeight/16-6;
    console.log('this.drawerHeight',this.drawerHeight);

  }

  hideKeyboard() {
    if (window.Keyboard && this.cordovaService.platform === 'Android') window.Keyboard.hide(true);
    console.log('hi');
    setTimeout(() => {
      this.drawerHeight = (window.innerHeight/16-6)/2;
    }, 100);
    console.log('this.drawerHeight',this.drawerHeight);
  }

  showExp(event) {
    // show
    this.mobiScrollClick = true;
    window.scrollTo(0, 0);
    document.body.classList.add('disableOverflow');
  }

  closeExp(event) {
    // close
    document.body.classList.remove('disableOverflow');
    this.mobiScrollClick = false;
    document.querySelectorAll('robin-input input')[2].classList.add('set-exp-date');
  }

  setExp(event, i) {
    console.log('fff', this.expOutput, event.valueText);
    this.expOutput = event.valueText;
  }

  choosefriends() {
    this.navbarService.next('choosefriends');
  }

  choosefriendsWhatsapp() {
    this.friendsOpen = false;
    this.cordovaSrevice.open('inviteWhatsapp', this.accountService.inviteLink);
  }

  get showInviteFriends() {
    if (this.cordovaSrevice.isMobile) {
      return true;
    } else {
      return false;
    }
  }

}
