export const uiStrings = {
    buttons: {
        lets_start: "בואו נתחיל",
        already_registerd: "אני כבר רשום",

    },
    texts: { 
        hi_robin: "היי, אני רובין",
        my_mission: "משימתי היא לדאוג לעתיד הפיננסי שלך! אני יודע לסרוק ולמצוא את כל התוכניות הפנסיה והביטוח שלך."

    },
    intro: {
        hi_robin: {
            index: 0,
            title: "היי, אני רובין",
            subtitle: "משימתי היא לדאוג לעתיד הפיננסי שלך! אני יודע לסרוק ולמצוא את כל תוכניות הפנסיה והביטוח שלך.",
            url: "assets/animations/onboarding/introscreenv.png"
        },
        upgrade_savings: {
            index: 1,
            title: "שדרוג וחסכונות",
            subtitle: "אני אמליץ ואסייע לך בשידרוגים שיגדילו את חסכונותיך בהרבה מאוד כסף.",
            url: "assets/animations/onboarding/wallet.gif"
        },
        security: {
            index: 2,
            title: "אבטחת מידע",
            subtitle: "על מנת להבטיח שפרטיך ישמרו חסויים, אני מצוייד בכלי אבטחת המידע המתקדמים ביותר.",
            url: "assets/animations/onboarding/security.gif"
        },

    },
    letsKnow: {
        0: {
            title: {
                title: "בואו נכיר",
            },
            inputs: {
                firstName: {
                    placeholder: 'שם פרטי',
                    errorMessage: 'יש למלא שם פרטי'
                },
                lastName: {
                    placeholder: 'שם משפחה',
                    errorMessage: 'יש למלא שם משפחה'
                },
                email: {
                    placeholder: 'מייל',
                    errorMessage: 'כתובת המייל אינה תקינה'
                },
                phone: {
                    placeholder: 'מספר נייד',
                    errorMessage: 'מספר הנייד אינו תקין'
                },
            },
            toggles: {
                toggle :[
                    {
                        text: 'אשמח לקבל מרובין הודעות ועדכונים',
                        
                    },
                    {
                        text: 'קראתי ואני מאשר את',
                        policy: 'התקנון'
                    }
                ]
            },
            buttons:{
                text: 'הרשמה'
            },
            message:"ישנה שגיאה",
            bottom:{
                register:'בלחיצה על "הרשמה" אני מאשר/ת את',
                conditions:"תנאי השימוש",
                robin:"של רובין",
                alreadyregistered:"רשומים? היכנסו מפה",
            }
 

        }
    },
    identification: {
        idCard:{
            idCardMain:{
                title: `צילום תעודת זהות או רישיון נהיגה`,
                subtitle: `לצורך התחברות למסלקה הפנסיונית של משרד האוצר, יש להעלות צילום של תעודה מזהה`,
                subTextCapture: `להעלאת תמונת של תעודה מזהה`,
                bottomEnd: `אין ברשותי כרגע תעודה מזהה`
            }
        }
    },
    pleaseWait: {
        subTitle: 'אנא המתינו. זה לוקח רק דקה',
        bullets: [
            {text: 'בודק תקינות תעודת זהות', },
            {text: 'מאבחן מבנה ותווי פנים'},
            {text: 'מצליב אבחון פנים לתמונת פספורט'},
        ]
    },
    takanon:{
        title: "תקנון השימוש ולורס איפסום",
    },
    reminder:{
        title: "אני מזכירה מעולה! מתי להזכיר לך?",
        subtitle: "אפשר לאמת את זהותך באמצעות התאמה בין מספר תעודות זהות ומספר כדטיס אשראי",
        buttons: "שמור"
    },
    questionnaire:{
        title:"שאלון התאמה אישית",
        subtitle: "נשאר לנו רק לואם איפסום כדי חשיבות של שאלון של רובין לורם איפסום דלורס",
        textbottom: "תענה רק על 7 שאלות קצרות",
    },
    otp:{
        header: "הזנת קוד אימות",
        didntarrive: "קוד האימות לא הגיע? ניתן לנסות שוב באמצעות",
        support: "עדיין ללא הצלחה? ניתן לפנות לתמיכה הטכנית ונבין ביחד מה הבעיה",
        otpdescription: "קוד האימות אמור להגיע אליך תוך מספר שניות",
        sms: "SMS",
        call: "שיחה קולית",
        passwordcreate: "יצירת סיסמה אישית",
        infodefense: "להגנת פרטיך האישיים, יש ליצור סיסמה בת 6 ספרות",
        button: "שמור סיסמה",
    },
    laststep:{
        header:{
            title: "שלב אחרון חביב",
            subtitle: " נשאר רק לענות על שתי שאלות אחרונות, בהתאם להנחיות משרד האוצר, ואני יוצא לדרך!",
        },
        questions:{
            firstquestion: "האם יצאת מהארץ ב-3 שנים האחרונות?",
            secondquestion: "האם הונפק לך דרכון ב-3 שנים האחרונות?",
        },
        button: "אתר את מוצרי",
    },
    howitworks:{
        title: "מה אעשה עבורך עכשיו?",
        codeverification: "הצגת תמונת מצב פיננסי נוכחי",
        title01: "הצגת תמונת מצב פיננסי נוכחי",
        text01: "נאמת את זהותך ובכך אוכל לאתר את כל מוצרי הביטוח והחיסכון הפנסיוני שנפתחו על שמך אי פעם.",
        text0101: "",
        title02: "מקסום הכסף שלך",
        text02: "אמליץ לך על שדרוגים והתאמות שישפרו משמעותית את עתידך הפיננסי.",
        button: "נשמע נהדר, בוא נתחיל",
    },
    idcardtips:{
        title:"טיפים לצילום מושלם",
        tips:{
            tip1:"1. מומלץ להניח את התעודה על רקע כהה",
            tip2:"2. יש לוודא שהתעודה ממוקמת במרכז התמונה",
            tip3:"3. יש לוודא שהתמונה מפוקסת וברורה",
        },
        button: "הבנתי",
    },
    idcardpreview:{
        title:"צילום תעודת זהות",
        subtitle:"התמונה מפוקסת וברורה?",
        newpicture:"העלאת תמונה חדשה",
        message: "השמירת התמונה לא הצליח",
        button: "המשך",
    },
    selfiepreview: {
        title: "צילום עצמי (סלפי)",
        subtitle: "התמונה מפוקסת וברורה?",
        button: "יצא טוב! המשך",
        newpicture: "צילום תמונה חדשה",
        message: " התמונה לא הצליח",
    },
    selfie: {
        title:"צילום עצמי (סלפי)",
        subtitle: "יש לכוון את המצלמה לכיוון הפנים, ממש כמו ראי",
        selfie: "",
        newselfie: "צילום תמונה חדשה",
        button: "המשך",
        message: "השמירת התמונה לא הצליח",
    },
    checkinfo:{
        header:{
            title:"איתור מוצרים ביטוחיים",
            subtitle: "יש להקליד את מספר הזהות ותאריך הנפקת תעודת הזהות כפי שמופיעים בתעודת הזהות",
        },
        errormessage:{
            fnamerror:"יש לציין שם פרטי",
            lnameerror:"יש לציין שם משפחה",
            iderror: "יש לציין תעודת זהות",
            iddateerror: "יש לציין תאריך הנפקה",
        },
        buttons:{
            footerbutton: "הכל נכון! המשך",
        }

    },
    noidmodal:{
        header:{
            title:"אין ברשותך כרגע תעודת זהות?",
            subtitle: " ניתן לאמת את זהותך גם דרך הצלבת מס' הזהות שלך עם מספר כרטיס אשראי כלשהו על שמך",
        },
        buttons:{
            creditcard: "אימות כרטיס אשראי",
            footerbutton:"הזכר לי מאוחר יותר",
        },
    },
    risklevel:{
            title: "רמת הסיכון:",
    },
    newrobin: {
        title: "התחדשתי!",
        subtitle: "נתנו לי עיצוב חדש דנדש, חוזקתי בטכנולוגיה ופיצ'רים מתקדמים!",
        button: "בוא נתחיל לחסוך",
    },
    login:{
        title:"נרשמת בעבר?",
        subtitle:"יש להזין את הפרטים שאיתם נרשמת בעבר בכדי שאוכל לזהות אותך",
        input1:"אימייל",
        inputerror1:"האימייל לא תקין",
        input2:"מספר טלפון",
        inputerror2:"מספר הטלפון לא נכון",
        footer:"העבר אותי להרשמה"
    },
    imageoptionmodal:{
        button1: "גלריה",
        button2: "מצלמה"
    }



}