import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { SummaryStatus } from 'src/app/account/insurance/components/insurance-my-life/insurance-my-life.component';
import { CacheService } from './cache.service';
import { Store } from '@ngrx/store';
import { AppState, selectInsurance } from 'src/app/store/app.states';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(private cacheService: CacheService, private store: Store<AppState>) { }

  setErrorInEmptyFields(form: FormGroup) {
    Object.keys(form.value).map(key => {
      if (!!!(form.value[key])) {
        form.controls[key].markAsTouched()
      }
    })
  }

  b64toBlob(b64Data, contentType, sliceSize = null) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  imagetoblob(ImageURL) {
    // Split the base64 string in data and contentType
    var block = ImageURL.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1];// In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    return this.b64toBlob(realData, contentType);
  }
  convertMartialStatus(martialStatus) {
    switch (martialStatus) {
      case 'לא רלוונטי':
        return 0;
      case 'רווק':
        return 1;
      case 'נשוי':
        return 2;
      case 'גרוש':
        return 3;
      case 'אלמן':
        return 4;
      case 'יודע בציבור':
        return 100000000;
      case 'לא רלוונטי':
        return 5;
    }
  }

  //this method customizes the cached object during the process and returns it
  prepareObject(num) {
    return new Promise((res, rej) => {
      //TODO: get all info from store and replace within the obj that being sent
      this.store.select(selectInsurance).pipe(map((data: any) => data.insuranceDetails && data.insuranceDetails.LifeInsurance)).subscribe(
        data => {
          const obj: any = {}
          const cachedObj = this.cacheService.cache;
          obj.DigitalProcessId = this.cacheService.generatedProcessId.DigitalEventId
          obj.FilesLinks = []
          obj.Data = {}
          obj.Data.Address = cachedObj[0]
          obj.Data.Address.City = obj.Data.Address.city
          obj.Data.Address.Street = obj.Data.Address.street
          obj.Data.Address.House = obj.Data.Address.number
          
          obj.Data.Occupation = cachedObj[0].occ;
          obj.Data.MaritalStatus = this.convertMartialStatus(cachedObj[0].familyStatus);
          // obj.Data.heightAndWeight = cachedObj[1]
          obj.Data.Height = cachedObj[1].height;
          obj.Data.Weight = cachedObj[1].weight;
          obj.Data.Beneficiaries = cachedObj[3]
          obj.Data.Questionnaire = cachedObj[4]
          obj.Data.NewPremium = data.SummaryInsuranceRecommendation.RecommendedInfo.NewPremium;
          if (cachedObj[8] && cachedObj[8].creditInfo && cachedObj[8].token) {
            // obj.Data.CreditCardToken = cachedObj[8].token;
            obj.Data.CreditcardInfo = cachedObj[8].creditInfo;
            obj.Data.CreditcardInfo.Token = cachedObj[8].token;
          }
          delete obj.Data.Address.occ;
          delete obj.Data.Address.city;
          delete obj.Data.Address.street;
          delete obj.Data.Address.number;
          delete obj.Data.Address.familyStatus;
          obj.Data = JSON.stringify(obj.Data)
          // TODO: check whether or not creditcard token exists
          switch (num) {
            //purchase =>
            case (SummaryStatus.Under):
              obj.FilesLinks.push(cachedObj[5])
              break;
            //upgrade => cancel policy section doesnt exists
            case SummaryStatus.Upgrade:
              obj.FilesLinks.push(cachedObj[5])
              obj.FilesLinks.push(cachedObj[6])
              break;
          }
          // const id = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.FromProduct && data.SummaryInsuranceRecommendation.FromProduct.Id
          // const combinedArr = data.CombinedFrom && data.CombinedFrom[id]
          // if (id) {
          //   obj.ProductId = combinedArr.ProductId
          //   obj.RecommendedProductId = combinedArr.ProductInsuranceRecommendation && combinedArr.ProductInsuranceRecommendation.RecommendedManufacturer && combinedArr.ProductInsuranceRecommendation.RecommendedManufacturer.ID
          // }
          //TODO: ask andrey
          obj.PaymentMethod = 2;
          //TODO: 
          obj.SubCategory = 4;
          //TODO: complete when andrey finishes the api
          obj.Amount = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.RecommendedInfo && data.SummaryInsuranceRecommendation.RecommendedInfo.Amount
          obj.Price = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.RecommendedInfo && data.SummaryInsuranceRecommendation.RecommendedInfo.NewPremium
          obj.ManufacturerCode = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.RecommendedManufacturer && data.SummaryInsuranceRecommendation.RecommendedManufacturer.Code
          if (data.SummaryInsuranceRecommendation.FromProduct) { //שחלוף
            obj.ProductId = data.CombinedFrom[data.SummaryInsuranceRecommendation.FromProduct.Id].ProductId;
            obj.EventType = 2;
          } else {
            obj.EventType = 1;
          }
          obj.RecommendedProductId = data.SummaryInsuranceRecommendation && data.SummaryInsuranceRecommendation.RecommendedManufacturer && data.SummaryInsuranceRecommendation.RecommendedManufacturer.ID;


          res(obj);
        }
      )
  })
}
}

export const mobiScrollOptions = {
  theme: 'robin-v2',
  lang: 'he',
  // max: Date.now(),
  dateWheels: 'yyyy mm dd',
  dateFormat: 'dd/mm/y'
};

export const mobiScrollOptions2 = {
  theme: 'robin-v2',
  lang: 'he',
  // max: Date.now(),
  dateWheels: 'yyyy mm',
  dateFormat: 'mm/y'
};
