import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FetchService {
  baseUrl;
  constructor(private http: HttpClient) {
    this.baseUrl = environment.endPoint;
  }

  fetchComments() {
    return this.http.get(`${this.baseUrl}/rateus`);
  }
  sendAComment(data) {
    return this.http.post(`${this.baseUrl}/rateus`, data);
  }
  likeAComment(id, like) {
    return this.http.put(`${this.baseUrl}/rateus`, {
      CommentId: id,
      Liked: like
    });
  }
  deleteAComment(id) {
    return this.http.delete(`${this.baseUrl}/rateus/${id}`);
  }
}
