import { Component, OnInit } from '@angular/core';
import { NavManagerService } from '../../../../robin-navbar/robin-navbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private navbarService: NavManagerService,
  private _router: Router) {
    
   }

  ngOnInit() {
  }

  redirectTo(url) {
    this.navbarService.next(url);
  }
  back() {
    var url;
    for (let i=this.navbarService.history.length-2; i>=0; i-- ) 
    {
      if (this.navbarService.history[i].url !== '/termsofcondition' && this.navbarService.history[i].url !=='/about' && this.navbarService.history[i].url !=='/privacy') {
        url = this.navbarService.history[i].url;
        break;
      }
    }
    this._router.navigate([url])
  }
}
