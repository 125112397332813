import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectToken, selectAnonymous, selectAuthenticated, selectRegisterState } from 'src/app/store/app.states';
import { RobinSideNavService } from '../../robin-side-nav.service';
import { NavManagerService } from 'src/app/shared/components/robin-navbar/robin-navbar.service';
import { AccountService } from 'src/app/account/account.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ModalService } from 'src/app/shared/components/robin-modal/modal.service';
import { FormBuilder } from '@angular/forms';
import { HomepageService } from 'src/app/homepage/homepage.service';
import { ErrorModalService } from '../../../error-modal/modal.service';


@Component({
  selector: 'app-personal-profile',
  templateUrl: './personal-profile.component.html',
  styleUrls: ['./personal-profile.component.scss']
})
export class PersonalProfileComponent implements OnInit {
  user;
  userAuthenticated;
  risklevelWords;
  maritalStatusWords;
  userRegisterInfo;
  maritalForm;
  selected;
  objmarital;
  occupationStatus;
  bannerQuestionnaire = false;
  showQuestionnaire = false;
  showNoEdit = false;
  addressClick = false;
  urlClick;

  constructor(private store: Store<AppState>,
    private sideNavService: RobinSideNavService,
    private navbarService: NavManagerService,
    public accountService: AccountService,
    private router: Router,
    private modalService: ModalService,
    private fb: FormBuilder,
    private homepageService: HomepageService,
    private errorModalService: ErrorModalService) {

    this.store.select(selectAnonymous).subscribe((data: any) => {
      this.user = data.anonymous;
      console.log(this.user, 'this.user');
    });

    this.store.select(selectRegisterState).subscribe((data: any) => {
      this.userRegisterInfo = data.user;
      console.log(this.userRegisterInfo, 'userregisterinfo');
    });

    // this.store.select(selectAuthenticated).pipe(map(data => data.authenticated)).subscribe(data => {
    //   this.userAuthenticated = data;
    //   if (this.userAuthenticated)
    //     this.risklevelWords = this.convertRiskLevelToWords(this.userAuthenticated.RiskLevel)
    //   console.log(this.userAuthenticated, 'this.userAuthenticated');
    // });
    this.userAuthenticated = this.accountService.authenticatedModel;
    if (this.userAuthenticated) {
      if (this.accountService.authenticatedModel.Childrens.length > 0) {
        this.accountService.authenticatedModel.Childrens = this.createArrayChildrenYears(this.accountService.authenticatedModel.Childrens);
      }
      this.risklevelWords = this.convertRiskLevelToWords(this.userAuthenticated.RiskLevel);
      this.maritalStatusWords = this.convertMaritalStatusToWords(this.userAuthenticated.AccountMaritalStatus);
      this.occupationStatus = this.convertOccupationStatusToWords(this.userAuthenticated.Position)
    }
    this.maritalForm = this.fb.group({
      radio: [this.userAuthenticated.AccountMaritalStatus],

    });

    this.isShowQuestionnaireBanner(this.homepageService.firstTimeQuestionnaire, this.user.FilledQuistionnaire);
  }

  ngOnInit() {
    this.objmarital = [
      {
        text: 'רווק/ה',
        value: 1
      },
      {
        text: 'נשוי/אה',
        value: 2
      },
      {
        text: 'גרוש/ה',
        value: 3
      },
      {
        text: 'אלמן/ה',
        value: 4
      },
      {
        text: 'ידוע/ה בציבור',
        value: 100000000
      },

    ]
  }

  createArrayChildrenYears(childrenarray) {
    if (childrenarray[0].BirthDate) {
      for (let i = 0; i < childrenarray.length; i++) {
        childrenarray[i] = childrenarray[i].BirthDate;
      }
    }
    return childrenarray;
  }

  convertRiskLevelToWords(risklevel) {
    switch (risklevel) {
      case 1:
        return 'שמרן'
      case 2:
        return 'זהיר'
      case 3:
        return 'מאוזן'
      case 4:
        return 'צמיחה'
      case 5:
        return 'מקצוען'
      default:
        return 'לא הוגדר'
    }
  }
  convertMaritalStatusToWords(maritalstatus) {
    switch (maritalstatus) {
      case 0:
        return ''
      case 1:
        return 'רווק/ה'
      case 2:
        return 'נשוי'
      case 3:
        return 'גרוש/ה'
      case 4:
        return 'אלמן/ה'
      case 100000000:
        return 'ידוע/ה בציבור'
      case 5:
        return ''
      default:
        return ''
    }
  }

  clickOnNotEdit() {
    this.showNoEdit = true;
    setTimeout(() => {
      this.showNoEdit = false;
    }, 3000);
  }

  convertOccupationStatusToWords(occupationStatus) {
    switch (occupationStatus) {
      case 1:
        return 'שכיר'
      case 2:
        return 'עצמאי'
      // case 3:
      //   return 'בעל שליטה'
      // case 4:
      //   return 'פנסיונר'
      default:
        return ''
    }
  }

  sendNag(maritalstatus) {
    if (maritalstatus) {
      switch (maritalstatus) {
        case 0:
          return
        case 1:
          return 0
        case 2:
          return 1
        case 4:
          return 2
        case 8:
          return 3
        case 16:
          return 4
        case 32:
          return
        default:
          return
      }
    } else
      return;
  }

  goToPersonalSupport() {
    this.router.navigate(['/support']);
  }

  goToQuestionnaire() {
    this.router.navigate(['/questionnaire']);
  }

  NoQuestionnaire() {
    this.showQuestionnaire = false;
  }

  isShowQuestionnaireBanner(firstTime, isQuestionnaire) {
    if (!firstTime) {
      if (!isQuestionnaire) {
        this.bannerQuestionnaire = true;
        this.showQuestionnaire = true;
      }
    } else {
      this.bannerQuestionnaire = false;
      this.showQuestionnaire = false;
    }

  }

  editField(url) {
    
    console.log('url ',url);
    this.urlClick = url;
    setTimeout(() => {
      if (url === 'maritalStatus')
        this.modalService.open('maritalStatus');
      else
        this.router.navigate([url]);
        this.urlClick = false;

    }, 100)

  }

  save() {
    var sendNewDetails = {
      RiskLevel: this.accountService.authenticatedModel.RiskLevel,
      City: this.accountService.authenticatedModel.City,
      StreetName: this.accountService.authenticatedModel.StreetName,
      HouseNumber: this.accountService.authenticatedModel.HouseNumber,
      PostalCode: this.accountService.authenticatedModel.PostalCode,
      Country: this.accountService.authenticatedModel.Country,
      AccountMaritalStatus: this.maritalForm.value.radio,
      LastSalary: this.accountService.authenticatedModel.Salary,
      JobTitle: this.accountService.authenticatedModel.JobTitle,
      Position: this.accountService.authenticatedModel.Position,
      EmployerName: this.accountService.authenticatedModel.EmployerName,
      // NumberOfChildrens: this.accountService.authenticatedModel.NumberOfChildrens
      ChildrensBirthdates: this.accountService.authenticatedModel.Childrens

    };
    this.accountService.updateProfile(sendNewDetails).subscribe((data: any) => {
      if (data.Code === 0) {
        this.userAuthenticated.AccountMaritalStatus = this.maritalForm.value.radio;
        this.maritalStatusWords = this.convertMaritalStatusToWords(this.maritalForm.value.radio);
        this.accountService.showSuccessUpdate = true;
        setTimeout(() => {
          this.accountService.showSuccessUpdate = false;
        }, 3000);
        this.modalService.close('maritalStatus');
      } else {
        this.modalService.close('maritalStatus');
        this.errorModalService
          .setNewError({
            title: "אופס...",
            description: "נראה שמשהו השתבש בדרך",
            type: "error",
            buttonText: "נסה שנית"
          })
          .open();
      }
    });

    this.modalService.close('maritalStatus');
  }

}
