import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule  } from '@angular/material/form-field';
import { PreventDoubleSubmit } from './../core/directives/disableafterclick.directive';
import { RouterModule } from '@angular/router';
import { RobinQuestionComponent } from './components/robin-question/robin-question.component';
import { RobinBooleanComponent } from './components/buttons/robin-boolean/robin-boolean.component';
import { RobinTextareaComponent } from './components/robin-textarea/robin-textarea.component';


// third party
import { MbscModule } from '../../lib/mobiscroll/js/mobiscroll.angular.min';
import { SignaturePadModule } from 'angular2-signaturepad';


// angular's modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// directives
import { FocusDirective } from './directives/focus.directive';
import { EmailValidatorDirective } from './directives/email-validator.directive';
import { LongPress } from './directives/on-hold.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';

// animations
import { FadeAnimation } from './animations/fade.animation';

// Robin's shared components
import { RobinButtonComponent } from './components/buttons/robin-button/robin-button.component';
import { RobinInputComponent } from './components/robin-input/robin-input.component';
import { RobinToggleComponent } from './components/robin-toggle/robin-toggle.component';
import { RobinModalComponent } from './components/robin-modal/robin-modal.component';
import { SupportMainComponent } from './components/support/main/main.component';
import { SupportSuccessComponent } from './components/support/success/success.component';
import { SupportMessageComponent } from './components/support/message/message.component';
import { RobinMessageComponent } from './components/robin-message/robin-message.component';
import { RobinIndicationComponent } from './components/robin-indication/robin-indication.component';
import { RobinBottomDrawerComponent } from './components/robin-bottom-drawer/robin-bottom-drawer.component';
import { RobinDropdownComponent } from './components/robin-dropdown/robin-dropdown.component';
import { RobinBinaryButtonsComponent } from './components/questionnaire/robin-binary-buttons/robin-binary-buttons.component';
import { RobinSliderComponent } from './components/robin-slider/robin-slider.component';
import { RobinAutocompleteComponent } from './components/robin-autocomplete/robin-autocomplete.component';
import { RobinCheckboxComponent } from './components/robin-checkbox/robin-checkbox.component';
import { RobinRadioButtonsComponent } from './components/robin-radio-buttons/robin-radio-buttons.component';
import { RobinRadioItemComponent } from './components/robin-radio-buttons/robin-radio-item/robin-radio-item.component';
import { RobinHeaderComponent } from './components/typography/robin-header/robin-header.component';
import { RobinRoundedButtonComponent } from './components/buttons/robin-rounded-button/robin-rounded-button.component';
import { RobinNavbarComponent } from './components/robin-navbar/robin-navbar.component';
import { RobinHamburgerComponent } from './components/indicators/robin-hamburger/robin-hamburger.component';
import { RobinSecondaryButtonComponent } from './components/buttons/robin-secondary-button/robin-secondary-button.component';
import { RobinStateComponent } from './components/indicators/robin-state/robin-state.component';
import { RobinMorseComponent } from './components/indicators/robin-morse/robin-morse.component';
import { RobinCoverIconComponent } from './components/indicators/robin-cover-icon/robin-cover-icon.component';
import { RobinRiskLevelComponent } from './components/indicators/robin-risk-level/robin-risk-level.component';
import { RobinSwipeComponent } from './components/robin-swipe/robin-swipe.component';
import { DrawerComponent } from './components/robin-navbar/drawer/drawer.component';
import { RobinRecommendedSolutionComponent } from './components/robin-recommended-solution/robin-recommended-solution.component';
import { HeartComponent } from './components/robin-recommended-solution/heart/heart.component';
import { RobinPasscodeComponent } from './components/robin-passcode/robin-passcode.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { RobinVComponent } from './components/robin-v/robin-v.component';
import { ErrorModalComponent } from './components/modals/error-modal/error-modal.component';
import { RobinLoadingComponent } from './components/robin-loading/robin-loading.component';
import { RecommendationBarComponent } from './components/indicators/recommendation-bar/recommendation-bar.component';
import { BarComponent } from './components/indicators/recommendation-bar/bar/bar.component';
import { RobinBooleanItemComponent } from './components/buttons/robin-boolean/robin-boolean-item/robin-boolean-item.component';
import { RobinSideNavComponent } from './components/modals/robin-side-nav/robin-side-nav.component';
import { RobinSideNavItemComponent } from './components/modals/robin-side-nav/robin-side-nav-item/robin-side-nav-item.component';
import { TechSupportComponent } from './components/support/tech-support/tech-support.component';


import { SettingsComponent } from './components/modals/robin-side-nav/pages/settings/settings.component';
import { AboutComponent } from './components/modals/robin-side-nav/pages/about/about.component';
import { PrivacyComponent } from './components/modals/robin-side-nav/pages/privacy/privacy.component';
import { SecurityComponent } from './components/modals/robin-side-nav/pages/security/security.component';
import { PhonePipe } from './directives/phonepipe';
import { RobinStepperBarComponent } from './components/digital-proccess/robin-stepper-bar/robin-stepper-bar.component';
import { StepperBarItemComponent } from './components/digital-proccess/robin-stepper-bar/stepper-bar-item/stepper-bar-item.component';
import { RobinSignatureComponent } from './components/robin-signature/robin-signature.component';
import { RobinIncrementorComponent } from './components/robin-incrementor/robin-incrementor.component';
import { RobinBubbleStepComponent } from './components/robin-bubble-step/robin-bubble-step.component';
import { BubbleStepItemComponent } from './components/robin-bubble-step/bubble-step-item/bubble-step-item.component';
import { BubbleComponent } from './components/robin-bubble-step/bubble-step-item/bubble/bubble.component';

import { SafeHtmlPipe } from './directives/safe-html.pipe';
import { PersonalProfileComponent } from './components/modals/robin-side-nav/pages/personal-profile/personal-profile.component';
import { UpdateAddressComponent } from './components/modals/robin-side-nav/pages/personal-profile/update-address/update-address.component';
import { UpdateChildrenComponent } from './components/modals/robin-side-nav/pages/personal-profile/update-children/update-children.component';
import { UpdateOccupationComponent } from './components/modals/robin-side-nav/pages/personal-profile/update-occupation/update-occupation.component';
import { UpdateOccupationStatusComponent } from './components/modals/robin-side-nav/pages/personal-profile/update-occupation-status/update-occupation-status.component';
import { UpdateEmployerNameComponent } from './components/modals/robin-side-nav/pages/personal-profile/update-employer-name/update-employer-name.component';
import { UpdateSalaryComponent } from './components/modals/robin-side-nav/pages/personal-profile/update-salary/update-salary.component';
import { UpdateRiskLevelComponent } from './components/modals/robin-side-nav/pages/personal-profile/update-risk-level/update-risk-level.component';
import { CreditcardComponent } from './components/creditcard/creditcard.component';
import { PickerComponent } from './components/picker/picker.component';
import { ValidatorService } from './services/validator.service';
import { OrInputComponent } from './components/or-input/or-input.component';
import { OrCheckboxComponent } from './components/or-checkbox/or-checkbox.component';
import { BarsComparisonComponent } from './components/bars-comparison/bars-comparison.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MbscModule,
    MatSliderModule,
    MatSelectModule,
    MatFormFieldModule,
    SignaturePadModule,
  ],
  declarations: [
    RobinStepperBarComponent,
    AutofocusDirective,
    LongPress,
    RobinStateComponent,
    RobinToggleComponent, RobinModalComponent,
    RobinButtonComponent, RobinInputComponent,
    FocusDirective, EmailValidatorDirective,
    RobinToggleComponent, RobinModalComponent,
    ClickOutsideDirective,
    SupportMainComponent,
    SupportSuccessComponent,
    SupportMessageComponent,
    RobinMessageComponent,
    RobinIndicationComponent,
    RobinBottomDrawerComponent,
    RobinDropdownComponent,
    RobinBinaryButtonsComponent,
    RobinTextareaComponent,
    RobinBooleanComponent,
    RobinSliderComponent,
    RobinAutocompleteComponent,
    RobinCheckboxComponent,
    RobinRadioButtonsComponent,
    RobinRadioItemComponent,
    RobinQuestionComponent,
    RobinHeaderComponent,
    RobinRoundedButtonComponent,
    RobinNavbarComponent,
    RobinHamburgerComponent,
    RobinSecondaryButtonComponent,
    RobinMorseComponent,
    RobinCoverIconComponent,
    RobinRiskLevelComponent,
    RobinSwipeComponent,
    DrawerComponent,
    RobinRecommendedSolutionComponent,
    HeartComponent,
    RobinPasscodeComponent,
    RobinVComponent,
    ErrorModalComponent,
    RobinLoadingComponent,
    RecommendationBarComponent,
    BarComponent,
    RobinBooleanItemComponent,
    RobinSideNavComponent,
    RobinSideNavItemComponent,
    TechSupportComponent,
    SettingsComponent,
    AboutComponent,
    PrivacyComponent,
    SecurityComponent,
    PreventDoubleSubmit,
    PhonePipe,
    RobinStepperBarComponent,
    StepperBarItemComponent,
    RobinSignatureComponent,
    RobinIncrementorComponent,
    RobinBubbleStepComponent,
    BubbleStepItemComponent,
    BubbleComponent,
    SafeHtmlPipe,
    PersonalProfileComponent,
    UpdateAddressComponent,
    UpdateChildrenComponent,
    UpdateOccupationComponent,
    UpdateOccupationStatusComponent,
    UpdateEmployerNameComponent,
    UpdateSalaryComponent,
    UpdateRiskLevelComponent,
    CreditcardComponent,
    PickerComponent,
    OrInputComponent,
    OrCheckboxComponent,
    BarsComparisonComponent,
  ],

  exports: [
    RobinBubbleStepComponent,
    RobinIncrementorComponent,
    RobinSignatureComponent,
    RobinStepperBarComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    MbscModule,
    RobinSideNavItemComponent,
    RobinSideNavComponent,
    ErrorModalComponent,
    RobinRecommendedSolutionComponent,
    RobinSwipeComponent,
    RobinRiskLevelComponent,
    RobinCoverIconComponent,
    RobinMorseComponent,
    RobinRoundedButtonComponent,
    RobinStateComponent,
    RobinBottomDrawerComponent, RobinCheckboxComponent,
    RobinBinaryButtonsComponent,
    ClickOutsideDirective, SupportSuccessComponent, RobinMessageComponent,
    LongPress, SupportMainComponent, SupportMessageComponent, RobinIndicationComponent,
    RobinToggleComponent, RobinModalComponent,
    RobinButtonComponent, RobinInputComponent, FocusDirective, EmailValidatorDirective,
    RobinRadioButtonsComponent,
    RobinHeaderComponent, RobinNavbarComponent,
    RobinHamburgerComponent, RobinSecondaryButtonComponent, RobinPasscodeComponent,
    RobinDropdownComponent, RobinBooleanComponent, RobinSliderComponent, RobinAutocompleteComponent, RobinTextareaComponent, RobinQuestionComponent,
    AutofocusDirective,
    RobinVComponent,
    RobinLoadingComponent,
    RecommendationBarComponent,
    BarComponent,
    TechSupportComponent, PreventDoubleSubmit, PhonePipe, SafeHtmlPipe,
    CreditcardComponent,
    PickerComponent,
    OrInputComponent,
    OrCheckboxComponent,
    BarsComparisonComponent,
  ],
  providers:[
    ValidatorService
  ]
})
export class SharedModule { }
