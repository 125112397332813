import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'like',
  templateUrl: './like.component.html',
  styleUrls: ['./like.component.scss']
})
export class LikeComponent implements OnInit {
  clicked = false;

  @Output() heartClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  heartClick(){
      this.clicked = !this.clicked;
      this.heartClicked.emit(this.clicked);
  }

}
