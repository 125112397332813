import { OnInit } from '@angular/core';
import { UtilityService } from './utility.service';
import { OtpService } from './../../onboarding/services/otp/otp.service';
import { Store } from '@ngrx/store';
import { AppState, selectToken } from '../../store/app.states';
import { HTTP_INTERCEPTORS, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse, HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import * as uid from 'uuid';
import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { Token } from '@angular/compiler';
import { shareReplay, map, catchError, filter, take, switchMap, timeout, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorModalService } from 'src/app/shared/components/modals/error-modal/modal.service';
import { environment } from '../../../environments/environment';
import { InsuranceService } from 'src/app/account/insurance/services/insurance.service';
import { ConfigService } from './config.service';


const API_TIMEOUT = 1000 * 60 * 5;

@Injectable()
export class HttpInterceptorService implements HttpInterceptor, OnInit {

  //We need to inject the auth service
  getState: Observable<any>;
  private token;
  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  version;
  usecache = 'true';
  public showError = true;


  constructor(private authService: OtpService,
    private uilityService: UtilityService,
    private router: Router,
    private errorModalService: ErrorModalService,
    private store: Store<AppState>,
    private insuranceService: InsuranceService,
    private configService: ConfigService) {
    this.store.select(selectToken).pipe(map(data => data.token)).subscribe(data => {
      this.token = data;
    });
    this.version = environment.version;
    this.usecache = this.insuranceService.usecache;
    // console.log('trying...');
    // this.getState = this.store.select(selectToken).pipe(
    //   map(stoken => stoken.token),
    //   shareReplay(1)
    // );
    // this.getState.subscribe(tkn => this.token = tkn);
  }


  ngOnInit() {
    console.log('on init');
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`,
        correlationId: uid(),
        // correlationId:uid
        version: this.version,
        usecache: this.insuranceService.usecache
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (this.router.url !== '/onboarding/pleasewait' && this.router.url !== '/onboarding/otp') {
      this.uilityService.setLoaderStatus(true);
    }
    return next.handle(this.addToken(req, this.token)).pipe(

      tap(ev => {
        if (ev instanceof HttpResponse) {
          this.uilityService.setLoaderStatus(false);
        }
      }),
      catchError((err) => {

        if (err instanceof HttpErrorResponse) {
          this.uilityService.setLoaderStatus(false);
          if ((<HttpErrorResponse>err).status !== 200 || (<HttpErrorResponse>err).status !== 201) {
            // switch ((<HttpErrorResponse>err).status) {
            //   case 401:
            //     this.handle401Error(req, next);
            //     break;
            //   case 400:
            //     // this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך...', 'נסה שנית',next, req);
            //     // this.requestContinuesDelivery(req,next);
            //     break;
            //   default:
            //     // return req;
            //     return throwError('שגיאה');
            // }

            // for (let i = 0; i <= this.configService.configModel.notShowHttpError.length; i++) {
            //   if (req.url === this.configService.configModel.notShowHttpError[i]) {
            //     this.showError = false;
            //   }
            // }
            if (req.url === 'https://robinplatformapi.robinpro.co.il/accounts/consult-expert-checkbox-toggle') {
              this.showError = false;
            }
            if (req.url === 'https://robinaudit.azurewebsites.net/api/AuditAppend?code=tOtMaGUwPDG55MoWJa9wqrPa6GZaKe452ykjNaUpGz/imnQacZZi4A==') {
              this.showError = false;
            }
            if (!!this.showError) {
              this.errorModalService.setNewError({
                title: 'אופס...',
                description: 'נראה שמשהו השתבש בדרך',
                type: 'error',
                buttonText: 'נסה שנית',
              }).open();
            }
          }
          return throwError('שגיאה');
        }
      }), timeout(API_TIMEOUT)
    );
  }
  openErrorMessage(title, desc, textButton, next, req) {
    this.errorModalService
      .setNewError({
        title: title,
        description: desc,
        type: 'error',
        buttonText: textButton,
      }).open();
    // return next.handle(req); 

  }

  requestContinuesDelivery(req, next) {
    return next.handle(req);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);
      return this.authService.createToken('123456').pipe(
        map((data: { access_token: string }) => {
          if (data.access_token) {
            this.tokenSubject.next(data.access_token);
            return next.handle(this.addToken(req, data.access_token)).pipe(timeout(API_TIMEOUT))
          }
        })
      ),
        err => {
          alert('interceptor couldnt handle the 401 error: ' + err);
        },
        () => {
          this.isRefreshingToken = false;
        }
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token)).pipe(timeout(API_TIMEOUT));
        }));
    }
  }
}




export const httpInterceptorProviders = [

  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },

];
