import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';

export interface Question {
  id: Number;
  question: String;
  type: String;
  answers: String[];
  yesNext?: Number;
  noNext?: Number;
  hasExtension?: boolean;
  bullets?: String[];
  isExtension?: boolean;
}
@Component({
  selector: 'questionnnaire',
  templateUrl: './questionnnaire.component.html',
  styleUrls: ['./questionnnaire.component.scss']
})
export class QuestionnnaireComponent implements OnInit {

  _extension = false
  _currentQuestion = 0;
  numOfExtensionQuestions = 0
  _numOfQuestions = -1;
  _userAnswers = {}
  manipulatedQuestions: Question[] = []
  // _questions = [];
  _questions: Question[] = [{
    id: 1,
    question: 'האם אתה מעשן?',
    answers: ['כן', 'לא'],
    type: 'textarea',
    yesNext: 2,
    noNext: 3,
    bullets: ['a', 'a', 'a', 'a'],
    // hasExtension: true
  }, {
    id: 2,
    answers: ['כן', 'לא'],
    question: 'האם אתה שותה?',
    type: 'bool',
    yesNext: 3,
    noNext: 3,
  },
  {
    id: 3,
    answers: ['כן', 'לא'],
    question: 'האם אתה 3?',
    type: 'bool',
    yesNext: 4,
    noNext: 4,
  },
  {
    id: 4,
    answers: ['כן', 'לא'],
    question: 'האם אתה 2?',
    type: 'bool',
    yesNext: 5,
    noNext: 5,
  },
  {
    id: 5,
    answers: ['כן', 'לא'],
    question: 'האם אתה 2?',
    type: 'bool',
    yesNext: 6,
    noNext: 6,
  },
  {
    id: 6,
    answers: ['כן', 'לא'],
    question: 'האם אתה 2?',
    type: 'bool',
    yesNext: 7,
    noNext: 7,
  },
  {
    id: 7,
    answers: ['כן', 'לא'],
    question: 'האם אתה 2?',
    type: 'bool',
    yesNext: 8,
    noNext: 8,
  },
  {
    id: 8,
    answers: ['כן', 'לא'],
    question: 'האם אתה 2?',
    type: 'bool',
    yesNext: 9,
    noNext: 9,
  },
  {
    id: 9,
    answers: ['כן', 'לא'],
    question: 'האם אתה 1?',
    type: 'bool',
  },
  ]

  @Output() completed = new EventEmitter()
  @Input() set questions(q) { this._questions = q; this.init(); }
  @Input() set extension(q) { this._extension = q }
  @Input() set numOfQuestions(q) { if (q) this._numOfQuestions = q }

  constructor(private CriticalillnessService: CriticalillnessService, 
              private cdf: ChangeDetectorRef,
              private analyticService: AnalyticsService) { }

  ngOnInit() {
    this.init()
    if (this._numOfQuestions === 8 ) {
      this.analyticService.trackByMixpanel("DP - Purchase Risk - Short Health Quest");
      this.analyticService.trackByAppFlyer("DP - Purchase Risk - Short Health Quest", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
    } else if (this._numOfQuestions === 16) {
      this.analyticService.trackByMixpanel("DP - Purchase Risk - Long Health Quest");
      this.analyticService.trackByAppFlyer("DP - Purchase Risk - Long Health Quest", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    } else if (this._numOfQuestions === 11) {
      this.analyticService.trackByMixpanel("DP - Shihluf Risk - Short Health Quest");
      this.analyticService.trackByAppFlyer("DP - Shihluf Risk - Short Health Quest", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
    } else if (this._numOfQuestions === 11) {
      this.analyticService.trackByMixpanel("DP - Shihluf Risk - Long Health Quest");
      this.analyticService.trackByAppFlyer("DP - Shihluf Risk - Long Health Quest", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
    }

  }

  calcTranslation(i) {
    let el: any = document.querySelectorAll('questioncard')
    // i -= 1
    return !!(i) ? (el[i].offsetHeight / 16) + 8 : (el[i].offsetHeight / 16) + 8 + 9.5625
  }

  translate = 0;
  init() {
    this._questions.forEach((val, ind) => {
      this._userAnswers[ind] = null
    })
    if (this._numOfQuestions === -1)
      this._numOfQuestions = this._questions.length
    this._currentQuestion = 0
    if (!!!(this.manipulatedQuestions.length))
      this.manipulatedQuestions.push(this._questions[0])
  }

  next() {
    this.completed.emit(this._userAnswers)
    console.log(this._userAnswers);
  }

  _transformIndexToId(index) {
    const question = this.manipulatedQuestions[index]
    return this.getQuestionIndex(index)
  }

  _checkIfAnswerAlreadyExists(index) {
    return this._userAnswers[index]
  }

  getQuestionIndex(id) {
    return this._questions.map(el => el.id).findIndex(el => el === id)
  }

  _isTrailingQuestion(quesIndex, answer) {
    // return this._questions[quesIndex].yesNext !== this._questions[quesIndex].noNext
    let answerIndex: any = -1
    switch (answer) {
      case 'כן':
        answerIndex = this._questions[quesIndex].yesNext
        break;
      case 'לא':
        answerIndex = this._questions[quesIndex].noNext
        break;
      default:
        answerIndex = -2
    }
    let ind = this.getQuestionIndex(answerIndex)
    return answerIndex === -2 ? false : this._questions[ind] && this._questions[ind].isExtension
  }

  fixedTransition = 15;

  //the index is from the manipulated question
  questionAnswered(index, answer) {
    const questionIndex = this._transformIndexToId(this.manipulatedQuestions[index].id)
    //check if already answered
    if (this._userAnswers[questionIndex]) {
      //check if its a trailing question
      if (this._isTrailingQuestion(questionIndex, answer)) {
        this._userAnswers[questionIndex] = answer;
      }
      else {
        this._userAnswers[questionIndex] = answer;
        this._userAnswers[questionIndex] = null;
        this.manipulatedQuestions.splice(this._currentQuestion + 1, 1)
        this.cdf.detectChanges();
        this._userAnswers[questionIndex] = answer;
      }
    }
    else {
      if (this._isTrailingQuestion(questionIndex, answer)) {
        this._userAnswers[questionIndex] = answer;
      } else {
        this._userAnswers[questionIndex] = answer;
      }

    }
    this.handleNextQuestion(index, answer)
  }

  handleNextQuestion(index, answer) {

    if (this._currentQuestion - this.numOfExtensionQuestions < this._numOfQuestions) {
      const questionIndex = this._transformIndexToId(this.manipulatedQuestions[index].id)
      //checking if its a trailing question
      if (this._isTrailingQuestion(questionIndex, answer)) {
        let idOfNextQuestion: any = -1
        let indexOfNextQuestion = -1
        switch (answer) {
          case 'כן':
            idOfNextQuestion = this._questions[questionIndex].yesNext || null
            break;
          case 'לא':
            idOfNextQuestion = this._questions[questionIndex].noNext || null
            break;
        }
        //last question
        if (!idOfNextQuestion) {
          //perform a transition of const value
          // this.translate += this.fixedTransition
        }
        else {
          indexOfNextQuestion = this.getQuestionIndex(idOfNextQuestion)
          this.manipulatedQuestions[this._currentQuestion + 1] = this._questions[indexOfNextQuestion]
          this.cdf.detectChanges()
          this.numOfExtensionQuestions++
        }
      }
      // let indOfNextQuestion;
      //not a trailing question
      else {
        //WORKS!!
        // const idOfNextQuestion = this._questions[questionIndex].noNext
        // const indexOfNextQuestion = this.getQuestionIndex(idOfNextQuestion)
        // if(indexOfNextQuestion > -1){
        //   this.manipulatedQuestions[this._currentQuestion + 1] = this._questions[indexOfNextQuestion]
        //   this.cdf.detectChanges()
        //MODIFICATIONS
        if (this._questions[questionIndex].noNext === this._questions[questionIndex].yesNext) {
          const idOfNextQuestion = this._questions[questionIndex].noNext
          const indexOfNextQuestion = this.getQuestionIndex(idOfNextQuestion)
          if (indexOfNextQuestion > -1) {
            if (this._questions[indexOfNextQuestion].isExtension) this.numOfExtensionQuestions++
            this.manipulatedQuestions[this._currentQuestion + 1] = this._questions[indexOfNextQuestion]
            this.cdf.detectChanges()
          }
        }
        else if (answer === 'לא') {
          const idOfNextQuestion = this._questions[questionIndex].noNext
          const indexOfNextQuestion = this.getQuestionIndex(idOfNextQuestion)
          if (indexOfNextQuestion > -1) {
            if (this._questions[indexOfNextQuestion].isExtension) this.numOfExtensionQuestions++
            this.manipulatedQuestions[this._currentQuestion + 1] = this._questions[indexOfNextQuestion]
            this.cdf.detectChanges()
          }
        }
        else if (answer === 'כן') {
          const idOfNextQuestion = this._questions[questionIndex].yesNext
          const indexOfNextQuestion = this.getQuestionIndex(idOfNextQuestion)
          if (indexOfNextQuestion > -1) {
            if (this._questions[indexOfNextQuestion].isExtension) this.numOfExtensionQuestions++
            this.manipulatedQuestions[this._currentQuestion + 1] = this._questions[indexOfNextQuestion]
            this.cdf.detectChanges()
          }
        }
        //last question
        else {
          //perform a transition of const value
          this.translate += this.fixedTransition
        }

      }
      this._currentQuestion++
      if (this._currentQuestion - this.numOfExtensionQuestions === this._numOfQuestions) {
        this.next();
      }
      //perform a transition to the next element
      // if(indOfNextQuestion > -1)
      try {
        console.log('here')
        this.translate += this.calcTranslation(this._currentQuestion - 1)
      }
      catch (e) {
        //hack need to solve that -> dont have enough time
        console.log('didnt find quescard elem with that index on the dom...')
      }
    }
  }




  nextQuestion() {
    if (this._userAnswers[this._currentQuestion] && this._currentQuestion - this.numOfExtensionQuestions < this._numOfQuestions) {
      if (this.manipulatedQuestions[this._currentQuestion + 1]) {
        const question = this.manipulatedQuestions[this._currentQuestion]
        const answer = this._userAnswers[this._currentQuestion]
        if (this.manipulatedQuestions[this._currentQuestion + 1].isExtension) this.numOfExtensionQuestions++
        this._currentQuestion++;
        this.translate += this.calcTranslation(this._currentQuestion - 1)
      }
      //translate to the button
      else {
        this._currentQuestion++;
        this.translate += this.fixedTransition
      }
    }
    //in case this is the last question and we want to translate to the next button
    else if (this._userAnswers[this._currentQuestion] && this._currentQuestion + this.numOfExtensionQuestions < this._numOfQuestions) {
      //perform a transition of const value
      console.log('going to the button')
      this.translate += this.fixedTransition
    }
  }

  prevQuestion() {
    if (this._currentQuestion > 0) {
      if (this.manipulatedQuestions[this._currentQuestion]) {
        if (this.manipulatedQuestions[this._currentQuestion].isExtension) this.numOfExtensionQuestions--;
        this._currentQuestion--;
        this.translate -= !!!(this._currentQuestion) ? this.translate : this.calcTranslation(this._currentQuestion)
      }
      //focusing the button
      else {
        this._currentQuestion--;
        // this.translate -= this.fixedTransition
        this.translate -= !!!(this._currentQuestion) ? this.translate : this.calcTranslation(this._currentQuestion)
      }
    }
  }


}

