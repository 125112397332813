import { AppState, selectRegisterState } from './../../../store/app.states';
import { OcrService } from './../../services/ocr/ocr.service';
import { NavManagerService } from '../../../shared/components/robin-navbar/robin-navbar.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { uiStrings } from '../../assets/heb.ui_strings';
import { ErrorModalService } from '../../../shared/components/modals/error-modal/modal.service';
import { AnalyticsModule } from '../../../analytics/analytics.module';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { Store } from '@ngrx/store';
import { OnboardingService } from '../../services/onboarding.service';
import { HomepageService } from '../../../homepage/homepage.service';
import { Response } from './../../../models/global.models';


const TIME = 1000 * 3;
@Component({
  selector: 'app-last-step',
  templateUrl: './last-step.component.html',
  styleUrls: ['./last-step.component.scss']
})
export class LastStepComponent implements OnInit {
  answers = {};
  public uiStrings;
  error = null;
  didAnswer = 'blue';
  submitted = false;
  public consultChecked = true;

  constructor(private errorModalService: ErrorModalService,
    private store: Store<AppState>,
    private router: Router,
    private ocrService: OcrService,
    private navbarService: NavManagerService,
    private analyticService: AnalyticsService,
    private onboardingservice: OnboardingService,
    private homepageservice: HomepageService,
    private onboardingService: OnboardingService) {
    this.uiStrings = uiStrings.laststep;

  }
  phoneNumber;
  obj = [
    { value: true, text: 'כן', active: false, index: 0 },
    { value: false, text: 'לא', active: false, index: 1 }
  ]

  ngOnInit() {
    this.analyticService.trackByMixpanel("User Registration - FDC - InsuranceMountain");
    this.analyticService.trackByAppFlyer("User Registration - FDC - InsuranceMountain", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
    this.store.select(selectRegisterState).subscribe((data: any) => {
      this.phoneNumber = data.user.phoneNumber;
    })
    console.log('this.ocrService.checkInfoObject', this.ocrService.checkInfoObject)
    if (window.innerWidth < 361) {
      console.log(window.innerWidth);
    }
  }

  setAnError(str) {
    this.error = str;
    setTimeout(() => this.error = null, TIME);
  }


  saveForm() {
    if (this.isValidForm() && !this.submitted) {
      this.submitted = true;
      if (this.navbarService.history[this.navbarService.history.length - 3].url === "/account/insurance") {
        this.ocrService.insuranceRegistration({
          IdNumber: this.ocrService.checkInfoObject.idNumber,
          PhoneNumber: this.phoneNumber,
          IdIssueDate: this.ocrService.checkInfoObject.issueDate.toJSON().toString(),
          DidWentAbroad: this.convertStringToBool(this.answers["exitCountry"]),
          DidIssuedPassport: this.convertStringToBool(this.answers["passport"])
        }).subscribe((data: any) => {
          this.submitted = false;
          if (data.Code === 0) {


            const consult = {
              IsChecked: this.consultChecked
            };
            this.onboardingService.consultExpertCheckbox(consult).subscribe((data: Response) => {
              if (data.Code === 0) {
                this.redirectTo(1);
              }
              this.analyticService.trackByMixpanel("User Registration -  FDC - CreditCard - End");
              this.analyticService.trackByAppFlyer("User Registration -  FDC - CreditCard - End", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
              this.submitted = false;
            });



          } else {
            this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך', 'נסה שנית');
          }
        },
          // err => this.openErrorMessage('שגיאה', err, 'אישור'))
          err => {
            this.submitted = false;
            this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך', 'נסה שנית');
          })

      } else {
        this.ocrService.dataAggregationRegistration({
          IdNumber: this.ocrService.checkInfoObject.idNumber,
          PhoneNumber: this.phoneNumber,
          IdIssueDate: this.ocrService.checkInfoObject.issueDate.toJSON().toString(),
          DidWentAbroad: this.convertStringToBool(this.answers["exitCountry"]),
          DidIssuedPassport: this.convertStringToBool(this.answers["passport"])
        }).subscribe((data: any) => {
          this.submitted = false;
          if (data.Code === 0) {
            const consult = {
              IsChecked: this.consultChecked
            };
            this.onboardingService.consultExpertCheckbox(consult).subscribe((data: Response) => {
              if (data.Code === 0) {
                this.redirectTo(2);
              }
              this.analyticService.trackByMixpanel("User Registration -  FDC - CreditCard - End");
              this.analyticService.trackByAppFlyer("User Registration -  FDC - CreditCard - End", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
              this.submitted = false;
            });
          } else {
            this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך', 'נסה שנית');
          }
        },
          // err => this.openErrorMessage('שגיאה', err, 'אישור'))
          err => {
            this.submitted = false;
            this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך', 'נסה שנית');
          })
      }

    }
    else
    // this.openErrorMessage('שגיאה', 'עליך לענות על כל השאלות על מנת שרובין יוכל לעשות את עבודתו נאמנה', 'אישור');
    {
      this.submitted = false;
      this.setAnError('על מנת להתקדם יש לענות על כל השאלות');
    }

  }

  convertStringToBool(str) {
    return str === 'כן' ? true : false;
  }

  sendForm() {

  }

  isValidForm() {
    return this.objectSize(this.answers) === 2 ? true : false;
  }

  booleanValue(ev, src) {
    this.answers[src] = ev;
    if (Object.keys(this.answers).length === 2) {
      this.didAnswer = 'green';

    }
    else {
      this.didAnswer = 'blue';
    }
    console.log(this.answers);
  }

  objectSize(obj) {
    var size = 0, key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  }

  redirectTo(i) {
    if (i === 2)
      this.homepageservice.firstTime = true;
    else {
      this.homepageservice.firstTime = false;
    }
    this.navbarService.next();
    this.analyticService.trackByMixpanel("User Registration - FDC - End");
    this.analyticService.trackByAppFlyer("User Registration - FDC - End", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
  }

  openErrorMessage(title, desc, buttonText) {
    this.errorModalService.setNewError({
      title: title,
      description: desc,
      type: 'error',
      buttonText: buttonText,
    }).open();
  }
  changeVal(event) {
    this.consultChecked = event;
  }

}
