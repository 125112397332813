import { ErrorModalService } from './../../../../../../../shared/components/modals/error-modal/modal.service';
import { NavManagerService } from '../../../../../../../shared/components/robin-navbar/robin-navbar.service';
import { LoggerService } from '../../../../../../../core/services/logger.service';
import { CordovaService } from '../../../../../../../core/services/cordova.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, Output, EventEmitter, NgZone } from '@angular/core';
import { ModalService } from '../../../../../../../shared/components/robin-modal/modal.service';
import { ImageCaptureService } from '../../../../../../services/image-capture.service';
import { uiStrings } from '../../../../../../assets/heb.ui_strings';
import { ImageCompressService, IImage } from 'ng2-image-compress';



@Component({
  selector: 'image-option-modal',
  templateUrl: './image-option-modal.component.html',
  styleUrls: ['./image-option-modal.component.scss']
})
export class ImageOptionModalComponent implements OnInit {
  @Output() fired = new EventEmitter();
  public uiStrings;
  constructor(private zone: NgZone,
    private navbarService: NavManagerService,
    private router: Router,
    public cordovaService: CordovaService,
    private loggerService: LoggerService,
    private modalService: ModalService,
    private captureService: ImageCaptureService,
    private errorModalService: ErrorModalService,
    private imgCompressService: ImageCompressService) {
    this.uiStrings = uiStrings.imageoptionmodal;
  }

  ngOnInit() {



  }
  openGallery() {
    this.fired.emit();
    if (this.cordovaService.isMobile) {
      this.cordovaService.selectLocalImage({}, (err, data) => {
        if (err) {
          console.log('error => ', err);
          this.onErr(err);
        }
        else {
          console.log('data => ', data);
          this.onData(data);
        }
      });
    } else {
      // $("#file-input").click();
      let el: any = document.querySelectorAll("#file-input")[1];
      var file = document.getElementById("file-input")
      
      file.addEventListener('change', (e: any) => {
        console.log('e',e);
        // this.doSomethingWithFiles(e.target.files);
        this.compressImage(e.target.files);
  
      })
    }
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  compressImage(fileInput) {
    let fileList: FileList;
 
    let images: Array<IImage> = [];
    
    ImageCompressService.filesToCompressedImageSource(fileInput).then(observableImages => {
      observableImages.subscribe((image) => {
        images.push(image);
        if (images !== null) {
          // let src = URL.createObjectURL(file);
          this.captureService.idImage.next(images[0].compressedImage.imageDataUrl);
          this.zone.run(() => {
            this.navbarService.next('idcardpreview');
          });
        }
      }, (error) => {
        console.log("Error while converting");
      }, () => {
          // this.processedImages = images;      
          // this.showTitle = true;          
      });
    });

 
    // or you can pass File[] 
    // let files = Array.from(fileInput);
 
    // ImageCompressService.filesArrayToCompressedImageSource(files).then(observableImages => {
    //   observableImages.subscribe((image) => {
    //     images.push(image);
    //   }, (error) => {
    //     console.log("Error while converting");
    //   }, () => {
    //             this.processedImages = images;      
    //             this.showTitle = true;          
    //   });
    // });
  }

  doSomethingWithFiles(fileList) {
    let file = null;

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/^image\//)) {
        file = fileList[i];
        break;
      }
    }

    if (file !== null) {
      // let src = URL.createObjectURL(file);
      this.getBase64(file).then(data =>{
        this.captureService.idImage.next(data);
        this.zone.run(() => {
          this.navbarService.next('idcardpreview');
        });
      });
    }
  }

  onData(data) {
    console.log('this is the image received: ', data);
    this.captureService.idImage.next(data);
    this.zone.run(() => {
      this.navbarService.next('idcardpreview');
    });
  }

  onErr(err) {
    this.loggerService.error(err);
    this.errorModalService.setNewError({
      title: 'שגיאה',
      description: 'אירעה שגיאה בבחירת התמונה',
      type: 'error',
      buttonText: 'אישור',
    }).open();
  }

  example() {
    this.fired.emit();
    this.navbarService.next('idcardpreview');
  }

  showModalTips(str) {
    if (this.cordovaService.isMobile) {
      if (this.captureService.onetime) {
        this.openCamera();
        this.fired.emit();
      } else {
        this.fired.emit();
        this.captureService.onetime = true;
        this.modalService.open(str);
      }
    } else {
      this.openCamera();
    }
  }


  openCamera() {
    if (this.cordovaService.isMobile) {
      this.cordovaService.captureImage({}, (err, data) => err ? this.onErrCamera(err) : this.onDataCamera(data));
    } else {
      // $("#file-input").click();
      let el: any = document.querySelectorAll("#file-input2")[1];
      var file = document.getElementById("file-input2")
      file.addEventListener('change', (e: any) => {
        console.log('e',e);
        this.compressImage(e.target.files);
        // this.doSomethingWithFiles(e.target.files);
      })
    }
  }
  //need to redirect to => idCardPreview
  onDataCamera(data) {
    // this.captureService.imageCapture.next(data);
    this.captureService.idImage.next(data);
    // this.captureService.onetime = true;
    this.zone.run(() => {
      this.navbarService.next('idcardpreview');
    });
  }

  onErrCamera(err) {
    this.loggerService.error(err);
    this.errorModalService.setNewError({
      title: 'שגיאה',
      description: 'אירעה שגיאה בבחירת התמונה',
      type: 'error',
      buttonText: 'אישור',
    }).open();
  }
}
