import { ApplicationRated } from './../../../redux/rate_the_app/rate.reducer';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { AppState } from 'src/app/store/app.states';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'rate-task',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit {
  @Output() cancel = new EventEmitter();

  firstStage = true;
  secondStage = false;
  thirdStage = false;
  done = false;

  constructor(private cordovaService:CordovaService,private store: Store<AppState>, private rotuer: Router,private analyticService: AnalyticsService) { }

  ngOnInit() {
  }


  /*
  *   handle the first popup
  */
  appUsefullTrue() {
    this.analyticService.trackByMixpanel("General - Rate Me - Like"); 
    this.analyticService.trackByAppFlyer("General - Rate Me - Like", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.firstStage = false;
    this.thirdStage = true;
  }
  appUsefullFalse() {
    this.analyticService.trackByMixpanel("General - Rate Me - Dislike"); 
    this.analyticService.trackByAppFlyer("General - Rate Me - Dislike", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.firstStage = false;
    this.secondStage = true;
  }

  /*
  *   answered false at the first phase and then true
  */
  publishAnIdea() { 
    this.analyticService.trackByMixpanel("General - Rate Me - Improvements - Yes"); 
    this.analyticService.trackByAppFlyer("General - Rate Me - Improvements - Yes", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.rotuer.navigate(['/recomendation']);
    //TODO: change the flag so when getting back the pension profile the disturbing popup wont be displayed
  }

  /*
  *   answered false at the first phase and then false
  */

 
  closeX() {
    this.cancel.emit();
  }

  closepopup(displayThankYouPopup = true) {
    this.analyticService.trackByMixpanel("General - Rate Me - Improvements - No"); 
    this.analyticService.trackByAppFlyer("General - Rate Me - Improvements - No", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });


    this.store.dispatch(new ApplicationRated({}));
    this.firstStage = false;
    this.secondStage = false;
    this.thirdStage = false;
    if(displayThankYouPopup){
    this.done = true;
    setTimeout(() => this.cancel.emit(), 3000);
    }
    else{
      this.cancel.emit();
    }
  }

  closepopup2(displayThankYouPopup = true) {
    this.analyticService.trackByMixpanel("General - Rate Me - Rating - No"); 
    this.analyticService.trackByAppFlyer("General - Rate Me - Rating - No", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });


    this.store.dispatch(new ApplicationRated({}));
    this.firstStage = false;
    this.secondStage = false;
    this.thirdStage = false;
    if(displayThankYouPopup){
    this.done = true;
    setTimeout(() => this.cancel.emit(), 3000);
    }
    else{
      this.cancel.emit();
    }
  }

  rateTheApp() {
    this.analyticService.trackByMixpanel("General - Rate Me - Rating - Yes"); 
    this.analyticService.trackByAppFlyer("General - Rate Me - Rating - Yes", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.thirdStage = false;
    this.closepopup(false);
    console.debug('rate the app...');
    if(this.cordovaService.isMobile){
      switch(this.cordovaService.platform){
        case 'Android':
        this.cordovaService.navigateFromApp('market://details?id=fr.prodware.robinhoodproapp');
        break;
        case 'iOS':
        this.cordovaService.navigateFromApp('itms-apps://itunes.apple.com/app/id1144281691');
        break;
      }
      this.store.dispatch(new ApplicationRated({}));
    }
  }
}
