import { Component, OnInit } from '@angular/core';
import { CacheService } from 'src/app/risk/services/cache.service';
import { Router } from '@angular/router';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { AccountService } from 'src/app/account/account.service';
import { dataValidator } from 'src/app/shared/services/validators';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  openTazrim = false;
  numOfCoins;
  tazrim;
  constructor(public ciservice: CriticalillnessService,
    private router: Router,
    private analyticService: AnalyticsService,
    private cordovaService: CordovaService,
    private accountService: AccountService,
    private cordovaSrevice: CordovaService) {
      this.ciservice.premiumSummary = Math.round(this.ciservice.premiumSummary);
      this.ciservice.cacheCritical;
      var objTazrim = {
        Smoking: this.ciservice.smoking,
        Amount: this.ciservice.amount,
        ChildAmount: this.ciservice.amountChildren,
        ChildrenBirthdays: this.getChildrenDate(),
      }    
      console.log('objTazrim',objTazrim)
      this.ciservice.getCriticalIllnessTazrim(objTazrim).subscribe((data: any) => {
        if (data.Code === 0) {
          this.tazrim = data.Data;
        }
      });
  }

  getChildrenDate() {
    var array = [];
    for (let i=0; i<this.ciservice.cacheCritical[3].length; i++) {
      array.push(this.ciservice.cacheCritical[3][i].BirthDate.toJSON());
    }
    return array;
  }

  clickTazrim() {
    this.openTazrim = !this.openTazrim
  }

  ngOnInit() {
    this.analyticService.trackByMixpanel("DP - Mahalot Kashot  - Payment");
    this.analyticService.trackByAppFlyer("DP - Mahalot Kashot  - Payment", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

  }

  next(e) {
    const { name, id, creditNumber, exp, cvv } = e
    let date = new Date(exp)
    const data = {
      CardNumber: creditNumber,
      Month: date.getMonth() + 1,
      Year: date.getFullYear(),
      CVV: cvv,
      IdentityNumber: id,
      PhoneNumber: localStorage.getItem('phoneNumber')
    }
    this.ciservice.creditCardPayment(data).subscribe((response: any) => {
      if (!!!(response.Code)) {
        this.ciservice.cacheCritical[6] = { token: response.Data, creditInfo: data }

        this.ciservice.createEvents(this.ciservice.createFinalObject()).subscribe((data: any) => {
          if (data.Code === 0) {
            this.accountService.coin$.subscribe(data => {
              this.numOfCoins = data + 1 || 1
            });
            this.accountService.coin.next(this.numOfCoins);
            this.router.navigate(['/criticalillness', 'success'])
          }
        });
      }
      else {

      }
    })
  }
  closeKeyboard(e) {

    this.cordovaService.closeKeyBoard();
  }


  openPdf() {
    if (this.cordovaService.isMobile)
      this.cordovaSrevice.open('pdf','https://robinblob.blob.core.windows.net/public/CriticalIllness/Menora.pdf');
    else
      window.open('https://robinblob.blob.core.windows.net/public/CriticalIllness/Menora.pdf');
  }

 
}
