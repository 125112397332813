import { trigger, transition, animate, style } from '@angular/animations';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Input, forwardRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'robin-autocomplete',
  templateUrl: './robin-autocomplete.component.html',
  styleUrls: ['./robin-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RobinAutocompleteComponent),
      multi: true
    }
  ],animations : [
    trigger('dropdownAnimation',[
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'scale(0)',
          transformOrigin: '50% 0%'
        }),
        animate('0.1s ease-in', style({
          opacity: 1,
          transform: 'scale(1)',
          transformOrigin: '50% 0%'
        }))
      ]),
      transition('* => void', [
        style({
          opacity: 1,
          transform: 'scale(1)',
          transformOrigin: '50% 0%'
        }),
        animate('0.1s ease-out', style({
          opacity: 0,
          transform: 'scale(0)',
          transformOrigin: '50% 0%'
        }))
      ]),
    ])
  ]
})
export class RobinAutocompleteComponent implements OnInit {
  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef;
  @ViewChild('drop') drop: ElementRef;

  @Input() answers: string[];
  @Input() placeholder: string;
  public filteredAnswers: string[];
  public yourAnswer = null;
  @Output() checked = new EventEmitter();
public autocompleteVisible = false;
public dropdownVisible = false;
public up = false;

  constructor() { }

  ngOnInit() {
    this.refactorRangeOfAnswers();
  }

  toggleDropdown() {
    // let el = document.querySelectorAll('robin-dropdown .data');
    // let val = window.innerHeight - Number(el[el.length-1].getBoundingClientRect().bottom);
    // if(val < 300){
    //   console.log('here')
    //   this.up = true;
    // }

    let val = window.innerHeight - Number(this.drop.nativeElement.getBoundingClientRect().bottom);
    if(val < 300){
      this.up = true;
    }
    this.dropdownVisible = !this.dropdownVisible;
  }
  refactorRangeOfAnswers() {
    if (this.answers && this.answers[1] === '-') {
      let elZero = this.answers[0];
      let elThree = this.answers[2];
      let tmpArr: string[] = [];
      for (let i = Number(elZero), j = 0; i <= Number(elThree); i++ , j++) tmpArr[j] = String(i);
      this.answers = tmpArr;
    }
  }
  close(e) {
    this.filteredAnswers = null;
  }
  displayRelevantOptions(e) {
    let value = e.target.value;
    this.filteredAnswers = this.answers.filter((val) => val.indexOf(value) !== -1);
  }
  choseAnswerFromAutocompleteDropdown(i) {
    this.autoCompleteInput.nativeElement.value = this.filteredAnswers[i];
    this.yourAnswer = this.filteredAnswers[i];
    this.checked.emit(this.yourAnswer);
    this.filteredAnswers = null;
    this.onChange(this.yourAnswer);
  }

  /*
  form control
  */

  writeValue(val: string): void {
    this.yourAnswer = val;
  }
  onChange = (rating) => {

   };
  registerOnChange(fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void { }
  setDisabledState(isDisabled: boolean): void { }
}
