import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'robin-rounded-button',
  templateUrl: './robin-rounded-button.component.html',
  styleUrls: ['./robin-rounded-button.component.scss']
})
export class RobinRoundedButtonComponent implements OnInit {
  @Input() text;
  constructor() { }

  ngOnInit() {
  }



}


