import { PensionSummary } from './../../models/global.models';
import { InsuranceActionTypes } from '../../store/user.actions';
import { All } from "../../store/user.actions";

export interface State {
    insuranceDetails: PensionSummary | null
}

export const initialState: State = {
    insuranceDetails: null
};

export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case InsuranceActionTypes.INSURANCE: {
            return {
                ...state,
                insuranceDetails: action.payload.insuranceDetails
            };
        }
        default: {
            return state;
        }
    }
}