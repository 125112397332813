import { DeleteUser } from './../../../models/global.models';
import { shareReplay } from 'rxjs/operators';
import { selectRegisterState } from '../../../store/app.states';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppState } from '../../../store/app.states';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class OtpService {
  private baseUrl;
  private store$: Observable<any>;
  private phoneNumber;
  public allowads;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.baseUrl = environment.endPoint;
    this.store$ = this.store.select(selectRegisterState).pipe(
      shareReplay(1)
    );
    this.store$.subscribe(usr => { this.phoneNumber = usr.user && usr.user.phoneNumber || null });
  }

  createToken(otp) {
    const body = {
      username: this.phoneNumber,
      password: otp
    };
    // .set('username', this.phoneNumber)
    // .set('password', otp)
    // .set('grant_type', 'password');
    return this.http.post(`${this.baseUrl}/auth/token`, body);
  }

  verifyOtp(otp) {
    return this.http.post(`${this.baseUrl}/accounts/verifycode`, otp);
  }


  createPassword(pass) {
    return this.http.post(`${this.baseUrl}/accounts/setpassword`, pass);
  }

  resendOtp(body) {
    return this.http.post(`${this.baseUrl}/accounts/sendcode/sms`, body);
  }

  resendOtpCall(body) {
    return this.http.post(`${this.baseUrl}/accounts/sendcode/call`, body);
  }

  savePincode(pin) {
    return this.http.post(`${this.baseUrl}/accounts/setpassword`, pin);
  }

  anonymousStatus(data) {
    const params = new HttpParams()
      .set('email', data.email);
    return this.http.get(`${this.baseUrl}/customers/${data.phoneNumber}/status`, { params });
  }

  updatePhoneNumber(data) {
    return this.http.put(`${this.baseUrl}/accounts/updatephone`, data);
  }

  sendSource(data) {
    return this.http.post(`${this.baseUrl}/accounts/sourceparams`, data);
  }



  deletePhoneNumber(data: DeleteUser) {
    const params = new HttpParams()
      .set('primaryIdentifier', data.PrimaryIdentifier)
      .set('secondaryIdentifier', data.SecondaryIdentifier);
    return this.http.delete(`${this.baseUrl}/accounts`, { params });
  }
  allowAds(data) {
    console.log(`${this.baseUrl}/customers/allowads?allowAds=${data}`)
    return this.http.put(`${this.baseUrl}/customers/allowads?allowAds=${data}`, null);
  }

  verifyCreditcard(data) {
    return this.http.post(`${this.baseUrl}/creditcard/verify`, data);
  }
  registerFingerPrint(data) {
    return this.http.put(`${this.baseUrl}/accounts/uuid`, data);
  }
  loginByFingerPrint(data) {
    return this.http.post(`${this.baseUrl}/auth/token/uuid`, data);
  }

}
