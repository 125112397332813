
export class RobinState {

    _stateArray;
    constructor(stateArray){
        this._stateArray = stateArray;

    }

    getArray() {
        return this._stateArray;
    }

    public getCurrentState(type){ 
        return this.getArray().find( obj => type === obj.name );
    }

    public setClass(color){
        return 'text ' + color;
    }
}

