import { CacheService } from './../../services/cache.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'process-height-and-weight',
  templateUrl: './height-and-weight.component.html',
  styleUrls: ['./height-and-weight.component.scss']
})
export class HeightAndWeightComponent implements OnInit {
@Output() completed = new EventEmitter()
answers = []
formValues:any = {}
heightAndWeightForm: FormGroup

  constructor(private fb: FormBuilder, 
              private cacheService: CacheService, 
              private utilService: UtilService,
              private analyticService: AnalyticsService) {
    if(this.cacheService.cache[1]){
      this.formValues.height = this.cacheService.cache[1].height
      this.formValues.weight = this.cacheService.cache[1].weight
    }
  }

  ngOnInit() {
    this.heightAndWeightForm = this.fb.group({
      height: [this.formValues.height || '', [Validators.required]],
      weight: [this.formValues.weight || '', [Validators.required]]
    })
    this.analyticService.trackByMixpanel("DP - Risk - Body Dimensions");
    this.analyticService.trackByAppFlyer("DP - Risk - Body Dimensions", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });


  }
  
  next(){
    console.log(this.heightAndWeightForm);
    if(this.heightAndWeightForm.valid){
      this.cacheService.cache[1] = this.cacheService.setCache(this.heightAndWeightForm.controls)
      this.cacheService.cache[2] = {}
      this.cacheService.currentTab.next(2)
    }
    else{
      this.utilService.setErrorInEmptyFields(this.heightAndWeightForm)
    }
    //TODO: error handling in case
  }

}
