import { ImageOptionModalComponent } from './../../../onboarding/components/identification/id-card/id-card-main/modals/image-option-modal/image-option-modal.component';
import { trigger, transition, state, style, animate } from '@angular/animations';
import { Component, OnInit, Input, DoCheck } from '@angular/core';

@Component({
  selector: 'robin-message',
  templateUrl: './robin-message.component.html',
  styleUrls: ['./robin-message.component.scss'],
  animations: [
    trigger('showMessage', [
      state(':leave', style({fontSize: '1rem', opacity: 1 })),
      state(':enter', style({fontSize: '0rem' , opacity: 0 })),
      transition('* <=> *', animate('300ms ease-in-out')),
    ])
  ]
})
export class RobinMessageComponent  {

  @Input() text;
  @Input() messageShow;
  constructor() { }

  
  // ngDoCheck() {
  //   this.myClass = this.messageShow ? 'message animated fadeInDown' : 'message animated fadeInDown active'
  //   console.log(this.myClass)
  // }

}
