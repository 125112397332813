import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'or-checkbox',
  templateUrl: './or-checkbox.component.html',
  styleUrls: ['./or-checkbox.component.scss']
})
export class OrCheckboxComponent implements OnInit {
  _checked
  @Output() setChecked = new EventEmitter()
  @Input() set checked(e){this._checked = e}
  constructor() { }

  ngOnInit() {
  }
  toggleCheckbox() {
    this._checked = !this._checked
    this.setChecked.emit(this._checked)
  }
}
