import { CameraOptions, Camera } from './../../models/global.models';
// import * as a from '../../models/global.models';
import { environment } from '../../../environments/environment';
import { Injectable, NgZone } from '@angular/core';
import { Observable, ConnectableObservable, ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { throwError } from 'rxjs';
import { LoggerService } from './logger.service';
import { ErrorModalService } from 'src/app/shared/components/modals/error-modal/modal.service';
import { selectAndroidLock, AppState } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';
import * as ANDROID_NGRX from '../../redux/android_fingerprint/android_fingerprint.reducer';

declare var window;

@Injectable({
  providedIn: 'root'
})
export class CordovaService {
  deviceReady$: Observable<Event>;
  deviceFeatures = {
    identification: {
      face: false,
      finger: false
    }
  }
  isMobile: boolean;
  platform;

  cameraOption: CameraOptions = {
    quality: 25,
    mediaType: 0,
    allowEdit: false,
    correctOrientation: true,
    encodingType: 0,
    sourceType: 1,
    cameraDirection: 0,
    destinationType: 0
  }
  //after 5 attempts os locks the fingerprint
  ANDROID_OS_LOCK = false;

  constructor(private store: Store<AppState>, private logger: LoggerService, private errorModalService: ErrorModalService, private zone: NgZone) {
    if (!window.cordova) {
      this.isMobile = false;
      this.deviceReady$ = throwError('Not a mobile');
    }
    else {
      this.store.select(selectAndroidLock).subscribe((data: any) => {
        this.ANDROID_OS_LOCK = data.LOCK;
      });
      this.isMobile = true;
      this.deviceReady$ = fromEvent(document, "deviceready").pipe(shareReplay(1));
    }
  }

  deviceReadyEvent() {
    var event = new Event("deviceready");
    document.dispatchEvent(event);
  }

  captureImage(options, cb) {
    if (this.isMobile) {
      this.cameraOption = { ...this.cameraOption, ...options };
      window.navigator.camera.getPicture((data) => {
        // navigator.camera.cleanup(() => {
        //   this.logger.debug('cleaing the image from temp performed successfuly...');
        // }, () => {
        //   this.logger.error('cleaing the image from temp couldnt be performed...');
        // });
        cb(null, data);
      }, (err) => cb(err), this.cameraOption);
    }
    else {
      this.logger.debug('not a mobile device');
    }
  }

  selectLocalImage(options, cb) {
    if (this.isMobile) {
      window.navigator.camera.getPicture(function (res) {
        cb(null, res);
      }, function (err) {
        cb(err);
      }, {
          quality: 25,
          sourceType: 0,
          allowEdit: false,
          destinationType: 0,
          encodingType: 0
        });
    }
    else {
      this.logger.debug('not a mobile device');
    }
  }

  open(app, link?) {
    switch (app) {
      case 'whatsapp':
        window.plugins.socialsharing.shareViaWhatsAppToPhone(environment.social.whatsapp.customerSupport.number, environment.social.whatsapp.customerSupport.message,
          null /* img */, null /* url */,
          function () {
            this.logger.debug('whatsapp opened successfully');
          },
          function (errormsg) {
            this.logger.error(errormsg);
          });
        break;
      case 'messenger':
        // window.plugins.socialsharing.shareVia('com.facebook.orca', environment.social.messenger.customerSupport.message, null, null, 'https://www.messenger.com/t/RobinhoodproAPP',
        //   function () {
        //     this.logger.debug('facebook messenger opened successfully');
        //   }, function (errormsg) {
        //     this.logger.error(errormsg);
        //   });
        if (this.platform === "iOS")
          window.open('https://m.me/RobinhoodproAPP', '_system', 'location=yes')
        else if (this.platform === "Android") {
          window.open('http://m.me/RobinhoodproAPP', '_system', 'location=no');
        }
        break;
      case 'inviteWhatsapp':
        var message = "היי, ראיתי מאפליקציית רובין שלי, שאין לך אותה עדיין. הגיע הזמן להוריד ולעשות סדר בכל מה שקשור לפנסיה ולביטוח. חינם. " + link;
        window.plugins.socialsharing.shareViaWhatsAppToPhone('', message,
          null /* img */, null /* url */,
          function () {
            this.logger.debug('whatsapp opened successfully');
          },
          function (errormsg) {
            this.logger.error(errormsg);
          });
        break;
      case 'pdf1':
        if (this.platform === "iOS")
          window.open('https://robinblob.blob.core.windows.net/public/Clal/healthinsurance/ClalHealthInsuranceLong.pdf', '_system', 'location=yes')
        else if (this.platform === "Android") {
          window.open('https://robinblob.blob.core.windows.net/public/Clal/healthinsurance/ClalHealthInsuranceLong.pdf', '_system', 'location=no');
        }
        break;
      case 'pdf2':
        if (this.platform === "iOS")
          window.open('https://robinblob.blob.core.windows.net/public/Clal/healthinsurance/ClalHealthInsuranceShort.pdf', '_system', 'location=yes')
        else if (this.platform === "Android") {
          window.open('https://robinblob.blob.core.windows.net/public/Clal/healthinsurance/ClalHealthInsuranceShort.pdf', '_system', 'location=no');
        }
        break;
      case 'pdf':
        if (this.platform === "iOS")
          window.open(link, '_system', 'location=yes')
        else if (this.platform === "Android") {
          window.open(link, '_system', 'location=no');
        }
        break;
    }
  }

  deviceFeature$: ReplaySubject<any> = new ReplaySubject<any>();
  availableDeviceFeatures() {
    //ios plugin
    if (this.platform === 'iOS') {
      window.plugins.touchid.isAvailable(res => {
        if (res === 'face') {
          this.deviceFeatures.identification.face = true;
          this.deviceFeature$.next('face');
        }
        else if (res === 'touch') {
          this.deviceFeatures.identification.finger = true;
          this.deviceFeature$.next('touch');
        }
      }, err => {
        console.error('IOS plugin is not availale: ', err);
      });
    }
    else {
      window.FingerprintAuth.isAvailable(res => {
        if (res.isAvailable) {
          this.deviceFeatures.identification.finger = true;
          this.deviceFeature$.next('touch');
        }
      }, err => {
        console.error('Android plugin is not availale: ', err);
      });
    }
  }



  retriesCounter: any = localStorage.getItem('deviceFeatureDisabled') || 0;
  loginWithFingerid(cb) {
    if (this.retriesCounter !== 'true') {
      const androidOSLockMechanism = () => {
        this.store.dispatch(new ANDROID_NGRX.AndroidLock({}));
        setTimeout(() => this.store.dispatch(new ANDROID_NGRX.AndroidUnlock({})), 1000 * 35);
      }

      let IOSErrors = {
        LIMIT_EXCEEDED: -1,
        LOCKED_OUT: -8,
        CANCEL: -2,
        FALLBACK_AUTHENTICATION: -3
      };

      let ANDROIDErrors = {
        CANCEL: 'FINGERPRINT_CANCELLED',
        LIMIT_EXCEEDED: 'FINGERPRINT_ERROR'
      };

      const successHandler = () => {
        if (localStorage.getItem('deviceFeatureDisabled')) localStorage.removeItem('deviceFeatureDisabled');
        cb(null);
      }

      const iosErrorHandler = (msg) => {
        let code = msg.code;
        if (code === IOSErrors.CANCEL) {
          cb('cancel');
        }
        else {
          localStorage.setItem('deviceFeatureDisabled', 'true');
          androidOSLockMechanism();
          cb('errorModal');
        }
      }

      const handleAndroidError = (err) => {
        if (err === ANDROIDErrors.CANCEL) {
          cb('cancel');
        }
        else {
          localStorage.setItem('deviceFeatureDisabled', 'true');
          androidOSLockMechanism();
          cb('errorModal');
        }
      }

      if (this.platform === 'iOS') {
        let msg = 'יש להזדהות באמצעות Face ID';
        //face
        if (this.deviceFeatures.identification.face) {
        }
        //finger
        else if (this.deviceFeatures.identification.finger) {
          msg = 'יש להניח את האצבע על הסורק להמשך';
        }
        window.plugins.touchid.verifyFingerprintWithCustomPasswordFallback(
          msg,
          successHandler,
          iosErrorHandler)
      }

      //platform: android
      else {
        let config = {
          clientId: "Robin",
          dialogMessage: "יש להניח את האצבע על הסורק להמשך",
          dialogTitle: "",
          disableBackup: true,
          password: "currentUserPassword",
          token: "Robin",
          username: "currentUser",
          withBackup: false,
          withFingerprint: true
        };
        window.FingerprintAuth.encrypt(config, successHandler, handleAndroidError);
      }
    }
    else {
      cb('errorModal');
    }
  }

  resetFingerPrintRestrictions() {
    this.retriesCounter = 0;
    if (localStorage.getItem('deviceFeatureDisabled'))
      localStorage.removeItem('deviceFeatureDisabled');
  }

  navigateFromApp(url) {
    if (this.platform === "Android")
      window.open(url, '_system', 'location=no');
    else
      window.open(url, '_system', 'location=yes');
  }

  skipToNextInput() {
    let elements = document.querySelectorAll('input')
    let currentActiveElement = document.activeElement
    let activeIndex = Array.prototype.indexOf.call(elements, currentActiveElement)
    if (activeIndex === elements.length - 1) {
      this.closeKeyBoard()
    }
    else {
      elements[activeIndex + 1].focus()
    }
  }

  isLastInput(){
    let elements = document.querySelectorAll('input')
    let currentActiveElement = document.activeElement
    let activeIndex = Array.prototype.indexOf.call(elements, currentActiveElement)
    return activeIndex === elements.length - 1
  }

  closeKeyBoard() {
    if (this.platform === 'iOS') {
      window.Keyboard.hide();
    }
  }

  unableFontPreference() {
    console.log('hi');
    window.MobileAccessibility.usePreferredTextZoom(false);
  }

}
