import { NewRobinComponent } from './../onboarding/components/intro/new-robin/new-robin.component';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ImageCaptureService } from './services/image-capture.service';
// ANGULAR'S MODULES
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// MODUELS
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { OnBoardingRoutingModule } from './onboarding-routing.module';

// 3RD PARTY MODULES
import { LottieAnimationViewModule } from 'lottie-angular2';

// SERVICES
import { CacheService } from './services/cache.service';
import { OnboardingService } from './services/onboarding.service';


// COMPONENTS
import { IntroComponent } from './components/intro/intro.component';
import { SlideComponent } from './components/intro/slide/slide.component';
import { LoginComponent } from './components/login/login.component';
import { LetsKnowComponent } from './components/lets-know/lets-know.component';
import { OtpComponent } from './components/otp/otp.component';
import { TakanonComponent } from './components/takanon/takanon.component';
import { AuthService } from './services/auth.service';
import { IdCardMainComponent } from './components/identification/id-card/id-card-main/id-card-main.component';
import { IdCardTipsComponent } from './components/identification/id-card/id-card-main/id-card-tips/id-card-tips.component';
import { IdCardPreviewComponent } from './components/identification/id-card/id-card-preview/id-card-preview.component';
import { SelfieComponent } from './components/identification/selfie/selfie.component';
import { CheckInfoComponent } from './components/check-info/check-info.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { PleaseWaitComponent } from './components/please-wait/please-wait.component';
import { PleaseWaitLoaderComponent } from './components/please-wait/please-wait-loader/please-wait-loader.component';
import { ReminderComponent } from './components/reminder/reminder.component';
import { CollectSuccessComponent } from './components/collect-success/collect-success.component';
import { LastStepComponent } from './components/last-step/last-step.component';
import { SelfiePreviewComponent } from './components/identification/selfie/id-card-preview/selfie-preview.component';
import { NoIdModalComponent } from './components/identification/id-card/id-card-main/modals/no-id-modal/no-id-modal.component';
import { ImageOptionModalComponent } from './components/identification/id-card/id-card-main/modals/image-option-modal/image-option-modal.component';
import { RegisterService } from './services/register/register.service';
import { OtpService } from './services/otp/otp.service';
import { FirstPageComponent } from './components/first-page/first-page.component';

// import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '../../../node_modules/@angular/platform-browser';
// import * as Hammer from 'hammerjs';
import { AboutComponent } from './components/about/about.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SupportComponent } from './components/support/support.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { httpInterceptorProviders } from '../core/services/http-interceptor.service';
// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     // override hammerjs default configuration
//     'swipe': { direction: Hammer.DIRECTION_ALL }
//   }
// }
@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    OnBoardingRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  declarations: [
    NewRobinComponent,
    LoginComponent,
    IntroComponent,
    SlideComponent,
    LetsKnowComponent,
    OtpComponent,
    TakanonComponent,
    IdCardMainComponent,
    IdCardTipsComponent,
    IdCardPreviewComponent,
    SelfieComponent,
    CheckInfoComponent,
    HowItWorksComponent,
    PleaseWaitComponent,
    PleaseWaitLoaderComponent,
    ReminderComponent,
    CollectSuccessComponent,
    LastStepComponent,
    SelfiePreviewComponent,
    NoIdModalComponent,
    ImageOptionModalComponent,
    AboutComponent,
    SettingsComponent,
    SupportComponent,
    CreditCardComponent,
    FirstPageComponent
   
  ],
  providers: [
    OnboardingService,
    CacheService,
    AuthService,
    RegisterService,
    OtpService,
    httpInterceptorProviders
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: MyHammerConfig
    // }
  ],
  entryComponents: []
})
export class OnBoardingModule { }
