import { FetchService } from './../../services/fetch.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  _text = 'אהלן וסהלן';
  _likes = 0;
  _id;

  @Input() set id(txt) {
    this._id = txt;
  }
  @Input() set text(txt) {
    this._text = txt;
  }
  @Input() set likes(likes) {
    this._likes = likes;
  }
  constructor(private fetchService: FetchService) { }

  ngOnInit() {
  }
  heartClick(e) {
    this.fetchService.likeAComment(this._id, e ? true : false).subscribe(data => { });
    e ? this._likes++ : this._likes--;
  }
}
