import { CacheService } from 'src/app/risk/services/cache.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectInsurance } from 'src/app/store/app.states';
import { map } from 'rxjs/operators';

import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

export interface Question {
  id: Number;
  question: String;
  type: String;
  answers: String[];
  yesNext?: Number;
  noNext?: Number;
  hasExtension?: boolean;
  bullets?: String[];
  isExtension?: boolean;
}

@Component({
  selector: 'app-health-declaration',
  templateUrl: './health-declaration.component.html',
  styleUrls: ['./health-declaration.component.scss']
})
export class HealthDeclarationComponent implements OnInit {
  quesType = -1
  manufacturer = -1
  upgradeOrPurchase = -1
  numOfQuestions;
  questionnaireToPerform = []

  me = [
    {
      id: 1,
      answers: ['כן', 'לא'],
      question: 'מעשן/ת?',
      type: 'bool',
      yesNext: 2,
      noNext: 4,
    },
    {
      id: 2,
      question: 'סיגריות/אחר (פרט איזה סוג)',
      type: 'textarea',
      yesNext: 3,
      noNext: 3,
      isExtension: true,
    },
    {
      id: 3,
      question: 'כמות סיגריות יומית?',
      type: 'textarea',
      yesNext: 4,
      noNext: 4,
      isExtension: true,
    },
    {
      id: 4,
      answers: ['כן', 'לא'],
      question: 'אלכוהול: צריכת אלכוהול כעת ו/או בעבר יותר מכוס ביום של בירה/ יין או משקה אלכוהולי אחר',
      type: 'bool',
      yesNext: 6,
      noNext: 6,
    },
    {
      id: 5,
      answers: ['כן', 'לא'],
      question: 'סמים: האם הנך צורך/ת סמים בהווה את צרכת סמים בעבר למעט שימוש חד פעמי?',
      type: 'bool',
      yesNext: 6,
      noNext: 6,
    },
    {
      id: 6,
      answers: ['כן', 'לא'],
      question: 'היסטוריה משפחתית: האם למיטב ידיעתך, בקרב אחד מקרובייך (אב/אם אחים/ אחיות) אובחנו המחלות הבאות: מחלות לב, שבץ מוחי, סרטן המעי הגס, סרטן שחלות, סרטן שד, סוכרת, כליות פוליציסטיות, טרשת נפוצה, ניוון שרירים, מחלת הנטינגון, מחלות נפשיות, מחלת פרקינסון, מחלת אלצהיימר',
      type: 'bool',
      yesNext: 7,
      noNext: 7,
    },
    {
      id: 7,
      answers: ['כן', 'לא'],
      question: 'אשפוזים: האם אושפזת ב-5 שנים האחרונות או שידוע לך על צורך באשפוז או בדיקה פולשנית (למעט אשפוז למטרת לידה)',
      type: 'bool',
      yesNext: 8,
      noNext: 8,
    },
    {
      id: 8,
      answers: ['כן', 'לא'],
      question: 'ניתוחים: האם בשנתיים האחרונות עברת או הומלץ לך לעבור ניתוח?',
      type: 'bool',
      yesNext: 9,
      noNext: 9,
    }, 
    {
      id: 9,
      answers: ['כן', 'לא'],
      question: 'תרופות: האם בשנה האחרונה אתה נוטל תרופות או הומלץ לך ליטול תרופות באופן קבוע בשל מחלה כרונית?',
      type: 'bool',

      yesNext: 10,
      noNext: 10,
    },
    {
      id: 10,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['מחלה ממארת','גידולים לרבות גידול שפיר, סרטני וטרום סרטני','מחלת כבד','צהבת','הפרעות בתפקוד כבד'],
      yesNext: 11,
      noNext: 11,
    },
    {
      id: 11,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['אי ספיקת כליות','כליה פוליצסטית','מום בכליה','מערכת כליות ודרכי השתן','אבנים','רפלוקס דרכי השתן','דלקות','דם ו/או חלבון בשתן'],
      yesNext: 12,
      noNext: 12,
    },
    {
      id: 12,
      answers: ['כן', 'לא'],
      question: 'האם נעדרת מהעבודה מסיבה רפואית או היית בחופשת מחלה יותר מ 10 ימים רצופים ב 12 החודשים האחרונים',
      type: 'bool',
      yesNext: 13,
      noNext: 13,
    },

    {
      id: 13,
      question: 'אם אובחנת במהלך חייך במחלות/ הפרעות ו/או בעיות רפואיות: מחלות מין ואיידס/נשא, HIV, ומחלות ומחלות זיהומיות שאובחנו בשנה האחרונה ודורשות טיפול רציף של חודשיים לפחות',
      answers: ['כן', 'לא'],
      type: 'bool',
      yesNext: 14,
      noNext: 14,
    },
    {
      id: 14,
      answers: ['כן', 'לא'],
      question: 'בדיקות אבחנתיות: האם ב3 שנים האחרונות עברת או הומלץ לך לבצע אחת או יותר מן הבדיקות הבאות',
      type: 'bool',
      bullets: ['צינטור כלי דם','צינטור כליות','מיפוי לב/עצמות/כליות','אקו לב','CT','MRI','ביופסה','קולונוסקפיה'],
      yesNext: 15,
      noNext: 15,
    },
    {
      id: 15,
      question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בעיות רפואיות:',
      type: 'bool',
      answers: ['כן', 'לא'],
      bullets: ['מערכת העצבים והמוח - לרבות טרשת נפוצה, תסמונת דאון, אוטיזם, גושה, ניוון שרירים, אפילפסיה, פרקינסון ושיתוק, האם פנית לרופא בתלונות הקשורות לירידה בזכרון ב-3 השנים האחרונות?'],
      yesNext: 16,
      noNext: 16,
    },
    {
      id: 16,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בעיות רפואיות:',
      type: 'bool',
      bullets: ['מערכת הלב וכלי דם - לרבות לב, כלי דם, אירוע מוחי לרבות אירוע איסכמי - מוחי, חולף TIA','יתר לחץ דם ב5 שנים האחרונות ומערכת כלי דם היקפית - פריפריאלי'],
      yesNext: 17,
      noNext: 17,
    },
    {
      id: 17,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בעיות רפואיות:',
      type: 'bool',
      bullets: ['מערכת דם - לרבות דם בעיות קרישה','מערכת מוטובולית - לרבות סוכרת כולל סכרת הריון, שומנים/כולסטרול/טריגליצרידים בדם מחלות הקשורות בבלוטות: בלוטת המגן, בלוטות יותרת המוח, בלוטת יתרת הכליה, בלוטת הפאראתירואיד'],
      yesNext: 18,
      noNext: 18,
    },
    {
      id: 18,
      answers: ['כן', 'לא'],
      question: 'האם אובחנת במהלך חייך במחלות/ הפרעות ו/או בעיות רפואיות:',
      bullets: ['מחלות ראומטולוגיות - רקמות חיבור ודלקות פרקים, לרבות גאוט, לופוס/זאבת, פיברומיאלגיה וקדחת ים תיכונית (FMF) תסמונת תשישות כרונית, סקלרודרמה'],
      type: 'bool',
    },
  ];
  meAndChildren = [
    {
      id: 1,
      answers: ['כן', 'לא'],
      question: 'מעשן/ת?',
      type: 'bool',
      yesNext: 2,
      noNext: 4,
    },
    {
      id: 2,
      question: 'סיגריות/אחר (פרט איזה סוג)',
      type: 'textarea',
      yesNext: 3,
      noNext: 3,
      isExtension: true,
    },
    {
      id: 3,
      question: 'כמות סיגריות יומית?',
      type: 'textarea',
      yesNext: 4,
      noNext: 4,
      isExtension: true,
    },
    {
      id: 4,
      answers: ['כן', 'לא'],
      question: 'אלכוהול: צריכת אלכוהול כעת ו/או בעבר יותר מכוס ביום של בירה/ יין או משקה אלכוהולי אחר',
      type: 'bool',
      yesNext: 6,
      noNext: 6,
    },
    {
      id: 5,
      answers: ['כן', 'לא'],
      question: 'סמים: האם הנך צורך/ת סמים בהווה את צרכת סמים בעבר למעט שימוש חד פעמי?',
      type: 'bool',
      yesNext: 6,
      noNext: 6,
    },
    {
      id: 6,
      answers: ['כן', 'לא'],
      question: 'היסטוריה משפחתית: האם למיטב ידיעתך, בקרב אחד מקרובייך (אב/אם אחים/ אחיות) ו/או קרובי ילדך אובחנו המחלות הבאות: מחלות לב, שבץ מוחי, סרטן המעי הגס, סרטן שחלות, סרטן שד, סוכרת, כליות פוליציסטיות, טרשת נפוצה, ניוון שרירים, מחלת הנטינגון, מחלות נפשיות, מחלת פרקינסון, מחלת אלצהיימר',
      type: 'bool',
      yesNext: 7,
      noNext: 7,
    },
    {
      id: 7,
      answers: ['כן', 'לא'],
      question: 'אשפוזים: האם הנך או ילדך אושפזו ב-5 שנים האחרונות או שידוע לך על צורך באשפוז או בדיקה פולשנית (למעט אשפוז למטרת לידה)',
      type: 'bool',
      yesNext: 8,
      noNext: 8,
    },
    {
      id: 8,
      answers: ['כן', 'לא'],
      question: 'ניתוחים: האם בשנתיים האחרונות הנך או ילדיך עברתם או הומלץ לכם לעבור ניתוח?',
      type: 'bool',
      yesNext: 9,
      noNext: 9,
    }, 
    {
      id: 9,
      answers: ['כן', 'לא'],
      question: 'תרופות: האם בשנה האחרונה הנך או ילדיך נטלתם תרופות או הומלץ לך ליטול תרופות באופן קבוע בשל מחלה כרונית?',
      type: 'bool',

      yesNext: 10,
      noNext: 10,
    },
    {
      id: 10,
      answers: ['כן', 'לא'],
      question: 'האם הנך או ילדיך אובחנתם במהלך חייכם במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['מחלה ממארת','גידולים לרבות גידול שפיר, סרטני וטרום סרטני','מחלת כבד','צהבת','הפרעות בתפקוד כבד'],
      yesNext: 11,
      noNext: 11,
    },
    {
      id: 11,
      answers: ['כן', 'לא'],
      question: 'האם הנך או ילדיך אובחנתם במהלך חייכם במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['אי ספיקת כליות','כליה פוליצסטית','מום בכליה','מערכת כליות ודרכי השתן','אבנים','רפלוקס דרכי השתן','דלקות','דם ו/או חלבון בשתן'],
      yesNext: 12,
      noNext: 12,
    },
    {
      id: 12,
      answers: ['כן', 'לא'],
      question: 'הם הנך או ילדיך נעדרתם מהעבודה/לימודים מסיבה רפואית או הייתם בחופשת מחלה יותר מ 10 ימים רצופים ב 12 החודשים האחרונים',
      type: 'bool',
      yesNext: 13,
      noNext: 13,
    },

    {
      id: 13,
      question: 'אם הנך או ילדיך אובחנתם במהלך חייכם במחלות/ הפרעות ו/או בעיות רפואיות: מחלות מין ואיידס/נשא, HIV, ומחלות ומחלות זיהומיות שאובחנו בשנה האחרונה ודורשות טיפול רציף של חודשיים לפחות',
      answers: ['כן', 'לא'],
      type: 'bool',
      yesNext: 14,
      noNext: 14,
    },
    {
      id: 14,
      answers: ['כן', 'לא'],
      question: 'בדיקות אבחנתיות: האם ב3 שנים האחרונות הנך או ילדיך עברתם או הומלץ לכם לבצע אחת או יותר מן הבדיקות הבאות',
      type: 'bool',
      bullets: ['צינטור כלי דם','צינטור כליות','מיפוי לב/עצמות/כליות','אקו לב','CT','MRI','ביופסה','קולונוסקפיה'],
      yesNext: 15,
      noNext: 15,
    },
    {
      id: 15,
      question: 'האם הנך או ילדיך אובחנתם במהלך חייכם במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      answers: ['כן', 'לא'],
      bullets: ['מערכת העצבים והמוח - לרבות טרשת נפוצה, תסמונת דאון, אוטיזם, גושה, ניוון שרירים, אפילפסיה, פרקינסון ושיתוק, האם פנית לרופא בתלונות הקשורות לירידה בזכרון ב-3 השנים האחרונות?'],
      yesNext: 16,
      noNext: 16,
    },
    {
      id: 16,
      answers: ['כן', 'לא'],
      question: 'האם הנך או ילדיך אובחנתם במהלך חייכם במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['מערכת הלב וכלי דם - לרבות לב, כלי דם, אירוע מוחי לרבות אירוע איסכמי - מוחי, חולף TIA','יתר לחץ דם ב5 שנים האחרונות ומערכת כלי דם היקפית - פריפריאלי'],
      yesNext: 17,
      noNext: 17,
    },
    {
      id: 17,
      answers: ['כן', 'לא'],
      question: 'האם הנך או ילדיך אובחנתם במהלך חייכם במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['מערכת דם - לרבות דם בעיות קרישה','מערכת מוטובולית - לרבות סוכרת כולל סכרת הריון, שומנים/כולסטרול/טריגליצרידים בדם מחלות הקשורות בבלוטות: בלוטת המגן, בלוטות יותרת המוח, בלוטת יתרת הכליה, בלוטת הפאראתירואיד'],
      yesNext: 18,
      noNext: 18,
    },
    {
      id: 18,
      answers: ['כן', 'לא'],
      question: 'האם הנך או ילדיך אובחנתם במהלך חייכם במחלות/ הפרעות ו/או בעיות רפואיות',
      bullets: ['מחלות ראומטולוגיות - רקמות חיבור ודלקות פרקים, לרבות גאוט, לופוס/זאבת, פיברומיאלגיה וקדחת ים תיכונית (FMF) תסמונת תשישות כרונית, סקלרודרמה'],
      type: 'bool',
    },
  ];
  children = [
    {
      id: 1,
      answers: ['כן', 'לא'],
      question: 'היסטוריה משפחתית: האם למיטב ידיעתך, בקרב אחד מקרובי ילדייך (אב/אם אחים/ אחיות) אובחנו המחלות הבאות: מחלות לב, שבץ מוחי, סרטן המעי הגס, סרטן שחלות, סרטן שד, סוכרת, כליות פוליציסטיות, טרשת נפוצה, ניוון שרירים, מחלת הנטינגון, מחלות נפשיות, מחלת פרקינסון, מחלת אלצהיימר',
      type: 'bool',
      yesNext: 2,
      noNext: 2,
    },
    {
      id: 2,
      answers: ['כן', 'לא'],
      question: 'אשפוזים: הםא ילדך אושפזו בבית חולים לתקופה העולה על 48 שעות במהלך השנתיים האחרונות? (למעט לידה)',
      type: 'bool',
      yesNext: 3,
      noNext: 3,
    },
    {
      id: 3,
      answers: ['כן', 'לא'],
      question: 'ניתוחים: האם בשנתיים האחרונות עברו או הומלץ לילדך לעבור ניתוח?',
      type: 'bool',
      yesNext: 4,
      noNext: 4,
    }, 
    {
      id: 4,
      answers: ['כן', 'לא'],
      question: 'תרופות: האם בשנה האחרונה ילדיך נוטלים תרופות או הומלץ להם ליטול תרופות באופן קבוע בשל מחלה כרונית?',
      type: 'bool',
      yesNext: 5,
      noNext: 5,
    },
    {
      id: 5,
      answers: ['כן', 'לא'],
      question: 'האם ילדך אובחנו במהלך חייהם במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['מחלה ממארת','גידולים לרבות גידול שפיר, סרטני וטרום סרטני','מחלת כבד','צהבת','הפרעות בתפקוד כבד'],
      yesNext: 6,
      noNext: 6,
    },
    {
      id: 6,
      answers: ['כן', 'לא'],
      question: 'האם ילדך אובחנו במהלך חייהם במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['אי ספיקת כליות','כליה פוליצסטית','מום בכליה','מערכת כליות ודרכי השתן','אבנים','רפלוקס דרכי השתן','דלקות','דם ו/או חלבון בשתן'],
      yesNext: 7,
      noNext: 7,
    },
    {
      id: 7,
      answers: ['כן', 'לא'],
      question: 'האם נעדרו ילדיך מהעבודה מסיבה רפואית או היית בחופשת מחלה יותר מ 10 ימים רצופים ב 12 החודשים האחרונים',
      type: 'bool',
      yesNext: 8,
      noNext: 8,
    },

    {
      id: 8,
      question: 'אם ילדיך אובחנו במהלך חייהם במחלות/ הפרעות ו/או בעיות רפואיות: מחלות מין ואיידס/נשא, HIV, ומחלות ומחלות זיהומיות שאובחנו בשנה האחרונה ודורשות טיפול רציף של חודשיים לפחות',
      answers: ['כן', 'לא'],
      type: 'bool',
      yesNext: 9,
      noNext: 9,
    },
    {
      id: 9,
      answers: ['כן', 'לא'],
      question: 'בדיקות אבחנתיות: האם ב3 שנים האחרונות עברו ילדיך או הומלץ להם לבצע אחת או יותר מן הבדיקות הבאות',
      type: 'bool',
      bullets: ['צינטור כלי דם','צינטור כליות','מיפוי לב/עצמות/כליות','אקו לב','CT','MRI','ביופסה','קולונוסקפיה'],
      yesNext: 10,
      noNext: 10,
    },
    {
      id: 10,
      question: 'האם ילדך אובחנו במהלך חייך במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      answers: ['כן', 'לא'],
      bullets: ['מערכת העצבים והמוח - לרבות טרשת נפוצה, תסמונת דאון, אוטיזם, גושה, ניוון שרירים, אפילפסיה, פרקינסון ושיתוק, האם פנית לרופא בתלונות הקשורות לירידה בזכרון ב-3 השנים האחרונות?'],
      yesNext: 11,
      noNext: 11,
    },
    {
      id: 11,
      answers: ['כן', 'לא'],
      question: 'האם ילדך אובחנו במהלך חייהם במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['מערכת הלב וכלי דם - לרבות לב, כלי דם, אירוע מוחי לרבות אירוע איסכמי - מוחי, חולף TIA','יתר לחץ דם ב5 שנים האחרונות ומערכת כלי דם היקפית - פריפריאלי'],
      yesNext: 12,
      noNext: 12,
    },
    {
      id: 12,
      answers: ['כן', 'לא'],
      question: 'האם ילדך אובחנו במהלך חייהם במחלות/ הפרעות ו/או בעיות רפואיות',
      type: 'bool',
      bullets: ['מערכת דם - לרבות דם בעיות קרישה','מערכת מוטובולית - לרבות סוכרת כולל סכרת הריון, שומנים/כולסטרול/טריגליצרידים בדם מחלות הקשורות בבלוטות: בלוטת המגן, בלוטות יותרת המוח, בלוטת יתרת הכליה, בלוטת הפאראתירואיד'],
      yesNext: 13,
      noNext: 13,
    },
    {
      id: 13,
      answers: ['כן', 'לא'],
      question: 'האם ילדך אובחנו במהלך חייהם במחלות/ הפרעות ו/או בעיות רפואיות',
      bullets: ['מחלות ראומטולוגיות - רקמות חיבור ודלקות פרקים, לרבות גאוט, לופוס/זאבת, פיברומיאלגיה וקדחת ים תיכונית (FMF) תסמונת תשישות כרונית, סקלרודרמה'],
      type: 'bool',
    },
  ];


  constructor(private cacheService: CacheService,
    private store: Store<AppState>,
    private ciservice: CriticalillnessService,
    private analyticService: AnalyticsService
  ) {
    this.manipulateQuestionnaire(this.ciservice.whoToCover)
  }

  ngOnInit() {
    this.analyticService.trackByAppFlyer("DP - Mahalot Kashot  - Health Quest", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
  }

  manipulateQuestionnaire(type) {
    let chosen = []

    switch (type) {
      case 1:
        chosen = this.me;
        this.numOfQuestions = 15;
        break;

      case 2:
        chosen = this.children;
        this.numOfQuestions = 13;
        break;

      case 3:
        chosen = this.meAndChildren
        this.numOfQuestions = 15;
        break;
      }
      this.questionnaireToPerform = chosen;
    }

  next(e) {
    this.ciservice.cacheCritical[4] = e;
    this.ciservice.cacheCritical[5] = [];
    this.ciservice.currentTab.next(5);  
  }
}
