import { RegisterActionTypes, LoginActionTypes } from '../../store/user.actions';
import { User } from "../../core/models/user.model";
import { All } from "../../store/user.actions";

export interface State {
    token: string
}

export const initialState: State = {
    token: (() => localStorage.getItem('token'))()
};

export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case LoginActionTypes.LOGIN: {
            return {
                ...state
            };
        }
        case LoginActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                token: action.payload.token
            };
        }
        case LoginActionTypes.LOGIN_FAILURE: {
            return {
                ...state,
                token: null
            };
        }
        default: {
            return state;
        }
    }
}