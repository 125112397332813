import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class LastStateService {
  locationHistory: any = { pension: [] }
  offsetHistory = 0;
  pensionTransition = [];
  cacheInd = 0;
  insuranceLife = 0;
  insuranceHealth = 0;
  handleBottomNav = new ReplaySubject();
  handleBottomNav$ = this.handleBottomNav.asObservable();

  constructor(route: ActivatedRoute, private router: Router) {
    //bottom navbar item clicked, we want to clean the cache
    this.handleBottomNav$.subscribe(data => {
      this.cleanCache();
    });
  }

  cleanCache() {
    this.locationHistory = { pension: [] };
    this.offsetHistory = 0;
    this.pensionTransition = [];
    this.cacheInd = 0;
    this.insuranceLife;
    this.insuranceHealth;
  }
}


