import { selectAnonymous, selectRegisterState } from './../../../../../../store/app.states';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/app.states';
import { map, ignoreElements } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { OtpService } from '../../../../../../onboarding/services/otp/otp.service';
import { validatorsStrings } from '../../../../../assets/heb.validators';
import { CalledAnonymous } from '../../../../../../store/user.actions';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  settingsForm: FormGroup;
  allowAds: boolean = true;
  public uiString;
  public user;

  constructor(private otpService: OtpService, private store: Store<AppState>, private fb: FormBuilder) {
    this.uiString = validatorsStrings.settings;
    this.allowAds = this.otpService.allowads;
    // this.store.select(selectAnonymous).pipe(map((data: any) => data.anonymous.AllowAds)).subscribe(data => this.allowAds = data);
    this.settingsForm = this.fb.group({
      adv: [this.allowAds || false, []]
    });
  }


  ngOnInit() {
  }
  update(e) {
    this.otpService.allowAds(e).subscribe((data: any) => {
      if (!data.Message)
      this.allowAds = e;
    }, err => { });
  }

}
