import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from './../../../shared/components/robin-modal/modal.service';
import { Register, CalledAnonymous } from './../../../store/user.actions';
import { Response, UpdatePhoneNumber } from './../../../models/global.models';
import { ErrorModalService } from './../../../shared/components/modals/error-modal/modal.service';
import { LoggerService } from './../../../core/services/logger.service';
import { Login, RegisterActionTypes } from '../../../store/user.actions';
import { Location } from '@angular/common';
import { NavManagerService } from '../../../shared/components/robin-navbar/robin-navbar.service';
import { OtpService } from '../../services/otp/otp.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState, selectAnonymous } from '../../../store/app.states';
import { Store } from '@ngrx/store';
import * as uid from 'uuid';
import { User } from '../../../core/models/user.model';
import { uiStrings } from "../../assets/heb.ui_strings";
import { UtilityService } from '../../../core/services/utility.service';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { BranchService } from 'src/app/core/services/branch.service';
import { AccountService } from 'src/app/account/account.service';
import { RegisterService } from '../../services/register/register.service';
import { CordovaService } from 'src/app/core/services/cordova.service';


declare const window;
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  to;
  private tokenGenerated = '';
  public timePassed = false;
  public otpVerified = false;
  //public otpVerified = true;
  public editPhoneDrawer = false;
  private user: User;
  public robinHeaderSubtitle: string;
  phoneNumberEdited = false;
  allowResendPhoneNumber = false;
  password;
  phoneNumber = '';
  newPhoneNumber = '';
  textError = '';
  enableEditMode = true;
  indicationState = '';
  retries = 1;
  isIndication = false;
  public uiStrings;
  changePhone: FormGroup;
  otpErrorMessage = '';
  otpErrorMessageShow = false;
  submitted = false;
  forgotPasswordOpen = false;
  showErrorEdit = false;
  hackButton = false;

  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private errorModalService: ErrorModalService,
    private utilityService: UtilityService,
    private loggerService: LoggerService,
    private _router: Router,
    private otpService: OtpService,
    private _nav: NavManagerService,
    private _location: Location,
    private analyticService: AnalyticsService,
    private _modalService: ModalService,
    private branchService: BranchService,
    private accountService: AccountService,
    private registerService: RegisterService,
    private cordovaService: CordovaService) {
    this.uiStrings = uiStrings.otp;
  }
  
  
  focusFirstInput() {
    let el: any = document.querySelectorAll('form input')[0];
    if (el) el.focus();
  }

  ngOnInit() {
    //Otp verification process started
    this.analyticService.trackByMixpanel("User Registration - OTP - Start");
    this.analyticService.trackByAppFlyer("User Registration - OTP - Start", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.route.queryParams.subscribe(params => {
      if (params.editMode) {
        this.enableEditMode = params.editMode;
      }
    });
    this.smsDidntArrive();
    this.store.select('registerState').subscribe(data => {
      this.user = data.user;
      this.robinHeaderSubtitle = "ברגעים אלו שלחתי אליך סמס עם קוד אימות למס' הטלפון";
      if (data.user && data.user.phoneNumber) this.phoneNumber = data.user.phoneNumber;
      else this.phoneNumber = '';
    });

    this.changePhone = this.fb.group({
      rephone: [this.phoneNumber, [Validators.required, Validators.pattern(/^05[.\d]+$/), Validators.minLength(10), Validators.maxLength(10)]],
      // allowAds: [true, []],
      // policy: [false, [Validators.requiredTrue]],
    });

    console.log('phoneNumber', this.phoneNumber)
  }



  keydown(e, len) {
    this.showErrorEdit = false;
    // this.utilityService.goToNextInput(e);
    this.utilityService.detectKey(e);
    this.utilityService.inputLength(e, len)

  }
  restartCountDownAnimation() {
    let container1: any = document.querySelector('.container1');
    let container2: any = document.querySelector('.container2');
    if (container1 && container2) {
      container1.classList.remove('container1');
      container2.classList.remove('container2');
    }
    setTimeout(() => {
      if (container1 && container2) {
        container1.classList.add('container1');
        container2.classList.add('container2');
      }
    }, 100);
  }
  smsDidntArrive() {
    this.timePassed = false;
    if (this.to) {
      clearInterval(this.to);
      this.restartCountDownAnimation();
    }
    this.to = setTimeout(() => {
      if (!this.otpVerified)
        this.timePassed = true;
    }, 30 * 1000);
  }

  passwordCompleted(pass) {
    this.password = pass;
  }

  savePassword() {
    let el: any = document.querySelectorAll('robin-passcode input')[0];
    if (el.value.length === 6 && !this.submitted) {
      this.password = el.value;
      let body = {
        PrimaryIdentifier: this.user.phoneNumber,
        TokenGenerated: this.tokenGenerated,
        NewPassword: this.password
      };
      this.submitted = true;
      this.hackButton = true;
      this.utilityService.setLoaderStatus(true);
      this.otpService.savePincode(body).subscribe(
        (data: Response) => {
          if (data.Code === 0) {         
            this.submitted = false;
            this._nav.strict = false;
            //User code success
            this.analyticService.trackByMixpanel("User Registration - PIN - End");
            this.analyticService.trackByAppFlyer("User Registration - PIN - End", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
            this.tokenGenerated = '';

            this.utilityService.setLoaderStatus(true);
            this.otpService.createToken(this.password).subscribe((data: string) => {
              this.utilityService.setLoaderStatus(false);

              if (data) {
                this.store.dispatch(new Login({ token: data }));
                this.accountService.getAuthenticatedStatusMode((err, res) => {
                  if (!err) {
                    this.accountService.authenticatedModel = res;
                    this.accountService.coin.next(res.Credits);
                    this.accountService.coupon = res.Coupon;
                    this.accountService.inviteLink = res.InviteLink;
                  }
                })
                document.body.classList.remove('disableOverflow');
                this.store.select(selectAnonymous).subscribe((anon: any) => {
                  console.log('my anon => ', anon)
                  if (anon.anonymous) {
                    if (anon.anonymous.MislakaStatus >= 4) {
                      this._nav.next('homepage');
                    } else {
                      this._nav.next('howitworks');
                    }
                  }
                  else {
                    this._nav.next('howitworks');
                  }
                });
                // this._router.navigate(['/onboarding', 'howitworks']);
              }
            },
              // err => 
              //this.errorModalService.setNewError({
              //   title: 'שגיאה',
              //   description: 'אנא ודא שכל השדות תקינים',
              //   type: 'error',
              //   buttonText: 'אישור',
              // }).open()
            );
            // this.hackButton = false;

          }
          else {
            this.submitted = false;
            this.hackButton = false;

            // this.errorModalService.setNewError({
            //   title: 'אופס...',
            //   description: 'נראה שמשהו השתבש בדרך',
            //   type: 'error',
            //   buttonText: 'נסה שנית',
            // }).open()
          }
        },
        err => {
          this.submitted = false;
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open();
          this.hackButton = false;

        }
      )

    }
    else {
      this.showErrorMessagePin();
      this.hackButton = false;

    }


  }

  showErrorMessagePin() {
    this.forgotPasswordOpen = true;
    setTimeout(() => {
      this.forgotPasswordOpen = false;
    }, 1500);
  }


  showErrorMessage() {
    this.otpErrorMessageShow = true;
    return this;

  }

  setIndication(state: string, show: boolean) {
    this.isIndication = show;
    this.indicationState = state;
    console.log(this.indicationState)
    return this;
  }

  otpChanges(e) {
    let SourceParams;
    let otp = e.target.value;
    this.setIndication('', false).showErrorMessage();
    this.otpErrorMessage = '';
    let body = {
      OtpCode: otp,
      SecondaryIdentifier: this.user.email,
      PrimaryIdentifier: this.user.phoneNumber,
      invitedCoupon: this.branchService.coupon,
    };
    if (otp.length === 6) {

      this.setIndication('loading', true).showErrorMessage();

      e.target.disabled = true;
      this.otpService.verifyOtp(body).subscribe(
        (data: Response) => {
          if (data.Code === 0) {

            this.setIndication('success', true).showErrorMessage();

            if (!this.registerService.source) {
              if (this.cordovaService.isMobile) {
                SourceParams = 'Robin';
              } else {
                SourceParams = 'Web';
              }
            } else {
              SourceParams = this.registerService.source;
            }
            const source = {
              SourceParams: SourceParams,
              PhoneNumber: this.user.phoneNumber
            };
            this.otpService.sendSource(source).subscribe((data: Response) => {});
            //User enter right 6 digits OTP + got success response
            this.analyticService.trackByMixpanel("User Registration - OTP - End");
            let phoneNumber = this.analyticService.phoneNumber;
            let uuid = this.analyticService.uuid;
            this.analyticService.trackByAppFlyer("User Registration - OTP - End", { phoneNumber, uuid });
            this.tokenGenerated = <string>data.Data;
            this.otpVerified = !this.otpVerified;
            setTimeout(() => this.focusFirstInput(), 10);
            //we strict the navigation from android devices (back button)
            this._nav.strict = true;
            //Popover of create pin opened
            this.analyticService.trackByMixpanel("User Registration - PIN - Start");
            this.analyticService.trackByAppFlyer("User Registration - PIN - Start", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          }
          else {
            e.target.disabled = false;
            e.target.value = '';

            this.setIndication('error', true);
            this.showErrorMessage();
            this.otpErrorMessage = 'קוד האימות שהזנת שגוי';
          }
        },
        err => {
          this.setIndication('error', true);
          e.target.disabled = false;
          e.target.value = '';
          this.showErrorMessage();
          this.otpErrorMessage = 'קוד האימות שהזנת שגוי';
        }
      );
    }
  }
  openSupport() {
    this._modalService.open('support');
  }
  reSendSms() {
    if (this.retries < 3)
      setTimeout(() => this.retries += 1, 1000 * 5);
    if (!this.submitted) {
      let body = {
        PrimaryIdentifier: this.user.phoneNumber,
        SecondaryIdentifier: this.user.email
      };
      this.submitted = true;
      this.otpService.resendOtp(body).subscribe((data: Response) => {
        if (data.Code === 0) {
          this.submitted = false;
          this.smsDidntArrive();
        } else {
          this.submitted = false;
          //Code send failed
          this.analyticService.trackByMixpanel("User Registration - OTP - Fail");
          this.analyticService.trackByAppFlyer("User Registration - OTP - Fail", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open()
        }
      },
        err => {
          this.submitted = false;
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open()
        }
      );
    }
  }

  reSendCall() {
    if (this.retries < 3)
      setTimeout(() => this.retries += 1, 10);
    if (!this.submitted) {
      let body = {
        PrimaryIdentifier: this.user.phoneNumber,
        SecondaryIdentifier: this.user.email
      };
      this.submitted = true;
      this.otpService.resendOtpCall(body).subscribe((data: Response) => {
        if (data.Code === 0) {
          this.submitted = false;
          this.otpVerified = false;
          this.timePassed = false;
          this.smsDidntArrive();
        } else {
          this.submitted = false;
          //Code send failed
          this.analyticService.trackByMixpanel("User Registration - OTPCall - Fail");
          this.analyticService.trackByAppFlyer("User Registration - OTPCall - Fail", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open()
        }
      },
        err => {
          this.submitted = false;
          this.errorModalService.setNewError({
            title: 'אופס...',
            description: 'נראה שמשהו השתבש בדרך',
            type: 'error',
            buttonText: 'נסה שנית',
          }).open()
        }
      );
    }
  }

  reEnterPhoneNumber(e) {
    this.allowResendPhoneNumber = e.target.value.length === 10 ? true : false;
    if (this.allowResendPhoneNumber) this.newPhoneNumber = e.target.value;
  }

  reSendPhoneNumber(e) {
    if (this.showErrorEdit)
      this.showErrorEdit = false;
    let obj: UpdatePhoneNumber = new UpdatePhoneNumber();
    obj.NewPhoneNumber = this.newPhoneNumber;
    obj.OldPhoneNumber = this.phoneNumber;
    if (this.newPhoneNumber === this.phoneNumber) {
      this.textError = "אנא עדכן את המספר";
      this.showErrorEdit = true;
      // setTimeout(() => {
      //   this.showErrorEdit = false;
      //   this.textError = ''}, 3000
      // );
    }
    else {
      if (this.changePhone.valid) {
        this.otpService.updatePhoneNumber(obj).subscribe((data: Response) => {
          if (data.Code === 0) {
            //replace the local storage phonenumber
            this.phoneNumber = this.newPhoneNumber;
            this.store.dispatch(new Register({ user: { phoneNumber: this.phoneNumber } }));
            this.phoneNumberEdited = false;
            this.smsDidntArrive();
          }
          else if (data.Code === 1002) {
            this.textError = "משתמש קיים";
            this.showErrorEdit = true;
            setTimeout(() => { this.textError = ""; this.showErrorEdit = false; }, 1500);

          }
          else {
            this.textError = "אנא לוודא שהמספר שהוזן תקין";
            this.showErrorEdit = true;
            setTimeout(() => { this.textError = ""; this.showErrorEdit = false; }, 1500);
          }
        });
      }
    }



  }

  closeDrawerClicked() {
    this.phoneNumberEdited = false;
    this.changePhone.reset();
  }

  editPhoneNumber(e) {
    //User pressed edit phone number button
    this.analyticService.trackByMixpanel("User Registration - OTP - Edit");
    this.analyticService.trackByAppFlyer("User Registration - OTP - Edit", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.changePhone.controls['rephone'].setValue('');
    this.phoneNumberEdited = true;
    // this.newPhoneNumber = this.phoneNumber;
  }

  pinCloseClicked() {
    //this.analyticService.trackByMixpanel("User Registration - PIN - Close");
    //this._router.navigate([`/onboarding`, 'letsknow']);
  }

  updateAds(val) {
    this.otpService.allowAds(val).subscribe((data: any) => { },
      err => { }
    );
  }

}

