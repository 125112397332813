import { RegisterActionTypes } from '../../store/user.actions';
import { User } from "../../core/models/user.model";
import { All } from "../../store/user.actions";

export interface State {
    user: User | null
}

export const initialState: State = {
    user: null
};

export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case RegisterActionTypes.REGISTER_SUCCESS: {
            return {
                ...state,
                user: action.payload.user
            };
        }
        default: {
            return state;
        }
    }
}