import { Component, OnInit } from '@angular/core';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
import { Router } from '@angular/router';
import { AccountRoutingModule } from 'src/app/account/account-routing.module';
import { AccountService } from 'src/app/account/account.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss']
})
export class AmountComponent implements OnInit {
  amountAnswered;
  amount;
  premium;
  premiumChild
  premiumSummary
  isDrawer = false;

  constructor(public ciservice: CriticalillnessService,
    private router: Router,
    private accountservice: AccountService,
    private analyticService: AnalyticsService) {

    if (this.ciservice.whoToCover !== 2) {
      this.amountAnswered = this.ciservice.amount;
      this.calculatePremium();
    }
    else {
      this.amountAnswered = this.ciservice.amountChildren ;
      this.calculatePremium();
    }
  }

  ngOnInit() {
    this.analyticService.trackByAppFlyer('DP - Mahalot Kashot - Choose Cover', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
  }


  openDrawer(e){
    this.isDrawer = true;
  }

  closeDrawer(event){
    this.isDrawer = false;
  }

  showMark() {
    if (this.ciservice.whoToCover!==2) {
      if (this.accountservice.authenticatedModel.Age < 50 )
        return false;
      else if (this.accountservice.authenticatedModel.Age > 50 && this.accountservice.authenticatedModel.Age < 60 && this.amountAnswered>=300000) 
        return true;
      else if (this.accountservice.authenticatedModel.Age > 60)
        return true;
    } 
    return false;
    
  }

  calculatePremium() {
    if (this.ciservice.whoToCover === 1) {
      this.premium = (this.amountAnswered / 100000) * this.ciservice.premiumPer100k;
      this.premiumChild = 0;
    }
    else if (this.ciservice.whoToCover === 2) {
      this.premium = 0;
      this.premiumChild = (this.amountAnswered / 100000) * this.ciservice.premiumPer100kChild;
    } else if (this.ciservice.whoToCover === 3) {
      this.premiumChild = (this.amountAnswered / 100000) * this.ciservice.premiumPer100kChild;
      this.premium = (this.amountAnswered / 100000) * this.ciservice.premiumPer100k;
    }
    this.premiumSummary = Math.round(this.premium + this.premiumChild * this.ciservice.numOfChildren);
  }

  changeSlider(e) {
    this.amountAnswered = e.target.value;
    this.calculatePremium();
    this.styleDotByStep(e.target.value);
    // this.answered.emit({value: e.target.value, category: 'RiskLevel'});
  }

  styleDotByStep(step){
    for (let i = 0; i < 4; i++) {
      step > i ? this.setBackgroundForElement('transparent', i) : this.setBackgroundForElement('#ABC2F8', i);
    }
    // this.setStepIndicatorIntoRoundedSlider(step);
  }

  setBackgroundForElement(color, place){
    document.getElementsByClassName('mbsc-progress-step-label')[place]['style'].backgroundColor = color;
  }

  ngOnChanges() {
    setTimeout(() => {
      document.getElementsByClassName('mbsc-progress-step-label')[4]['style'].backgroundColor = '#ABC2F8';
      this.InitStepIndicatorIntoRoundedSlider();
    }, 500);
  }

  // setStepIndicatorIntoRoundedSlider(step){
  //   var stepToString = step.toString();
  //   document.getElementsByClassName('mbsc-slider-handle')[0].children[0].innerHTML = stepToString;                       
  // }

  InitStepIndicatorIntoRoundedSlider(){
    var node = document.createElement("SPAN");                 
    var textnode = document.createTextNode("4");         
    node.appendChild(textnode);    
    if (document.getElementsByClassName('mbsc-slider-handle')[0].firstChild == null)
      document.getElementsByClassName('mbsc-slider-handle')[0].appendChild(node);
  }

  

  next() {
    this.ciservice.amount = Number(this.amountAnswered);
    this.ciservice.amountChildren = Number(this.amountAnswered);
    this.ciservice.premium = this.premium;
    this.ciservice.premiumChildren = this.premiumChild;
    this.router.navigate([`/criticalillness`, 'process']);
  }

}
