
import { AuthenticationModule } from './authentication/authentication.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BootstrapService } from './core/services/bootstrap.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { RegisterEffects } from './store/effects/register.effects';
import { PensionSummaryComponent } from './account/pension/components/pension-profile/pension-summary/pension-summary.component';
import { reducers } from './store/app.states';
import { BottomNavbarComponent } from './account/components/personal-account/bottom-navbar/bottom-navbar.component';
import { TabsComponent } from './account/components/personal-account/tabs/tabs.component';
import { PersonalAccountComponent } from './account/components/personal-account/personal-account.component';
import { RouterModule } from '@angular/router';
import { ImageCaptureService } from './onboarding/services/image-capture.service';
import { AnalyticsModule } from './analytics/analytics.module';
import { OnBoardingRoutingModule } from './onboarding/onboarding-routing.module';
import { OnBoardingModule } from './onboarding/onboarding.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule, ChangeDetectorRef } from '@angular/core';
import { LoggerModule } from 'ngx-logger';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { httpInterceptorProviders, HttpInterceptorService } from './core/services/http-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerService } from './core/services/logger.service';
import { AppRoutingModule } from './core/routing/router.module';
import { CordovaService } from './core/services/cordova.service';
import { HomepageModule } from './homepage/homepage.module';
import { SharedModule } from './shared/shared.module';


import { AssistantComponent } from './account/components/assistant/assistant.component';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './redux/register/register.reducers';
import { LoginEffects } from './store/effects/login.effects';
import { PensionProfileComponent } from './account/pension/components/pension-profile/pension-profile.component';
import { AutofocusDirective } from './shared/directives/autofocus.directive';
import { NavManagerService } from './shared/components/robin-navbar/robin-navbar.service';
import {PreventDoubleSubmit} from './core/directives/disableafterclick.directive';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { TestComponent } from './test/test.component';
import { LastInputDirective } from './shared/directives/last-input.directive';
import { InviteFriendsComponent } from './account/components/invite-friends/invite-friends.component';
import { ChooseFriendsComponent } from './account/components/choose-friends/choose-friends.component';
import { UpdateDataMislakaComponent } from './account/components/update-data-mislaka/update-data-mislaka.component';

import { ContactService } from './core/services/contact.service';
import { ContactsContainerComponent } from './account/components/choose-friends/contacts-container/contacts-container.component'
import { SingleContactComponent } from './account/components/choose-friends/contacts-container/single-contact/single-contact.component'
// import { branch } from 'branch-cordova-sdk'
import {LoginComponent} from './core/components/login/login.component';
import { LastStateService } from './core/services/last-state.service';
import { LoaderComponent } from './account/loader/loader.component';
import { EntertainmentModule } from './entertainment/entertainment.module';
import { RiskModule } from './risk/risk.module';
import { CreditUpdateMislakaComponent } from './account/components/credit-update-mislaka/credit-update-mislaka.component';
import { UpgradeProcessComponent } from './risk/components/upgrade-process/upgrade-process.component';
import { QuestionnaireModule } from './risk/components/questionnaire/questionnaire.module';
import { CriticalillnessModule } from './criticalillness/criticalillness.module';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    // override hammerjs default configuration
    'swipe': { direction: Hammer.DIRECTION_ALL }
  }
}

@NgModule({
  declarations: [
  AppComponent,
  PersonalAccountComponent,
  TabsComponent,
  BottomNavbarComponent,
  AssistantComponent,
  TestComponent,
  LastInputDirective,
  InviteFriendsComponent,
  UpdateDataMislakaComponent,
  ChooseFriendsComponent,
  ContactsContainerComponent,
  SingleContactComponent,
  LoaderComponent,
  LoginComponent,
  CreditUpdateMislakaComponent,
  UpgradeProcessComponent,
  ],
  imports: [
  FormsModule,
  RouterModule,
  SharedModule,
  QuestionnaireModule,
  AppRoutingModule,
  HttpClientModule,
  ReactiveFormsModule,
  BrowserModule, BrowserAnimationsModule,
  LoggerModule.forRoot(environment.loggerConfig),
  OnBoardingModule,
  HomepageModule,
  AnalyticsModule,
  ReactiveFormsModule,
  StoreModule.forRoot(reducers),
  EffectsModule.forRoot([LoginEffects, RegisterEffects]),
  StoreDevtoolsModule.instrument({
    maxAge: 5
  }),
  AuthenticationModule,
  EntertainmentModule,
  RiskModule,
  CriticalillnessModule
  ],
  providers: [
  ImageCaptureService,
  httpInterceptorProviders,
  LoggerService,
  NavManagerService,
  CordovaService,
  BootstrapService,
  ContactService,
  LastStateService,
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }

// export const asd = [{ provide:PLATFORM_DIRECTIVES,useValue: PreventDoubleSubmit, multi: true} ]


