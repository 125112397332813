import { Component, OnInit } from '@angular/core';
import { CriticalillnessService } from 'src/app/criticalillness/services/criticalillness.service';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  amount;
  amountChildren;
  premium ;
  premiumChildren;

  isDrawer = false;
  isDrawerChildren = false;
  tempAmount;
  tempAmountChildren;
  numOfChildren;
  adult = 0;
  showDrawerPil = false;
  showPil = false;

  constructor(public ciservice: CriticalillnessService,
    private router: Router,
    private analyticService: AnalyticsService) {
    this.premium = this.ciservice.premium;
    this.premiumChildren = this.ciservice.premiumChildren;

    this.numOfChildren = this.ciservice.numOfChildren;
    if (this.ciservice.whoToCover !== 2) {
      this.adult = 1;
    }
    this.amount = this.ciservice.amount;
    this.amountChildren = this.ciservice.amountChildren;
  }

  ngOnInit() {
    this.analyticService.trackByAppFlyer('DP - Mahalot Kashot - Policy Summary', { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
  }

  editAmount() {
    this.tempAmount = this.amount;
    this.isDrawer = true;
  }

  editAmountChildren() {
    this.tempAmountChildren = this.amountChildren;
    this.isDrawerChildren = true;
  }

  openDrawer() {

  }

  closeDrawer(event) {
    this.isDrawer = false;
    this.isDrawerChildren = false;
  }

  addChildren() {
    if (this.numOfChildren > 3) {
      return;
    } else {
      this.numOfChildren += 1;
    }
  }


  minusChildren() {
    if (this.numOfChildren > 1) {
      this.numOfChildren -= 1;
    }
    else if (this.numOfChildren == 1) {
      if (this.adult === 1) {
        this.numOfChildren -= 1;
      }
      else {
        this.showPil = true;
        setTimeout(() => {
          this.showPil = false;
        }, 3000);
        return;
      }

    } else {
      this.showPil = true;
      setTimeout(() => {
        this.showPil = false;
      }, 3000);
      return;
    }
  }

  addAdult() {
    if (this.adult > 0) {
      return;
    } else {
      this.adult += 1;
    }
  }


  minusAdult() {
    if (this.adult === 1) {
      if (this.numOfChildren > 0)
        this.adult = 0;
      else {
        this.showPil = true;
        setTimeout(() => {
          this.showPil = false;
        }, 3000);
        return;
      }
    } else {
      this.showPil = true;
      setTimeout(() => {
        this.showPil = false;
      }, 3000);
      return;
    }
  }


  add() {
    if (this.tempAmount < 400000) {
      this.tempAmount += 50000;
      this.premium = (this.tempAmount / 100000) * this.ciservice.premiumPer100k;
    }
  }

  minus() {
    if (this.tempAmount > 100000) {
      this.tempAmount -= 50000;
      this.premium = (this.tempAmount / 100000) * this.ciservice.premiumPer100k;
    }
    else {
      this.showDrawerPil = true;
      setTimeout(() => {
        this.showDrawerPil = false;
      }, 3000);
    }
  }

  addSumChildren() {
    if (this.tempAmountChildren < 400000) {
      this.tempAmountChildren += 50000;
      this.premiumChildren = (this.tempAmountChildren / 100000) * this.ciservice.premiumPer100kChild;
    }
  }

  minusSumChildren() {
    if (this.tempAmountChildren > 100000) {
      this.tempAmountChildren -= 50000;
      this.premiumChildren = (this.tempAmountChildren / 100000) * this.ciservice.premiumPer100kChild;
    }
    else {
      this.showDrawerPil = true;
      setTimeout(() => {
        this.showDrawerPil = false;
      }, 3000);
    }
  }

  save() {
    this.amount = this.tempAmount;
    this.isDrawer = false;
  }


  saveChildren() {
    this.amountChildren = this.tempAmountChildren;
    this.isDrawerChildren = false;
  }

  next() {
    this.ciservice.amount = this.amount;
    this.ciservice.amountChildren = this.amountChildren;
    this.ciservice.numOfChildren = this.numOfChildren;
    this.ciservice.premium = this.premium;
    this.ciservice.premiumChildren = this.premiumChildren
    this.ciservice.premiumSummary = this.premium * this.adult + this.premiumChildren * this.numOfChildren
    if (this.adult === 0) {
      if (this.numOfChildren > 0) {
        this.ciservice.whoToCover = 2; //Only Children
        this.ciservice.amount = 0;
      } else {

      }
    } else {
      if (this.numOfChildren > 0) { //Adult & Children
        this.ciservice.whoToCover = 3;
      } else { //Only adult
        this.ciservice.whoToCover = 1;
      }
    }
    this.ciservice.initDigitalEvent(1024).subscribe((data: any) => {
      console.log('initDigitalEvent::', data)
      if (data.Code === 0) {
        this.ciservice.digitalEventId = data.Data.DigitalEventId;
        this.router.navigate([`/criticalillness`, 'criticalupgrade']);
      }
    });
  }

}
