import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'robin-stepper-bar',
  templateUrl: './robin-stepper-bar.component.html',
  styleUrls: ['./robin-stepper-bar.component.scss']
})
export class RobinStepperBarComponent implements OnInit {

  constructor() { }

  stepperData = [
    {text: '1234567890123456'},
    {text: '1234567890123456'},
    {text: '1234567890123456'},
    {text: '1234567890123456'},
    {text: '1234567890123456'}
  ];

  widthWrapper = window.innerWidth;

  ngOnInit() {
  }

  swipeStepper(direction, e) {
    console.log('eee', e, direction)
  }

}
