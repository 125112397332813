import { Response } from './../../../models/global.models';
import { OtpService } from './../../services/otp/otp.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UtilityService, expirationDateValidator } from '../../../core/services/utility.service';
import { NavManagerService } from '../../../shared/components/robin-navbar/robin-navbar.service';
import { ErrorModalService } from '../../../shared/components/modals/error-modal/modal.service';
import { idValidator } from '../../../shared/services/validators';
import { OcrService } from '../../services/ocr/ocr.service';
import { Store } from '../../../../../node_modules/@ngrx/store';
import { AppState, selectRegisterState } from '../../../store/app.states';
import { MbscDatetimeOptions } from '../../../../lib/mobiscroll-package/dist/js/mobiscroll';
import { CordovaService } from '../../../core/services/cordova.service';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { OnboardingService } from '../../services/onboarding.service';

declare const window;

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {
  creditcardForm: FormGroup;
  phoneNumber;
  submitted = false;
  creditcardErrorMessageShow = false;
  creditErrorMessage = '';
  aYearFromNow = new Date();
  expOutput = '';
  @ViewChild('exp') exp: ElementRef;
  mobiScrollClick;
  

  options: MbscDatetimeOptions = {
    theme: 'robin-v2',
    lang: 'he',
    max: new Date(new Date(this.aYearFromNow.setFullYear(this.aYearFromNow.getFullYear() + 7)).getFullYear(), new Date().getMonth(), 1),
    min: Date.now(),
    dateWheels: 'yyyy mm',
    dateFormat: 'mm/y'
    
  };

  public consultChecked = true;
  constructor(
    private errorModalService: ErrorModalService,
    private navbarService: NavManagerService,
    private utilService: UtilityService,
    private fb: FormBuilder,
    private creditcardService: OtpService,
    private ocrService: OcrService,
    private store: Store<AppState>,
    private cordovaService: CordovaService,
    private analyticService: AnalyticsService,
    private onboardingService: OnboardingService
  ) { }



  ngOnInit() {
    //mixpanel notification
    this.analyticService.trackByMixpanel('User Registration -  FDC - CreditCard - Start');
    this.analyticService.trackByAppFlyer('User Registration -  FDC - CreditCard - Start', {phoneNumber:this.analyticService.phoneNumber, uuid:this.analyticService.uuid});
    this.store.select(selectRegisterState).subscribe((data: any) => {
      this.phoneNumber = data.user.phoneNumber;
    })
    this.createForm();
    // console.log('phone',  this.aYearFromNow.setFullYear(this.aYearFromNow.getFullYear() + 5))


  }
  continiue() {
    if (this.creditcardForm.valid && !this.submitted) {
      let data = this.prepareFormData();
      this.submitted = true;
      this.creditcardService.verifyCreditcard(data).subscribe((data: Response) => {
        if (data.Code === 0 && data.Data === true) {
          this.submitted = false;
          this.ocrService.mislakaRegistration({
            IdNumber: this.creditcardForm.value.id,
            PhoneNumber: this.phoneNumber

          }).subscribe((data: any) => {
            // (data.Code === 0) ? this.redirectTo() : this.openErrorMessage('שגיאה', 'אירעה שגיאה בשרת', 'אישור');
            if (data.Code === 0) {
              const consult = {
                IsChecked: this.consultChecked
              };
              this.onboardingService.consultExpertCheckbox(consult).subscribe((data: Response) => {
                if (data.Code === 0) {
                  this.navbarService.next();
                }
                this.analyticService.trackByMixpanel("User Registration -  FDC - CreditCard - End");
                this.analyticService.trackByAppFlyer("User Registration -  FDC - CreditCard - End", {phoneNumber:this.analyticService.phoneNumber, uuid:this.analyticService.uuid});
                this.submitted = false;
              });
              // this.navbarService.next();
            }
            else {
              this.submitted = false;
              this.errorModalService.setNewError({
                title: 'אופס...',
                description: 'נראה שמשהו השתבש בדרך',
                type: 'error',
                buttonText: 'נסה שנית',
              }).open();

            }
          },
            // err => this.openErrorMessage('שגיאה', err, 'אישור'))
            err => {
              this.submitted = false;
              this.errorModalService.setNewError({
                title: 'אופס...',
                description: 'נראה שמשהו השתבש בדרך',
                type: 'error',
                buttonText: 'נסה שנית',
              }).open()
            });
        }

        // Not Verify from credit cart
        else {
          this.submitted = false;
          this.setErrorMessage('נראה שפרטי האשראי שהוזנו אינם תואמים למס׳ תעודת הזהות', true);

          // this.errorModalService.setNewError({
          //   title: 'אופס...',
          //   description: 'נראה שפרטי האשראי שהוזנו אינם תואמים למס׳ תעודת הזהות',
          //   type: 'error',
          //   buttonText: 'אישור',
          // }).open();
        }
      },
        err => {
          this.submitted = false;
          this.setErrorMessage('נראה שפרטי האשראי שהוזנו אינם תואמים למס׳ תעודת הזהות', true);
          // this.errorModalService.setNewError({
          //   title: 'אופס...',
          //   description: 'נראה שפרטי האשראי שהוזנו אינם תואמים למס׳ תעודת הזהות',
          //   type: 'error',
          //   buttonText: 'אישור',
          // }).open();
        });
    } else {
      this.creditcardErrorMessageShow = true;
      this.creditErrorMessage = 'יש למלא את כל השדות';
    }
  }



  createForm() {
    this.creditcardForm = this.fb.group({
      id: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9), idValidator]],
      creditcard: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
      expiration: [this.expOutput, [Validators.required]],
      cvv: ['', [Validators.required, Validators.pattern(/^[0-9]/), Validators.minLength(3), Validators.maxLength(4)]],
    });
  }


  check(e, len) {
    this.utilService.digitOnly(e);
    this.utilService.inputLength(e, len);
  }

  hideKeyboard(){
    if(window.Keyboard && this.cordovaService.platform === 'Android')window.Keyboard.hide(true);
  }

  showExp(event){
    // show
    this.mobiScrollClick = true;
    window.scrollTo(0, 0);
    document.body.classList.add('disableOverflow');
  }
  closeExp(event){
    // close
    document.body.classList.remove('disableOverflow');
    this.mobiScrollClick = false;
    document.querySelectorAll('robin-input input')[2].classList.add('set-exp-date');
  }

  setExp(event, i) {
    console.log('fff', this.expOutput, event.valueText);

    this.expOutput = event.valueText;
  }

  setErrorMessage(text, value) {
    this.creditcardErrorMessageShow = value;
    setTimeout(() =>{
      this.creditcardErrorMessageShow = false;
    }, 1000 * 3);
    this.creditErrorMessage = text;
  }

  prepareFormData() {
    let Month;
    let tempMonth = new Date(this.creditcardForm.controls['expiration'].value).getMonth() + 1;
    let tempYear = new Date(this.creditcardForm.controls['expiration'].value).getFullYear();
    if (tempMonth < 10)
      Month = '0' + tempMonth.toString();
    else
      Month = tempMonth.toString();

    console.log('tempmonth', tempMonth);
    console.log('tempYear', tempYear)

    let temp = { ...this.creditcardForm.controls };
    const IdentityNumber = temp.id.value;
    const Cvv = temp.cvv.value;
    const CardNumber = temp.creditcard.value;

    // let parsedData = temp.expiration.value.split('/');
    const Year = tempYear.toString().substring(2, 4);
    const PhoneNumber = this.phoneNumber;
    return { IdentityNumber, Cvv, CardNumber, Month, Year, PhoneNumber };
  }

  changeVal(event){
    this.consultChecked = event;
  }
}
