import { RegisterActionTypes, Register, RegisterSuccess } from '../user.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AppState } from '../app.states';


@Injectable()
export class RegisterEffects {

    constructor(
        private actions: Actions,
        private router: Router,
        private store: Store<AppState>
    ) { }

    // @Effect()
    // Register: Observable<any> = this.actions
    //     .ofType(RegisterActionTypes.REGISTER).pipe(
    //         map((action: Register) => action.payload),
    //         switchMap(payload => {
    //             return this.registerService.register(payload).pipe(
    //                 // map((user) => new RegisterSuccess(payload)),
    //                 map((user) => ({type: 'REGISTER_SUCCESS', payload: payload})),
    //                 catchError((error) => {
    //                     console.log(error);
    //                     return of(new RegisterFailure({ error: error }));
    //                 }));
    //         }));

    @Effect()
    Register: Observable<any> = this.actions
        .ofType(RegisterActionTypes.REGISTER).pipe(
            map((data: Register) => {
                if (!localStorage.getItem('email') && data.payload.user.email) {
                    localStorage.setItem('email', data.payload.user.email)
                }
                if (!localStorage.getItem('phoneNumber') && data.payload.user.phoneNumber) {
                    localStorage.setItem('phoneNumber', data.payload.user.phoneNumber)
                }
                // data.payload.user.phoneNumber ? localStorage.setItem('phoneNumber', data.payload.user.phoneNumber) : localStorage.removeItem('phoneNumber');
                // data.payload.user.email ? localStorage.setItem('email', data.payload.user.email) : localStorage.removeItem('email');
                if (data.payload.user && data.payload.user.phoneNumber) { localStorage.setItem('phoneNumber', data.payload.user.phoneNumber); }
                if (data.payload.user && data.payload.user.email) { localStorage.setItem('email', data.payload.user.email); }
                else
                    data.payload.user.email = localStorage.getItem('email');
                return new RegisterSuccess({ user: data.payload.user });
            })
        );
}