import { environment } from 'src/environments/environment.dev';
import { Component, OnInit, NgZone } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { AppState } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';
import { OnboardingService } from 'src/app/onboarding/services/onboarding.service';
import { OtpService } from 'src/app/onboarding/services/otp/otp.service';
import { uiStrings } from '../../assets/heb.ui_strings';
import { Login, LoginFailed } from 'src/app/store/user.actions';
import { ErrorModalService } from 'src/app/shared/components/modals/error-modal/modal.service';

declare const window;

@Component({
  selector: 'app-join-face-id',
  templateUrl: './join-face-id.component.html',
  styleUrls: ['./join-face-id.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ]),
    trigger('Fading', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', animate('800ms ease-out')),
      transition(':leave', animate('800ms ease-in')),
    ])
  ]
})
export class JoinFaceIdComponent implements OnInit {
  readMore = false;
  login = false;
  password = null;
  public uiStrings;
  error;


  constructor(private errorModalService: ErrorModalService, private zone: NgZone, private router: Router, private cordovaService: CordovaService, private store: Store<AppState>, private onboardingService: OnboardingService, private otpService: OtpService) {
    this.uiStrings = uiStrings.joinfingerid;
  }

  ngOnInit() {
  }
  continiue() {
    // this.navmanagerService.prev();
    // this._location.back();
    this.login = true;
  }
  modalClosed() {
    this.login = false;
  }
  passwordCaptured(e) {
    this.password = e;
  }


  nextPhase() {
    if (this.password) {
      this.otpService.createToken(this.password).subscribe(
        (token) => {
          if (token) {
            this.store.dispatch(new Login({ token }));
            // if (!environment.production) {
            //   this.zone.run(() => {
            //     localStorage.setItem('loginMethod', 'face');
            //     this.router.navigate(['authentication/authsuccessfullycompleted', { finger: false }]);
            //   });
            // }
            // else {
              this.otpService.registerFingerPrint({ uuid: window.device && window.device.uuid || -1 }).subscribe((data: any) => {
                this.store.dispatch(new LoginFailed({}));
                if (data.Code === 0) {
                  this.cordovaService.loginWithFingerid((err, res) => {
                    //TODO: handle the error returned
                    if (err) {
                      if(err === 'errorModal'){
                        this.zone.run(() => {
                          this.login = false;
                          this.errorModalService.setNewError({
                            title: 'שיטת הזדהות זו נחסמה',
                            description: 'באפשרותך להזדהות באמצעות סיסמה אישית וכך לשחרר את החסימה',
                            type: 'error',
                            buttonText: 'אישור',
                          }).open();
                        });
                      }
                    }
                    else {
                      this.store.dispatch(new Login({ token }));
                      this.zone.run(() => {
                        localStorage.setItem('loginMethod', 'face');
                        this.router.navigate(['authentication/authsuccessfullycompleted', { finger: false }]);
                      });
                    }
                  });
                }
              });
            // }
          }
          else {
            this.error = 'סיסמה שגוייה';
            setTimeout(() => this.error = '', 3000);
          }
        },
        err => { });
    }
    else {
      this.error = 'סיסמה שגוייה';
      setTimeout(() => this.error = '', 3000);
    }
  }

}
