import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'robin-binary-buttons',
  templateUrl: './robin-binary-buttons.component.html',
  styleUrls: ['./robin-binary-buttons.component.scss']
})
export class RobinBinaryButtonsComponent implements OnInit {

  @Input() obj;
  @Output() binaryPressed = new EventEmitter();
  

  constructor() { 
    this.obj = [
      {value: true, text: 'כן', image: 'fff', active: true, index: 0},
      {value: false, text: 'לא', image: 'fff' , active: false , index: 1}
    ]
  }

  ngOnInit() {
  }

  setActiveClass(o){
    // this.obj.map(el=> {
    //   el.active = false;
    // });
    return o.active ? 'btn active' : 'btn';
  }

  setActive(o){
    //this.obj
  }

  pressButton(o){
    this.binaryPressed.emit(o);
    this.propegateChange(o);
  }

  
  writeValue(value: any) {
    // this.input.setText(value);
    //this. = value;
  }

  propegateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propegateChange = fn;
  }
  registerOnTouched() { }

}
