import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { AppState, selectInsurance } from 'src/app/store/app.states';
import { SummaryStatus } from 'src/app/account/insurance/components/insurance-my-life/insurance-my-life.component';

import { BootstrapService } from '../../../core/services/bootstrap.service';
import { CacheService } from '../../services/cache.service';
import { routes } from '../../risk.module'

declare const window;

@Component({
  selector: 'upgrade-process',
  templateUrl: './upgrade-process.component.html',
  styleUrls: ['./upgrade-process.component.scss']
})
export class UpgradeProcessComponent implements OnInit, OnDestroy {
  sections = []
  currentCategory = -1
  purchaseOrUpgrade = -1
  disableBack = false
  androidBack$;
  constructor(private router: Router, private cacheService: CacheService, private store: Store<AppState>, private bootstrapService: BootstrapService, private cdf: ChangeDetectorRef) {

    // this.androidBack$ = this.bootstrapService.androidBackButton$.subscribe(data => {
    // if(window.location.href.indexOf('risk') > -1){
    //   this.cdf.detectChanges()
    // }
    // });
    this.cacheService.currentTab$.subscribe(data => {
      //need to refactor => this code takes care to current active tab indicator
      const url = window.location.href
      const seperatedUrl = url.split('/')
      const currentRoute = seperatedUrl[seperatedUrl.length - 1]
      const index = routes.findIndex(el => {
        return el.path.toLowerCase() === currentRoute.toLowerCase()
      })
      this.currentCategory = this.sections.findIndex(el => el === routes[index].data.title)
      this.currentCategory = this.currentCategory > -1 ? this.currentCategory + 1 : 0

      if (!!!(this.currentCategory)) this.router.navigate(['/risk/generaldetails'])
      else {
        if (Array.isArray(this.cacheService.cache[3])) {
          if (!this.beneficiariesAvailable() && this.sections.indexOf('חלוקת אחוזים') > -1) {
            this.sections.splice(3, 1)
            this.createRoutingManipulation()
          }
          else if (this.beneficiariesAvailable() && this.sections.indexOf('חלוקת אחוזים') === -1) {
            this.sections.splice(3, 0, 'חלוקת אחוזים')
            this.createRoutingManipulation()
          }
        }
        //if the current route is payment disable the back
        if (routes[index + 1].data.title === 'תשלום') {
          this.disableBack = true
        }

        this.newManipulation(this.currentCategory)
      }
    })

    this.store.select(selectInsurance).pipe(map((data: any) => data.insuranceDetails && data.insuranceDetails.LifeInsurance && data.insuranceDetails.LifeInsurance.SummaryInsuranceRecommendation && data.insuranceDetails.LifeInsurance.SummaryInsuranceRecommendation.SummaryStatus))
      .subscribe(data => {
        this.purchaseOrUpgrade = data
        switch (data) {
          //purchase
          case (SummaryStatus.Under):
            this.sections = ['פרטים כלליים', 'משקל וגובה', 'מינוי מוטבים', 'חלוקת אחוזים', 'הצהרת בריאות', 'חתימה אלקטרונית', 'תשלום']
            break;
          //upgrade
          case SummaryStatus.Upgrade:
            this.sections = ['פרטים כלליים', 'משקל וגובה', 'מינוי מוטבים', 'חלוקת אחוזים', 'הצהרת בריאות', 'חתימה אלקטרונית', 'ביטול פוליסה קודמת', 'תשלום']
            break;
        }
        this.createRoutingManipulation()
      })
  }

  createRoutingManipulation() {
    let obj = {}
    this.sections.forEach((elem, index) => {
      const routeIndex = routes.map(el => el && el.data && el.data.title).findIndex(el => el === elem)
      obj[elem] = routes[routeIndex].path
    })
    this.obj = obj
  }

  obj = {}

  newManipulation(i) {
    this.router.navigate(['/risk/' + this.obj[Object.keys(this.obj)[i]]])
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    // this.androidBack$.unsubscribe();
  }

  transitionPerformed(direction) {
    switch (direction) {
      case 'next':
        if (this.currentCategory < this.sections.length - 1) {
          this.currentCategory++
        }
        break;
      case 'back':
        if (this.currentCategory < this.sections.length && this.currentCategory > 0) {
          this.currentCategory--
        }
        break;
    }
    if (this.cacheService.cache[this.currentCategory]) {
      this.newManipulation(this.currentCategory)
    }
  }

  routingManipulation(i) {
    if (!!(this.cacheService.cache[this.currentCategory] || !!!(i))) {
      let url = '/risk'
      switch (i) {
        case 0:
          url = `${url}/generaldetails`
          break;
        case 1:
          url = `${url}/heightandweight`
          break;
        case 2:
          url = `${url}/addbeneficiaries`
          break;
        case 3:
          if (!this.beneficiariesAvailable() && this.sections.indexOf('חלוקת אחוזים') > -1) {
            //remove the tab
            this.sections.splice(3, 1)
            // url = `${url}/healthquestionnnaire`
            this.cacheService.currentTab.next(4);

          }
          else if (this.beneficiariesAvailable() && this.sections.indexOf('חלוקת אחוזים') === -1) {
            this.sections.splice(3, 0, 'חלוקת אחוזים')
            url = `${url}/distributionofpercentages`
          }
          else if (this.sections.indexOf('חלוקת אחוזים') === -1) {
            url = `${url}/healthquestionnnaire`
            this.cacheService.currentTab.next(4)
          }
          else {
            url = `${url}/distributionofpercentages`
          }
          break;
        case 4:
          url = `${url}/healthquestionnnaire`
          break;
        case 5:
          url = `${url}/electronicsignature`
          break;
        case 6:
          if (this.purchaseOrUpgrade === SummaryStatus.Upgrade)
            url = `${url}/cancelpreviouspolicy`
          else if (this.purchaseOrUpgrade === SummaryStatus.Under) {
            url = `${url}/payment`
            this.cacheService.currentTab.next(7);
          }
          break;
        case 7:
          this.disableBack = true
          url = `${url}/payment`
          break;
      }
      this.router.navigate([url])
    }
  }

  beneficiariesAvailable() {
    return this.cacheService.cache[3] && this.cacheService.cache[3].length > 1
  }
}
