import { style } from '@angular/animations';
import { OcrService } from './../../services/ocr/ocr.service';
import { ErrorModalService } from './../../../shared/components/modals/error-modal/modal.service';
import { NavManagerService } from '../../../shared/components/robin-navbar/robin-navbar.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { uiStrings } from '../../assets/heb.ui_strings';
import { idValidator, dataValidator } from '../../../shared/services/validators';
import { UtilityService } from '../../../core/services/utility.service';
import { Store } from '../../../../../node_modules/@ngrx/store';
import { AppState, selectRegisterState } from '../../../store/app.states';
import { OnboardingService } from '../../services/onboarding.service';
import { HomepageService } from '../../../homepage/homepage.service';
import { AnalyticsService } from '../../../analytics/analytics.service';

import { MbscDatetimeOptions } from '../../../../lib/mobiscroll/js/mobiscroll.angular.min';
import { CordovaService } from 'src/app/core/services/cordova.service';

@Component({
  selector: 'app-check-info',
  templateUrl: './check-info.component.html',
  styleUrls: ['./check-info.component.scss']
})
export class CheckInfoComponent implements OnInit {
  infoForm: FormGroup;
  public uiStrings;
  color = 'blue';
  phoneNumber;
  idnumber;
  myDateOptions;
  buttonText;
  options: MbscDatetimeOptions = {
    theme: 'robin-v2',
    max: (function(d){ d.setDate(d.getDate()-1); return d})(new Date),
    min: new Date(1920, 1, 1),
    dateWheels: 'yyyy MM mm dd',
    lang: 'he'
  };
  errorOnSubmit: boolean = false;
  messageError: string = '';


  constructor(
    private ocrService: OcrService,
    private errorModalService: ErrorModalService,
    private fb: FormBuilder,
    private router: Router, private navbarService: NavManagerService,
    private utilityService: UtilityService,
    private store: Store<AppState>,
    private onboardingservice: OnboardingService,
    private homepageservice: HomepageService,
    private analyticService: AnalyticsService,
    private cordovaService: CordovaService
  ) {
    this.createForm();
    this.uiStrings = uiStrings.checkinfo;
    this.buttonText = uiStrings.checkinfo.buttons.footerbutton;
  }

  idLength(e) {
    this.utilityService.inputLength(e, 9);
  }

  keydown(e) {
    if (this.errorOnSubmit)
      this.errorOnSubmit = false;
    this.utilityService.detectKey(e);

  }

  date(e) {
    // let date = this.changeDayAndMonthDate(e.valueText);
    let date = e.valueText;
    this.infoForm.controls['idDate'].setValue(date);
    let parseddate = this.changeDayAndMonthDate(this.infoForm.controls['idDate'].value);
    // this.color = this.isGreenColor(this.isMoreThanThreeMonths(new Date(parseddate)));
    this.color = this.isGreenColor(false);
    // this.buttonText = this.isMoreThanThreeMonths(new Date(parseddate)) ? uiStrings.laststep.button : uiStrings.checkinfo.buttons.footerbutton;
    this.buttonText = uiStrings.checkinfo.buttons.footerbutton;

  }

  focus() {
    document.querySelectorAll('input')[1].click();
  }

  closed(e) {
    document.querySelectorAll('robin-input input')[1].classList.add('set-exp-date');
  }

  createForm() {
    this.infoForm = this.fb.group({
      idNumber: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9), idValidator]],
      idDate: [''],
    });
  }

  ngOnInit() {
    this.analyticService.trackByMixpanel("User Registration - FDC - OCR");
    this.analyticService.trackByAppFlyer("User Registration - FDC - OCR", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.idnumber = this.onboardingservice.getIdentityNumber();
    this.store.select(selectRegisterState).subscribe((data: any) => {
      console.log('dadadada', data)
      this.phoneNumber = data.user.phoneNumber;
    })

    //PrimaryIdentifier
  }

  changeDayAndMonthDate(date) {
    console.log('date',date);
    let b = date.split('/');
    console.log('b',b);

    return b[1] + '/' + b[0] + '/' + b[2] + ' 03:01';
  }

  saveForm() {
    if (this.infoForm.value.idNumber === "000000000") {
      this.messageError = 'תעודת זהות לא תקינה';
      this.errorOnSubmit = true;
      return;
    }
    if (this.infoForm.valid) {
      var now = new Date();
      if (!this.infoForm.controls['idDate'].value) {
        var last = new Date(1900, 1, 1);
      } else {
        var last = new Date(this.changeDayAndMonthDate(this.infoForm.controls['idDate'].value));
      }
      console.log('last',last);
      if ((now.getTime() - last.getTime()) > 0) {
        this.ocrService.checkInfoObject = {
          issueDate: last,
          idNumber: this.idnumber
        }
        if (this.color === 'green') {
          //only if button is green update appFlyer and mixpanel
          this.analyticService.trackByMixpanel("User Registration -  FDC - PensionOnly - End");
          this.analyticService.trackByAppFlyer("User Registration -  FDC - PensionOnly - End", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
        }
        // this.redirectTo(this.changeNavigation(!this.isMoreThanThreeMonths(last)));
        this.redirectTo('laststep');

      } else this.openErrorMessage('שגיאה', 'אין אפשרות לבחור תאריך מאוחר יותר מהיום', 'אישור');

    } else {
      this.messageError = 'יש למלא את כל השדות כדי להמשיך';
      this.errorOnSubmit = true;
    }
  }

  isGreenColor(bool) {
    return bool ? 'green' : 'blue';
  }

  monthDiff(DisplayTo: Date, DisplayFrom: Date) {
    return DisplayTo.getMonth() - DisplayFrom.getMonth() + (12 * (DisplayTo.getFullYear() - DisplayFrom.getFullYear()));
  }

  isMoreThanThreeMonths(date: Date) {
    var today;
    today = new Date(Date.now());
    console.log('this.monthDiff ',this.monthDiff(new Date(Date.now()), date))
    // var d = this.monthDiff(new Date(Date.now()), date) < 3;
    if (this.monthDiff(new Date(Date.now()), date) < 3) {
      return true
    } else if (this.monthDiff(new Date(Date.now()), date) === 3) {
      console.log('today',today.getDate());
      if (today.getDate() > date.getDate())
        return false;
      else 
        return true;
    } else if (this.monthDiff(new Date(Date.now()), date) > 3) {
      return false;
    }
    // console.log('d', d)
    // return d;
  }

  changeNavigation(bool: Boolean) {
    return bool ? 'laststep' : 'collectsuccess';
  }

  redirectTo(url: String) {

    if (this.infoForm.valid) {
      this.ocrService.checkInfoObject.idNumber = this.infoForm.controls["idNumber"].value;

      if (url == 'collectsuccess') {
        if (this.navbarService.history[this.navbarService.history.length - 2].url === "/account/insurance") {
          this.ocrService.insuranceRegistration({
            IdNumber: this.ocrService.checkInfoObject.idNumber,
            PhoneNumber: this.phoneNumber,
            IdIssueDate: this.ocrService.checkInfoObject.issueDate.toJSON().toString(),
            DidWentAbroad: null,
            DidIssuedPassport: null
          }).subscribe((data: any) => {
            if (data.Code === 0) {
              this.navbarService.next(url);
            } else {
              this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך...', 'נסה שנית');
            }

          }, err => this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך...', 'נסה שנית'))
        }

        else {
          this.ocrService.dataAggregationRegistration({
            IdNumber: this.ocrService.checkInfoObject.idNumber,
            PhoneNumber: this.phoneNumber,
            IdIssueDate: this.ocrService.checkInfoObject.issueDate.toJSON().toString(),
            DidWentAbroad: null,
            DidIssuedPassport: null
          }).subscribe((data: any) => {
            if (data.Code === 0) {
              this.homepageservice.firstTime = true;
              this.navbarService.next(url);
            } else this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך...', 'נסה שנית');

          }, err => this.openErrorMessage('אופס...', 'נראה שמשהו השתבש בדרך...', 'נסה שנית'))
        }

      } else this.navbarService.next(url)

    } else {
      this.messageError = 'יש למלא את כל השדות כדי להמשיך';
      this.errorOnSubmit = true;
    }
  }

  openErrorMessage(title, desc, textButton) {
    this.errorModalService
      .setNewError({
        title: title,
        description: desc,
        type: 'error',
        buttonText: textButton,
      }).open();
  }

  closeKeyboard(e) {
    console.log('closeKeyboard ', e);
    if (!e) {
      this.cordovaService.closeKeyBoard()
    }
  }

}

