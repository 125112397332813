import { DeeplinkService } from './../../services/deeplink.service';
import { ChangeDetectorRef } from '@angular/core';
import { NgZone, OnDestroy } from '@angular/core';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { AppState } from 'src/app/store/app.states';
import { OtpService } from './../../../onboarding/services/otp/otp.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Login } from 'src/app/store/user.actions';
import { ErrorModalService } from 'src/app/shared/components/modals/error-modal/modal.service';
import { Router } from '@angular/router';
import { fadeInUp } from 'ng-animate'
import { trigger, transition, useAnimation } from '@angular/animations';
import { HomepageService } from 'src/app/homepage/homepage.service';
import { DeeplinkRemovelink } from 'src/app/redux/deeplink/deeplink.reducer';
import { InsuranceService } from 'src/app/account/insurance/services/insurance.service';
declare const window;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(fadeInUp, { params: { timing: 0.5, delay: 0 } }))])
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  pincode;
  fingerPrintSuccess = false;
  errorMessage = '';
  openFeature = false;
  deviceFeatureLoginText = '';
  featureSupported = '';
  icon = '';
  keyboardOpen = false;
/*
  *   Deep link handling
  */
 deeplinkUrl = null;

  constructor(private deeplinkService: DeeplinkService,
              private router: Router,
              private cdr: ChangeDetectorRef, 
              private errorModalService: ErrorModalService, 
              private zone: NgZone, private otpService: OtpService, 
              private store: Store<AppState>, 
              public cordovaService: CordovaService, 
              private homepageService: HomepageService,
              private insuranceservice: InsuranceService) { 
    this.deeplinkUrl = this.deeplinkService.deeplinkUrl;
  }

  ngOnInit() {
    this.getDeviceFeatures();
    if (localStorage.getItem('deviceFeatureDisabled') !== 'true' && localStorage.getItem('loginMethod')) {
      this.loginBy();
    }
  }
  ngOnDestroy(){
  }

  passwordCaptured(e) {
    this.pincode = e;
  }
  loginWithPincode() {
    if (this.pincode) {
      this.otpService.createToken(this.pincode).subscribe(
        data => {
          if (data) {
            this.store.dispatch(new Login({ token: data }));
            this.cordovaService.resetFingerPrintRestrictions();
            this.deeplinkService.apiCalls();

            if (this.deeplinkUrl) {
              this.store.dispatch(new DeeplinkRemovelink({}));
              this.router.navigate([this.deeplinkUrl]);
            }
            // this.router.navigate(['/account/pension']);
            else this.router.navigate([this.getNavigatedStringUrl(`${this.homepageService.action}`)]);
          } else {
            this.showErrorMessage();
          }
        },
        err => {
          this.showErrorMessage();
        }
      );
    }
    else {
      this.showErrorMessage();
    }
  }

  showErrorMessage(str = 'קוד שגוי') {
    this.errorMessage = str;
    setTimeout(() => {
      this.errorMessage = "";
    }, 3000);
  }


  loginBy() {
    if (localStorage.getItem('deviceFeatureDisabled') === 'true') {
      this.zone.run(() => {
        this.openFeature = false;
        this.errorModalService.setNewError({
          title: 'שיטת הזדהות זו נחסמה',
          description: 'באפשרותך להזדהות באמצעות סיסמה אישית וכך לשחרר את החסימה',
          type: 'error',
          buttonText: 'אישור',
        }).open();
      });
    }
    else {
      const _loginByDeviceFeature = () => {
        this.otpService.loginByFingerPrint({ Username: localStorage.getItem('phoneNumber') || -1, UUID: window.device && window.device.uuid || -1 }).subscribe((data: any) => {
          if (data) {
            this.store.dispatch(new Login({ token: data }));
            this.deeplinkService.apiCalls();
            this.zone.run(() => {
              setTimeout(() => {
                if (this.deeplinkUrl) {
                  this.store.dispatch(new DeeplinkRemovelink({}));
                  this.router.navigate([this.deeplinkUrl]);
                }
                else this.router.navigate([this.getNavigatedStringUrl(`${this.homepageService.action}`)]);
              }, 2000);
              this.fingerPrintSuccess = true;
            });
          }

        }, (err) => { });
      }
      //check if this is the first time using that service behave accordingly
      if (!localStorage.getItem('loginMethod')) {
        this.router.navigate([`/authentication/join${this.featureSupported}id`]);
      }
      else {
        this.openFeature = true;
        this.cordovaService.loginWithFingerid((err, res) => {
          if (err) {
            if (err === 'errorModal') {
              this.zone.run(() => {
                this.openFeature = false;
                this.errorModalService.setNewError({
                  title: 'שיטת הזדהות זו נחסמה',
                  description: 'באפשרותך להזדהות באמצעות סיסמה אישית וכך לשחרר את החסימה',
                  type: 'error',
                  buttonText: 'אישור',
                }).open();
              });
            }
          }
          else {
            console.log('success');
            _loginByDeviceFeature();
          }
        });
        // }

      }
    }
  }

  getDeviceFeatures() {
    let features = this.cordovaService.deviceFeatures.identification;
    if (features.face) {
      this.deviceFeatureLoginText = 'כניסה עם Face ID'
      this.featureSupported = "face";
      this.icon = "assets/authorization/face_id.png";
    }
    else if (features.finger) {
      this.deviceFeatureLoginText = 'כניסה עם טביעת אצבע'
      this.icon = "assets/authorization/fingeprint_blue_gray.png";
      this.featureSupported = "touch";
    }
    //testing purposes 
    else {
      console.error('no faceid / fingerpring was found...');
      // if (!environment.production) {
      //   this.deviceFeatureLoginText = 'כניסה עם טביעת אצבע'
      //   this.icon = "assets/authorization/fingeprint_blue_gray.png";
      //   this.featureSupported = "touch";
      // }
    }
  }
  forgotPasswordClicked() {
    this.router.navigate(["/authentication/passwordemail"]);
  }

  loginWithPassword() {
    this.openFeature = false;
  }

  getNavigatedStringUrl(url) {
    switch (url) {
      case 'howitworks':
        return '/onboarding/howitworks';

      case 'pension':
        return '/account/pension';

      case 'homepage':
        return url;

      case 'questionnaire':
        return url;

      default:
        return url;
    }
  }
  closeKeyboard(e) {
    console.log('closeKeyboard ', e);
    if (!e) {
      this.cordovaService.closeKeyBoard()
    }
  }
}
