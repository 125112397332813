import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AccountService } from 'src/app/account/account.service';
import { NavManagerService } from 'src/app/shared/components/robin-navbar/robin-navbar.service';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { ErrorModalService } from '../../../../error-modal/modal.service';

@Component({
  selector: 'app-update-employer-name',
  templateUrl: './update-employer-name.component.html',
  styleUrls: ['./update-employer-name.component.scss']
})
export class UpdateEmployerNameComponent implements OnInit {

  employerName = this.accountService.authenticatedModel.EmployerName;

  generalDetailsForm;
  errorLabel = false;

  constructor(private fb: FormBuilder, 
    private accountService: AccountService,
    private navbarService: NavManagerService,
    private cordovaService: CordovaService,
    private errorModalService: ErrorModalService) { }

  ngOnInit() {
    this.generalDetailsForm = this.fb.group({
      employerName: [this.employerName]
    });
  }

  save() {
    if (this.generalDetailsForm.value.employerName) {
      this.errorLabel = false;
      var sendNewDetails = {
        RiskLevel: this.accountService.authenticatedModel.RiskLevel,
        City: this.accountService.authenticatedModel.City,
        StreetName: this.accountService.authenticatedModel.StreetName,
        HouseNumber: this.accountService.authenticatedModel.HouseNumber,
        PostalCode: this.accountService.authenticatedModel.PostalCode,
        Country: this.accountService.authenticatedModel.Country,
        AccountMaritalStatus: this.accountService.authenticatedModel.AccountMaritalStatus,
        LastSalary: this.accountService.authenticatedModel.Salary,
        JobTitle: this.accountService.authenticatedModel.JobTitle,
        Position: this.accountService.authenticatedModel.Position,
        EmployerName: this.generalDetailsForm.value.employerName,
        // NumberOfChildrens: this.accountService.authenticatedModel.NumberOfChildrens
        ChildrensBirthdates: this.accountService.authenticatedModel.Childrens

      };
      this.accountService.updateProfile(sendNewDetails).subscribe((data: any) => {
        if (data.Code === 0) {
          this.accountService.authenticatedModel.EmployerName = this.generalDetailsForm.value.employerName;
          this.accountService.showSuccessUpdate = true;
          setTimeout(() => {
            this.accountService.showSuccessUpdate = false;
          }, 3000);
          this.navbarService.prev();
        } else {
          this.errorModalService
            .setNewError({
              title: "אופס...",
              description: "נראה שמשהו השתבש בדרך",
              type: "error",
              buttonText: "נסה שנית"
            })
            .open();
        }
      });
    } else {
      this.errorLabel = true;
    }
  }

  closeKeyboard(e) {
    console.log('closeKeyboard ', e);
    if (!e) {
      this.cordovaService.closeKeyBoard();
    }
  }

}
