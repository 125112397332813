
export const navState = [
    {
        name: 'pension', 
        title: 'חומן',
        isStatic: false,
        color: 'light-blue',
        isMenu: true
    },
    {
        name: 'home', 
        title: '222',
        isStatic: false,
        color: 'blue',
        isMenu: true
    },
    {
        name: 'onboarding', 
        title: '111',
        isStatic: false,
        color: 'blue',
        isMenu: true
    }
]
    