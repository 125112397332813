import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'robin-header',
  templateUrl: './robin-header.component.html',
  styleUrls: ['./robin-header.component.scss']
})
export class RobinHeaderComponent implements OnInit {

  @Input() title;
  @Input() subtitle;

  constructor() { }

  ngOnInit() {
  }

}
