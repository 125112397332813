export const uiStrings = {

    authsuccesscompleted: {
        title: "תהליך האיתור הושלם!",
        subtitle: "מעכשיו כל מוצריך הפנסיונים והביטוחים יופיעו בחשבונך האישי.",
        button: "כניסה לחשבון האישי",
    },
    joinfingerid:{ 
        title: "להגן את הנתונים יותר קל",
        content: "מעכשיו תוכל להיכנס לחשבונך באפליקציית רובין באמצעות טביעת אצבע, ללא צורך בהקלדת סיסמה",
        readmore: "לקרוא עוד",
        joinfinferinfo:{
            joinFingerIdp1:"ההזדהות מבוססת על טביעת האצבע המוגדרת במכשירך הנייד. רובין אינה שומרת את טביעת האצבע.",
            joinFingerIdp2:"לידיעתך, ניתן יהיה להיכנס לחשבון ויתכן שאף לבצע פעולות מסוימות באמצעות כל אחת מטביעות האצבע המוגדרות במכשירך. לכן, עליך לוודא כי במכשירך יישמרו טביעות האצבע שלך בלבד.",
            joinFingerIdp3:"בהצטרפות להזדהות בטביעת אצבע את/ה מאשר כי מכשירך מוגן בקוד נעילה, הידוע לך בלבד.",
            joinFingerIdp4:"בכל החלפה, מכירה ו/או העברה של מכשירך הנייד לצד שלישי עליך לבטל את ההזדהות בטביעת אצבע.",
            joinFingerIdp5:"בכל מקרה של אובדן ו/או גניבה עליך להודיע לנו בטלפון.",
            joinFingerIdp6:"באישור ביצוע פעולות מסוימות תתבקש להזדהות בטביעת אצבע או באופן אחר פעם נוספת.",
            joinFingerIdp7:"הזדהות בטביעת אצבע אינה מבטלת את אמצעי הזיהוי האחרים שלך (שם משתמש, סיסמה וכיוב), ולכן עליך לוודא כי הם ידועים לך.",
            joinFingerIdp8: "תוכל לבטל את ההזדהות בטביעת אצבע בכל שלב במסך 'הגדרות'.",
        },
        button: "המשך",
        bottomtitle: "כניסה לאפליקציה",
        bottomsubtitle: "עלייך לבצע כניסה לאפליקציה להשלמת הרישום של טביעת האצבע.",
        bottombutton: "כניסה",
    }
}