import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CacheService } from 'src/app/risk/services/cache.service';
import { ValidatorService } from 'src/app/shared/services/validator.service';

import { ApiService } from '../../services/api.service';
import { AppState } from '../../../store/app.states';
import {Store} from '@ngrx/store'
import {selectInsurance} from '../../../store/app.states'

import {map} from 'rxjs/operators'
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { CordovaService } from 'src/app/core/services/cordova.service';

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  showCreditCard = true;
  openTazrim = false;
  data;
  recommendedManufacturer;
  
  constructor(private store: Store<AppState>,
              private router: Router, 
              private validatorService: ValidatorService, 
              private cacheService: CacheService, 
              private apiService: ApiService,
              private analyticService: AnalyticsService,
              private cordovaService: CordovaService) {
    this.showCreditCard = Object.keys(this.cacheService.cache[4]).filter(el => {
      return this.cacheService.cache[4][el] === "כן"
    }).length === 0
    if (!this.showCreditCard) {
      //TODO: go to success page
      this.router.navigate(['/risksuccess'])
    }

    this.store.select(selectInsurance).pipe(map((data:any) => data.insuranceDetails && data.insuranceDetails.LifeInsurance && data.insuranceDetails.LifeInsurance.SummaryInsuranceRecommendation))
    .subscribe(data => {
      this.data = data.RecommendedInfo; 
      console.log(data);
      this.recommendedManufacturer = data.RecommendedManufacturer
    })
  }

  clickTazrim(){
    this.openTazrim = !this.openTazrim
  }
  ngOnInit() {
    this.analyticService.trackByMixpanel("DP - Risk - Payment");
    this.analyticService.trackByAppFlyer("DP - Risk - Payment", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });


  }

  next(e) {
    const { name, id, creditNumber, exp, cvv } = e
    let date = new Date(exp)
    const data = {
      CardNumber: creditNumber,
      Month: date.getMonth() + 1,
      Year: date.getFullYear(),
      CVV: cvv,
      IdentityNumber: id,
      PhoneNumber: localStorage.getItem('phoneNumber')
    }
    this.apiService.creditCardPayment(data).subscribe((response: any) => {
      if (!!!(response.Code)) {
        this.cacheService.cache[8] = {token: response.Data, creditInfo: data}
        // this.router.navigate(['/risk/success'])
        this.router.navigate(['/risksuccess'])
      }
      //TODO: error handling 
      else {

      }
    })
  }

  openPdf() {
    if (this.cordovaService.isMobile)
      this.cordovaService.open('pdf','https://robinblob.blob.core.windows.net/public/Risk/MenoraRisk.pdf');
    else 
      window.open('https://robinblob.blob.core.windows.net/public/Risk/MenoraRisk.pdf');
  }

  closeKeyboard(e) {
    
    this.cordovaService.closeKeyBoard();
  }
}
