import { Injectable, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, BehaviorSubject } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { AppState, selectRegisterState, selectAnonymous } from '../../../store/app.states';
import { CacheService } from '../../../risk/services/cache.service';
import { CalledAnonymous } from '../../../store/user.actions';
import { HomepageService } from '../../../homepage/homepage.service';
import { ModalService } from '../robin-modal/modal.service';
import { OcrService } from '../../../onboarding/services/ocr/ocr.service';
import { OtpService } from './../../../onboarding/services/otp/otp.service';
import { Response } from './../../../models/global.models';
import { RobinSideNavService } from '../modals/robin-side-nav/robin-side-nav.service';

declare const window;

import { ErrorModal } from '../modals/error-modal/error-model.modal';
import { ErrorModalService } from '../modals/error-modal/modal.service';

export const instruction = {
  //Risk digital process
  risk: { back: '**', next: '/', title: 'ביטוח ריסק' },
  purchase: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  upgrade: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  risksuccess: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  riskGeneraldetails: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  riskHeightandweight: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  riskAddbeneficiaries: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  riskDistributionofpercentages: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  riskHealthquestionnnaire: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  riskElectronicsignature: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  riskCancelpreviouspolicy: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  riskPayment: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },
  riskAddnewbeneficiary: { back: '**', next: '/', title: 'ביטוח ריסק', showBack: true, },

  //Critical illness
  criticalillness: { back: '**', next: '/', title: 'הצעת מחיר', showBack: true, },
  criticalillnessQuestionnairecriticalillnes: { back: '**', next: '/', title: 'הצעת מחיר', showBack: true, },
  criticalillnessAmount: { back: '**', next: '/', title: 'הצעת מחיר', showBack: true, },
  criticalillnessProcess: { back: '**', next: '/', title: 'הצעת מחיר', showBack: true, },
  criticalillnessSummary: { back: '/criticalillness/amount', next: '/', title: 'הצעת מחיר', showBack: true, },

  criticalillnessCriticalupgrade: { back: '**', next: '/', title: 'מחלות קשות' },
  criticalillnessCriticalupgradeHmo: { back: '**', next: '/', title: 'מחלות קשות' },
  criticalillnessCriticalupgradeHeightandweight: { back: '**', next: '/', title: 'מחלות קשות' },
  criticalillnessCriticalupgradeChildren: { back: '**', next: '/', title: 'מחלות קשות' },
  criticalillnessCriticalupgradeHealthquestionnnaire: { back: '**', next: '/', title: 'מחלות קשות' },
  criticalillnessCriticalupgradeElectronicsignature: { back: '**', next: '/', title: 'מחלות קשות' },
  criticalillnessCriticalupgradePayment: { back: '**', next: '/', title: 'מחלות קשות' },
  criticalillnessSuccess: { back: '/account/insurance', next: '/', title: 'מחלות קשות' },




  //Pension digital process
  digitalproccessUpgradepension: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgrade: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeGeneraldetails: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeHeightandweight: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeEmployerdetails: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeCitizenship: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeBusinessid: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeInvestmenttrack: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeChildren: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeBeneficiaries: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeAddnewbeneficiary: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeBeneficiariespercentages: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradePictureid: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeHealthquestionnnaire: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeElectronicsignature: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionupgradeAddnewchild: { back: '**', next: '/', title: 'ניוד פנסיה' },
  digitalproccessPensionsuccess: { back: '**', next: '/', title: 'ניוד פנסיה' },

  test: { back: '**', next: '/', title: '' },
  login: { back: '**', next: '/', title: 'התחברות', menu: false },
  authenticationPasswordemail: { back: '**', next: '/authentication/passwordotp', title: 'חידוש סיסמה' },
  authenticationPasswordotp: { back: '/authentication/passwordemail', next: '/homepage', title: 'חידוש סיסמה' },
  onboardingIntro: { showBack: false, next: '/onboarding', title: 'ברוך הבא', display: false, letsknow: 'letsknow', login: 'login', firstpage: 'firstpage'},
  account: { back: '/', next: 'account', title: '', pension: 'pension', insurance: 'insurance', background: 'black' },
  onboardingTakanon: { back: '**', next: '/onboarding/letsknow', title: 'תקנון' },
  onboardingLogin: { back: '**', next: '/', title: 'התחברות', letsknow: 'onboarding/letsknow', firstpage: 'onboarding/firstpage', idcardmain: 'onboarding/idcardmain', homepage: 'homepage', questionnaire: 'questionnaire', otp: 'onboarding/otp', howitworks: 'onboarding/howitworks' },
  onboardingLetsknow: { showBack: false, next: 'onboarding', title: 'הרשמה', login: 'login', otp: 'otp' },
  onboardingFirstpage: { showBack: false, next: 'onboarding', title: 'הרשמה', login: 'login', otp: 'otp',letsknow: 'letsknow' },
  onboardingOtp: { back: '/onboarding/letsknow', next: '/', howitworks: 'onboarding/howitworks', homepage: '/homepage', title: 'סיסמה' },
  onboardingHowitworks: { showBack: false, next: 'onboarding/idcardmain', title: 'יוצאים לדרך' },
  onboardingIdcardmain: { back: '/questionnaire', next: 'onboarding', idcardpreview: 'idcardpreview', credit: 'credit', title: 'אימות זהות' },
  onboardingReminder: { back: '/questionnaire', next: 'onboarding/otp', title: 'הזכר לי' },
  onboardingIdcardpreview: { back: '/questionnaire', next: 'onboarding', selfie: 'selfie', idcardpreview: 'idcardpreview', title: 'אימות זהות' },
  onboardingSelfie: { showBack: false, next: 'onboarding/selfiepreview', title: 'אימות זהות' },
  onboardingSelfiepreview: { showBack: false, next: 'onboarding/checkinfo', title: 'אימות זהות' },

  // onboardingSelfiepreview: { showBack: false, next: 'onboarding/pleasewait', title: 'אימות זהות' },
  onboardingPleasewait: { showBack: false, next: 'onboarding/checkinfo', title: 'אימות זהות' },
  onboardingCheckinfo: { showBack: false, back: '**', next: 'onboarding', title: 'איתור מוצרים פיננסים', laststep: 'laststep', collectsuccess: 'collectsuccess' },
  onboardingLaststep: { showBack: false, back: '**', next: 'onboarding/collectsuccess', title: 'איתור מוצרים פיננסים' },
  onboardingCollectsuccess: { showBack: false, next: 'questionnaire', title: 'איתור מוצרים פיננסים' },
  onboardingCredit: { back: '/questionnaire', next: 'onboarding/collectsuccess', title: 'אימות זהות' },
  questionnaire: { back: '/homepage', next: 'homepage', title: 'שאלון המלצות אישיות' },
  homepage: { back: '/', next: '/', title: 'ROBIN', static: true, menu: true, background: '#4F8BFC', howitworks: 'onboarding/howitworks', pension: 'account/pension', strict: true },
  homepageLoginclickedTrue: { back: '/', next: '/', title: 'ROBIN', static: true, menu: true, background: '#4F8BFC', howitworks: 'onboarding/howitworks', pension: 'account/pension', strict: true },
  authenticationJointouchid: { showBack: true, menu: false, back: '**', next: '/', title: 'זיהוי טביעת אצבע', static: true, background: '#4F8BFC', },
  authenticationJoinfaceid: { showBack: true, menu: false, back: '**', next: '/', title: 'זיהוי תמונת פנים', static: true, background: '#4F8BFC', },
  authenticationAuthsuccessfullycompleted: { support: false, back: '**', next: '/', title: 'חידוש סיסמא', static: true, showBack: false, menu: false, background: '#4F8BFC', },
  authenticationAuthsuccessfullycompletedFingerFalse: { strict: true, support: false, back: '**', next: '/', title: 'זיהוי תמונת פנים', static: true, showBack: false, menu: false, background: '#4F8BFC', },
  authenticationAuthsuccessfullycompletedFingerTrue: { strict: true, support: false, back: '**', next: '/', title: 'זיהוי טביעת אצבע', static: true, showBack: false, menu: false, background: '#4F8BFC', },
  authentication: {},

  accountPension: { back: 'pension', next: 'account', menu: true, title: 'פרופיל פנסיוני', background: '#4F8BFC', productalternative: 'pension/productalternative', extentionview: 'pension/extentionview', pension: 'pension', insurance: 'insurance', strict: true },
  accountPensionProductalternative: { back: '/account/pension', next: 'account', title: 'פרופיל פנסיוני', background: '#4F8BFC', extentionview: 'pension/extentionview' },
  accountPensionGemelalternativeview: { back: '/account/pension', next: 'account', title: 'קופות גמל', background: '#4F8BFC', extentionview: 'pension/extentionview', gemelextensioneview: 'pension/gemelextensioneview' },
  accountPensionGemelextensioneview: { back: '**', next: 'account', title: 'קופות גמל', background: '#4F8BFC', extentionview: 'pension/extentionview' },
  accountPensionHishtalmutalternativeview: { back: '/account/pension', next: 'account', title: 'קרנות השתלמות', background: '#4F8BFC', extentionview: 'pension/extentionview', hishtalmutextensioneview: 'pension/hishtalmutextensioneview' },
  accountPensionHishtalmutextensioneview: { back: '**', next: 'account', title: 'קרנות השתלמות', background: '#4F8BFC', extentionview: 'pension/extentionview' },
  accountPensionForecast: { back: '/account/pension', menu: false, next: 'account', title: 'פרופיל פנסיוני', background: '#4F8BFC', extentionview: 'pension/extentionview' },
  accountPensionExtentionview: { back: '**', next: 'account/pension', title: 'פרופיל פנסיוני' },
  accountPensionPensionextensioneview: { back: '**', next: 'account/pension', title: 'קרנות פנסיה' },
  accountPensionPensionalternativeview: { back: '**', next: 'account/pension', title: 'קרנות פנסיה' },

  accountPensionMutavim: { back: '**', next: 'account/pension', title: 'מוטבים' },

  accountInsurance: { back: '/insurance', next: 'account', menu: true, title: 'פרופיל ביטוחי', pension: 'pension', insurance: 'insurance', strict: true },
  accountInsuranceMylife: { next: 'account', menu: true, pension: 'pension', insurance: 'insurance', myhealth: 'insurance/myhealth', title: 'פרופיל ביטוחי', strict: true },
  accountInsuranceMyhealth: { next: 'account', menu: true, pension: 'pension', insurance: 'insurance', mylife: 'insurance/mylife', title: 'פרופיל ביטוחי', strict: true },
  accountInsuranceInsuranceextendeddeatailscard: { back: '**', next: 'account', pension: 'pension', insurance: 'insurance', menu: false, title: 'פרופיל ביטוחי' },
  // accountInsuranceInsuranceextendeddetails: { back: '/account/insurance/myhealth', next: 'account', pension: 'pension', insurance: 'insurance', menu: false, title: 'פרופיל ביטוחי' },
  accountInsuranceInsuranceextendeddetails: { back: '**', next: 'account', pension: 'pension', insurance: 'insurance', menu: false, title: 'פרופיל ביטוחי' },
  accountInsuranceHealthinsuranceextendedview: { back: '/account/insurance', next: 'account', pension: 'pension', insurance: 'insurance', myhealth: 'myhealth', menu: false, title: 'ביטוח בריאות' },
  // accountInsuranceHealthinsuranceextendedview: { back: '/account/insurance/myhealth', next: 'account', pension: 'pension', insurance: 'insurance', myhealth:'myhealth', menu: false, title: 'ביטוח בריאות' },
  accountInsuranceHealthinsuranceproductslist: { back: '**', next: 'account', pension: 'pension', insurance: 'insurance', myhealth: 'myhealth', menu: false, title: 'ביטוח בריאות' },
  accountInsuranceNursinginsuranceproductslist: { back: '**', next: 'account', pension: 'pension', insurance: 'insurance', myhealth: 'myhealth', menu: false, title: 'ביטוח סיעודי' },
  accountInsurancePersonalaccidentsinsuranceproductslist: { back: '**', next: 'account', pension: 'pension', insurance: 'insurance', myhealth: 'myhealth', menu: false, title: 'ביטוח תאונות אישיות' },
  accountInsuranceCriticalillnessinsuranceproductslist: { back: '**', next: 'account', pension: 'pension', insurance: 'insurance', myhealth: 'myhealth', menu: false, title: 'ביטוח מחלות קשות' },
  accountInsuranceCriticalillnessinsuranceproblem: { back: '**', next: 'account', pension: 'pension', insurance: 'insurance', myhealth: 'myhealth', menu: false, title: 'ביטוח מחלות קשות' },

  about: { back: '**', next: '/', privacy: 'privacy', termsofcondition: 'termsofcondition', title: 'אודות', static: true, menu: false, background: '#4F8BFC' },
  privacy: { back: '**', next: '/', title: 'פרטיות ואבטחת מידע', static: true, menu: false, background: '#4F8BFC' },
  termsofcondition: { back: '**', next: '/', title: 'תנאי שימוש', static: true, menu: false, background: '#4F8BFC' },
  support: { back: '**', next: '/', title: 'תמיכה טכנית', static: true, menu: false, background: '#4F8BFC' },
  settings: { back: '**', next: '/', title: 'הגדרות', static: true, menu: false, background: '#4F8BFC' },

  digitalproccessGemel: { back: '**', next: 'onboarding/idcardmain', title: 'יוצאים לדרך' },
  digitalproccessHishtalmut: { back: '**', next: 'onboarding/idcardmain', title: 'יוצאים לדרך' },

  accountInvitefriends: { next: 'account', updatedata: 'updatedata', pension: 'pension', insurance: 'insurance', choosefriends: 'choosefriends', title: 'ארנק מטבעות', static: true, menu: true, strict: true, background: '#4F8BFC' },
  accountUpdatedata: { back: '**', next: 'account', creditupdatemislaka: 'creditupdatemislaka', choosefriends: 'choosefriends', invitefriends: 'invitefriends', pension: 'pension', title: 'עדכון נתונים', static: true, menu: false, background: '#4F8BFC' },
  accountChoosefriends: { back: '**', next: 'account', invitefriends: 'invitefriends', updatedata: 'updatedata', pension: 'pension', title: 'הזמנת חברים לאפליקציה', static: true, menu: false, background: '#4F8BFC' },
  accountCreditupdatemislaka: { back: '**', next: 'account', invitefriends: 'invitefriends', pension: 'pension', title: 'תשלום', static: true, menu: false, background: '#4F8BFC' },

  accountLoader: { back: '**', next: 'account', title: 'ביטוח בריאות', static: true, menu: false, background: '#4F8BFC' },
  recomendation: { back: '**', title: 'הצעות', static: true, menu: false, showBack: true },

  personalprofile: { back: '**', next: '/', termsofcondition: 'termsofcondition', title: 'פרופיל אישי', static: true, menu: false, background: '#4F8BFC' },
  updateaddress: { back: '**', next: '/', title: 'עדכון כתובת', static: true, menu: false, background: '#4F8BFC' },
  updatechildren: { back: '**', next: '/', title: 'עדכון מספר ילדים', static: true, menu: false, background: '#4F8BFC' },
  updateoccupation: { back: '**', next: '/', title: 'עדכון עיסוק', static: true, menu: false, background: '#4F8BFC' },
  updateoccupationstatus: { back: '**', next: '/', title: 'עדכון עיסוק', static: true, menu: false, background: '#4F8BFC' },
  updateemployername: { back: '**', next: '/', title: 'עדכון עיסוק', static: true, menu: false, background: '#4F8BFC' },
  updaterisklevel: { back: '**', next: '/', title: 'עדכון רמת סיכון', static: true, menu: false, background: '#4F8BFC' },
  updatesalary: { back: '**', next: '/', title: 'עדכון שכר חודשי נטו', static: true, menu: false, background: '#4F8BFC' },


};


@Injectable({
  providedIn: 'root'
})
export class NavManagerService {
  // public getTitle$: Subject<any>;
  public getTitle$ = new BehaviorSubject({});
  public currentRoute;
  private defaultBackground = '#427dfb';
  phoneNumber;
  email;
  lastRoute;
  history = [];
  showback = false;
  public displayLogout = new Subject();
  public displayLogout$ = this.displayLogout.asObservable();
  public urlChanged = new Subject();
  public urlChanged$ = this.urlChanged.asObservable();
  strict = false;
  //every property needs to be matched exactly to the router path pay attention to camel case(!!)
  get getInstruction() {
    return instruction;
  }

  constructor(private sideNavService: RobinSideNavService,
    private otpService: OtpService,
    private zone: NgZone,
    private _router: Router,
    private modalService: ModalService,
    private modalError: ErrorModalService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private _location: Location,
    private homepageService: HomepageService,
    private ocrService: OcrService,
    private errorModalService: ErrorModalService,
    private riskCacheService: CacheService
  ) {
    this.init();
  }

  next(within = null, options = null) {
    this.lastRoute = this.currentRoute;
    this.urlChanged.next(this.lastRoute);
    console.log(instruction[this.currentRoute][within]);
    if (instruction[this.currentRoute][within]) {
      this._router.navigate([`${instruction[this.currentRoute].next}/${instruction[this.currentRoute][within]}`], { queryParams: { ...options } });
    }
    else {
      this._router.navigate([instruction[this.currentRoute].next], { queryParams: { ...options } });
    }
  }

  prev(force = false) {
    console.log('1');
    // if (this.currentRoute === 'onboardingCheckinfo' && this.history[this.history.length - 2].url !== '/account/insurance/myhealth')
    if (this.currentRoute === 'onboardingCheckinfo' && this.history[this.history.length - 2].url !== '/account/insurance')
      return;

    // if (this.currentRoute === 'onboardingLaststep' && this.history[this.history.length - 3].url !== '/account/insurance/myhealth')
    if (this.currentRoute === 'onboardingLaststep' && this.history[this.history.length - 3].url !== '/account/insurance')
      return;
    if (instruction[this.currentRoute].strict) {
      this.sideNavService.close();
      return;
    }
    if (this.modalService.arraymodals.length > 0) {
      let arr = this.modalService.arraymodals.filter(function (modal) { return modal.animationState === "active" });
      if (arr.length > 0) {
        var stinkyHack = false; //stinky hack that occur only on support => ignore.. its all because of Or...
        arr.forEach(el => {
          if (el.id === 'success' && this.history[this.history.length - 2].url !== 'support') {
            // if (el.id === 'success' && this.lastRoute !== 'support') {

            stinkyHack = true;
          } else {
            this.modalService.close(el.id)
          }
        });
        if (!stinkyHack) {
          return; 
        }
      }
    }
    this.lastRoute = this.currentRoute;
    this.urlChanged.next(this.lastRoute);
    console.log('2');
    // if (instruction[this.currentRoute].back === '**') { 
    //   this.modalService.open('areYouSure');
    // }


    if (instruction[this.currentRoute].back === '**') {

      if (this.currentRoute.indexOf('risk') > -1 && this.currentRoute !== "updaterisklevel") {
        if (force) {
          this.zone.run(() => this._router.navigate([`/account`, 'insurance']));
          window.Keyboard.hide();
        }
        else
          this.modalService.open('areYouSure');
        window.Keyboard.hide();
      }

      else if (this.currentRoute.indexOf('digitalproccessPensionupgrade') > -1) {

        if (force) {
          this.zone.run(() => {
            this._router.navigate([`/account`, 'pension']);
          });
          window.Keyboard.hide();
        }
        else {
          this.modalService.open('areYouSure');
        }

        window.Keyboard.hide();
      }
      else if(this.currentRoute.indexOf('Criticalupgrade') > -1) {
        if (force) {
          this.zone.run(() => this._router.navigate([`/account`, 'insurance']));
          window.Keyboard.hide();
        }
        else
          this.modalService.open('areYouSure');
        window.Keyboard.hide();
      }

      else if (this.currentRoute === 'accountLoader') {
        if (force) {
          // this.zone.run(() => { this._router.navigate([instruction[this.currentRoute].back])});     
          // this._router.navigate(['/account', 'insurance', 'myhealth']);
          this._router.navigate(['/account', 'insurance']);
          window.Keyboard.hide();

        }
        else {
          this.modalService.open('areYouSure');
          window.Keyboard.hide();

        }
      }
      else if (this.currentRoute === 'digitalproccessHishtalmut' || this.currentRoute === 'digitalproccessGemel') {
        if (force) {
          this.zone.run(() => this._router.navigate([`/account`, 'pension']));
          window.Keyboard.hide();
        }
        else
          this.modalService.open('areYouSure');
        window.Keyboard.hide();
      }

      else if (this.lastRoute === 'onboardingCredit') {
        if (this.ocrService.creditToBack === true)
          this._router.navigate([this.getPreviousUrl().url]);
        // this._location.back();
        else
          this._router.navigate(['/questionnaire'])
      }

      // if (this.lastRoute === '/pleasewait') {
      //   if (this.ocrService.creditToBack === true)
      //     this.modalService.open('areYouSure');
      //   else
      //     this._router.navigate(['/homepage'])
      // }

      else if (this.lastRoute === 'about') {
        var url;
        for (let i = this.history.length - 2; i >= 0; i--) {
          if (this.history[i].url !== '/termsofcondition' && this.history[i].url !== '/about' && this.history[i].url !== '/privacy') {
            url = this.history[i].url;
            break;
          }
        }
        this._router.navigate([url])
      }
      else {
        this._router.navigate([this.getPreviousUrl().url])
        // this._location.back();
      }
    }
    else if (instruction[this.currentRoute].menu) {
      this.sideNavService.currentState.next(true);
    }
    else if (this.currentRoute === 'onboardingOtp') {
      if (force) {
        this.zone.run(() => this._router.navigate([instruction[this.currentRoute].back]));
      }
      else {
        this.modalService.open('areYouSure');
      }
      // let obj = new DeleteUser();
      // obj.PrimaryIdentifier = localStorage.getItem('phoneNumber');
      // obj.SecondaryIdentifier = localStorage.getItem('email');
      // this.otpService.deletePhoneNumber(obj).subscribe((data: any) => {
      //   if (data.Code === 0) {
      //     this.store.dispatch(new Register({ user: { phoneNumber: null, email: null } }));
      //   }
      // });
    }

    else if (instruction[this.currentRoute].back === '/questionnaire') {
      if (force) {
        this.store.select(selectRegisterState).subscribe((data: any) => {
          if (data.user !== null) {
            this.phoneNumber = data.user.phoneNumber;
            this.email = data.user.email;
            let user = { email: this.email, phoneNumber: this.phoneNumber };

            this.store.select(selectAnonymous).subscribe((data: any) => {
              if (data.anonymous !== null) {
                if (this.homepageService.firstTimeQuestionnaire === true) {
                  this.zone.run(() => this._router.navigate(['/homepage']));
                } else {
                  if (data.anonymous.FilledQuistionnaire === false)
                    this.zone.run(() => this._router.navigate([instruction[this.currentRoute].back]));
                  else
                    this.zone.run(() => this._router.navigate(['/homepage']));
                }


              } else {
                this.homepageService.anonymousStatus(user).subscribe((data: Response) => {

                  if (data.Code === 0) {
                    console.log('data ==> ', data)
                    // this.store.dispatch(new FetchingSuccess());
                    this.store.dispatch(new CalledAnonymous({ anonymous: data.Data }));
                    if (data.Data.FilledQuistionnaire === false)
                      this.zone.run(() => this._router.navigate([instruction[this.currentRoute].back]));
                    else
                      this.zone.run(() => this._router.navigate(['/homepage']));
                  } else {
                    this.errorModalService.setNewError({
                      title: 'אופס...',
                      description: 'נראה שמשהו השתבש בדרך',
                      type: 'error',
                      buttonText: 'נסה שנית',
                    }).open();
                  }

                });
              }
            });


          }
        });
      }
      else
        this.modalService.open('areYouSure');
    }

    else if (instruction[this.currentRoute].back === '/homepage') {
      if (force)
        this.zone.run(() => this._router.navigate([instruction[this.currentRoute].back]));
      else
        this.modalService.open('areYouSure');
    }
    else {
      this.zone.run(() => this._router.navigate([instruction[this.currentRoute].back]));
    }
    this.history.splice(-2, 2);
  }


  getNextOf(ins) {
    return instruction[ins].next;
  }
  public getPreviousUrl(): NavigationEnd {
    return this.history[this.history.length - 2] || '/homepage';
  }

  init() {
    this._router.events.pipe(
      shareReplay(1),
      filter((event) => { return event instanceof NavigationEnd }),
      map((url: any) => {
        // url.urlAfterRedirects.split('/')[1];
        url.url = url.url.split('?')[0];
        this.history = [...this.history, url];
        return this.formatToCamelCase(url.urlAfterRedirects);
        // return url.urlAfterRedirects;
      })
    ).subscribe((currentRoute: string) => {
      this.getTitle$.next({
        showBack: instruction[currentRoute].showBack === undefined ? true : instruction[currentRoute].showBack,
        support: instruction[currentRoute].support === undefined ? true : instruction[currentRoute].support,
        route: currentRoute,
        menu: instruction[currentRoute].menu || false,
        static: instruction[currentRoute].static || true,
        title: instruction[currentRoute].title,
        display: instruction[currentRoute].display === undefined ? true : false,
        background: instruction[currentRoute].background === undefined ? this.defaultBackground : instruction[currentRoute].background || 'black'
      });
      // this._title = this.navbarService.getInstruction[currentRoute].title;
      this.currentRoute = currentRoute;
      if (this.currentRoute.includes('account')) {
        this.displayLogout.next(true);
      }
      else {
        this.displayLogout.next(false);
      }

    });
  }
  fillQuest() {
    var fillquest;
    return fillquest;
  }

  formatToCamelCase(str) {
    str = str.split('?')[0];
    return str.trim(str)
      .replace(/[^A-Za-z]/g, ' ')
      .replace(/(.)/g, function (a, l) { return l.toLowerCase(); })
      .replace(/(^.|\s.)/g, function (a, l) { return l.toUpperCase(); })
      .replace(/[^A-Za-z\u00C0-\u00ff]/g, '');
  }

  transformToValidUrl(str) {
    str.split('').forEach((el, ind, arr) => {
      if (arr[ind] === arr[ind].toUpperCase()) {
        arr.splice(ind, 1, '/' + el.toLowerCase());
        str = arr;
      }
    });
    return str.join('');
  }
}