
export class RobinHamburger {

    private _isMenu;
    private _isStatic;


    constructor(isMenu?, isStatic?) {
        this._isMenu = isMenu;
        this._isStatic = isStatic;
    }

    getCurrentState() {
        return this._isMenu;
    }

    getCurrentStatic() {
        return this._isStatic;
    }

    setStatic(state){
        this._isStatic = state;
        return this;
    }

    public setAllStates(isMenu, isStatic){

        isMenu === undefined ?
            this.setNewState(true) : this.setNewState(isMenu);

        isStatic === undefined ?
            this.setStatic(true) : this.setStatic(isStatic);
        
        return this;
    }

    public setNewState(state) {
        if(!this.getCurrentStatic())
            this._isMenu = state;
        
        return this;
    }

    public getCurrentClass() {
        return this.getCurrentState() ?
                 classObj.active : classObj.inactive;
    }
}


export enum classObj {
    inactive = 'bt-menu-trigger bt-menu-open',
    active = 'bt-menu-trigger '
} 