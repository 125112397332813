import { Component, OnInit, HostListener } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { CordovaService } from "src/app/core/services/cordova.service";
import { AccountService } from "src/app/account/account.service";
import { NavManagerService } from "src/app/shared/components/robin-navbar/robin-navbar.service";
import { ErrorModalService } from "../../../../error-modal/modal.service";

@Component({
  selector: "app-update-address",
  templateUrl: "./update-address.component.html",
  styleUrls: ["./update-address.component.scss"]
})
export class UpdateAddressComponent implements OnInit {
  obj = {
    city: this.accountService.authenticatedModel.City,
    street: this.accountService.authenticatedModel.StreetName,
    number: this.accountService.authenticatedModel.HouseNumber
  };

  generalDetailsForm;
  errorLabel = false;

  constructor(
    private fb: FormBuilder,
    private cordovaService: CordovaService,
    private accountService: AccountService,
    private navbarService: NavManagerService,
    private errorModalService: ErrorModalService
  ) { }
  @HostListener("document:keydown", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.key === "Enter") {
      this.cordovaService.skipToNextInput();
    }
  }
  ngOnInit() {
    this.generalDetailsForm = this.fb.group({
      city: [this.obj.city, [Validators.required, Validators.minLength(2)], ],
      street: [this.obj.street, [Validators.required, Validators.minLength(2)]],
      number: [this.obj.number, [Validators.required, Validators.pattern(/^[.\d]+$/)]]
    });
  }

  save() {
    if (this.generalDetailsForm.status === "VALID") {
      this.errorLabel = false;
      var sendNewDetails = {
        RiskLevel: this.accountService.authenticatedModel.RiskLevel,
        City: this.generalDetailsForm.value.city,
        StreetName: this.generalDetailsForm.value.street,
        HouseNumber: this.generalDetailsForm.value.number,
        PostalCode: this.accountService.authenticatedModel.PostalCode,
        Country: this.accountService.authenticatedModel.Country,
        AccountMaritalStatus: this.accountService.authenticatedModel.AccountMaritalStatus,
        LastSalary: this.accountService.authenticatedModel.Salary,
        JobTitle: this.accountService.authenticatedModel.JobTitle,
        Position: this.accountService.authenticatedModel.Position,
        EmployerName: this.accountService.authenticatedModel.EmployerName,
        // NumberOfChildrens: this.accountService.authenticatedModel.NumberOfChildrens
        ChildrensBirthdates: this.accountService.authenticatedModel.Childrens

      };

      this.accountService.updateProfile(sendNewDetails).subscribe((data: any) => {
        if (data.Code === 0) {
          this.accountService.authenticatedModel.City = this.generalDetailsForm.value.city;
          this.accountService.authenticatedModel.StreetName = this.generalDetailsForm.value.street;
          this.accountService.authenticatedModel.HouseNumber = this.generalDetailsForm.value.number;
          this.accountService.showSuccessUpdate = true;
          setTimeout(() => {
            this.accountService.showSuccessUpdate = false;
          }, 3000);
          this.navbarService.prev();
        } else {
          this.errorModalService
            .setNewError({
              title: "אופס...",
              description: "נראה שמשהו השתבש בדרך",
              type: "error",
              buttonText: "נסה שנית"
            })
            .open();
        }
      });
    } else {
      this.errorLabel = true;
    }
  }

  closeKeyboard(e) {
    console.log("closeKeyboard ", e);
    if (!e) {
      this.cordovaService.closeKeyBoard();
    }
  }
}
