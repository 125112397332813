import { Component, OnInit } from '@angular/core';
import { uiStrings } from '../../../assets/heb.ui_strings';
import { CordovaService } from '../../../../core/services/cordova.service';

@Component({
  selector: 'home-screen-error',
  templateUrl: './home-screen-error.component.html',
  styleUrls: ['./home-screen-error.component.scss']
})
export class HomeScreenErrorComponent implements OnInit {

  public uiStrings;
  constructor(private cordovaService: CordovaService) { 
    this.uiStrings = uiStrings.homescreeneerror;
  }

  ngOnInit() {
  }

  whatsAppRequest() {
    window.open('https://api.whatsapp.com/send?phone=+972544598589&text=', '_system', 'location=yes');
  }
  openwhatsApp() {
    if(this.cordovaService.isMobile){
      this.cordovaService.open('whatsapp');
    }
  }

  openFacebook() {
    if(this.cordovaService.isMobile){
      this.cordovaService.open('messenger');
    }
  }


}
