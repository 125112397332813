import { Component, OnInit, Input } from '@angular/core';
import { RobinState } from './robin-state.model';
import * as STATEOBJ from './stateObj';

@Component({
  selector: 'robin-state',
  templateUrl: './robin-state.component.html',
  styleUrls: ['./robin-state.component.scss']
})
export class RobinStateComponent implements OnInit {
  @Input() text;
  @Input() type;
  robinState;
  currentState;
   
  constructor() { 
  }

  ngOnInit() {
    this.robinState = new RobinState(STATEOBJ.stateArr);
    this.currentState = this.robinState.getCurrentState(this.type)
  } 
}
