import { NavManagerService } from './../../../../shared/components/robin-navbar/robin-navbar.service';
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Tabs } from './tabs.model';

import * as tabMock from './tabs.mock';
import { IndicatorAnimation } from './tabs.animation';
import { Router } from '@angular/router';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  // animations: [
  //   IndicatorAnimation
  // ]
})
export class TabsComponent implements OnInit, AfterViewInit {

  _currentActiveTab;
  @Input() set currentActiveTab(t) {
    console.log('tab shot => ',t);
    this._currentActiveTab = t;
    this.tabs.setActiveTab(t);
    this.isTextClassActive(t);
    this.indicatorX = this.setIndicatorLocation(t);
  }
  tabs;
  indicatorX = 0;
  //animationState = '0';

  constructor(private router: Router) {
    this.tabs = new Tabs(tabMock.tabMock, 0);

  }

  ngAfterViewInit(){
    this.tabs.setActiveTab(this._currentActiveTab || 0);
    this.isTextClassActive(this._currentActiveTab || 0);
    this.indicatorX = this.setIndicatorLocation(this._currentActiveTab || 0);
  }
  
  ngOnInit() {
    this.indicatorX = this.setIndicatorLocation(this.tabs.getCurrentActiveTab());
  }

  tabClicked(tab, i) {
    this.tabs.setActiveTab(i);
    this.isTextClassActive(i);
    this.indicatorX = this.setIndicatorLocation(i);
    this.router.navigate(['/account', 'insurance', tab.url]);

  }
  setIndicatorLocation(i) {
    return - i * (window.innerWidth / this.tabs.getTabArrayLength());
    // this.animationState = i;
  }

  isTextClassActive(index) {
    return index === this.tabs.getCurrentActiveTab() ? 'tab-item active' : 'tab-item'
  }

  setIndicatorWidth() {
    return (window.innerWidth / this.tabs.getTabArrayLength()) + 'px';
  }

}
