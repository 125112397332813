import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { Timer } from './time.model';

@Component({
  selector: 'timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements  AfterViewInit {

  @Input() precentage;

  numOfbits = 70;

  timer = new Timer(this.numOfbits, window.innerWidth);
  bitClass = 'bit';
  transformOrigin = '0px ' + this.timer.radius + 'px';
  constructor() { 
    console.log(this.timer.bitsArr)
  }

  ngAfterViewInit() {
    this.startAnimating();
  }

  startAnimating(){
    this.timer.setActiveBits(this.calculatingBits(this.precentage, this.numOfbits));
    this.isHeightActiveAlternative();
  }

  calculatingBits(precentage, ofBits){
    console.log()
    return precentage * ofBits;
  }

  isHeightActive(bit) {
    return bit === 1 ? 'bit active' : 'bit';
  }

  isHeightActiveAlternative(){
    
    for (let i = 0; i < this.numOfbits; i++) {
      console.log('i-', document.querySelector('.bit-' + i),  i);

      document.querySelector('.bit-' + i).classList.add('bit active');
      
    }

    
  }

}
