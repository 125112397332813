import { NavManagerService } from './../../../shared/components/robin-navbar/robin-navbar.service';
import { Router } from '@angular/router';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AccountService } from '../../account.service';
import { trigger, animate, style, group, animateChild, query, stagger, transition } from '@angular/animations';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { CordovaService } from 'src/app/core/services/cordova.service';
import { fromEvent } from 'rxjs';


@Component({
  selector: 'app-personal-account',
  templateUrl: './personal-account.component.html',
  styleUrls: ['./personal-account.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition('* <=> *', [
        /* order */
        /* 1 */ query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' })
          , { optional: true }),
        /* 2 */ group([  // block executes in parallel
          query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
          ], { optional: true }),
          query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))
          ], { optional: true }),
        ])
      ])
    ])
  ]
})
export class PersonalAccountComponent implements OnInit {
  animationFinished = false;
  scrollListener;
  loading = false;
  showBottomNav = true;
  showTabs = true;
  isShowDrawer = false;
  showConsult = true;
  currentActiveTab = null;
  route = 'accountPension';
  constructor(private _router: Router, 
              public accountService: AccountService, 
              private navbarService: NavManagerService,
              private analyticService: AnalyticsService,
              private cordovaSrevice: CordovaService,
              ) {
    this.accountService.loading$.subscribe(data => {
      this.loading = data;
    });
    this.navbarService.getTitle$.subscribe((data: any) => {
      this.showBottomNav = this.subRouteOfPension(data.route);
      this.showTabs = !this.subRouteOfInsurance(data.route);
      this.showConsultByRoute(data.route);
      // this.showBottomNav = this.isBottomNav(data.route);
      // this.isBottomNav(data.route);
    });
    this.accountService.isShowDrawer;
    this._router.events.subscribe((data: any) => {
      let url = data.url;

      // console.log('url => ', url);
      if (String(url).indexOf('myhealth') !== -1) { console.log('myhealth'); this.currentActiveTab = 1; }
      else if (String(url).indexOf('mylife') !== -1) { console.log('mylife'); this.currentActiveTab = 0; }
    });


    // this.navbarService.urlChanged$.subscribe((data: string) => {
    //   console.log('data: => ', data); //accountInsuranceInsuranceextendeddetails
    //   if (data === 'accountInsuranceInsuranceextendeddetails') {
    //     this.currentActiveTab = 1;
    //   }
    // });
  }

  //animation finished
  onDone(e) {
    this.animationFinished = true;
  }

  navBarClicked(event) {
    if (event.name === 'insurance')
      this.currentActiveTab = 0;
  }

  showConsultByRoute(route) { 
    if (route === 'accountPensionForecast' || 
        route === 'accountPensionProductalternative' || 
        route==='accountInsuranceInsuranceextendeddetails' ||
        route==='accountInvitefriends' ||
        route==='accountUpdatedata' ||
        route==='accountChoosefriends' || 
        route =='accountCreditupdatemislaka' ||
        route === 'accountLoader' ||
        route === 'accountInsuranceHealthinsuranceextendedview' ||
        route === 'accountPensionGemelalternativeview' ||
        route === 'accountPensionHishtalmutalternativeview' ||
        route ==='accountPensionPensionalternativeview' ||
        route === 'accountInsuranceCriticalillnessinsuranceproblem'
        ) 
      this.showConsult = false;
    else
      this.showConsult = true;
  }


  subRouteOfInsurance(route) {
    if (route === 'accountInsuranceInsuranceextendeddetails' || 
        route === 'accountInsuranceInsuranceextendeddeatailscard' ||
        route === 'accountInsuranceHealthinsuranceextendedview' ||
        route === 'accountInsuranceHealthinsuranceproductslist' ||
        route === 'accountInsuranceNursinginsuranceproductslist' ||
        route === 'accountInsurancePersonalaccidentsinsuranceproductslist' ||
        route === 'accountInsuranceCriticalillnessinsuranceproductslist' ||
        route === 'accountInsuranceCriticalillnessinsuranceproblem' ||
        route === 'accountInsurance'
        ) {
      return true;
    } else {
      if (/Insurance/.test(route) === true)
        this.route = route.substr(0, 16);

      return !(/Insurance/.test(route));
    }
  }
  subRouteOfPension(route) {
    if (route === 'accountInsuranceInsuranceextendeddetails' || 
        route === 'accountInsuranceInsuranceextendeddeatailscard' || 
        route === 'accountUpdatedata' || 
        route === 'accountChoosefriends' ||
        route === 'accountCreditupdatemislaka' || 
        route === 'accountLoader' ||
        route === 'accountInsuranceHealthinsuranceextendedview' ||
        route === 'accountInsuranceCriticalillnessinsuranceproductslist' ||
        route === 'accountInsuranceCriticalillnessinsuranceproblem' ||
        route === 'accountInsuranceHealthinsuranceproductslist'){
      return false;
    } else {
      if (/Pension+[A-Z]/.test(route) === true)
        this.route = route.substr(0, 14);

      return !(/Pension+[A-Z]/.test(route));
    }
  }
  ngOnInit() {

  }

  closeLoginClicked() {
    this.accountService.closeLoginClicked();
  }

  openAssist() {
    this.analyticService.trackByMixpanel("Consult Expert - Round Button");
    this.analyticService.trackByAppFlyer("Consult Expert - Round Button", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.accountService.openAssist();
  }

  showSuccess(e) {
    this.accountService.showSuccess();
    // setTimeout(() => {
    //   this.accountService.isShowDrawer = false;
    // }, 1000 * 6);
  }

  cancelAssistance() {
    this.analyticService.trackByMixpanel("Consult Expert - Cancel Request");
    this.analyticService.trackByAppFlyer("Consult Expert - Cancel Request", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.accountService.isSuccess = false;
    this.accountService.showConsultCancel();
    // this.accountService.cancelAssistance().subscribe(data => { console.log('dor', data) });
    setTimeout(() => {
      this.accountService.isShowDrawer = false;
    }, 1000 * 3);

  }

  getState(outlet) {
    return outlet.activatedRouteData.innerstate;
  }

  closeFriendsClicked() {
    this.accountService.friendsOpen = false;
  }

  choosefriends() {
    // this.navbarService.next('choosefriends');
    this.accountService.friendsOpen = false;
    this._router.navigate(['/account','choosefriends']);
  }

  choosefriendsWhatsapp() {
    this.accountService.friendsOpen = false;
    console.log('aaa');
    this.cordovaSrevice.open('inviteWhatsapp', this.accountService.inviteLink);
  }

}
