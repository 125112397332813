import { NavManagerService } from './../../../shared/components/robin-navbar/robin-navbar.service';
import { CacheService } from '../../services/cache.service';
import { UtilityService } from '../../../core/services/utility.service';
import { uiStrings } from '../../assets/heb.ui_strings';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../../shared/components/robin-modal/modal.service';
import { AnalyticsService } from '../../../analytics/analytics.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit, AfterViewInit {
  public uiStrings;
  public slideWidth = 0;
  public positionleft = 0;
  public sliderContainerWidth = 0;
  public slides = [];
  public slidePadding = 20;
  public dotIndex = 0;
  public translateX = '0';
  public timeOutCounter;
  private baseUrl;
  constructor(
    private navbarService: NavManagerService,
    private utilityService: UtilityService,
    private analyticsService: AnalyticsService) {

    this.slideWidth = window.innerWidth;
    this.slides = this.utilityService.convertObjToArray(uiStrings.intro);
    this.sliderContainerWidth = (this.slides.length * this.slideWidth); // 20 both sides = 40px for outter padding
    this.baseUrl = environment.endPoint;
  }

  ngOnInit() {
    localStorage.clear();
    this.uiStrings = uiStrings;
  }
  ngAfterViewInit() {
  }

  swipe(direction) {
    if (direction !== 'left') {
      if (this.dotIndex < this.slides.length - 1) {
        this.dotIndex++;
        this.positionleft = this.setPosition(direction, this.positionleft, this.slideWidth, this.slidePadding);
      }
    } else {
      if (this.dotIndex !== 0) {
        this.dotIndex--;
        this.positionleft = this.setPosition(direction, this.positionleft, this.slideWidth, this.slidePadding);
      }
    }
    this.translateX = this.getTranslateX(this.positionleft);
  }

  setPosition(direction, positionLeft, slideWidth, slidePadding) {
    var result = direction !== 'right' ? positionLeft -= (slideWidth) :
      positionLeft += (slideWidth);

    return result;
  }

  getTranslateX(pxValue) {
    return `${pxValue}`;
  }

  dotClass(index) {
    return index === this.dotIndex ? 'inner-dot active' : 'inner-dot';
  }


  cancelAutoSwiping() {
    clearInterval(this.timeOutCounter);
  }

  redirectTo(str) {
    //Pressed lets begin button in homescreen
    if (str === "firstpage") {
      if (this.dotIndex < 2) {
        this.swipe('right');
        return
      }
      //Current slide is "I'm Robin" 
      if (this.dotIndex == 0) {
        this.analyticsService.trackByMixpanel("Intro Screens - Begin Registration S1");
        this.analyticsService.trackByMixpanel("Intro Screens - First Time Entrance - S1");
        this.analyticsService.trackByAppFlyer("Intro Screens - First Time Entrance - S1", { phoneNumber: this.analyticsService.phoneNumber, uuid: this.analyticsService.uuid });
      }
      //Current slide is "Upgrades & Savings" 
      else if (this.dotIndex == 1)
        this.analyticsService.trackByMixpanel("Intro Screens - Begin Registration S2");
        
      //Current slide is "Data Security"
      else
        this.analyticsService.trackByMixpanel("Intro Screens - Begin Registration S3");
    } else if (str == "login") {
      //Current slide is "I'm Robin" 
      if (this.dotIndex == 0) {
        this.analyticsService.trackByMixpanel("Intro Screens - Registered User S1");
        this.analyticsService.trackByAppFlyer("Intro Screens - I'm a Registered User", { phoneNumber: this.analyticsService.phoneNumber, uuid: this.analyticsService.uuid });
      }
      //Current slide is "Upgrades & Savings" 
      else if (this.dotIndex == 1) {
        this.analyticsService.trackByMixpanel("Intro Screens - Registered User S2");
        this.analyticsService.trackByAppFlyer("Intro Screens - I'm a Registered User", { phoneNumber: this.analyticsService.phoneNumber, uuid: this.analyticsService.uuid });
      }
      //Current slide is "Data Security"
      else {
        this.analyticsService.trackByMixpanel("Intro Screens - Registered User S3");
        this.analyticsService.trackByAppFlyer("Intro Screens - I'm a Registered User", { phoneNumber: this.analyticsService.phoneNumber, uuid: this.analyticsService.uuid });
      }
    }
    this.navbarService.next(str);
    // switch (str) {
    //   case 'letsKnow':
    //     this._router.navigate([
    //       '/onboarding', 'letsknow'
    //     ])
    //     break;

    //   case 'login':
    //     this._router.navigate([
    //       '/onboarding', 'login'
    //     ])
    //     break;

    //   default:
    //     break;
    // }
  }
}
