import { Component, OnInit } from '@angular/core';
import { CordovaService } from '../../../../../../core/services/cordova.service';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {

  constructor(private cordovaService: CordovaService) { }

  ngOnInit() {
  }
  send(){
    
  }

  
  openWhatsapp() {
    if (this.cordovaService.isMobile) {
      this.cordovaService.open('whatsapp');
    }
    else {

    }
  }
}
