

export const validatorsStrings = {
    email:{
        hebrew: 'אסור לך לעשות גומיות',
    },
    1:{
        hebrew: 'אסור לך לעשות חומיות'
    },
    settings:{
        title:"",
        subtitle:"אישור קבלת חומר פרסומי והטבות",
    }

}