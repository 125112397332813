import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'please-wait-loader',
  templateUrl: './please-wait-loader.component.html',
  styleUrls: ['./please-wait-loader.component.scss']
})
export class PleaseWaitLoaderComponent implements OnInit {


  @Input() type; // standby, done, loading
  @Input() text;

  constructor() { }

  ngOnInit() {
  }

}
