import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { ContactService } from 'src/app/core/services/contact.service';

@Component({
  selector: 'single-contact',
  templateUrl: './single-contact.component.html',
  styleUrls: ['./single-contact.component.scss']
})
export class SingleContactComponent implements OnInit, OnChanges {
  @Input() contact;
  @Input() active;
  @Input() indexArray = [];
  @Input() index;
  @Input() isRobinArray  = [];

  @Output() clickedOn = new EventEmitter();

  contactHasRobin = false;
  chooseContact;


  constructor(private contactService: ContactService) {
  }

  ngOnInit() {
    if (this.indexArray.includes(this.index)) {
      this.chooseContact = true;
    }
    else     
      this.chooseContact = false;

  }

  ngOnChanges(changes): void {
    // if (changes.active.currentValue)
    //   this.chooseContact = changes.active.currentValue;
    if (changes.isRobinArray.currentValue.length > 0) {
      //console.log(changes.isRobinArray.currentValue);
      this.index;
      this.contactHasRobin = changes.isRobinArray.currentValue[this.index].ExistsAsCustomer;
    }
  }

  clickOnContact(contact) {
    if (this.contactHasRobin === true) {
      this.contactService.openHasRobin = true;
      setTimeout(() => {
        this.contactService.openHasRobin = false;
      }, 3000);
      return;
    }
    if (this.chooseContact === true) {
      this.chooseContact = false;
      //this.active = false;
    }
    else {
      this.chooseContact = true;
    }
    this.clickedOn.emit(contact);
  }

}
