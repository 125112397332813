import { PensionSummary } from './../../models/global.models';
import { PensionActionTypes } from '../../store/user.actions';
import { All } from "../../store/user.actions";

export interface State {
    pensionDetails: any | null
}

export const initialState: State = {
    pensionDetails: -1
};

export function reducer(state = initialState, action: All): State {
    switch (action.type) {
        case PensionActionTypes.PENSION: {
            return {
                ...state,
                pensionDetails: action.payload.pensionDetails
            };
        }
        default: {
            return state;
        }
    }
}