import { CordovaService } from './../../../core/services/cordova.service';
import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UtilityService } from '../../../core/services/utility.service';

declare const window;

@Component({
  selector: 'robin-passcode',
  templateUrl: './robin-passcode.component.html',
  styleUrls: ['./robin-passcode.component.scss']
})
export class RobinPasscodeComponent implements OnInit, AfterViewInit, OnDestroy {
  public _type = 'text';
  public active = false;
  public _length = 6;
  @ViewChild('input') input: ElementRef;
  @ViewChild('password') password: ElementRef;
  pass: number[];
  @Output() passcodeCompleted: EventEmitter<string> = new EventEmitter<string>();
  @Input() set length(len) {
    this._length = len;
  }

  @Input() set type(typ) {
    this._type = typ;
  }

  constructor(private cdr: ChangeDetectorRef,private utilityService: UtilityService, private cordovaService: CordovaService) {
    // this.pass = new Array(this._length);
    this.pass = new Array(this._length);
    this.pass.fill(-1);
  }

  _init() {
    if (this.password) {
      Array.prototype.forEach.call(this.password.nativeElement.children,
        function (el, index) {
          el.addEventListener('input', function (e) {
            this.pass[index] = e.target.value;
            if (e.target.parentNode.children[index - 1]) {
              if (Number(e.data)) {
                e.target.parentNode.children[index - 1].value = '*';
              }
              else {
                e.target.parentNode.children[index].value = '';
              }
            }
            if (e.target.parentNode.children[index + 1]) {
              // e.target.parentNode.children[index + 1].focus();
            }
            else {
              e.target.parentNode.children[index].value = '*';
              // let a: HTMLElement = document.querySelector('robin-button');
              // a.click();
            }
            let num = '';
            this.pass.forEach(digit => {
              if (Number(digit) !== NaN) {
                num += String(digit);
              }
              else return;
            })
            if (num.length === 6)
              this.passcodeCompleted.emit(Number(num));
          }.bind(this));
        }.bind(this)
      );
    }
  }
  ngOnInit() {
    // setTimeout(() => {
    //   let el: any = document.querySelectorAll('form input')[0];
    //   el.classList.remove('not-active');
    //   el.classList.add('active');
    //   this.active = true;
    // }, 300);
  }
  out(e) {
    if (!e) {
      this.active = false;
    }
    else this.active = true;
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    // let el: any = document.querySelectorAll('form input')[0];
    // console.debug('passcode ngAfterViewInit ', el);
    // if (el) el.focus();
  }

  ngOnDestroy(): void {
    this.input.nativeElement.value = '';
  }

  //miklassoulinr@gmail.com
  //0526389510
  // nextInput(e) {
  //   console.log('e: ', e);

  //   this.utilityService.passCodeUtil(e, { type: 'digits' }, (action, index, data) => {
  //     switch (action) {
  //       case 'add':
  //         this.pass[index] = data;
  //         break;
  //       case 'remove':
  //         this.pass[index] = -1;
  //         break;
  //     }
  //     let pass = this.pass.join('');
  //     console.log('pass: ', pass);

  //     if ((pass.length === this._length)) {
  //       this.passcodeCompleted.emit(String(pass));
  //     }
  //   });
  // }

  nextInput(e) {
    // this.utilityService.passCodeUtil(e, { type: 'digits' }, (action, index, data) => {
    //   switch (action) {
    //     case 'add':
    //       this.pass[index] = data;
    //       break;
    //     case 'remove':
    //       this.pass[index] = -1;
    //       break;
    //   }
    //   let pass = this.pass.join('');
    //   if (pass.length === this._length) {
    //     console.log('DONE');
    //     this.passcodeCompleted.emit(String(pass));
    //   }
    //   console.log(pass);
    // });
    if (e.target.value.length !== 0)
      this.pass[0] = 0;
    else
      this.pass[0] = -1;


    this.utilityService.digitOnly(e);
    this.utilityService.inputLength(e, 6);
    if (e.target.value.length === 6) {
      this.passcodeCompleted.emit(e.target.value);
    }
  }
}
