import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ForgotpasswordService {

  _forgotPasswordObj = { };

  constructor() { }

  setForgotpasswordObj(obj){
    this._forgotPasswordObj = obj;
  }
  getForgotpasswordObj() {
    return this._forgotPasswordObj;
  }
  
}
