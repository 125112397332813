import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ErrorModalService } from '../../../../shared/components/modals/error-modal/modal.service';
import { NavManagerService } from '../../../../shared/components/robin-navbar/robin-navbar.service';
import { ForgotpasswordService } from '../services/forgotpassword.service';
import { OtpService } from '../../../../onboarding/services/otp/otp.service';
import { extractStyleParams } from '@angular/animations/browser/src/util';
import { UtilityService } from '../../../../core/services/utility.service';
import { CordovaService } from 'src/app/core/services/cordova.service';

@Component({
  selector: 'app-password-email',
  templateUrl: './password-email.component.html',
  styleUrls: ['./password-email.component.scss']
})
export class PasswordEmailComponent implements OnInit {

  messageError: string = '';
  errorOnSubmit: boolean = false;

  constructor(
    private _nav: NavManagerService,
    private fb: FormBuilder,
    private errorModalService: ErrorModalService,
    private forgotPasswordService: ForgotpasswordService,
    private otpService: OtpService,
    private utilityService: UtilityService,
    private cordovaService: CordovaService
  ) { }
  passwordform;

  ngOnInit() {
    this.passwordform = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required, Validators.pattern(/^05[.\d]+$/), Validators.minLength(10), Validators.maxLength(10)]],
    });
  }

  submit() {
    // console.log(this.passwordform.controls['phone'].value, this.passwordform.controls['email'].value)
    if(!this.passwordform.valid){
      // this.errorModalService.setNewError({
      //   title: 'אופס...',
      //   description: 'שכחת למלא שדות חשובים',
      //   type: 'error',
      //   buttonText: 'אישור',
      // }).open();
      this.messageError = 'יש למלא את כל השדות כדי להמשיך';
      this.errorOnSubmit = true;

    }else{


      this.isAuthUser(this.passwordform.controls['phone'].value, this.passwordform.controls['email'].value)
      .subscribe((data: any)=>{
        
        if(data.Code === 0) {
          console.log('going in!!')
          this.packageObject();
        } else{
          // this.errorModalService.setNewError({
          //   title: 'אופס...',
          //   description: 'אחד או שניים מהפרטים שהזנת שגויים',
          //   type: 'error',
          //   buttonText: 'אישור',
          // }).open();
          this.messageError = 'אחד או שניים מהפרטים שהזנת שגויים';
          this.errorOnSubmit = true;
        }
      })

    }
    

  }

  packageObject(){
    if (!this.passwordform.valid) {
      // this.errorModalService.setNewError({
      //   title: 'אופס...',
      //   description: 'שכחת למלא שדות חשובים',
      //   type: 'error',
      //   buttonText: 'אישור',
      // }).open();
      this.messageError = 'יש למלא את כל השדות כדי להמשיך';
      this.errorOnSubmit = true;
    } else {
      this.forgotPasswordService.setForgotpasswordObj({
        phone: this.passwordform.controls['phone'].value,
        email: this.passwordform.controls['email'].value
      })
      this._nav.next();
       console.log('ok!')
    }
  }
  

  isAuthUser(phoneNumber, email){
    let data = { phoneNumber, email };
    
    return this.otpService.anonymousStatus(data)
  }


  keydownWithLimit(e, len) {
    if(this.errorOnSubmit)
        this.errorOnSubmit = false;

    this.utilityService.inputLength(e, len);
  }

  keydown(e) {
    if(this.errorOnSubmit)
        this.errorOnSubmit = false;
  }

  closeKeyboard(e) {
    console.log('closeKeyboard ', e);
    if (!e) {
      console.log('false');
      this.cordovaService.closeKeyBoard();
    }
  }
}
