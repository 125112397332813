import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RemindService {
  baseUrl;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.endPoint;
   }

  remindMe(data){
    return this.http.post(`${this.baseUrl}/`, data);
  }
}
