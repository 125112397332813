import { Window } from './../../../../models/global.models';
import { CordovaService } from './../../../../core/services/cordova.service';
import { Injectable } from '@angular/core';
import { ModalService }  from '../../robin-modal/modal.service';

const TIME = 1000;
declare const window;
@Injectable({
  providedIn: 'root'
})
export class ErrorModalService {

  // constructor(private modalService: ModalService,private cordovaService:CordovaService) {  }
  constructor(private modalService: ModalService) {  }
  _title;
  _description;
  _type;
  _buttonText;


  setArrayReference(ref) {
    this._title = ref.title;
    this._description = ref.description;
    this._type = ref.type;
    this._buttonText = ref.buttonText;
  }

  getTitle(){
    return this._title;
  }

  getDesc(){
    return this._description;
  }

  getType(){
    return this._type;
  }

  getButtonText(){
    return this._buttonText;
  }

  open(){
    this.modalService.open('general-error');
    return this;  
  }

  close(){
    this.modalService.close('general-error');
  }
  
  /*
   * title
   * description
   * type ('ok', 'error')
   * buttonText
   */
  setNewError(obj){
    // if(this.cordovaService.isMobile){
    //   window.navigator.vibrate(TIME);
    // }
    this.setArrayReference(obj);
    return this;
  }

}
