import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'robin-risk-level',
  templateUrl: './robin-risk-level.component.html',
  styleUrls: ['./robin-risk-level.component.scss']
})
export class RobinRiskLevelComponent implements OnInit {
  @Input() height;
  @Input() width;
  canvas: any;
  context: any;
  @Input() to: number;
  @ViewChild('ref') canv: ElementRef;

  ngOnInit() { }

  ngAfterViewInit() {
    this.init();
  }
  init() {
    this.canvas = document.getElementById("measureCanvas");
    this.context = this.canvas.getContext("2d");
    let area = this.canvas.getBoundingClientRect();
    let numOfLines = 5;
    let greyColor = '#ECE1CE';
    let blueColor = '#427dfb';
    let margin = 6;
    let start = 180,
      end = (180 - (margin * numOfLines)) / numOfLines;
    for (let i = 1; i < numOfLines + 1; i++) {
      // let to = start + end > 360 ? 360 : start + end;
      let until = i === numOfLines ? start + end + 5 : start + end;
      let color = i <= this.to ? blueColor : greyColor;
      this.drawArc(48, 48, 40, start, until, false, color, null, false);
      start = until + margin;
    }
    this.drawArc(48, 48, 35, 180, 360, false, '', "#D5E3FE", true);
    this.context.font = "24px Arial";
    this.context.fillStyle = blueColor;
    this.context.fillText(this.to, 55, 44);
    this.context.font = "14px Arial";
    this.context.fillStyle = "rgba(127,143,164,1)";
    this.context.fillText("רמת סיכון", 75, 60);
  }

  drawArc(xPos, yPos,
    radius,
    startAngle: number, endAngle: number,
    anticlockwise,
    lineColor, fillColor, fill) {
    var startAngle = startAngle * (Math.PI / 180);
    var endAngle = endAngle * (Math.PI / 180);
    var radius = radius;
    this.context.strokeStyle = lineColor;
    this.context.fillStyle = fillColor;
    this.context.lineWidth = 4;
    this.context.beginPath();
    this.context.arc(xPos, yPos,
      radius,
      startAngle, endAngle,
      anticlockwise);
    if (fill)
      this.context.fill();
    else
      this.context.stroke();
  };
}
