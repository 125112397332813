import { DeeplinkService } from 'src/app/core/services/deeplink.service';
import { environment } from './../../../environments/environment.prod';
import { LoginFailed, LoginActionTypes } from './../../store/user.actions';
import { UtilityService } from './utility.service';
import { style } from '@angular/animations';
import { NavManagerService } from './../../shared/components/robin-navbar/robin-navbar.service';
import { CordovaService } from './cordova.service';
import { Injectable, NgZone } from '@angular/core';
import { LoggerService } from './logger.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { Router } from '@angular/router';
import { AppState, selectAndroidLock } from 'src/app/store/app.states';
import { Store } from '@ngrx/store';
import { DeeplinkSetlink } from 'src/app/redux/deeplink/deeplink.reducer';
import {ReplaySubject} from 'rxjs'
import { BranchService } from './branch.service';
import { RegisterService } from 'src/app/onboarding/services/register/register.service';

declare var window;
@Injectable({
    providedIn: 'root'
})
export class BootstrapService {
    optUserOut() {
        //opt the user out after 20 mins
        let time = 1000 * 60 * 20;
        setTimeout(() => {
            this.router.navigate(['/homepage']);
            this.store.dispatch({ type: LoginActionTypes.LOGIN_FAILURE });
        }, time);
    }
    androidBackButton$ = new ReplaySubject();
    constructor(
        private store: Store<AppState>, 
        private router: Router,
         private utilService: UtilityService, 
         private zone: NgZone, 
         private cordovaService: CordovaService, 
         private loggerService: LoggerService, 
         private navbarService: NavManagerService, 
         private analyticService: AnalyticsService,
         private deeplinkService: DeeplinkService,
         private branchService: BranchService,
         private registerService: RegisterService) {
        loggerService.debug('bootstrap service successfuly initiated...');
 

        this.cordovaService.deviceReady$.subscribe(data => {
            if (environment.production)
                this.optUserOut();

            if (window.MobileAccessibility) {
                window.MobileAccessibility.usePreferredTextZoom(false);
            }
            document.addEventListener("pause", function (e) {
                e.preventDefault();
                console.log('[pause] user quit the app...');
                window.mixpanel.track('[put the app in background] APP - Bounce Rate');
                if (window.location.href.indexOf('onboarding/intro') !== -1) {
                    console.log('[put the app in background] Intro Screens - Bounce Rate');
                    console.debug('[put the app in background] Intro Screens - Bounce Rate');
                    window.plugins.appsFlyer.trackEvent('[put the app in background] Intro Screens - Bounce Rate');
                }
            }, false);

            window.onunload = function (e) {
                e.preventDefault();
                console.log('[onunload] user quit the app...');
                window.mixpanel.track('[removed the app from background] APP - Bounce Rate');
                if (window.location.href.indexOf('onboarding/intro') !== -1) {
                    console.log('[removed the app from background] Intro Screens - Bounce Rate');
                    console.debug('[removed the app from background] Intro Screens - Bounce Rate');
                    window.plugins.appsFlyer.trackEvent('[removed the app from background] Intro Screens - Bounce Rate');
                }
            }


            document.addEventListener("backbutton", function (e) {
                e.preventDefault();
                zone.run(() => {
                    navbarService.prev(false);
                });
                this.androidBackButton$.next();
            }.bind(this), false);



            this.cordovaService.platform = window.device.platform;
            window.open = window.cordova.InAppBrowser.open;

            // window.addEventListener('native.keyboardshow', (e) => {
            //     if (this.cordovaService.platform !== 'iOS') {
            //         // document.activeElement.scrollIntoView();
            //         let els = document.querySelectorAll('input');
            //         //last input element
            //         if (document.activeElement !== els[0] && els.length > 1) {
            //             document.activeElement.scrollIntoView();
            //         }
            //     }
            //     else {
            //     }
            // });

            // window.Keyboard.hideFormAccessoryBar(true, function (a, b) { });
            // window.Keyboard.disableScrollingInShrinkView(true);
            // window.Keyboard.automaticScrollToTopOnHiding = false;
            setTimeout(function () {
                window.navigator.splashscreen.hide();
            }, 1000);

            this.store.select(selectAndroidLock).subscribe((data: any) => {
                console.log('this.cordovaService.availableDeviceFeatures');
                this.cordovaService.availableDeviceFeatures();
            });
            /****
             * 
             * cordova-plugin-ionic-keyboard
             */
            window.addEventListener('keyboardDidShow', (event) => {
                document.querySelector('html').style.height = 'auto';
                // window.Keyboard.shrinkView(true, (a)=>{});
            });
            window.addEventListener('keyboardDidHide', () => {
                document.querySelector('html').style.height = '100%';
                // window.Keyboard.shrinkView(false, (a)=>{});
            });

            window.StatusBar.backgroundColorByHexString('427dfb');

            window.Branch.initSession().then(data => {
                if (data["+clicked_branch_link"]) {
                    this.branchService.link = data["~referring_link"];
                    if (data.state) {
                        let urlToRedirect = '/' + this.navbarService.transformToValidUrl(data.state);
                        console.log('dor4', urlToRedirect);
                        this.store.dispatch(new DeeplinkSetlink(urlToRedirect));
                    }
                    if (data.link) {
                        this.registerService.source = data.link;
                        this.branchService.source = data.link;
                    }
                    const arr = data["~referring_link"].split('/');
                    this.branchService.coupon = arr[arr.length - 1];
                }
            });
        });

    }
}
