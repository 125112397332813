import { NavManagerService } from './../../../../../shared/components/robin-navbar/robin-navbar.service';
import { ModalService } from '../../../../../shared/components/robin-modal/modal.service';
import { LoggerService } from '../../../../../core/services/logger.service';
import { Component, OnInit, ViewChild, ElementRef, NgZone} from '@angular/core';
import { uiStrings } from '../../../../assets/heb.ui_strings';
import { CordovaService } from '../../../../../core/services/cordova.service';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../../../../analytics/analytics.service';
import { ImageCompressService, IImage, ResizeOptions } from 'ng2-image-compress';
import { ImageCaptureService } from 'src/app/onboarding/services/image-capture.service';

@Component({
  selector: 'id-card-main',
  templateUrl: './id-card-main.component.html',
  styleUrls: ['./id-card-main.component.scss']
})
export class IdCardMainComponent implements OnInit {
  isDrawerOpen = false;
  isTipsOpen = false;

  ui_strings = uiStrings.identification.idCard.idCardMain;
  constructor(public cordovaService: CordovaService,
    public router: Router,
    public loggerService: LoggerService,
    public modalService: ModalService,
    private navbarService: NavManagerService,
    private analyticService: AnalyticsService,
    private captureService: ImageCaptureService,
    private zone: NgZone
    ) { }

  isNoIdOpen = false;
  ngOnInit() {
    //User entered to 'Financial Data Collection' process first page
    this.analyticService.trackByMixpanel("User Registration - FDC - Start");
    this.analyticService.trackByAppFlyer("User Registration - FDC - Start", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });
  }

  showDrawer(value) {
    if (this.cordovaService.isMobile) {
      this.isDrawerOpen = value;
    } else {
      this.openCamera();
    }
  }
  showModalTips(value) {
    // this.htmlInsideModal.open();
    this.isTipsOpen = value;
    this.showDrawer(false);
  }
  toggleModal() {
    this.isNoIdOpen = !this.isNoIdOpen;
  }
  showNoIdModal(value) {
    //User pressed "i have no id button"
    this.analyticService.trackByMixpanel("User Registration - FDC - NoID - Click");
    this.analyticService.trackByAppFlyer("User Registration - FDC - NoID - Click", { phoneNumber: this.analyticService.phoneNumber, uuid: this.analyticService.uuid });

    this.modalService.open('noIdPopup');
  }
  closeTipsModal() {
    this.showModalTips(false);
  }
  closeDrawer(event) {
    this.showDrawer(false);
  }

  closeDrawerModal(e) {
    this.showNoIdModal(false);
  }

  navigateTo(route) {
    switch (route) {
      case 'reminder':
        this.router.navigate([`/${route}`]);
        break;
    }
  }



  closeModal() {

  }


  openCamera() {
    let el: any = document.querySelectorAll("#file-input")[1];
    var file = document.getElementById("file-input")

    file.addEventListener('change', (e: any) => {
      console.log('e', e);
      this.compressImage(e.target.files);
      // this.doSomethingWithFiles(e.target.files);
    });
  }

  compressImage(fileInput) {
    let fileList: FileList;

    let images: Array<IImage> = [];

    let option:ResizeOptions = { Resize_Max_Height : 600 , Resize_Max_Width : 600, Resize_Quality:30, Resize_Type : 'image/jpg'  };

    ImageCompressService.filesToCompressedImageSourceEx(fileInput,option).then(observableImages => {
      observableImages.subscribe((image) => {
        images.push(image);
        if (images !== null) {
          // let src = URL.createObjectURL(file);
          this.captureService.idImage.next(images[0].compressedImage.imageDataUrl);
          this.zone.run(() => {
            this.navbarService.next('idcardpreview');
          });
        }
      }, (error) => {
        console.log("Error while converting");
      }, () => {
        // this.processedImages = images;
        // this.showTitle = true;
      });
    });
  }


}
